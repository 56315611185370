import { useContext } from "react"

import {
  Box,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import clsx from "clsx"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { UTCDateInJST } from "utils/tools"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage } from "./styles"

const SCALE_BOX_WIDTH = 130

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    td: {
      "&:not(:first-of-type)": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  "& td": {
    border: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    "&:first-of-type": {
      width: theme.spacing(2),
      padding: 0,
      "&.focus": {
        backgroundColor: theme.palette.common.green,
      },
    },
    "&:nth-of-type(3)": {
      paddingLeft: theme.spacing(4),
    },
  },
}))

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  "& th": {
    border: 0,
    padding: "0px 16px",
    "&:first-of-type": {
      padding: 0,
      width: theme.spacing(3),
    },
  },
}))

const CompetingEventsOverview = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const competingGame = playDataEventReportData.charts.competingEventsOverview

  const { t, i18n } = useTranslation("playdata")

  const theme = useTheme()

  const localisedLabel = useLocalisedLabel()

  return (
    <>
      {competingGame.map((data) => {
        const { maxPCU, maxACU, maxHoursWatched, maxBroadcasts } = data.events.reduce(
          (acc, curr) => {
            return {
              maxPCU: Math.max(acc.maxPCU, curr.summary.pcu),
              maxACU: Math.max(acc.maxACU, curr.summary.acu),
              maxHoursWatched: Math.max(acc.maxHoursWatched, curr.summary.hoursWatched),
              maxBroadcasts: Math.max(acc.maxBroadcasts, curr.summary.dateCount),
            }
          },
          { maxPCU: 0, maxACU: 0, maxHoursWatched: 0, maxBroadcasts: 0 }
        )
        return (
          <BorderedPage key={data.game.id}>
            <Stack
              height="100%"
              justifyContent="space-between"
            >
              <Stack gap={4}>
                <Header title={t("sectionTitles.competingEventsByGame")} />
                <Typography
                  fontSize="32px"
                  fontWeight="900"
                  px={6}
                  textTransform="uppercase"
                >
                  {localisedLabel(data.game)}
                </Typography>
              </Stack>
              <Box
                flex={1}
                sx={{
                  width: "100%",
                  flex: 1,
                  paddingLeft: theme.spacing(3),
                  paddingRight: theme.spacing(6),
                  marginTop: theme.spacing(5),
                }}
              >
                <TableContainer>
                  <Table sx={{ borderCollapse: "separate", borderSpacing: `0 ${theme.spacing(1)}` }}>
                    <TableHead>
                      <StyledTableHeaderRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ width: "141px", padding: 0 }}></TableCell>
                        <TableCell width="800px">
                          <Typography
                            fontSize="16px"
                            pl={2}
                          >
                            {t("competingEventsByGameColumn")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="16px"
                            noWrap
                            mr={8}
                          >
                            {t("sectionTitles.pcu")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="16px"
                            noWrap
                            mr={8}
                          >
                            {t("sectionTitles.acu")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="16px"
                            noWrap
                            mr={8}
                          >
                            {t("sectionTitles.hoursWatched")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            fontSize="16px"
                            noWrap
                            mr={8}
                          >
                            {t("sectionTitles.broadcasts")}
                          </Typography>
                        </TableCell>
                      </StyledTableHeaderRow>
                    </TableHead>
                    <TableBody>
                      {[...data.events]
                        .sort((a, b) => b.summary.hoursWatched - a.summary.hoursWatched)
                        .map((event) => (
                          <StyledTableRow key={event.name}>
                            <TableCell
                              className={clsx({
                                focus: event.id === playDataEventReportData.id,
                              })}
                            ></TableCell>
                            <TableCell
                              sx={{
                                padding: 0,
                                backgroundImage: `url(${
                                  event.imageUrl || "/images/taiyoro-visual-fallback.png"
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></TableCell>
                            <TableCell>
                              <Link
                                fontSize="24px"
                                fontWeight={event.id === playDataEventReportData.id ? "700" : "300"}
                                noWrap
                                target="_blank"
                                href={`https://taiyoro.gg/${i18n.language}/event/${event.id}`}
                                underline="none"
                                color="text.primary"
                                textOverflow="ellipsis"
                                display="block"
                              >
                                {event.name}
                              </Link>
                              <Stack
                                direction="row"
                                gap={1}
                              >
                                <Typography
                                  fontSize="16px"
                                  fontWeight="300"
                                >
                                  {`${UTCDateInJST(event.startDatetime).format(
                                    "YYYY.MM.DD"
                                  )} ~ ${UTCDateInJST(event.lastStartDatetime).format("YYYY.MM.DD")}`}
                                </Typography>
                                {event.summary.finishedDateCount !== event.summary.dateCount && (
                                  <Typography
                                    fontSize="16px"
                                    fontWeight="300"
                                  >
                                    {t("ongoing")}
                                  </Typography>
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Box
                                width={SCALE_BOX_WIDTH}
                                height="32px"
                              >
                                <Box
                                  bgcolor={event.summary.pcu === maxPCU ? "common.pink" : "grey.600"}
                                  width={`${(event.summary.pcu / maxPCU) * 100}%`}
                                  height="32px"
                                />
                              </Box>
                              <Typography
                                fontSize="16px"
                                fontWeight="300"
                              >
                                {event.summary.pcu.toLocaleString()}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                width={SCALE_BOX_WIDTH}
                                height="32px"
                              >
                                <Box
                                  bgcolor={event.summary.acu === maxACU ? "common.pink" : "grey.600"}
                                  width={`${(event.summary.acu / maxACU) * 100}%`}
                                  height="32px"
                                />
                              </Box>
                              <Typography
                                fontSize="16px"
                                fontWeight="300"
                              >
                                {event.summary.acu.toLocaleString()}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                width={SCALE_BOX_WIDTH}
                                height="32px"
                              >
                                <Box
                                  bgcolor={
                                    event.summary.hoursWatched === maxHoursWatched
                                      ? "common.pink"
                                      : "grey.600"
                                  }
                                  width={`${(event.summary.hoursWatched / maxHoursWatched) * 100}%`}
                                  height="32px"
                                />
                              </Box>
                              <Typography
                                fontSize="16px"
                                fontWeight="300"
                              >
                                {event.summary.hoursWatched.toLocaleString()}
                              </Typography>
                            </TableCell>
                            <TableCell width={SCALE_BOX_WIDTH}>
                              <Box
                                width={SCALE_BOX_WIDTH}
                                height="32px"
                              >
                                <Box
                                  bgcolor={
                                    event.summary.dateCount === maxBroadcasts ? "common.pink" : "grey.600"
                                  }
                                  width={`${(event.summary.dateCount / maxBroadcasts) * 100}%`}
                                  height="32px"
                                />
                              </Box>

                              <Stack
                                direction="row"
                                alignItems="flex-end"
                              >
                                <Typography
                                  fontSize="16px"
                                  fontWeight="300"
                                >
                                  {event.summary.finishedDateCount.toLocaleString()}
                                </Typography>
                                {event.summary.dateCount !== event.summary.finishedDateCount && (
                                  <Typography
                                    fontSize="12px"
                                    fontWeight="300"
                                    position="relative"
                                    top="-1px"
                                  >
                                    {"/" + event.summary.dateCount.toLocaleString()}
                                  </Typography>
                                )}
                              </Stack>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Stack gap={4}>
                <Disclaimer>
                  <Stack gap={1}>
                    <Typography fontSize="14px">{t("competingEventsByGameDisclaimer.line1")}</Typography>
                    <Typography fontSize="14px">{t("competingEventsByGameDisclaimer.line2")}</Typography>
                    <Typography fontSize="14px">{t("competingEventsByGameDisclaimer.line3")}</Typography>
                  </Stack>
                </Disclaimer>
                <Footer />
              </Stack>
            </Stack>
          </BorderedPage>
        )
      })}
    </>
  )
}

export default CompetingEventsOverview
