import type SignificantPlayer from "models/Taiyoro/Meta/SignificantPlayer"
import type Team from "models/Taiyoro/Meta/Team"

import type Caster from "../../models/Taiyoro/Meta/Caster"
import type Producer from "../../models/Taiyoro/Meta/Producer"
import EventSponsor from "../../models/Taiyoro/Sponsor"
import type { ContributionType, PriorityType } from "../../models/Taiyoro/Sponsor"
import EventStream, { RecoveryStatus } from "../../models/Taiyoro/Stream"
import Event from "../../models/Taiyoro/event"
import type { AnalyticsData, EventTier, RegistrationType } from "../../models/Taiyoro/event"
import EventDate, { LocalMomentFromUTCDateTime } from "../../models/Taiyoro/eventDate"
import GraphQL, { stringifyObjectForPayload } from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

const fetchEndpoint = process.env.REACT_APP_QUERYAPI || "http://localhost:3999/api/query"

export interface ApiEventResponse {
  id: string
  name: string
  urlSlug: string
  taiyoroRating: number
  budget?: number
  currency: string
  eventUrl?: string
  tier: EventTier
  twitterHandle: string
  hashtag: string
  imageUrl?: string
  description?: string
  origin?: string
  notes?: string
  registrationUrl?: string
  registrationType?: RegistrationType
  registrationLimit?: number
  registrationEnabled: number
  registrationStartDatetime: string
  registrationEndDatetime: string
  owner: string | null
  lastUpdater?: string
  createdAt: string
  published: 0 | 1
  games: {
    gameId: string
    prizePool?: number
    currency: string
    resultsUrl?: string
    participantCount: null | number
    participants: {
      teams: Array<Team>
      players: Array<SignificantPlayer>
    }
  }[]
  tagIds: string[]
  organizerIds: string[]
  dates: {
    id: string
    name: string
    startDatetime: string
    endDatetime: string
    isEstimated: 0 | 1
    attendees?: number
    isOffline: 0 | 1
    venueId?: string
    gameId?: string
    isCancelled: 0 | 1
    isHidden: 0 | 1
    analytics: AnalyticsData
    taiyoroRating: number
    streams: {
      id: string
      platformId: string
      url: string
      viewsLiveUnique: number | null
      viewsLiveTotal: number | null
      viewsLiveConcurrentPeak: number | null
      viewsLiveMinutesWatched: number | null
      viewsLiveConcurrentAverage: number | null
      liveBroadcastDuration: number | null
      recoveryStatus: RecoveryStatus
      isVod: 0 | 1
      views1Week: number | null
      viewsWeek2: number | null
      viewsWeek3: number | null
      viewsWeek4: number | null
      views1Month: number | null
      language: string
      isDataCollectionScheduled: 0 | 1
      dataCollectionError: 0 | 1
      viewsLiveConcurrentPeakManual: number | null
      viewsLiveMinutesWatchedManual: number | null
      viewsLiveConcurrentAverageManual: number | null
      views1WeekManual: number | null
      viewsWeek2Manual: number | null
      viewsWeek3Manual: number | null
      viewsWeek4Manual: number | null
      automaticallyGenerated: number | null
      automaticallyGenerateVods: number | null
      isSubStream: number | null
      channelId: string | null
      channelIconUrl: string | null
      channelDisplayName: string | null
      liveBroadcastStartDatetime: string | null
      sourceStreamDatePlatformId: string | null
    }[]
  }[]
  sponsors: {
    sponsorId: string
    revenue: number
    currency: string
    contributionTypes: ContributionType[]
    priority: PriorityType
    dateIds: string[]
    allDates: 0 | 1
  }[]
  producers?: Producer[]
  casters?: Caster[]
  analytics: AnalyticsData
}

export const fetchEvent = async (id: string): Promise<Event> => {
  return GraphQL(
    `query {
          admin {
              event {
                  get(
                      eventId: "${id}"
                      activeFrom: ""
                      activeTo: ""
                  ) {
                      resultCount
                      results {
                        id
                        name
                        urlSlug
                        budget
                        currency
                        eventUrl
                        tier
                        twitterHandle
                        hashtag
                        imageUrl
                        description
                        origin
                        notes
                        registrationUrl
                        registrationType
                        registrationLimit
                        registrationEnabled
                        registrationStartDatetime
                        registrationEndDatetime
                        owner
                        lastUpdater
                        createdAt
                        published
                        taiyoroRating
                        analytics {
                          viewsLiveMinutesWatched
                          viewsLiveConcurrentAverage
                          viewsLiveConcurrentPeak
                          averageDailyMinutesWatched
                        }
                        producers {
                          id
                          name
                          nameJa
                          sortJa
                          isGameDeveloper
                        }
                        casters {
                          id
                          name
                          nameJa
                          sortJa
                          primaryImage
                        }
                        games {
                            gameId
                            prizePool
                            currency
                            resultsUrl
                            participantCount
                            participants {
                              players {
                                player {
                                  id
                                  name
                                  nameJa
                                  sortJa
                                  primaryImage
                                  teamOrganization {
                                    id
                                    name
                                    nameJa
                                    sortJa
                                    primaryImage
                                    secondaryImage
                                  }
                                }
                                quantifier
                                placement {
                                  id
                                  name
                                  nameJa
                                  sortJa
                                  quantifiable
                                  quantifier
                                }
                              }
                              teams {
                                team {
                                  id
                                  name
                                  nameJa
                                  sortJa
                                  primaryImage
                                  secondaryImage
                                  teamOrganization {
                                    id
                                    name
                                    nameJa
                                    sortJa
                                    primaryImage
                                    secondaryImage
                                  }
                                }
                                quantifier
                                placement {
                                  id
                                  name
                                  nameJa
                                  sortJa
                                  quantifiable
                                  quantifier
                                }
                              }
                            }
                          }
                          tagIds
                          organizerIds
                          dates {
                              id
                              name
                              startDatetime
                              endDatetime
                              isEstimated
                              attendees
                              isOffline
                              venueId
                              gameId
                              taiyoroRating
                              streams {
                                  id
                                  platformId
                                  url
                                  viewsLiveUnique
                                  viewsLiveTotal
                                  viewsLiveConcurrentPeak
                                  viewsLiveMinutesWatched
                                  viewsLiveConcurrentAverage
                                  liveBroadcastDuration
                                  isVod
                                  views1Week
                                  viewsWeek2
                                  viewsWeek3
                                  viewsWeek4
                                  views1Month
                                  language
                                  isDataCollectionScheduled
                                  recoveryStatus
                                  dataCollectionError
                                  viewsLiveConcurrentPeakManual
                                  viewsLiveMinutesWatchedManual
                                  viewsLiveConcurrentAverageManual
                                  views1WeekManual
                                  viewsWeek2Manual
                                  viewsWeek3Manual
                                  viewsWeek4Manual
                                  automaticallyGenerated
                                  automaticallyGenerateVods
                                  isSubStream
                                  channelId
                                  channelIconUrl
                                  channelDisplayName
                                  liveBroadcastStartDatetime
                                  sourceStreamDatePlatformId
                              }
                              analytics {
                                viewsLiveMinutesWatched
                                viewsLiveConcurrentAverage
                                viewsLiveConcurrentPeak
                                averageDailyMinutesWatched
                              }
                              isCancelled
                              isHidden
                          }
                          sponsors {
                              sponsorId
                              revenue
                              currency
                              contributionTypes
                              priority
                              dateIds
                              allDates
                          }
                        }
                    }
                }
            }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => ModelFromApiResponse(response.data.admin.event.get.results[0]))
}

export const fetchDatelessEvents = async (): Promise<any> => {
  let response = await GraphQL(
    `query {
        admin {
            event {
                get(limit: 100, offset: 0) {
                    results {
                      id
                      name
                    }
                }
            }
        }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return response.data.admin.event.get.results
}

export const fetchEventList = async (params): Promise<any> => {
  const { from, to, filterTags } = params
  const activeFrom = from ? `activeFrom: "${from.clone().utc().format("YYYY-MM-DDTHH:mm:ss")}", ` : ""
  const activeTo = to ? `activeTo: "${to.clone().utc().format("YYYY-MM-DDTHH:mm:ss")}", ` : ""
  const filterTagsQuery = filterTags
    ? `filterTagIds: ${JSON.stringify(filterTags.map((filterTag) => filterTag.id))}, `
    : ""
  let response = await GraphQL(
    `query {
        admin {
            event {
              getMinimal(${activeFrom}${activeTo}${filterTagsQuery}limit: ${1000}, offset: ${0}) {
                    results {
                      id
                      name
                      currency
                      origin
                      published
                      notes
                      analytics {
                        viewsLiveMinutesWatched
                        viewsLiveConcurrentAverage
                        viewsLiveConcurrentPeak
                        averageDailyMinutesWatched
                      }
                      games {
                          gameId
                      }
                      dates {
                          id
                          startDatetime
                          endDatetime
                      }
                      tags {
                        id
                        name
                        nameJa
                        sortJa
                      }
                    }
                }
            }
        }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return response.data.admin.event.getMinimal.results
}

export const fetchEvents = async (params): Promise<any> => {
  const { from, to, offset, limit } = params
  const activeFrom = from ? `activeFrom: "${from.clone().utc().format("YYYY-MM-DDTHH:mm:ss")}", ` : ""
  const activeTo = to ? `activeTo: "${to.clone().utc().format("YYYY-MM-DDTHH:mm:ss")}", ` : ""
  let response = await GraphQL(
    `query {
        admin {
            event {
                get(${activeFrom}${activeTo}limit: ${limit}, offset: ${offset}) {
                    resultCount
                    results {
                      id
                      name
                      urlSlug
                      taiyoroRating
                      budget
                      currency
                      eventUrl
                      tier
                      twitterHandle
                      hashtag
                      imageUrl
                      description
                      origin
                      notes
                      registrationUrl
                      registrationType
                      registrationLimit
                      registrationEnabled
                      registrationStartDatetime
                      registrationEndDatetime
                      owner
                      lastUpdater
                      createdAt
                      published
                      analytics {
                        viewsLiveMinutesWatched
                        viewsLiveConcurrentAverage
                        viewsLiveConcurrentPeak
                        averageDailyMinutesWatched
                      }
                      producers {
                        id
                        name
                        nameJa
                        sortJa
                        isGameDeveloper
                      }
                      casters {
                        id
                        name
                        nameJa
                        sortJa
                        primaryImage
                      }
                      games {
                          gameId
                          prizePool
                          currency
                          resultsUrl
                          participantCount

                        }
                      tagIds
                      organizerIds
                      dates {
                          id
                          name
                          startDatetime
                          endDatetime
                          attendees
                          venueId
                          gameId
                          isOffline
                          isEstimated
                          analytics {
                            viewsLiveMinutesWatched
                            viewsLiveConcurrentAverage
                            viewsLiveConcurrentPeak
                            averageDailyMinutesWatched
                          }
                          streams {
                              id
                              platformId
                              url
                              viewsLiveUnique
                              viewsLiveTotal
                              viewsLiveConcurrentPeak
                              viewsLiveConcurrentAverage
                              viewsLiveMinutesWatched
                              liveBroadcastDuration
                              recoveryStatus
                              isVod
                              views1Week
                              viewsWeek2
                              viewsWeek3
                              viewsWeek4
                              views1Month
                              language
                              isDataCollectionScheduled
                              dataCollectionError
                              viewsLiveConcurrentPeakManual
                              viewsLiveMinutesWatchedManual
                              viewsLiveConcurrentAverageManual
                              views1WeekManual
                              viewsWeek2Manual
                              viewsWeek3Manual
                              viewsWeek4Manual
                              automaticallyGenerated
                              automaticallyGenerateVods
                              isSubStream
                          }
                          isCancelled
                          isHidden
                      } sponsors {
                          sponsorId
                          revenue
                          currency
                          contributionTypes
                          priority
                          dateIds
                          allDates
                      }
                    }
                }
            }
        }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return {
    events: response.data.admin.event.get.results.map((e: ApiEventResponse) => ModelFromApiResponse(e) || []),
    total: response.data.admin.event.get.resultCount || 0,
  }
}

export const editEvent = (
  id: string,
  field: string,
  value: any,
  noStringify?: boolean
): Promise<any | null> => {
  const fieldValue = noStringify ? value : JSON.stringify(value)
  const query = `mutation {
    admin {
      event {
        save (event: {
          id: ${JSON.stringify(id)}
          ${field}: ${fieldValue}
        }){
          ${field}
        }
      }
    }
  }`

  return GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const createEvent = async (event: Event) => {
  const query = `mutation {
    admin {
      event {
        save (event: {
          ${event.id ? `id: "${event.id}"` : ""}
          name: ${JSON.stringify(event.name)}
          urlSlug: ${JSON.stringify(event.urlSlug)}
          budget: ${event.budget !== null && event.budget !== "" ? `"${event.budget}"` : "null"}
          currency: "${event.currency}"
          eventUrl: ${event.eventUrl !== null && event.eventUrl !== "" ? `"${event.eventUrl}"` : "null"}
          imageUrl: ${event.imageUrl !== null && event.imageUrl !== "" ? `"${event.imageUrl}"` : "null"}
          description: ${JSON.stringify(event.description)}
          origin: ${JSON.stringify(event.origin)}
          published: ${event.published}
          notes: ${JSON.stringify(event.notes)}
        }){
          id
          name
        }
      }
    }
  }`

  let response = await GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (response.data && response.data.admin && response.data.admin.event) || null
}

export const deleteEvent = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        event {
          delete (
            eventId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.delete &&
      response.data.admin.event.delete) ||
    null
  )
}

export const deleteDatePlatform = async (id) => {
  let response = await GraphQL(
    `mutation {
      admin {
        event {
          deleteDatePlatform (
            datePlatformId: "${id}"
          )
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteDatePlatform &&
      response.data.admin.event.deleteDatePlatform[0]) ||
    null
  )
}

export const deleteDate = async (id) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteDate (
              dateId: "${id}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteDate &&
      response.data.admin.event.deleteDate[0]) ||
    null
  )
}

export const saveDate = async (eventId, date) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            saveDate (
              date: {
                ${date.id ? `id: "${date.id}"` : ""}
                name: "${date.name}"
                eventId: "${eventId}"
                gameId: "${date.game}"
                startDatetime: "${date.startTime.clone().utc().format("YYYY-MM-DDTHH:mm:ss")}"
                endDatetime: "${date.endTime.clone().utc().format("YYYY-MM-DDTHH:mm:ss")}"
                attendees: ${date.attendees ? date.attendees : null}
                venueId: ${date.venue ? `"${date.venue}"` : null}
                isOffline: ${date.offline}
                isCancelled: ${date.cancelled}
                isEstimated: ${date.estimated}
                isHidden: ${date.hidden}
              }
            )
            {
              id
              name
              gameId
              startDatetime
              endDatetime
              attendees
              venueId
              isOffline
              isCancelled
              isEstimated
              isHidden
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.saveDate &&
      response.data.admin.event.saveDate) ||
    null
  )
}

export const editDate = (dateId, updates) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            saveDate (
              date: {
                id: ${JSON.stringify(dateId)}
                ${Object.keys(updates).reduce(
                  (acc, curr: string) => `${acc} ${curr}: ${JSON.stringify(updates[curr])}`,
                  ""
                )}
              }
            )
            {
              id
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  // return (
  //   (response.data &&
  //     response.data.admin &&
  //     response.data.admin.event &&
  //     response.data.admin.event.saveDate &&
  //     response.data.admin.event.saveDate) ||
  //   null
  // );
}

export const saveDatePlatform = async (dateId, platform) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            saveDatePlatform (
              datePlatform: {
                ${platform.id ? `id: "${platform.id}"` : ""}
                dateId: "${dateId}"
                platformId: "${platform.platform}"
                url: "${platform.url}"
                isVod: ${platform.isVod}
                language: "${platform.language}"
                viewsLiveUnique: ${platform.viewsLiveUnique ? platform.viewsLiveUnique : null}
                viewsLiveTotal: ${platform.viewsLiveTotal ? platform.viewsLiveTotal : null}
                viewsLiveConcurrentPeak: ${
                  platform.viewsLiveConcurrentPeak ? platform.viewsLiveConcurrentPeak : null
                }
                viewsLiveMinutesWatched: ${
                  platform.viewsLiveMinutesWatched ? platform.viewsLiveMinutesWatched : null
                }
                liveBroadcastDuration: ${
                  platform.liveBroadcastDuration ? platform.liveBroadcastDuration : null
                }
                viewsLiveConcurrentAverage: ${
                  platform.viewsLiveConcurrentAverage ? platform.viewsLiveConcurrentAverage : null
                }
                views1Week: ${platform.views1Week ? platform.views1Week : null}
                viewsWeek2: ${platform.viewsWeek2 ? platform.viewsWeek2 : null}
                viewsWeek3: ${platform.viewsWeek3 ? platform.viewsWeek3 : null}
                viewsWeek4: ${platform.viewsWeek4 ? platform.viewsWeek4 : null}
                views1Month: ${platform.views1Month ? platform.views1Month : null}
                viewsLiveConcurrentPeakManual: ${
                  platform.viewsLiveConcurrentPeakManual ? platform.viewsLiveConcurrentPeakManual : null
                }
                viewsLiveMinutesWatchedManual: ${
                  platform.viewsLiveMinutesWatchedManual ? platform.viewsLiveMinutesWatchedManual : null
                }
                viewsLiveConcurrentAverageManual: ${
                  platform.viewsLiveConcurrentAverageManual ? platform.viewsLiveConcurrentAverageManual : null
                }
                views1WeekManual: ${platform.views1WeekManual ? platform.views1WeekManual : null}
                viewsWeek3Manual: ${platform.viewsWeek3Manual ? platform.viewsWeek3Manual : null}
                viewsWeek4Manual: ${platform.viewsWeek4Manual ? platform.viewsWeek4Manual : null}
                automaticallyGenerateVods: ${
                  platform.automaticallyGenerateVods ? platform.automaticallyGenerateVods : 0
                }
                isSubStream: ${platform.isSubStream ? platform.isSubStream : 0}
                recoveryStatus: ${platform.recoveryStatus ? platform.recoveryStatus : RecoveryStatus.NOT_SET}
              }   
            ) {
              id
              dateId
              platformId
              url
              viewsLiveUnique
              viewsLiveTotal
              viewsLiveConcurrentPeak
              viewsLiveMinutesWatched
              liveBroadcastDuration
              viewsLiveConcurrentAverage
              recoveryStatus
              isVod
              views1Week
              viewsWeek2
              viewsWeek3
              viewsWeek4
              views1Month
              language
              dataCollectionError
              isDataCollectionScheduled
              viewsLiveConcurrentPeakManual
              viewsLiveMinutesWatchedManual
              viewsLiveConcurrentAverageManual
              views1WeekManual
              viewsWeek2Manual
              viewsWeek3Manual
              viewsWeek4Manual
              automaticallyGenerateVods
              automaticallyGenerated
              isSubStream
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      (response.data &&
        response.data.admin &&
        response.data.admin.event &&
        response.data.admin.event.saveDatePlatform &&
        response.data.admin.event.saveDatePlatform) ||
      null
    )
  })
}

export const editDatePlatform = (id, updates, noStringify?: boolean) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            saveDatePlatform (
              datePlatform: {
                id: ${JSON.stringify(id)}
                ${Object.keys(updates).reduce(
                  (acc, curr: string) =>
                    `${acc} ${curr}: ${noStringify ? updates[curr] : JSON.stringify(updates[curr])}`,
                  ""
                )}
              }
            ) {
              id
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const saveEventGame = async (id, eventGame) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            saveEventGame (
              eventGame: {
                eventId: "${id}"
                gameId: "${eventGame.id}"
                winnerTeamId: ${eventGame.winner ? `"${eventGame.winner.id}"` : null}
                winnerSignificantPlayerId: ${
                  eventGame.winnerSignificantPlayer ? `"${eventGame.winnerSignificantPlayer.id}"` : null
                }
                prizePool: ${eventGame.prizePool ? `"${eventGame.prizePool}"` : null}
                participantCount: ${eventGame.participantCount ? eventGame.participantCount : null}
                currency: "${eventGame.currency}"
                resultsUrl: ${eventGame.resultsUrl ? `"${eventGame.resultsUrl}"` : `""`}
              }
            ) {
              eventId
              gameId
              winnerTeamId
              winnerSignificantPlayerId
              prizePool
              currency
              resultsUrl
              participantCount
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.saveEventGame &&
      response.data.admin.event.saveEventGame[0]) ||
    null
  )
}

export const editEventGame = (eventId, gameId, updates) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            saveEventGame (
              eventGame: {
                eventId: ${JSON.stringify(eventId)}
                gameId: ${JSON.stringify(gameId)}
                ${Object.keys(updates).reduce(
                  (acc, curr: string) => `${acc} ${curr}: ${JSON.stringify(updates[curr])}`,
                  ""
                )}
              }
            ) {
              eventId
              gameId
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteEventGame = async (eventId, gameId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteEventGame (
              eventId: "${eventId}"
              gameId: "${gameId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteEventGame &&
      response.data.admin.event.deleteEventGame[0]) ||
    null
  )
}

export const deleteEventGameTeam = async (eventId, gameId, teamId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteEventGameTeam (
              eventId: "${eventId}"
              gameId: "${gameId}"
              teamId: "${teamId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteEventGameTeam &&
      response.data.admin.event.deleteEventGameTeam[0]) ||
    null
  )
}

export const addEventGameTeam = (eventId, gameId, teamId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            addEventGameTeam (
              eventId: "${eventId}"
              gameId: "${gameId}"
              teamId: "${teamId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteEventOrganizer = (eventId, organizerId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            deleteEventOrganizer (
              eventId: "${eventId}"
              organizerId: "${organizerId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const addEventOrganizer = (eventId, organizerId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            addEventOrganizer (
              eventId: "${eventId}"
              organizerId: "${organizerId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const addEventProducer = (eventId, producerId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            addEventProducer (
              eventId: "${eventId}"
              producerId: "${producerId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteEventProducer = async (eventId, producerId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteEventProducer (
              eventId: "${eventId}"
              producerId: "${producerId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteEventProducer) ||
    null
  )
}

export const addDateSponsor = (dateId, sponsorId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            addDateSponsor (
              dateId: "${dateId}"
              sponsorId: "${sponsorId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteDateSponsor = (dateId, sponsorId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            deleteDateSponsor (
              dateId: "${dateId}"
              sponsorId: "${sponsorId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const saveEventSponsor = async (eventId, eventSponsor) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            saveEventSponsor (
              eventSponsor: {
                eventId: "${eventId}"
                sponsorId: "${eventSponsor.id}"
                revenue: ${eventSponsor.revenue ? `"${eventSponsor.revenue}"` : null}
                currency: "${eventSponsor.currency}"
                priority: "${eventSponsor.priority}"
                allDates: ${eventSponsor.allDates ? 1 : 0}
              }
            ) {
              eventId
              sponsorId
              revenue
              currency
              contributionTypes
              priority
              allDates
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.saveEventSponsor) ||
    null
  )
}

export const deleteEventSponsor = async (eventId, sponsorId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteEventSponsor (
              eventId: "${eventId}"
              sponsorId: "${sponsorId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteEventSponsor) ||
    null
  )
}

export const editEventSponsor = (
  eventId: string,
  sponsorId: string,
  field: string,
  value: any
): Promise<any | null> => {
  const query = `mutation {
    admin {
      event {
        saveEventSponsor (
          eventSponsor: {
            eventId: ${JSON.stringify(eventId)}
            sponsorId: ${JSON.stringify(sponsorId)}
            ${field}: ${JSON.stringify(value)}
          }
        ) {
          eventId
          sponsorId
          ${field}
        }
      }
    }
  }`

  return GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const addEventSponsorContributionType = (
  eventId: string,
  sponsorId: string,
  contributionType: ContributionType
): Promise<any | null> => {
  const query = `mutation {
    admin {
      event {
        addEventSponsorContributionType (
            eventId: ${JSON.stringify(eventId)}
            sponsorId: ${JSON.stringify(sponsorId)}
            contributionType: ${JSON.stringify(contributionType)}
        )
      }
    }
  }`

  return GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteEventSponsorContributionType = (
  eventId: string,
  sponsorId: string,
  contributionType: ContributionType
): Promise<any | null> => {
  const query = `mutation {
    admin {
      event {
        deleteEventSponsorContributionType (
            eventId: ${JSON.stringify(eventId)}
            sponsorId: ${JSON.stringify(sponsorId)}
            contributionType: ${JSON.stringify(contributionType)}
        )
      }
    }
  }`

  return GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}
export type StreamStat = { datetime: string; concurrentViewers: number }

export const fetchDatePlatformStreamStats = async (datePlatformId: string): Promise<Array<StreamStat>> =>
  GraphQL(
    `query {
      admin {
        datePlatform  {
              stats(
                  id: "${datePlatformId}"
              ) {
                  datetime
                  concurrentViewers
              }
          }
      }
  }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.admin.datePlatform.stats)

export const fetchDatePlatform = async (datePlatformId) => {
  let response = await GraphQL(
    `query {
      admin {
        datePlatform  {
              stats(
                  id: "${datePlatformId}"
              ) {
                  datetime
                  concurrentViewers
              }
          }
      }
  }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.datePlatform &&
      response.data.admin.datePlatform.stats) ||
    []
  )
}

export const fetchDatePlatformClips = async (datePlatformId) => {
  let response = await GraphQL(
    `query {
      admin {
        datePlatform  {
              getClips(datePlatformId: "${datePlatformId}"){
                id
                datePlatformId
                thirdPartyId
                thumbnailUrl
                url
                embedUrl
                broadcasterName
                creatorName
                language
                title
                viewCount
                clipCreatedAt
                durationSeconds
                extraMeta
            }
          }
      }
  }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.datePlatform &&
      response.data.admin.datePlatform.getClips) ||
    []
  )
}

export const searchFeedAndMeta = async (searchString: string) => {
  return GraphQL(
    `query {
      admin {
        event  {
              searchFeedAndMeta(searchString: "${searchString}") {
                  feed {
                    name
                    event {
                      id
                      name
                    }
                  }
                  games {
                    id
                    name
                    nameJa
                  }
                  teams {
                    id
                    name
                    nameJa
                  }
                  platforms {
                    id
                    name
                    nameJa
                  }
                  gameGroups {
                    id
                    name
                    nameJa
                  }
                  producers {
                    id
                    name
                    nameJa
                  }
                  organizers {
                    id
                    name
                    nameJa
                  }
                  casters {
                    id
                    name
                    nameJa
                  }
                  significantPlayers {
                    id
                    name
                    nameJa
                  }
                  sponsors {
                    id
                    name
                    nameJa
                  }
                  tags {
                    id
                    name
                    nameJa
                  }
                  teamOrganizations {
                    id
                    name
                    nameJa
                  }
                  venues {
                    id
                    name
                    nameJa
                  }
              }
          }
      }
  }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    try {
      return response.data.admin.event.searchFeedAndMeta
    } catch {
      throw new Error()
    }
  })
}

export const ModelFromApiResponse = (resp: ApiEventResponse): Event => {
  return new Event(
    resp.id,
    resp.name,
    resp.urlSlug,
    resp.taiyoroRating,
    // @ts-ignore
    resp.budget,
    resp.currency,
    resp.eventUrl,
    resp.tier,
    resp.twitterHandle,
    resp.hashtag,
    resp.imageUrl,
    resp.description,
    resp.origin,
    resp.published,
    resp.dates.map(
      (date) =>
        new EventDate(
          date.id,
          date.name,
          LocalMomentFromUTCDateTime(date.startDatetime),
          LocalMomentFromUTCDateTime(date.endDatetime),
          date.gameId,
          date.venueId,
          date.isOffline,
          date.attendees,
          date.streams.map(
            (stream) =>
              new EventStream(
                stream.id,
                stream.platformId,
                stream.url,
                stream.isVod,
                stream.language,
                stream.isDataCollectionScheduled,
                stream.recoveryStatus,
                stream.viewsLiveUnique,
                stream.viewsLiveTotal,
                stream.viewsLiveConcurrentPeak,
                stream.viewsLiveMinutesWatched,
                stream.liveBroadcastDuration,
                stream.views1Week,
                stream.viewsWeek2,
                stream.viewsWeek3,
                stream.viewsWeek4,
                stream.views1Month,
                stream.viewsLiveConcurrentAverage,
                stream.dataCollectionError,
                stream.viewsLiveConcurrentPeakManual,
                stream.viewsLiveMinutesWatchedManual,
                stream.viewsLiveConcurrentAverageManual,
                stream.views1WeekManual,
                stream.viewsWeek2Manual,
                stream.viewsWeek3Manual,
                stream.viewsWeek4Manual,
                stream.automaticallyGenerateVods,
                stream.automaticallyGenerated,
                stream.isSubStream,
                stream.channelId,
                stream.channelIconUrl,
                stream.channelDisplayName,
                stream.liveBroadcastStartDatetime,
                stream.sourceStreamDatePlatformId
              )
          ),
          date.isEstimated,
          date.isCancelled,
          date.isHidden,
          date.analytics,
          date.taiyoroRating
        )
    ),
    resp.games,
    resp.organizerIds,
    resp.sponsors.map(
      (sponsor) =>
        new EventSponsor(
          sponsor.sponsorId,
          "",
          sponsor.revenue,
          sponsor.currency,
          sponsor.contributionTypes,
          sponsor.priority,
          sponsor.allDates,
          sponsor.dateIds
        )
    ),
    resp.producers,
    resp.casters,
    resp.tagIds,
    resp.notes,
    resp.registrationUrl,
    resp.registrationType,
    resp.registrationLimit,
    resp.registrationEnabled ? resp.registrationEnabled : 0,
    resp.registrationStartDatetime ? LocalMomentFromUTCDateTime(resp.registrationStartDatetime) : null,
    resp.registrationEndDatetime ? LocalMomentFromUTCDateTime(resp.registrationEndDatetime) : null,
    resp.analytics,
    resp.owner,
    resp.lastUpdater,
    LocalMomentFromUTCDateTime(resp.createdAt)
  )
}

export const addEventCaster = (eventId, casterId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            addEventCaster (
              eventId: "${eventId}"
              casterId: "${casterId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteEventCaster = async (eventId, casterId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteEventCaster (
              eventId: "${eventId}"
              casterId: "${casterId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteEventCaster) ||
    null
  )
}

export type TwitchClip = {
  id: string
  datePlatformId: string
  thirdPartyId: string
  thumbnailUrl: string
  url: string
  embedUrl: string
  broadcasterName: string
  creatorName: string
  language: string
  title: string
  viewCount: number | null
  clipCreatedAt: string | null
  durationSeconds: number
}

export const fetchTwitchClipsForEvent = (eventId: string): Promise<Array<TwitchClip>> =>
  fetch(fetchEndpoint + `?resource=clips&eventId=${eventId}`).then((response) => response.json())

type RecalculateTaiyoroScoreResponse = {
  data: {
    admin: {
      event: {
        refreshTaiyoroRating: boolean
      }
    }
  }
}

export const recalculateTaiyoroScores = (eventId?: string) => {
  const params: Record<string, string> = {}
  params.type = eventId ? "STANDARD" : "WEEK"
  if (eventId) {
    params.eventId = eventId
  }
  return GraphQL(
    `mutation {
        admin {
          event {
            refreshTaiyoroRating(${stringifyObjectForPayload(params)})
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((res: RecalculateTaiyoroScoreResponse) => res.data.admin.event.refreshTaiyoroRating)
}
