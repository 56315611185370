import { useContext } from "react"
import React from "react"

import { faClock } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { CartesianGrid, XAxis, YAxis, ComposedChart, ResponsiveContainer, Area } from "recharts"
import { UTCDateInJST } from "utils/tools"

import { LEGEND_COLORS, get24hrTimeRange, getIconFromChannelUrl } from "."
import CompetingEventsViewership from "./competing-events-viewership"
import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage } from "./styles"

const DateOverview = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const dates = playDataEventReportData.charts.dateOverview.filter((date) =>
    moment().isAfter(UTCDateInJST(date.startDatetime))
  )

  const { t } = useTranslation("playdata")

  const theme = useTheme()

  const colors = LEGEND_COLORS.map((color) => theme.palette.common[color])

  const dateViewershipBreakdown = playDataEventReportData.charts.competingEventsViewership.map(
    (dateData) => ({
      date: dateData.dateName,
      viewershipDataExists: dateData.data.length > 0,
      component: (
        <CompetingEventsViewership
          competingEventDataForDate={dateData}
          startDatetime={dateData.startDatetime}
        />
      ),
    })
  )

  const uniqueChannels = dates.reduce((acc, curr) => {
    for (const channel of curr.channels) {
      // Filter out 'Channel Unknown' and/or any entry without data.
      if (channel.pcu === 0) {
        continue
      }
      if (!acc.includes(channel.name)) {
        // Bring main channels to the top of the list
        if (channel.isSubStream === 1) {
          acc.push(channel.name)
        } else {
          acc.unshift(channel.name)
        }
      }
    }
    return acc
  }, [])

  const getColorForChannelName = (channelName: string) => {
    return colors[uniqueChannels.indexOf(channelName) % colors.length]
  }

  return (
    <>
      {dates.map((date) => {
        const channels = uniqueChannels
          .filter((channelName) => date.channels.some((channel) => channel.name === channelName))
          .map((channelName) => date.channels.find((channel) => channel.name === channelName))

        const viewershipBreakdownForDate = dateViewershipBreakdown.find(
          (breakdown) => breakdown.date === date.name
        )

        return (
          <React.Fragment key={date.name}>
            <BorderedPage>
              <Stack
                height="100%"
                gap={3}
                justifyContent="space-between"
              >
                <Header title={t("sectionTitles.dateOverview")} />
                <Stack
                  fontSize="32px"
                  gap={0.5}
                  direction="row"
                  px={6}
                  alignItems="flex-end"
                >
                  <Typography
                    fontSize="inherit"
                    fontWeight="900"
                    textTransform="uppercase"
                  >
                    {date.name}
                  </Typography>
                  <Typography
                    fontSize="inherit"
                    fontWeight="300"
                  >
                    &mdash;
                  </Typography>
                  <Typography
                    fontSize="inherit"
                    fontWeight="300"
                  >
                    {UTCDateInJST(date.startDatetime).format("YYYY.MM.DD")}
                  </Typography>
                  <Box mx={1}>
                    <FontAwesomeIcon
                      icon={faClock}
                      size="sm"
                    />
                  </Box>
                  <Typography
                    fontSize="inherit"
                    fontWeight="300"
                  >
                    {get24hrTimeRange(date.startDatetime, date.endDatetime)}
                  </Typography>
                  <Typography
                    fontSize="20px"
                    fontWeight="300"
                    lineHeight="32px"
                    pb={0.7}
                  >
                    {t("jst")}
                  </Typography>
                </Stack>
                <Stack
                  gap={6}
                  direction="row"
                  width="100%"
                  height="100%"
                  flex={1}
                  px={6}
                  overflow="hidden"
                >
                  <Box flex={1}>
                    <Stack
                      gap={4}
                      height="100%"
                    >
                      <Stack
                        justifyContent="space-between"
                        flex={1}
                      >
                        <Box
                          sx={{
                            padding: theme.spacing(2),
                            border: "solid 1px",
                            borderColor: theme.palette.grey[300],
                            backgroundColor: theme.palette.background.default,
                          }}
                        >
                          <Typography
                            fontSize="24px"
                            fontWeight="900"
                            lineHeight={1}
                          >
                            {t("legends.summary")}
                          </Typography>
                          <Grid
                            container
                            mt={1}
                            wrap="nowrap"
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={3}
                            >
                              <Typography
                                fontSize="24px"
                                fontWeight="300"
                                color="grey.600"
                              >
                                {t("sectionTitles.pcu")}
                              </Typography>
                              <Typography
                                fontSize="24px"
                                fontWeight="700"
                                color="grey.600"
                              >
                                {date.summary.pcu.toLocaleString()}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                            >
                              <Typography
                                fontSize="24px"
                                fontWeight="300"
                                color="grey.600"
                              >
                                {t("sectionTitles.acu")}
                              </Typography>
                              <Typography
                                fontSize="24px"
                                fontWeight="700"
                                color="grey.600"
                              >
                                {date.summary.acu.toLocaleString()}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                            >
                              <Typography
                                fontSize="24px"
                                fontWeight="300"
                                color="grey.600"
                              >
                                {t("legends.totalWatchTime")}
                              </Typography>
                              <Typography
                                fontSize="24px"
                                fontWeight="700"
                                color="grey.600"
                              >
                                {date.summary.hoursWatched.toLocaleString()}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                            >
                              <Typography
                                fontSize="24px"
                                fontWeight="300"
                                color="grey.600"
                              >
                                {t("legends.broadcastTime")}
                              </Typography>
                              <Typography
                                fontSize="24px"
                                fontWeight="700"
                                color="grey.600"
                              >
                                {`${Math.floor(date.summary.hoursBroadcast)
                                  .toString()
                                  .padStart(2, "0")}:${Math.floor(
                                  (date.summary.hoursBroadcast - Math.floor(date.summary.hoursBroadcast)) * 60
                                )
                                  .toString()
                                  .padStart(2, "0")}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          flex={1}
                          width="100%"
                        >
                          {date.data.length > 0 && (
                            <ResponsiveContainer
                              height="100%"
                              width="100%"
                            >
                              <ComposedChart
                                data={date.data}
                                margin={{
                                  top: 50,
                                  bottom: 50,
                                }}
                              >
                                <CartesianGrid
                                  strokeDasharray="3 3"
                                  vertical={false}
                                />
                                <XAxis
                                  dataKey="time"
                                  height={60}
                                  tick={{ fontSize: "16px", fill: theme.palette.text.primary, dy: 20 }}
                                  tickFormatter={(value) => moment(value).format("HH:mm")}
                                  tickLine={true}
                                  ticks={date.data
                                    .map((d) => d.time)
                                    .filter((time) => ["00", "30"].includes(moment(time).format("mm")))}
                                />
                                {channels.map((channel, index) => (
                                  <Area
                                    key={channel.name + index}
                                    dataKey={channel.name}
                                    fill={getColorForChannelName(channel.name)}
                                    strokeWidth={4}
                                    stroke="#FFFFFF"
                                    stackId="a"
                                    type="linear"
                                    fillOpacity={1}
                                    isAnimationActive={false}
                                  />
                                ))}
                                <YAxis
                                  tickFormatter={(value) => Number(value).toLocaleString()}
                                  tickLine={false}
                                  tick={{ fill: theme.palette.text.primary, fontWeight: "bold" }}
                                  tickCount={3}
                                  stroke={theme.palette.text.primary}
                                  width={90}
                                />
                              </ComposedChart>
                            </ResponsiveContainer>
                          )}
                          {date.data.length === 0 && (
                            <Box
                              width="100%"
                              height="400px"
                              mt="100px"
                              color="grey.500"
                              sx={{
                                backgroundImage: "url(/images/playdata-no-data-frame.svg)",
                                backgroundSize: "100%",
                                backgroundRepeat: "no-repeat",
                              }}
                            >
                              <Stack
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                width="100%"
                                gap={2}
                              >
                                <Box
                                  width="145px"
                                  height="112px"
                                  sx={{
                                    backgroundImage: "url(/images/playdata-no-data-icon.svg)",
                                    backgroundSize: "100%",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                />
                                <Typography
                                  fontSize="16px"
                                  color="inherit"
                                >
                                  {t("disclaimer.noGraphData")}
                                </Typography>
                                {!viewershipBreakdownForDate.viewershipDataExists && (
                                  <Typography
                                    fontSize="16px"
                                    color="inherit"
                                  >
                                    {t("disclaimer.noCompetingEventAnalysis")}
                                  </Typography>
                                )}
                              </Stack>
                            </Box>
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{ "& th": { border: 0, padding: `${theme.spacing(1)}`, paddingTop: "0px" } }}
                          >
                            <TableCell>
                              <Typography
                                fontSize="18px"
                                fontWeight="700"
                                noWrap
                              >
                                {t("legends.channelName")}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                fontSize="18px"
                                fontWeight="700"
                                noWrap
                              >
                                {t("sectionTitles.pcu")}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                fontSize="18px"
                                fontWeight="700"
                                noWrap
                              >
                                {t("sectionTitles.acu")}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                fontSize="18px"
                                fontWeight="700"
                                noWrap
                              >
                                {t("legends.totalWatchTime")}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[...channels]
                            .sort((a, b) => b.hoursWatched - a.hoursWatched)
                            .sort((a, b) => a.isSubStream - b.isSubStream)
                            .map((channel, index) => (
                              <TableRow
                                key={channel.name + index}
                                sx={{
                                  "& td": { border: 0, padding: `${theme.spacing(0.5)} ${theme.spacing(1)}` },
                                }}
                              >
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Box
                                      minWidth="16px"
                                      height="16px"
                                      bgcolor={getColorForChannelName(channel.name)}
                                    />
                                    {getIconFromChannelUrl(channel.url)}
                                    <Link
                                      fontSize="18px"
                                      fontWeight="300"
                                      noWrap
                                      target="_blank"
                                      href={channel.url}
                                      underline="none"
                                      color="text.primary"
                                      textOverflow="ellipsis"
                                      display="block"
                                    >
                                      {channel.name}
                                    </Link>
                                  </Stack>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    fontSize="18px"
                                    fontWeight="700"
                                  >
                                    {channel.pcu && channel.pcu.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    fontSize="18px"
                                    fontWeight="700"
                                  >
                                    {channel.acu && channel.acu.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    fontSize="18px"
                                    fontWeight="700"
                                  >
                                    {channel.hoursWatched && channel.hoursWatched.toLocaleString()}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Stack>
                <Stack gap={4}>
                  <Disclaimer>
                    <Typography fontSize="14px">{t("dateOverviewDisclaimer")}</Typography>
                  </Disclaimer>
                  <Footer />
                </Stack>
              </Stack>
            </BorderedPage>
            {viewershipBreakdownForDate.viewershipDataExists && viewershipBreakdownForDate.component}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default DateOverview
