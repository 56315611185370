import { useCallback, useEffect, useMemo, useState } from "react"

import type { PostType, PostList } from "services/Taiyoro/posts"
import { fetchPagedPosts } from "services/Taiyoro/posts"

const DEFAULT_QUERY_PARAMS = {
  page: 0,
  size: 100,
  type: "news" as PostType,
}

export const usePosts = () => {
  const [loading, setLoading] = useState(true)
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS)
  const [postsResults, setPostsResults] = useState<PostList>()

  const fetchPosts = useCallback(() => {
    setLoading(true)
    fetchPagedPosts(queryParams)
      .then((res) => {
        if (res.status === "success") {
          setPostsResults(res.data)
          return
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [queryParams])

  useEffect(() => {
    fetchPosts()
  }, [queryParams, fetchPosts])

  const posts = useMemo(() => {
    if (postsResults) {
      return postsResults.results
    }
    return []
  }, [postsResults])

  const totalCount = useMemo(() => {
    if (postsResults) {
      return postsResults.totalCount
    }
    return 0
  }, [postsResults])

  const setPage = (page: number) => {
    setQueryParams((prev) => ({ ...prev, page }))
  }

  const setSize = (size: number) => {
    setQueryParams((prev) => ({ ...prev, size }))
  }

  const setType = (type: PostType) => {
    setQueryParams((prev) => ({ ...prev, type }))
  }

  return {
    loading,
    totalCount,
    posts,
    queryParams,
    setPage,
    setSize,
    setType,
    fetchPosts,
  }
}
