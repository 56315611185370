import React, { useState, useEffect } from "react"

import { Grid } from "@mui/material"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"

import { editDate } from "../../../../../../services/Taiyoro/event"
import RealTimeUpdateField from "../../../../RealTimeUpdateField"

interface Props {
  dateId: string
  startTime: moment.Moment
  endTime: moment.Moment
  onUpdate: (startTime: moment.Moment, endTime: moment.Moment) => void
}

const getDurationFromTimeDifference = (startTime, endTime) =>
  moment.duration(endTime.diff(startTime)).asMinutes()

const DateTimings = (props: Props) => {
  const [durationState, setDurationState] = useState(
    getDurationFromTimeDifference(props.startTime, props.endTime)
  )

  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    const duration = getDurationFromTimeDifference(props.startTime, props.endTime)
    setDurationState(duration)
  }, [props.startTime, props.endTime])

  const generate24hrTimestamps = () => {
    const options = [...Array(24).keys()].reduce((acc: string[], curr: number) => {
      if (curr === 0) return acc
      return [
        ...acc,
        `${props.startTime
          .locale("en")
          .clone()
          .add(curr, "hours")
          .format("YYYY/MM/DD hh:mm A")} (${curr}hrs)`,
        `${props.startTime
          .clone()
          .locale("en")
          .add(curr, "hours")
          .add(30, "minutes")
          .format("YYYY/MM/DD hh:mm A")} (${curr}.5hrs)`,
      ]
    }, [])
    options.unshift(
      props.startTime.clone().add(30, "minutes").locale("en").format("YYYY/MM/DD hh:mm A") + " (0.5hrs)"
    )
    options.push(
      props.startTime.clone().add(24, "hours").locale("en").format("YYYY/MM/DD hh:mm A") + " (24hrs)"
    )
    return options
  }

  const getClosestValueFromTimeDifference = () => {
    const duration = getDurationFromTimeDifference(props.startTime, props.endTime)
    let hoursDifference = duration % 60 === 0 ? duration / 60 : (duration / 60).toFixed(1)
    return (
      props.startTime.clone().locale("en").add(duration, "minutes").format("YYYY/MM/DD hh:mm A") +
      ` (${hoursDifference}hrs)`
    )
  }

  return (
    <>
      <Grid
        item
        md={6}
        sm={12}
      >
        <RealTimeUpdateField
          type="datetime-local"
          variant="standard"
          label={t("edit.dates.startTime")}
          updateFunc={(value) => {
            const startMoment = moment(value, "YYYY/MM/DDTHH:mm")
            const endMoment = startMoment.clone().add(durationState, "minutes")
            props.onUpdate(startMoment.clone(), endMoment.clone())
            return editDate(props.dateId, {
              startDatetime: startMoment.clone().utc().format("YYYY-MM-DDTHH:mm:ss"),
              endDatetime: endMoment.clone().utc().format("YYYY-MM-DDTHH:mm:ss"),
            })
          }}
          initialValue={props.startTime.format("YYYY-MM-DDTHH:mm")}
        />
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
      >
        <RealTimeUpdateField
          key={props.startTime.format()}
          type="select"
          variant="standard"
          disableClearable
          options={generate24hrTimestamps()}
          label={t("edit.dates.duration")}
          updateFunc={(value) => {
            const hours = Number(value.split("(")[1].split("hrs")[0])
            const endMoment = props.startTime.clone().add(hours * 60, "minutes")
            props.onUpdate(props.startTime.clone(), endMoment.clone())
            return editDate(props.dateId, {
              startDatetime: props.startTime.clone().utc().format("YYYY-MM-DDTHH:mm:ss"),
              endDatetime: endMoment.clone().utc().format("YYYY-MM-DDTHH:mm:ss"),
            })
          }}
          initialValue={getClosestValueFromTimeDifference()}
        />
      </Grid>
    </>
  )
}

export default DateTimings
