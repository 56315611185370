import React from "react"

import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TableCell, Checkbox } from "@mui/material"
import type { CheckboxProps } from "@mui/material"
import { styled, Box } from "@mui/material"
import { green, red } from "@mui/material/colors"
import { withStyles } from "@mui/styles"

export const TableHeader = styled(TableCell)`
  border-bottom: 1px solid #fff !important;
`

export const NameText = styled(Box)`
  font-weight: bold;
`

export const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
    "&$disabled": {
      color: green[600],
    },
  },
  checked: {},
  disabled: {},
})((props: CheckboxProps) => (
  <Checkbox
    color="default"
    {...props}
    disabled
    icon={
      <FontAwesomeIcon
        icon={faTimes}
        color={red[600]}
      />
    }
    checkedIcon={
      <FontAwesomeIcon
        icon={faCheck}
        color={green[600]}
      />
    }
  />
))
