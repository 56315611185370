import i18next from "i18next"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: new URL(window.location.href).searchParams.get("language") || localStorage.getItem("language"),
    ns: ["common", "taiyoro", "data-collection", "scouting", "user-management", "playdata"],
    fallbackLng: "en",
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  })

export const localisedLabel = (entity) => {
  const language = localStorage.getItem("language")
  let label = ""
  if (!entity) {
    return ""
  }
  if (language === "en") {
    label = entity["name"] || entity["nameJa"]
  }
  if (language === "ja") {
    label = entity["nameJa"] || entity["name"]
  }
  return label || entity["nameJa"] || entity["name"] || ""
}
