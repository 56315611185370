import GraphQL, { stringifyObjectForPayload } from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export type FeaturedEventBaseData = {
  eventId: string
  orderValue: number
  featuredStartDatetime: string
  featuredEndDatetime: string
  backgroundColor: string
  heroAssetUrl: null | string
}

export type EventBase = {
  id: string
  urlSlug: string
  name: string
  imageUrl: string
}

export type FeaturedEvent = FeaturedEventBaseData & {
  id: string
  event: EventBase
}

const convertFeaturedEventToFeaturedEventBaseData = (featuredEvent: FeaturedEvent): FeaturedEventBaseData => {
  return {
    eventId: featuredEvent.eventId,
    orderValue: featuredEvent.orderValue,
    featuredStartDatetime: featuredEvent.featuredStartDatetime,
    featuredEndDatetime: featuredEvent.featuredEndDatetime,
    backgroundColor: featuredEvent.backgroundColor,
    heroAssetUrl: featuredEvent.heroAssetUrl,
  }
}

export const fetchFeaturedEvents = async (limit: number, offset: number): Promise<Array<FeaturedEvent>> =>
  GraphQL(
    `query {
            admin {
              featured {
                getFeaturedEvents(limit: ${limit}, offset: ${offset}) {
                    id
                  event {
                    id
                    name
                    urlSlug
                    imageUrl
                  }
                  eventId
                  orderValue
                  featuredStartDatetime
                  featuredEndDatetime
                  backgroundColor
                  heroAssetUrl
                }
              }
            }
          }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      response.data &&
      response.data.admin &&
      response.data.admin.featured &&
      response.data.admin.featured.getFeaturedEvents
    )
  })

export const addFeaturedEvent = async (featuredEvent: FeaturedEvent): Promise<boolean> => {
  const featuredEventBaseData = convertFeaturedEventToFeaturedEventBaseData(featuredEvent)
  return GraphQL(
    `mutation {
      admin {
        featured{
          addFeaturedEvent(featuredEvent: {${stringifyObjectForPayload(featuredEventBaseData)}}) {
              id
            event {
              id
              name
              urlSlug
              imageUrl
            }
            eventId
            orderValue
            featuredStartDatetime
            featuredEndDatetime
            backgroundColor
            heroAssetUrl
          }
        }
      }
   }
   `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      response.data &&
      response.data.admin &&
      response.data.admin.featured &&
      response.data.admin.featured.addFeaturedEvent
    )
  })
}

export const updateFeaturedEvent = async (featuredEvent: FeaturedEvent): Promise<boolean> => {
  const featuredEventBaseData = convertFeaturedEventToFeaturedEventBaseData(featuredEvent)
  return GraphQL(
    `mutation {
        admin {
          featured{
            updateFeaturedEvent(id: ${JSON.stringify(
              featuredEvent.id
            )}, featuredEvent: {${stringifyObjectForPayload(featuredEventBaseData)}})
          }
        }
      }
     `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      response.data &&
      response.data.admin &&
      response.data.admin.featured &&
      response.data.admin.featured.updateFeaturedEvent
    )
  })
}

export const deleteFeaturedEvent = async (id: string): Promise<boolean> =>
  GraphQL(
    `mutation {
        admin {
          featured {
            deleteFeaturedEvent(id: ${JSON.stringify(id)})
          }
        }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      response.data &&
      response.data.admin &&
      response.data.admin.featured &&
      response.data.admin.featured.deleteFeaturedEvent
    )
  })
