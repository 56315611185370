import { useContext } from "react"

import { Stack } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import type EventDate from "models/Taiyoro/eventDate"
import type { DailyPickemAny, DailyPickemGroupAny } from "services/Taiyoro/Pickem/daily"

import { DateSection } from "./DateSection"
import { GroupSection } from "./GroupSection"
import { PickemSection } from "./PickemSection"

export const DailyPickemList = () => {
  const { eventState, dailyPickemGroupList, dailyPickemList } = useContext(PickemSettingsContext)
  const allDates = eventState?.dates ?? []

  const datesWithGroups = new Map<EventDate, Array<DailyPickemGroupAny>>()
  const datesWithPickems = new Map<EventDate, Array<DailyPickemAny>>()

  for (const date of allDates) {
    const groups = dailyPickemGroupList.filter((group) => group.dateId === date.id)
    const pickems = dailyPickemList.filter((pickem) => pickem.dateId === date.id)

    datesWithGroups.set(date, groups)
    datesWithPickems.set(date, pickems)
  }

  const relevantDates = allDates.filter((date) => {
    const groups = datesWithGroups.get(date)
    const pickems = datesWithPickems.get(date)

    return (groups?.length && groups.length > 0) || (pickems?.length && pickems.length > 0)
  })

  return (
    <Stack gap={4}>
      {relevantDates.map((date) => {
        const groups = datesWithGroups.get(date)
        const pickems = datesWithPickems.get(date)

        return (
          <DateSection
            key={date.id}
            date={date}
          >
            <GroupSection groups={groups} />
            <PickemSection pickems={pickems} />
          </DateSection>
        )
      })}
    </Stack>
  )
}
