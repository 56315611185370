import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { link, deleteLink } from "../../../services/Taiyoro/gameGroups"
import {
  fetchGames,
  createGame,
  deleteGame,
  addGameAlternativeName,
  deleteGameAlternativeName,
} from "../../../services/Taiyoro/games"

const MetaGamePage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.games")}
      fetchFunc={fetchGames}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createGame(name, nameJa, sortJa, hashtag, urlSlug, primaryImage, secondaryImage, boxArtImage, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createGame(name, nameJa, sortJa, hashtag, urlSlug, primaryImage, secondaryImage, boxArtImage, id)}
      deleteFunc={deleteGame}
      hashtagFields
      primaryImageFieldsAndRatio={16 / 9}
      secondaryImageFieldsAndRatio={1}
      updateGameAlternativeNamesFunc={(added, removed) =>
        Promise.all([
          ...added.map((alternativeName) =>
            addGameAlternativeName(alternativeName.gameId, alternativeName.name)
          ),
          ...removed.map((alternativeName) =>
            deleteGameAlternativeName(alternativeName.gameId, alternativeName.name)
          ),
        ])
      }
      updateGroupsFunc={(added, removed) =>
        Promise.all([
          ...added.map((groupData) => link(MetaType.GAME, groupData.refId, groupData.parentGroupId)),
          ...removed.map((groupData) => deleteLink(MetaType.GAME, groupData.refId, groupData.parentGroupId)),
        ])
      }
      slugFields
      boxArtImageFields
      metaType={MetaType.GAME}
    />
  )
}

export default MetaGamePage
