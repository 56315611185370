import { useContext, useEffect, useState } from "react"
import React from "react"

import { useTheme } from "@mui/material"
import LoadingBox from "components/LoadingBox"
import { EventDateBracketsContext, type TimeMarks } from "contexts/event-date-brackets"
import { NotificationContext } from "contexts/notification"
import type EventStream from "models/Taiyoro/Stream"
import type Event from "models/Taiyoro/event"
import type EventDate from "models/Taiyoro/eventDate"
import { type RouteComponentProps } from "react-router"
import { withRouter } from "react-router-dom"
import { type EventDateBracket, fetchDateBrackets } from "services/Taiyoro/brackets"
import { type StreamStat, fetchDatePlatformStreamStats, fetchEvent } from "services/Taiyoro/event"

type PathParamsType = {
  eventId: string
  dateId: string
}

type Props = RouteComponentProps<PathParamsType> & {
  children: React.ReactNode
}

export const EventDateBracketsProvider = withRouter((props: Props) => {
  const [event, setEvent] = useState<Event>({} as Event)
  const [eventDate, setEventDate] = useState({} as EventDate)
  const [loading, setLoading] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [streamViewershipData, setStreamViewershipData] = useState<Record<string, Array<StreamStat>>>({})
  const [selectedStream, setSelectedStream] = useState<EventStream>({} as EventStream)
  const [streamIdColorMap, setStreamIdColorMap] = useState<Record<string, string>>({})
  const [timeMarks, setTimeMarks] = useState<TimeMarks>({ start: "", end: "", selected: "start" })
  const [brackets, setBrackets] = useState<Array<EventDateBracket>>([])
  const [playhead, setPlayhead] = useState("")
  const [streamVodMap, setStreamVodMap] = useState({})

  const { setNotification } = useContext(NotificationContext)

  const theme = useTheme()

  useEffect(() => {
    setLoading(true)
    setErrorState(false)
    fetchEvent(props.match.params.eventId)
      .then(async (serverEvent) => {
        const serverEventDate = serverEvent.dates.find((date) => date.id === props.match.params.dateId)
        if (!serverEventDate) {
          throw new Error("No date matching ID")
        }
        const eventDateStreams = serverEventDate.platforms.filter((datePlatform) => !datePlatform.isVod)
        if (eventDateStreams.length === 0) {
          throw new Error("No streams registered on the date")
        }
        const eventStream = eventDateStreams[0]

        const serverStreamVodMap = serverEventDate.platforms
          .filter((datePlatform) => datePlatform.sourceStreamDatePlatformId)
          .map((datePlatform) => [datePlatform.sourceStreamDatePlatformId!, datePlatform.id])
          .reduce(
            (acc, curr) => {
              return {
                ...acc,
                [curr[0]]: curr[1],
              }
            },
            {} as Record<string, string>
          )

        const streamViewershipDataServer: Record<string, Array<StreamStat>> = {}
        for (const stream of serverEventDate.platforms.filter((platform) => !platform.isVod)) {
          const viewershipData = await fetchDatePlatformStreamStats(stream.id)
          if (viewershipData.length !== 0) {
            streamViewershipDataServer[stream.id] = viewershipData
          }
        }
        const colors = [
          theme.palette.primary.main,
          theme.palette.success.light,
          theme.palette.warning.light,
          theme.palette.error.light,
          theme.palette.secondary.light,
          theme.palette.info.light,
        ]
        const serverStreamIdColorMap = Object.keys(streamViewershipDataServer).reduce((acc, curr, index) => {
          acc[curr] = colors[index % colors.length]
          return acc
        }, {})

        const serverBrackets = await fetchDateBrackets(props.match.params.dateId)

        // Get & compare the first of each date because the viewership data is ordered by datetime
        const earliestTimeMark = Object.keys(streamViewershipDataServer)
          .reduce((acc, curr) => {
            return [...acc, streamViewershipDataServer[curr][0].datetime]
          }, [] as Array<string>)
          .sort()[0]

        // Get & compare the last of each date because the viewership data is ordered by datetime
        const latestTimeMark = Object.keys(streamViewershipDataServer)
          .reduce((acc, curr) => {
            return [
              ...acc,
              streamViewershipDataServer[curr][streamViewershipDataServer[curr].length - 1].datetime,
            ]
          }, [] as Array<string>)
          .sort()
          .pop()

        if (earliestTimeMark && latestTimeMark) {
          setTimeMarks({ start: earliestTimeMark, end: latestTimeMark, selected: "start" })
        }

        setStreamVodMap(serverStreamVodMap)
        setStreamIdColorMap(serverStreamIdColorMap)
        setEvent(serverEvent)
        setSelectedStream(eventStream)
        setEventDate(serverEventDate)
        setStreamViewershipData(streamViewershipDataServer)
        setBrackets(serverBrackets)
      })
      .catch((error: Error) => {
        setNotification({
          message: error.message,
          severity: "error",
        })
        setErrorState(true)
      })
      .finally(() => setLoading(false))
  }, [props, setNotification, theme])

  // ReactCharts needs data to be in such a format.
  const chartData = Object.keys(streamViewershipData)
    .reduce(
      (acc, curr) => {
        for (const currentStreamData of streamViewershipData[curr]) {
          const existingDatetime = acc.find(
            (streamData) => streamData.datetime === currentStreamData.datetime
          )
          if (!existingDatetime) {
            acc.push({
              datetime: currentStreamData.datetime,
              [curr]: currentStreamData.concurrentViewers,
            } as { datetime: string } & Record<string, number>)
          } else {
            existingDatetime[curr] = currentStreamData.concurrentViewers
          }
        }
        return acc
      },
      [] as Array<{ datetime: string } & Record<string, number>>
    )
    .sort((a, b) => {
      if (a.datetime === b.datetime) {
        return 0
      }
      if (a.datetime < b.datetime) {
        return -1
      }
      return 1
    })

  return (
    <EventDateBracketsContext.Provider
      value={{
        event,
        eventDate,
        loading,
        streamViewershipData,
        selectedStream,
        setSelectedStream,
        streamIdColorMap,
        timeMarks,
        setTimeMarks,
        brackets,
        setBrackets,
        playhead,
        setPlayhead,
        chartData,
        streamVodMap,
        setStreamVodMap,
      }}
    >
      {!loading && !errorState && props.children}
      {loading && <LoadingBox />}
    </EventDateBracketsContext.Provider>
  )
})
