import { useContext, useEffect, useState } from "react"

import { faFloppyDisk } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import LoadingBox from "components/LoadingBox"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import { NotificationContext } from "contexts/notification"
import type Game from "models/Taiyoro/Meta/Game"
import { useTranslation } from "react-i18next"
import { type EventDateBracket, createBracket, deleteBracket } from "services/Taiyoro/brackets"
import { fetchGames } from "services/Taiyoro/games"
import { UTCDateInJST } from "utils/tools"

import { DeleteBracketConfirmation } from "./delete-bracket-confirmation"
import { LinkedMetaManagement } from "./linked-meta-management"
import { StreamAnalyticsList } from "./stream-analytics-list"

export const EventDateBracketsList = () => {
  const { brackets, setBrackets, timeMarks, eventDate, setTimeMarks } = useContext(EventDateBracketsContext)

  const [selectedBracket, setSelectedBracket] = useState<null | EventDateBracket>(null)

  const { setNotification } = useContext(NotificationContext)

  const { t } = useTranslation(["common", "taiyoro"])

  const [games, setGames] = useState<Array<Game>>([])

  const [bracketToAdd, setBracketToAdd] = useState<Omit<EventDateBracket, "streamAnalytics" | "linkedMeta">>({
    id: "",
    name: "",
    dateId: eventDate.id,
    startTime: UTCDateInJST(timeMarks.start).format("MM/DD HH:mm:ss"),
    endTime: UTCDateInJST(timeMarks.end).format("MM/DD HH:mm:ss"),
    viewsLiveConcurrentPeak: 0,
    viewsLiveMinutesWatched: 0,
    viewsLiveConcurrentAverage: 0,
  })

  // Note: Deleting a bracket will auto-delete all linked meta tags to that bracket, no
  // need to clear those manually on the front-end.
  const handleDelete = (id: string) => {
    deleteBracket(id)
      .then((success) => {
        if (!success) {
          throw new Error("There was an unexpected problem deleting bracket.")
        }
        setNotification({
          message: t("common:dataUpdateSuccess"),
          severity: "success",
        })
        setBrackets((oldBrackets) => [...oldBrackets].filter((b) => b.id !== id))
      })
      .catch((e: Error) => {
        setNotification({
          message: e.message,
          severity: "error",
        })
      })
  }

  const handleAddBracket = () => {
    createBracket(bracketToAdd.dateId, bracketToAdd.name, bracketToAdd.startTime, bracketToAdd.endTime)
      .then((newBracket) => {
        setBracketToAdd((oldBracket) => ({ ...oldBracket, name: "" }))
        setBrackets((oldBrackets) => [newBracket, ...oldBrackets])
      })
      .catch((e: Error) => {
        setNotification({
          message: e.message,
          severity: "error",
        })
      })
  }

  const handleRowClick = (bracket: EventDateBracket | null) => {
    setSelectedBracket(bracket)
    if (bracket) {
      const changeTimeMarks = timeMarks.start !== bracket.startTime || timeMarks.end !== bracket.endTime
      changeTimeMarks && setTimeMarks({ ...timeMarks, start: bracket.startTime, end: bracket.endTime })
      return
    }
    const changeTimeMarks =
      timeMarks.start !== bracketToAdd.startTime || timeMarks.end !== bracketToAdd.endTime
    changeTimeMarks &&
      setTimeMarks({ ...timeMarks, start: bracketToAdd.startTime, end: bracketToAdd.endTime })
  }

  // Whenever the time mark is changed via the scrubber, change the selected bracket to null (bracketToAdd)
  useEffect(() => {
    setBracketToAdd((oldBracket) => ({ ...oldBracket, startTime: timeMarks.start, endTime: timeMarks.end }))
    if (
      selectedBracket &&
      !brackets.some((bracket) => timeMarks.start === bracket.startTime && timeMarks.end === bracket.endTime)
    ) {
      setSelectedBracket(null)
    }
  }, [brackets, timeMarks, setBracketToAdd, setSelectedBracket, selectedBracket])

  useEffect(() => {
    void fetchGames().then(setGames)
  }, [])

  return (
    <>
      {games.length === 0 && <LoadingBox />}
      {games.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("taiyoro:brackets.tableHeaders.name")}</TableCell>
                <TableCell>{t("taiyoro:brackets.tableHeaders.from")}</TableCell>
                <TableCell>{t("taiyoro:brackets.tableHeaders.to")}</TableCell>
                <TableCell>{t("taiyoro:brackets.tableHeaders.linkedMeta")}</TableCell>
                <TableCell align="right">{t("taiyoro:list.header.pcu")}</TableCell>
                <TableCell align="right">{t("taiyoro:list.header.acu")}</TableCell>
                <TableCell align="right">{t("taiyoro:list.header.minutesWatched")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(null)}
                selected={!selectedBracket}
              >
                <TableCell
                  component="th"
                  scope="row"
                >
                  <TextField
                    sx={{
                      minWidth: "125px",
                    }}
                    variant="outlined"
                    value={bracketToAdd.name}
                    onChange={(event) =>
                      setBracketToAdd((oldBracketToAdd) => ({
                        ...oldBracketToAdd,
                        name: event.target.value || "",
                      }))
                    }
                  />
                </TableCell>
                <TableCell>{UTCDateInJST(bracketToAdd.startTime).format("MM/DD HH:mm:ss")}</TableCell>
                <TableCell>{UTCDateInJST(bracketToAdd.endTime).format("MM/DD HH:mm:ss")}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <IconButton
                    disabled={bracketToAdd.name === ""}
                    color="success"
                    onClick={handleAddBracket}
                  >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                  </IconButton>
                </TableCell>
              </TableRow>
              {brackets.map((bracket) => (
                <TableRow
                  key={bracket.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => handleRowClick(bracket)}
                  selected={selectedBracket === bracket}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {bracket.name}
                  </TableCell>
                  <TableCell>{UTCDateInJST(bracket.startTime).format("MM/DD HH:mm:ss")}</TableCell>
                  <TableCell>{UTCDateInJST(bracket.endTime).format("MM/DD HH:mm:ss")}</TableCell>
                  <TableCell>
                    <LinkedMetaManagement
                      bracket={bracket}
                      games={games}
                    />
                  </TableCell>
                  <TableCell align="right">{bracket.viewsLiveConcurrentPeak.toLocaleString()}</TableCell>
                  <TableCell align="right">{bracket.viewsLiveConcurrentAverage.toLocaleString()}</TableCell>
                  <TableCell align="right">{bracket.viewsLiveMinutesWatched.toLocaleString()}</TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      gap={1}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <StreamAnalyticsList bracket={bracket} />
                      <DeleteBracketConfirmation onConfirm={() => handleDelete(bracket.id)} />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
