import moment from "moment"

import type { User } from "../../models/UserManagement"
import GraphQL from "../../utils/graphql"
import { convertAndDownloadBlobAsCSV } from "../../utils/tools"

const endpoint = process.env.REACT_APP_GRAPHQL_USERS || "http://localhost:3988/api/graph"

const eventsApiEndpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

const LIST_USERS_REPSONSE_FIELDS = `
paginationKey
items {
  id
  extendedId
  email
  username
  profileImageUrl
  role
  created
}
`

const OPTIONAL_PAGINATION_KEY = (paginationKey?: string) =>
  paginationKey ? `, paginationKey: "${paginationKey}"` : ""
const OPTIONAL_ID_SEARCH_KEY = (searchQuery?: string) => (searchQuery ? `, userID: "${searchQuery}"` : "")

export const getUserById = (product, userId) => {
  return GraphQL(
    `query {
      admin {
        listUsers(platform: ${product}, limit: 1, userID: "${userId}") {
           ${LIST_USERS_REPSONSE_FIELDS} 
        }
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.listUsers.items[0] as User
  })
}

export const fetchUsers = (product: string, paginationKey?: string, searchId?: string) => {
  return GraphQL(
    `query {
      admin {
        listUsers(platform: ${product}, limit: 15${OPTIONAL_PAGINATION_KEY(
          paginationKey
        )}${OPTIONAL_ID_SEARCH_KEY(searchId)}) {
           ${LIST_USERS_REPSONSE_FIELDS} 
        }
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return {
      users: response.data.admin.listUsers.items as Array<User>,
      paginationKey: response.data.admin.listUsers.paginationKey as string,
    }
  })
}

export const createUser = (
  username: string,
  password: string,
  email: string,
  product: string,
  role: string
) => {
  return GraphQL(
    `mutation {
      admin {
        createUser(username: "${username}", password: "${password}", email: "${email}", platform: ${product}, role: ${role})
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.error(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.createUser
  })
}

export const updateUser = (id: string, product: string, username: string, role: string) => {
  return GraphQL(
    `mutation {
      admin {
        updateUser(id: "${id}", username: "${username}", platform: ${product}, role: ${role})
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.error(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.updateUser
  })
}

export const deleteTaiyoroUser = (id: string) => {
  return GraphQL(
    `mutation {
      playbrainAdmin {
        deleteUser(taiyoroUserId: "${id}")
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.error(err)
      throw err
    },
    eventsApiEndpoint
  ).then((response) => {
    return response.data.playbrainAdmin.deleteUser
  })
}

export const deletePlayBrainUser = (id: string) => {
  return GraphQL(
    `mutation {
    admin {
        deleteUser(id: ${JSON.stringify(id)}, platform: PLAYBRAIN)
     }
  }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.error(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.deleteUser
  })
}

export const fetchUserCSVData = (type: string) => {
  return GraphQL(
    `query {
    admin {
      analytics {
        taiyoroUsersSummaryCsv(type: "${type}")
      }
    }
  }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.error(err)
      throw err
    },
    eventsApiEndpoint
  ).then((response) => {
    return response.data.admin.analytics.taiyoroUsersSummaryCsv
  })
}

export const downloadTaiyoroUserCSV = async (type: string) => {
  const fileName = `TAIYORO User ${type.toLowerCase()} Report ${moment()
    .tz("Asia/Tokyo")
    .format("YYYY/MM/DD HH:mm:ss")}`
  fetchUserCSVData(type)
    .then((response) => new Blob([response], { type: "text/csv;encoding:utf-8" }))
    .then((blob) => convertAndDownloadBlobAsCSV(blob, fileName))
}

export type TaiyoroUser = {
  id: string
  extendedId: string
  email: string
  username: string
  profileImageUrl: string
  role: string
  created: string
  deleted: string
}
interface TaiyoroUserSearchResponse {
  data: {
    admin: {
      listUsers: {
        paginationKey: string
        count: number
        items: Array<TaiyoroUser>
      }
    }
  }
}

export const searchUsersByName = async (name: string, signal: AbortSignal) => {
  return GraphQL(
    `query {
            admin {
              listUsers (platform: TAIYORO, username: ${JSON.stringify(name)}){
                paginationKey
                count
                items{
                  id
                  extendedId
                  email
                  username
                  profileImageUrl
                  role
                  created
                  deleted
                }
              }
            }
          }
          
        `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.error(err)
      throw err
    },
    endpoint,
    signal
  ).then((response: TaiyoroUserSearchResponse) => {
    return response.data.admin.listUsers.items
  })
}
