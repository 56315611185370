import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useTranslation } from "react-i18next"
import type { DailyPickemType } from "services/Taiyoro/Pickem/daily"

interface Props {
  value: DailyPickemType
  updateValue: (value: DailyPickemType) => void
  updateStatus?: () => void
}

export const TypeControl = ({ value, updateValue, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  return (
    <Box>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(event) => {
          updateValue(event.target.value as DailyPickemType)
          updateStatus?.()
        }}
        row
      >
        <FormControlLabel
          value="match"
          control={<Radio />}
          label={t("eventPickem.daily.modal.match")}
        />
        <FormControlLabel
          value="quiz"
          control={<Radio />}
          label={t("eventPickem.daily.modal.quiz")}
        />
      </RadioGroup>
    </Box>
  )
}
