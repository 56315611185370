import { faTwitch, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faSignalStream } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Paper } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import getPlatformFromUrl, { Platform } from "@taiyoro/parse-stream-url"
import ChannelDateWatchtime from "components/PlayData/EventReport/channel-date-watchtime"
import ChannelWatchtime from "components/PlayData/EventReport/channel-watchtime"
import CompetingEventsOverview from "components/PlayData/EventReport/competing-events-overview"
import Concurrents from "components/PlayData/EventReport/concurrents"
import DateOverview from "components/PlayData/EventReport/date-overview"
import EventCover from "components/PlayData/EventReport/event-cover"
import EventDetails from "components/PlayData/EventReport/event-details"
import EventSummary from "components/PlayData/EventReport/event-summary"
import ReportDisclaimer from "components/PlayData/EventReport/report-disclaimer"
import Watchtime from "components/PlayData/EventReport/watchtime"
import WatchtimeHeatmap from "components/PlayData/EventReport/watchtime-heatmap"
import { playDataTheme } from "styles/PlayData"
import { UTCDateInJST } from "utils/tools"

export const PAGE_COUNTER = "page"

export const MAX_ITEMS_PER_GRAPH = 35
export const MAX_BAR_WIDTH = 300

export const LEGEND_COLORS = [
  "pink",
  "green",
  "blue",
  "yellow",
  "orange",
  "teal",
  "iron",
  "sky",
  "straw",
  "grass",
  "squirrel",
  "sea",
  "dusk",
  "smoke",
  "raspberry",
  "pumpkin",
  "lavendar",
  "emerald",
  "nightSky",
  "aquaMarine",
  "plum",
]

export const getIconFromChannelUrl = (channelUrl: string) => {
  try {
    switch (getPlatformFromUrl(channelUrl || "")) {
      case Platform.YouTube:
        return (
          <FontAwesomeIcon
            icon={faYoutube}
            size="xl"
          />
        )
      case Platform.Twitch:
        return (
          <FontAwesomeIcon
            icon={faTwitch}
            size="xl"
          />
        )
      case Platform.Mildom:
        return <i className="fak fa-mildom fa-xl" />
      case Platform.Afreeca:
        return <i className="fak fa-afreecatv-filled fa-xl" />
      case Platform.OpenRec:
        return <i className="fak fa-openrec fa-xl" />
      default:
        return (
          <FontAwesomeIcon
            icon={faSignalStream}
            size="xl"
          />
        )
    }
  } catch {
    return (
      <FontAwesomeIcon
        icon={faSignalStream}
        size="xl"
      />
    )
  }
}

export const get24hrTimeRange = (startDatetime: string, endDatetime: string) => {
  const start = UTCDateInJST(startDatetime)
  const end = UTCDateInJST(endDatetime)
  const dayDifferences = end.clone().startOf("day").diff(start.clone().startOf("day"), "days")
  const endHours = Number(end.format("HH"))
  return `${start.format("HH:mm")}~${(endHours + dayDifferences * 24)
    .toString()
    .padStart(2, "0")}${end.format(":mm")}`
}

const EventReport = () => {
  return (
    <ThemeProvider theme={playDataTheme}>
      <Paper
        elevation={0}
        sx={{ borderRadius: 0, width: "1920px", counterReset: PAGE_COUNTER }}
      >
        <EventCover />
        <EventDetails />
        <EventSummary />
        <Concurrents />
        <Watchtime />
        <WatchtimeHeatmap />
        <ChannelWatchtime />
        <ChannelDateWatchtime />
        <CompetingEventsOverview />
        <DateOverview />
        <ReportDisclaimer />
      </Paper>
    </ThemeProvider>
  )
}

export default EventReport
