import React from "react"

import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { StyleHeaderCell, StyleBodyCell, StyleBodyControlsCell, StyleBodyRow } from "./styles"

const TableList = (props) => {
  return (
    <Paper>
      <Table style={props.style}>{props.children}</Table>
    </Paper>
  )
}

TableList.Header = (props) => {
  return <TableHead style={props.style}>{props.children}</TableHead>
}

TableList.HeaderRow = (props) => {
  return <TableRow style={props.style}>{props.children}</TableRow>
}

TableList.HeaderCell = (props) => {
  return <StyleHeaderCell style={props.style}>{props.children}</StyleHeaderCell>
}

TableList.Body = (props) => {
  return <TableBody style={props.style}>{props.children}</TableBody>
}

TableList.BodyRow = (props) => {
  return (
    <StyleBodyRow
      style={props.style}
      onClick={props.onClick || null}
      colorBG={props.colorBG}
      {...props}
    >
      {props.children}
    </StyleBodyRow>
  )
}

TableList.BodyCell = (props) => {
  return <StyleBodyCell style={props.style}>{props.children}</StyleBodyCell>
}

TableList.ControlsCell = (props) => {
  return <StyleBodyControlsCell style={props.style}>{props.children}</StyleBodyControlsCell>
}

export default TableList
