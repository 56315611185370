import { FormControlLabel, Box, Link } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => `
    border-bottom: 1px solid #a9a9a9;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    align-items: start;
    transition: 0.5s ease;
    &:hover {
      background-color: ${theme.palette.background.default + "30"};
    }
    &.checked {
      background-color: ${theme.palette.primary.main + "16"};
    }
    .MuiFormControlLabel-label {
      width: 100%;
    }
    .MuiCheckbox-root {
      margin-top: 12px;
    }
`
)

export const Wrapper = styled(Box)(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(1)};
`
)

export const StyledLink = styled(Link)(
  ({ theme }) => `
    font-weight: 700;
    color: ${theme.palette.text.primary};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    span {
      overflow: hidden;
      max-width: 275px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
    svg {
      margin-left: ${theme.spacing(1)};
    }
    &:hover {
      text-decoration: underline;
    }
    &.loading:hover {
      text-decoration: none;
    }
`
)
