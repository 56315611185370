import type SignificantPlayer from "../../models/Taiyoro/Meta/SignificantPlayer"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type SignificantPlayersResponse = {
  data?: {
    admin?: {
      significantPlayer?: {
        get?: Array<SignificantPlayer>
      }
    }
  }
}

export const fetchSignificantPlayers = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          significantPlayer {
            get {
              id
              name
              nameJa
              sortJa
              teamId
              teamOrganizationId
              primaryImage
              urlSlug
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as SignificantPlayersResponse

    return response.data?.admin?.significantPlayer?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch Significant Players")
  }
}

export type SignificantPlayerResponse = {
  data?: {
    admin?: {
      significantPlayer?: {
        get?: SignificantPlayer | null
      }
    }
  }
}

export const fetchSignificantPlayer = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          significantPlayer {
            get(id: "${id}") {
              id
              name
              nameJa
              sortJa
              teamId
              teamOrganizationId
              primaryImage
              urlSlug
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as SignificantPlayerResponse

    return response.data?.admin?.significantPlayer?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch Significant Player")
  }
}

export type CreateSignificantPlayerResponse = {
  data?: {
    admin?: {
      significantPlayer?: {
        save?: SignificantPlayer | null
      }
    }
  }
}

export const createSignificantPlayer = async (
  name: string,
  urlSlug: string,
  nameJa?: string,
  sortJa?: string,
  teamId?: string,
  teamOrganizationId?: string,
  primaryImage?: string,
  id?: string
) => {
  try {
    const significantPlayerInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      `urlSlug: ${urlSlug === "" ? "null" : JSON.stringify(urlSlug)}`,
      `teamId: ${teamId === "" ? "null" : JSON.stringify(teamId)}`,
      `teamOrganizationId: ${teamOrganizationId === "" ? "null" : JSON.stringify(teamOrganizationId)}`,
      `primaryImage: ${primaryImage === "" ? "null" : JSON.stringify(primaryImage)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
      admin {
        significantPlayer {
          save (significantPlayer: {
            ${significantPlayerInput}
          }) {
            id
            name
            nameJa
            sortJa
            teamId
            urlSlug
            teamOrganizationId
            primaryImage
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateSignificantPlayerResponse

    return response.data?.admin?.significantPlayer?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create significant player")
  }
}

export type DeleteSignificantPlayerResponse = {
  data?: {
    admin?: {
      significantPlayer?: {
        delete: SignificantPlayer
      }
    }
  }
}

export const deleteSignificantPlayer = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        significantPlayer {
          delete (
              significantPlayerId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteSignificantPlayerResponse

    return response.data?.admin?.significantPlayer?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete significant player")
  }
}
