import React from "react"

import TableList from "components/Table/TableList"

type Props = {
  text: string
}

export const NoItems = ({ text }: Props) => {
  return (
    <TableList.BodyRow>
      <TableList.BodyCell>{text}</TableList.BodyCell>
    </TableList.BodyRow>
  )
}
