import React from "react"

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  isUnlockDialogOpenState: boolean
  onUnlock: () => void
  onCancelUnlock: () => void
}

const UnlockDialog = (props: Props) => {
  const { t } = useTranslation("taiyoro")

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-unlock"
      open={props.isUnlockDialogOpenState}
    >
      <DialogTitle id="confirmation-unlock">{t("edit.dates.streams.lock.title")}</DialogTitle>
      <DialogContent dividers>{t("edit.dates.streams.lock.warning")}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.onCancelUnlock}
          color="primary"
          variant="contained"
        >
          {t("edit.dates.streams.lock.cancel")}
        </Button>
        <Button
          onClick={props.onUnlock}
          color="error"
          variant="outlined"
        >
          {t("edit.dates.streams.lock.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnlockDialog
