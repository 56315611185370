import React from "react"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import PropTypes from "prop-types"

const ErrorDialog = (props) => (
  <Dialog
    open={props.open}
    onClose={props.onClose}
  >
    <DialogTitle>Error</DialogTitle>
    <DialogContent>
      <DialogContentText>{props.message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={props.onClose}
        color="primary"
        variant="contained"
        autoFocus
      >
        Dismiss
      </Button>
    </DialogActions>
  </Dialog>
)

ErrorDialog.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ErrorDialog
