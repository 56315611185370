import GraphQL from "utils/graphql"

import type { APIResponseNoData, APIResponse } from "./types/common"

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

const runQuery = async (query: string) =>
  await GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

type WithId = {
  id: string
}

export type ParticipantAnswer = {
  id: string
  type: "team" | "player"
}

export type FreetextAnswer = {
  id: string
  content: string
  contentJa: string
}

export type ParticipantAnswerOptions = {
  type: "participant"
  data: Array<ParticipantAnswer>
}

export type FreetextAnswerOptions = {
  type: "freetext"
  data: Array<FreetextAnswer>
}

export type AnswerOptions = FreetextAnswerOptions | ParticipantAnswerOptions

type StringifiedAnswerOptions = {
  type: "participant" | "freetext"
  data: string
}

export type DailyPickemType = "match" | "quiz"

type DailyPickemBase = {
  eventId: string
  dateId: string
  type: DailyPickemType
  prompt: string | null
  promptJa: string
  reward: number
  deadline: string
  priority: number | null
  groupId: string | null
}

export type DailyPickemCreate = DailyPickemBase & {
  answerOptions: AnswerOptions
}

export type DailyPickemUpdate = WithId &
  DailyPickemBase & {
    correctAnswer: string | null
    answerOptions: AnswerOptions
  }

type DailyPickemFetch = WithId &
  DailyPickemBase & {
    correctAnswer: string | null
    pointsHaveBeenAwarded: 1 | 0
    answerOptions: StringifiedAnswerOptions
  }

export type DailyPickemStatus = "saved" | "modified" | "new" | "deletedFromSaved" | "deletedFromModified"

export type DailyPickemSaved = DailyPickemUpdate & {
  status: "saved"
  pointsHaveBeenAwarded: 1 | 0
}

export type DailyPickemNew = WithId &
  DailyPickemCreate & {
    status: "new"
  }

export type DailyPickemModified = DailyPickemUpdate & {
  status: "modified"
}

export type DailyPickemDeleted = DailyPickemUpdate & {
  status: "deletedFromSaved" | "deletedFromModified"
}

export type DailyPickemAny = DailyPickemSaved | DailyPickemNew | DailyPickemModified | DailyPickemDeleted

type FetchDailyPickemListResponse = APIResponse<Array<DailyPickemFetch>>

type FetchDailyPickemListParams = {
  eventId: string
}

export const fetchDailyPickemList = async ({
  eventId,
}: FetchDailyPickemListParams): Promise<FetchDailyPickemListResponse> => {
  try {
    const query = `query {
      pickem {
        getDailyPickemsForEvent(eventId: "${eventId}") {
          id
          eventId
          dateId
          prompt
          promptJa
          reward
          deadline
          correctAnswer
          pointsHaveBeenAwarded
          awardedPointValue
          type
          groupId
          priority
          answerOptions {
            type
            data
          }
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.getDailyPickemsForEvent && {
        status: "success",
        data: response.data.pickem.getDailyPickemsForEvent,
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

type SaveDailyPickemParams = {
  dailyPickem: DailyPickemNew | DailyPickemModified | DailyPickemSaved
}

type SaveDailyPickemResponse = APIResponse<{ id: string }>

export const saveDailyPickem = async ({
  dailyPickem: {
    id,
    dateId,
    prompt,
    promptJa,
    reward,
    deadline,
    type,
    answerOptions,
    priority,
    groupId,
    ...params
  },
}: SaveDailyPickemParams): Promise<SaveDailyPickemResponse> => {
  try {
    const correctAnswer =
      params.status === "modified" || params.status === "saved" ? params.correctAnswer : null

    const query = `mutation {
      pickem {
        saveDailyPickem(dailyPickem: {
          ${params.status !== "new" ? `id: "${id}",` : ""}
          dateId: "${dateId}",
          prompt: ${JSON.stringify(prompt)},
          promptJa: ${JSON.stringify(promptJa)}, 
          reward: ${reward},
          deadline: "${deadline}",
          correctAnswer: ${correctAnswer ? `"${correctAnswer}"` : `null`},
          type: "${type}",
          answerOptions: {
            type: "${answerOptions.type}",
            data: "${JSON.stringify(answerOptions.data).replace(/"/g, '\\"')}", 
          },
          priority: ${priority !== null ? priority : 0}, 
          groupId: "${groupId !== null ? groupId : ``}"
        }) {
          id
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.saveDailyPickem && {
        status: "success",
        data: response.data.pickem.saveDailyPickem.id,
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

type DeleteDailyPickemParams = {
  dailyPickemId: string
}

export const deleteDailyPickem = async ({
  dailyPickemId,
}: DeleteDailyPickemParams): Promise<APIResponseNoData> => {
  try {
    const query = `mutation {
      pickem {
        deleteDailyPickem(dailyPickemId: "${dailyPickemId}")
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.deleteDailyPickem && {
        status: "success",
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

export type DailyPickemGroupStatus = "saved" | "modified" | "new" | "deletedFromSaved" | "deletedFromModified"

export type DailyPickemGroup = {
  id: string
  dateId: string
  name: string | null
  nameJa: string
  priority: number
}

export type DailyPickemGroupSaved = DailyPickemGroup & {
  status: "saved"
}

export type DailyPickemGroupNew = DailyPickemGroup & {
  status: "new"
}

export type DailyPickemGroupModified = DailyPickemGroup & {
  status: "modified"
}

export type DailyPickemGroupDeleted = DailyPickemGroup & {
  status: "deletedFromSaved" | "deletedFromModified"
}

export type DailyPickemGroupAny =
  | DailyPickemGroupSaved
  | DailyPickemGroupNew
  | DailyPickemGroupModified
  | DailyPickemGroupDeleted

type FetchDailyPickemGroupListParams = {
  eventId: string
}

export const fetchDailyPickemGroupList = async ({
  eventId,
}: FetchDailyPickemGroupListParams): Promise<APIResponse<Array<DailyPickemGroup>>> => {
  try {
    const query = `query {
      pickem {
        getDailyPickemGroupsForEvent(eventId: "${eventId}") {
          id
          dateId
          name
          nameJa
          priority
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.getDailyPickemGroupsForEvent && {
        status: "success",
        data: response.data.pickem.getDailyPickemGroupsForEvent,
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

type SaveDailyPickemGroupParams = {
  dailyPickemGroup: DailyPickemGroupNew | DailyPickemGroupModified | DailyPickemGroupSaved
}

type SaveDailyPickemGroupResponse = APIResponse<{ id: string }>

export const saveDailyPickemGroup = async ({
  dailyPickemGroup: { id, dateId, name, nameJa, priority, ...params },
}: SaveDailyPickemGroupParams): Promise<SaveDailyPickemGroupResponse> => {
  try {
    const query = `mutation {
      pickem {
        saveDailyPickemGroup(dailyPickemGroup: {
          ${params.status !== "new" ? `id: "${id}",` : ""}
          dateId: "${dateId}",
          name: ${name === "" ? "null" : JSON.stringify(name)},
          nameJa: ${JSON.stringify(nameJa)}, 
          priority: ${priority}
        }) {
          id
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.saveDailyPickemGroup && {
        status: "success",
        data: response.data.pickem.saveDailyPickemGroup.id,
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

type DeleteDailyPickemGroupParams = {
  dailyPickemGroupId: string
}

export const deleteDailyPickemGroup = async ({
  dailyPickemGroupId,
}: DeleteDailyPickemGroupParams): Promise<APIResponseNoData> => {
  try {
    const query = `mutation {
      pickem {
        deleteDailyPickemGroup(dailyPickemGroupId: "${dailyPickemGroupId}")
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.deleteDailyPickemGroup && {
        status: "success",
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

type AwardDailyPickemPointsParams = {
  dailyPickemId: string
}

export const awardDailyPickemPoints = async ({
  dailyPickemId,
}: AwardDailyPickemPointsParams): Promise<APIResponseNoData> => {
  try {
    const query = `mutation {
      pickem {
        awardDailyPickemPoints(pickemId: "${dailyPickemId}")
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.awardDailyPickemPoints && {
        status: "success",
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}
