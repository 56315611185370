import React, { useContext } from "react"

import { Stack } from "@mui/material"
import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type moment from "moment-timezone"
import { useTranslation } from "react-i18next"

import { StyleButton } from "../../../StyleMaterialUI"

const Calendar = () => {
  const { eventListSearchParams, setEventListSearchParams } = useContext(TaiyoroDataContext)

  const { t } = useTranslation("taiyoro")

  const changeMonth = (month: moment.Moment) => {
    setEventListSearchParams({
      ...eventListSearchParams,
      month,
    })
  }

  return (
    <Stack
      gap={2}
      direction="row"
    >
      <StyleButton
        variant="contained"
        color="primary"
        onClick={() => changeMonth(eventListSearchParams.month.clone().subtract(1, "month"))}
      >
        &larr;
      </StyleButton>
      <DatePicker
        views={["year", "month"]}
        label={t("list.mode.calendar.month")}
        value={eventListSearchParams.month}
        renderInput={(props) => (
          <TextField
            style={{ width: "100%" }}
            {...props}
          />
        )}
        onChange={(value) => changeMonth(value)}
      />
      <StyleButton
        variant="contained"
        color="primary"
        onClick={() => changeMonth(eventListSearchParams.month.clone().add(1, "month"))}
      >
        &rarr;
      </StyleButton>
    </Stack>
  )
}

export default Calendar
