import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import {
  fetchTeamOrganizations,
  createTeamOrganization,
  deleteTeamOrganization,
} from "../../../services/Taiyoro/teamOrganizations"

const MetaTeamOrganizationPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.teamOrganizations")}
      fetchFunc={fetchTeamOrganizations}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) =>
        createTeamOrganization(name, nameJa, sortJa, primaryImage, secondaryImage, urlSlug, homepageUrl, id)
      }
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) =>
        createTeamOrganization(name, nameJa, sortJa, primaryImage, secondaryImage, urlSlug, homepageUrl, id)
      }
      deleteFunc={deleteTeamOrganization}
      metaType={MetaType.TEAM_ORGANIZATION}
      primaryImageFieldsAndRatio={16 / 9}
      secondaryImageFieldsAndRatio={1}
      slugFields
      homepageUrlFields
    />
  )
}

export default MetaTeamOrganizationPage
