import { useEffect, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { faSpinner, faUndo } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Stack,
  MenuItem,
  Select,
  Pagination,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  Tooltip,
  Link,
  Typography,
} from "@mui/material"
import { LocalMomentFromUTCDateTime } from "models/Taiyoro/eventDate"
import { useTranslation } from "react-i18next"
import { fetchIgnoreList, PLATFORM_CHOICES, removeFromIgnoreList } from "services/Taiyoro/dataCollection"

import LoadingBox from "../../components/LoadingBox"
import { StylePaper } from "../../components/StyleMaterialUI"

const PAGE_SIZE = 50

type Query = {
  platform: string
  page: number
}

const IgnoreList = () => {
  const { t } = useTranslation("data-collection")

  const { user } = useAuth0()

  const [loading, setLoading] = useState(false)

  const [results, setResults] = useState(null)

  const [errorState, setErrorState] = useState(false)

  const [query, setQuery] = useState<Query>({
    platform: PLATFORM_CHOICES[0].label,
    page: 1,
  })

  const [totalCount, setTotalCount] = useState<null | number>(null)

  const [removeItem, setRemoveItem] = useState<null | any>(null)

  const [removingItemPending, setRemovingItemPending] = useState(false)

  const [removeItemSuccessResponse, setRemoveItemSuccessResponse] = useState(null)

  const load = async () => {
    setLoading(true)
    fetchIgnoreList(query.platform, PAGE_SIZE * (query.page - 1), PAGE_SIZE)
      .then((response) => {
        setTotalCount(response.totalCount)
        setResults(response.results)
      })
      .catch(() => {
        setErrorState(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    load()
  }, [query])

  const handleRemoveItem = async () => {
    setRemovingItemPending(true)
    removeFromIgnoreList(removeItem.platformName, removeItem.userName, removeItem.channelUrl, user.nickname)
      .then((response) => {
        setRemoveItemSuccessResponse(response)
        load()
      })
      .catch(() => setRemoveItemSuccessResponse(false))
      .finally(() => {
        setRemovingItemPending(null)
        setRemoveItem(null)
      })
  }

  return (
    <>
      <StylePaper>
        {loading && <LoadingBox />}
        {!loading && errorState && <Alert severity="error">{t("ignore.apiError")}</Alert>}
        {!loading && results && (
          <Stack gap={1}>
            <Box>
              <Select
                required
                value={query.platform}
                onChange={(e) => {
                  setQuery({ platform: e.target.value, page: 1 })
                }}
              >
                {PLATFORM_CHOICES.map((platformChoice) => (
                  <MenuItem
                    key={platformChoice.label}
                    value={platformChoice.label}
                  >
                    {platformChoice.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("explore.table.userName")}</TableCell>
                  <TableCell>{t("explore.table.streamTitle")}</TableCell>
                  <TableCell>{t("ignore.table.channelDescription")}</TableCell>
                  <TableCell>{t("ignore.table.date")}</TableCell>
                  <TableCell>{t("ignore.table.user")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={`${result.platform}-${result.userName}`}>
                    <TableCell>
                      <Link
                        target="_blank"
                        href={result.channelUrl}
                      >
                        {result.userName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography>{result.streamTitle}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {result.channelDescription}
                        {result.channelDescription?.length === 128 ? "..." : ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {LocalMomentFromUTCDateTime(result.createdAt)
                        .locale(localStorage.getItem("language") || "en")
                        .format("YYYY/MM/DD hh:mm A")}
                    </TableCell>
                    <TableCell>{result.adminUsername}</TableCell>
                    <TableCell align="right">
                      <Tooltip title={t("ignore.remove")}>
                        <IconButton onClick={() => setRemoveItem(result)}>
                          <FontAwesomeIcon icon={faUndo} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {totalCount !== null && (
              <Pagination
                sx={{ alignSelf: "center" }}
                count={Math.ceil(totalCount / PAGE_SIZE)}
                page={query.page}
                onChange={(_event, newPageNumber) =>
                  setQuery({ platform: query.platform, page: newPageNumber })
                }
              />
            )}
          </Stack>
        )}
      </StylePaper>
      <Snackbar
        open={removeItemSuccessResponse !== null}
        onClose={() => setRemoveItemSuccessResponse(null)}
      >
        <Alert severity={removeItemSuccessResponse === true ? "success" : "error"}>
          {removeItemSuccessResponse === true ? t("ignore.removeItemSuccess") : t("ignore.apiError")}
        </Alert>
      </Snackbar>
      {removeItem && (
        <Dialog
          open
          onClose={() => setRemoveItem(null)}
        >
          <DialogContent>{t("ignore.removeItemConfirmation")}</DialogContent>
          <DialogActions>
            <Button
              onClick={handleRemoveItem}
              autoFocus
              variant="outlined"
              color="error"
              disabled={removingItemPending}
            >
              {t("ignore.removeConfirm")}
              {removingItemPending && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  transform="right-6"
                  spin
                />
              )}
            </Button>
            <Button
              onClick={() => setRemoveItem(null)}
              color="primary"
              autoFocus
              variant="contained"
              disabled={removingItemPending}
            >
              {t("common:actions.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default IgnoreList
