import React from "react"
import { useState, useEffect } from "react"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useTranslation } from "react-i18next"
import { Prompt } from "react-router"
import { useBeforeUnload } from "react-use"

import { errorContext } from "../../../.."

interface Props {
  forceOpen: boolean
  onModalClosed: () => void
}

const InvalidStateDialog = (props: Props) => {
  const { t } = useTranslation("taiyoro")
  const interceptMessage = t("edit.errors.navigationWarning")
  const [errorState, setErrorState] = errorContext()
  const [showDialog, setShowDialog] = useState(false)

  const interceptNavigationAway = () => {
    if (errorState.length > 0) {
      setShowDialog(true)
      return true
    }
    return false
  }

  useEffect(() => {
    setErrorState([])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.forceOpen && !showDialog) {
      setShowDialog(true)
    }
    // eslint-disable-next-line
  }, [props.forceOpen])

  useBeforeUnload(interceptNavigationAway, interceptMessage)

  const handleClose = () => {
    props.onModalClosed()
    setShowDialog(false)
  }

  return (
    <>
      <Dialog
        open={showDialog && errorState.length > 0}
        onClose={handleClose}
      >
        <DialogTitle>{t("edit.errors.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {[...errorState].reverse().map((error) => (
              <p key={error}>{error}</p>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            {t("edit.errors.dismiss")}
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
        when={errorState.length > 0}
        message={interceptMessage}
      />
    </>
  )
}

export default InvalidStateDialog
