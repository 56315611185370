import React, { useState } from "react"

import { MetaPageContext } from "contexts/meta-page"
import type { SortParams } from "contexts/meta-page"

import type { MetaItem } from "../types"

export const MetaPageProvider = ({ children }: { children: React.ReactNode }) => {
  // Data
  const [metaListState, setMetaListState] = useState<Array<MetaItem>>([])
  const [preserveMetaListState, setPreserveMetaListState] = useState<Array<MetaItem>>([])

  // Pagination
  const [paginateNumberOfRecords, setPaginateNumberOfRecords] = useState(20)
  const [paginatePage, setPaginatePage] = useState(1)
  const [paginatedMetaListData, setPaginatedMetaListData] = useState<Array<MetaItem>>([])

  // Utility
  const [errorState, setErrorState] = useState<Error | string | null>(null)
  const [isLoadingState, setIsLoadingState] = useState(true)
  const [isSavingState, setIsSavingState] = useState(false)

  // Table Utility
  const [sortParamsState, setSortParamsState] = useState<SortParams>({
    field: "ja",
    sort: "asc",
  })
  const [deleteMetaObjectState, setDeleteMetaObjectState] = useState<MetaItem | null>(null)

  return (
    <MetaPageContext.Provider
      value={{
        // Data
        metaListState,
        setMetaListState,
        preserveMetaListState,
        setPreserveMetaListState,

        // Pagination
        paginateNumberOfRecords,
        setPaginateNumberOfRecords,
        paginatePage,
        setPaginatePage,
        paginatedMetaListData,
        setPaginatedMetaListData,

        // Utility
        errorState,
        setErrorState,
        isLoadingState,
        setIsLoadingState,
        isSavingState,
        setIsSavingState,

        // Table Header Utility
        sortParamsState,
        setSortParamsState,
        deleteMetaObjectState,
        setDeleteMetaObjectState,
      }}
    >
      {children}
    </MetaPageContext.Provider>
  )
}
