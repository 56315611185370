import { useContext } from "react"

import { Box, MenuItem, TextField } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"

interface Props {
  value: string
  updateValue: (value: string) => void
  updateStatus?: () => void
  availableGroupIds: Array<string>
}

export const GroupControl = ({ value, updateValue, updateStatus, availableGroupIds }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")
  const { dailyPickemGroupList } = useContext(PickemSettingsContext)

  const groupOptions = availableGroupIds
    .filter((groupId) => {
      const group = dailyPickemGroupList.find((group) => group.id === groupId)
      return group?.status !== "deletedFromSaved" && group?.status !== "deletedFromModified"
    })
    .map((groupId) => {
      const group = dailyPickemGroupList.find((group) => group.id === groupId)
      return (
        <MenuItem
          key={groupId}
          value={groupId}
          disabled={group?.status === "new"}
        >
          {language === "ja" || !group?.name ? group?.nameJa : group.name}
          {group?.status === "new" && " (Save to add to this group)"}
        </MenuItem>
      )
    })

  const noGroupValue = (
    <MenuItem
      key={"noGroup"}
      value={""}
    >
      {t("eventPickem.daily.modal.noGroup")}
    </MenuItem>
  )

  return (
    <Box>
      <TextField
        select
        label={t("eventPickem.daily.modal.group")}
        value={value}
        onChange={(event) => {
          updateValue(event.target.value)
          updateStatus?.()
        }}
        fullWidth
      >
        {[...groupOptions, noGroupValue]}
      </TextField>
    </Box>
  )
}
