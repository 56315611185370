import type Sponsor from "../../models/Taiyoro/Meta/Sponsor"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type SponsorsResponse = {
  data?: {
    admin?: {
      sponsor?: {
        get?: Array<Sponsor> | null
      }
    }
  }
}

export const fetchSponsors = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          sponsor {
            get {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as SponsorsResponse

    return response.data?.admin?.sponsor?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch sponsors")
  }
}

export type SponsorResponse = {
  data?: {
    admin?: {
      sponsor?: {
        get?: Sponsor | null
      }
    }
  }
}

export const fetchSponsor = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          sponsor {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as SponsorResponse

    return response.data?.admin?.sponsor?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch sponsor")
  }
}

export type CreateSponsorResponse = {
  data?: {
    admin?: {
      sponsor?: {
        save?: Sponsor | null
      }
    }
  }
}

export const createSponsor = async (name: string, nameJa?: string, sortJa?: string, id?: string) => {
  try {
    const sponsorInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
      admin {
        sponsor {
          save (sponsor: {
            ${sponsorInput}
          }) {
            id
            name
            nameJa
            sortJa
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateSponsorResponse

    return response.data?.admin?.sponsor?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create sponsor")
  }
}

export type DeleteSponsorResponse = {
  data?: {
    admin?: {
      sponsor?: {
        delete: Sponsor
      }
    }
  }
}

export const deleteSponsor = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        sponsor {
          delete (
              sponsorId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteSponsorResponse

    return response.data?.admin?.sponsor?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete sponsor")
  }
}
