import { styled, Box } from "@mui/material"

export const Root = styled(Box)`
  &:nth-child(2) {
    margin-top: 0;
  }
`

export const Title = styled(Box)`
  font-family: "Noto Sans JP";
  font-size: 16px;
  font-weight: bold;
  margin-top: 6px;
`

export const HelperText = styled(Box)`
  color: #ababab;
  font-family: "Noto Sans JP";
`
