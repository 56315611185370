import React from "react"
import type { ReactNode } from "react"

import { faBars } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box } from "@mui/material"
import { NotificationCenter } from "components/PageLayout/NotificationCenter"
import ArticlesTodoProvider from "components/Taiyoro/Articles/ArticlesTodoProvider"
import { useLocalStorage } from "react-use"

import SiteLanguage from "../../PageLayout/SiteLanguage"
import ThemeSelector from "../../PageLayout/ThemeSelector"
import UserProfile from "../../PageLayout/UserProfile"
import { StyleAppBar, StyleToolbar, StyleCssBaseline } from "../../StyleMaterialUI"
import QuickSearch from "../QuickSearch"
import Sidebar from "../Sidebar"
import {
  StylePageWrapper,
  StyleIconButtonMenuToggle,
  StyleMainContent,
  StyleLanguageWrapper,
  DrawerHeader,
} from "./styles"

interface Props {
  children?: ReactNode
}

const PageWrapper = ({ children }: Props) => {
  const [value, setValue] = useLocalStorage("sideBarOpen", "true")

  const open = value === "true"

  const handleDrawerToggle = () => {
    setValue(open ? "false" : "true")
  }

  const handleDrawerClose = () => {
    setValue("false")
  }

  return (
    <ArticlesTodoProvider>
      <StyleCssBaseline />
      <NotificationCenter />
      <StylePageWrapper>
        <StyleAppBar className={open ? "shift" : ""}>
          <StyleToolbar>
            <StyleIconButtonMenuToggle
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={open ? "hide" : ""}
            >
              <FontAwesomeIcon
                icon={faBars}
                size="1x"
              />
            </StyleIconButtonMenuToggle>
            <Box ml="auto">
              <QuickSearch />
            </Box>
            <ThemeSelector />
            <StyleLanguageWrapper>
              <SiteLanguage />
            </StyleLanguageWrapper>
            <UserProfile />
          </StyleToolbar>
        </StyleAppBar>
        <Sidebar
          open={open}
          onClose={handleDrawerClose}
        />
        <StyleMainContent className={open ? "open" : ""}>
          <DrawerHeader />
          {children}
        </StyleMainContent>
      </StylePageWrapper>
    </ArticlesTodoProvider>
  )
}

export default PageWrapper
