import React, { useEffect } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import Dashboard from "components/Dashboard"
import PlayDataPuppeteer from "components/PlayData/puppeteer"
import { ContextProvider } from "globalState/state"
import i18n from "i18next"
import { ErrorContextProvider } from "index"
import { UserRole } from "models/UserManagement"
import { useLocalStorage, useSearchParam } from "react-use"
import useRolesCanAccess from "utils/useRolesCanAccess"

import LoadingBox from "./components/LoadingBox"
import { StyleCssBaseline } from "./components/StyleMaterialUI"
import { ID_TOKEN_KEY } from "./utils/graphql"
import "./utils/i18n"

const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, user, getIdTokenClaims, error } = useAuth0()

  const [value, setValue, remove] = useLocalStorage<string | null>(ID_TOKEN_KEY, null, { raw: true })

  const isPuppeteerBot = useRolesCanAccess([UserRole.BOT])

  const language = useSearchParam("language")

  useEffect(() => {
    language && i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    const url = new URL(window.location.href)
    !isLoading &&
      !isAuthenticated &&
      !error &&
      loginWithRedirect({ appState: { returnTo: url.pathname + url.search } })
  }, [isLoading, isAuthenticated])

  const handleUserSignedIn = async () => {
    const tokenData = await getIdTokenClaims()
    const token = tokenData?.__raw as string
    setValue(token)
  }

  useEffect(() => {
    user && handleUserSignedIn()
    !user && remove()
  }, [user])

  return (
    <ContextProvider>
      <ErrorContextProvider>
        <StyleCssBaseline />
        <>
          {isLoading && <LoadingBox />}
          {!isLoading && isAuthenticated && value && (
            <>
              {!isPuppeteerBot && <Dashboard />}
              {isPuppeteerBot && <PlayDataPuppeteer />}
            </>
          )}
        </>
      </ErrorContextProvider>
    </ContextProvider>
  )
}

export default App
