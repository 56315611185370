import type Placement from "models/Taiyoro/Meta/Placement"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type PlacementsResponse = {
  data?: {
    admin?: {
      placement?: {
        get?: Array<Placement>
      }
    }
  }
}

export const fetchPlacements = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          placement {
            get {
              id
              name
              nameJa
              sortJa
              quantifiable
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as PlacementsResponse

    return response.data?.admin?.placement?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch placements")
  }
}

export type PlacementResponse = {
  data?: {
    admin?: {
      placement?: {
        get?: Placement | null
      }
    }
  }
}

export const fetchPlacement = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          placement {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              quantifiable
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as PlacementResponse

    return response.data?.admin?.placement?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch placement")
  }
}

export type CreatePlacementResponse = {
  data?: {
    admin?: {
      placement?: {
        save?: Placement | null
      }
    }
  }
}

export const createPlacement = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  quantifiable?: number | null,
  id?: string | null
) => {
  try {
    const placementInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      quantifiable !== undefined ? `quantifiable: ${quantifiable === 1 ? 1 : 0}` : null,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
      admin {
        placement {
          save (placement: {
            ${placementInput}
          }) {
            id
            name
            nameJa
            sortJa
            quantifiable
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreatePlacementResponse

    return response.data?.admin?.placement?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create placement")
  }
}

export type DeletePlacementResponse = {
  data?: {
    admin?: {
      placement?: {
        delete: Placement
      }
    }
  }
}

export const deletePlacement = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        placement {
          delete (
              placementId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeletePlacementResponse

    return response.data?.admin?.placement?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete placement")
  }
}
