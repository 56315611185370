import { createTheme } from "@mui/material/styles"

declare module "@mui/material/styles" {
  interface CommonColors {
    black: string
    white: string
    blue: string
    green: string
    pink: string
    orange: string
    yellow: string
    teal: string
    dusk: string
    grass: string
    sea: string
    straw: string
    raspberry: string
    smoke: string
    iron: string
    sky: string
    squirrel: string
    pumpkin: string
    lavendar: string
    emerald: string
    nightSky: string
    aquaMarine: string
    plum: string
  }

  interface TypeBackground {
    paper: string
    default: string
    border: string
  }

  interface Palette {
    common: CommonColors
    background: TypeBackground
  }
}

export const playDataTheme = createTheme({
  palette: {
    mode: "light",
    common: {
      black: "#000000",
      white: "#FFFFFF",
      blue: "#00B3FF",
      green: "#00DB70",
      pink: "#FF1478",
      orange: "#FD8300",
      yellow: "#FFDF82",
      teal: "#68EDCB",
      dusk: "#5B598A",
      grass: "#00924A",
      sea: "#3B938D",
      straw: "#DEBA6F",
      raspberry: "#B7124E",
      smoke: "#D5C7BC",
      iron: "#737675",
      sky: "#0075E4",
      squirrel: "#A53C00",
      pumpkin: "#C16200",
      lavendar: "#BA7ABF",
      emerald: "#12664F",
      nightSky: "#004E98",
      aquaMarine: "#006D77",
      plum: "#861657",
    },
    text: {
      primary: "#19191E",
    },
    background: {
      paper: "#FFFFFF",
      default: "#FFFFFF",
      border: "linear-gradient(41deg, #00B3FF, #00DB70)",
    },
  },
  typography: {
    fontFamily: `"Noto Sans JP", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
})

export const playDataThemeDark = createTheme({
  ...playDataTheme,
  palette: {
    mode: "dark",
    background: {
      border: "linear-gradient(41deg, #00B3FF, #00DB70)",
    },
  },
  typography: {
    fontFamily: `"Noto Sans JP", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
})
