import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, matchPath } from "react-router"

import { StyleNavLinkButton } from "../../PageLayout/Sidebar/styles"
import { StyleListItem, StyleListItemText } from "../../StyleMaterialUI"

const ScoutingSidebarEntries = () => {
  const location = useLocation()

  const { t } = useTranslation("scouting")

  const isScoutingRoute = matchPath(location.pathname, {
    path: "/scouting",
  })

  return (
    <>
      {isScoutingRoute && (
        <>
          <StyleNavLinkButton
            exact
            to="/scouting"
          >
            <StyleListItem selected={location.pathname === "/scouting"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSearch} />
              </ListItemIcon>
              <StyleListItemText primary={t("pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
        </>
      )}
    </>
  )
}

export default ScoutingSidebarEntries
