import { styled, Box } from "@mui/material"
import { Grid } from "@mui/material"

export const DateText = styled(Box)`
  padding: 2px 4px;
`

export const DateFirst = styled(Grid)`
  text-align: right;
  font-size: 14px;
`

export const DateLast = styled(Grid)`
  text-align: left;
  font-size: 14px;
`

export const TimeContainer = styled(Grid)`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`

export const TimeFirst = styled(TimeContainer)`
  text-align: right;
`

export const TimeLast = styled(TimeContainer)`
  text-align: left;
`
