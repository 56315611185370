import React from "react"

import { faDownload, faEdit, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import LoadingBox from "../../components/LoadingBox"
import CreateUser from "../../components/UserManagement/create-user"
import { Products } from "../../models/UserManagement"
import type { User } from "../../models/UserManagement"
import { downloadTaiyoroUserCSV, fetchUsers } from "../../services/UserManagement/user"
import { ADMIN_ROLES } from "../../utils/roles"
import useRolesCanAccess from "../../utils/useRolesCanAccess"
import UserDelete from "./user-delete"

const UsersList = () => {
  const params = useParams<{ product: string }>()
  const [productState, setProductState] = React.useState(params.product)
  const [loadingState, setLoadingState] = React.useState(true)
  const [deleteUserState, setDeleteUserState] = React.useState<null | User>(null)
  const [usersState, setUsersState] = React.useState<Array<User>>([])
  const [paginationKeyState, setPaginationKeyState] = React.useState<string | null>(null)
  const [searchIdState, setSearchIdState] = React.useState<string>("")
  const { t } = useTranslation(["user-management", "common"])
  const history = useHistory()
  const isAdmin = useRolesCanAccess([...ADMIN_ROLES])

  const getUsers = async (append: boolean = false) => {
    setLoadingState(true)
    fetchUsers(productState, paginationKeyState, searchIdState)
      .then(({ users, paginationKey }) => {
        setUsersState(append ? [...usersState, ...users] : users)
        setPaginationKeyState(paginationKey)
      })
      .catch(() => setUsersState([]))
      .finally(() => setLoadingState(false))
  }

  React.useEffect(() => {
    if (productState !== params.product) {
      setPaginationKeyState(null)
      setProductState(params.product)
    }
    // eslint-disable-next-line
  }, [params])

  React.useEffect(() => {
    getUsers()
    // eslint-disable-next-line
  }, [productState])

  return (
    <>
      <Box
        mb="12px"
        position="relative"
      >
        <CreateUser />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <TextField
            label={t("search")}
            value={searchIdState}
            type="search"
            variant="outlined"
            autoComplete="off"
            sx={{ marginLeft: "12px", marginRight: "12px" }}
            onChange={(event) => {
              setPaginationKeyState(null)
              setSearchIdState(event.target.value || "")
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                getUsers()
              }
            }}
          />
          {productState === Products.TAIYORO && (
            <>
              <Button
                onClick={() => downloadTaiyoroUserCSV("SUMMARY")}
                variant="contained"
              >
                <FontAwesomeIcon icon={faDownload} />
                <Typography sx={{ marginLeft: "6px" }}>{t("download.summary")}</Typography>
              </Button>
              <Button
                onClick={() => downloadTaiyoroUserCSV("FAVORITES")}
                variant="contained"
              >
                <FontAwesomeIcon icon={faDownload} />
                <Typography sx={{ marginLeft: "6px" }}>{t("download.favorites")}</Typography>
              </Button>
            </>
          )}
        </Stack>
      </Box>
      {loadingState && <LoadingBox />}
      {!loadingState && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("fields.id")}</TableCell>
                <TableCell>{t("fields.extendedId")}</TableCell>
                <TableCell>{t("fields.email")}</TableCell>
                <TableCell>{t("fields.username")}</TableCell>
                <TableCell>{t("fields.role")}</TableCell>
                <TableCell>{t("fields.created")}</TableCell>
                <TableCell>{t("fields.profileImage")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersState.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {user.id}
                  </TableCell>
                  <TableCell>{user.extendedId}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>{user.created}</TableCell>
                  <TableCell>{user.profileImageUrl}</TableCell>
                  <TableCell>
                    <Grid
                      container
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Tooltip title={t("common:actions.edit")}>
                          <IconButton
                            onClick={() => history.push(`/users/${productState}/${user.id}/edit`)}
                            sx={{ width: "32px", height: "32px" }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      {isAdmin && (
                        <Grid item>
                          <Tooltip title={t("common:actions.delete")}>
                            <IconButton
                              onClick={() => setDeleteUserState(user)}
                              sx={{ width: "32px", height: "32px" }}
                              color="error"
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {usersState.length > 0 && (
        <Button
          onClick={() => getUsers(true)}
          variant="contained"
          sx={{ marginTop: "12px" }}
          disabled={!paginationKeyState}
        >
          {t("common:actions.loadMore")}
        </Button>
      )}
      {deleteUserState && (
        <UserDelete
          product={productState}
          user={deleteUserState}
          onCancel={() => setDeleteUserState(null)}
        />
      )}
    </>
  )
}

export default UsersList
