import React from "react"

import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import type { SortField, SortState } from "../../../../pages/Taiyoro/Analytics/entities"
import { TABLE_IDENTIFIER } from "../../../../pages/Taiyoro/Analytics/eventReports"
import { SortableTableCell, SortArrow } from "./styles"

interface Props {
  fields: SortField[]
  data: any[]
  sort: SortState
  onSortChange: (sort: SortState) => void
}

const AnalyticsTable = ({ fields, data, sort, onSortChange }: Props) => {
  const handleSortChange = (field: SortField) => {
    field === sort.field && onSortChange({ field: field, order: sort.order === "DESC" ? "ASC" : "DESC" })
    field !== sort.field && onSortChange({ field: field, order: "DESC" })
  }

  const theme = useTheme()
  const { t } = useTranslation("taiyoro")

  return (
    <Table
      id={TABLE_IDENTIFIER}
      style={{
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <colgroup>
        {fields.map((field) => (
          <col style={field === sort.field ? { backgroundColor: theme.palette.action.focus } : {}} />
        ))}
      </colgroup>
      <TableHead>
        <TableRow>
          {fields.map((field) => (
            <SortableTableCell onClick={() => handleSortChange(field)}>
              {t(`analytics.results.${field}`)}
              <SortArrow component="span">
                {sort.field === field && (
                  <FontAwesomeIcon
                    color="#f30362"
                    icon={sort.order === "ASC" ? faArrowUp : faArrowDown}
                  />
                )}
              </SortArrow>
            </SortableTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((dataEntry, index) => (
          <TableRow key={index}>
            {fields.map((field) => (
              <TableCell>{dataEntry[field]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default AnalyticsTable
