import React, { useState } from "react"

import { faHistory } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Dialog,
  DialogContent,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
  Link,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { LocalMomentFromUTCDateTime } from "../../../models/Taiyoro/eventDate"
import { Products } from "../../../models/UserManagement"
import { fetchEvent } from "../../../services/Taiyoro/event"
import { fetchLogsForEntities } from "../../../services/Taiyoro/logs"
import { getUserById } from "../../../services/UserManagement/user"

interface Props {
  id: string
}

enum EntityType {
  EVENT = "event",
  EVENT_GAME = "event_game",
  EVENT_SPONSOR = "event_sponsor",
  DATE = "date",
  DATE_PLATFORM = "date_platform",
}

const LOG_PAGE_SIZE = 50

const ViewLogs = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [logsState, setLogsState] = useState([])
  const [loadingState, setLoadingState] = useState(true)
  const [userNameMapState, setUserNameMapState] = useState({})
  const [isMoreState, setIsMoreState] = useState(false)
  const { t } = useTranslation("taiyoro")

  const getUserNameFromId = (userId) => {
    return getUserById(Products.PLAYBRAIN, userId).then((user) => {
      let object = {}
      object[userId] = user.username || ""
      return object
    })
  }

  const handleClick = () => {
    setOpen(true)
    fetchData()
  }

  const handleClose = () => {
    setLoadingState(true)
    setLogsState([])
    setIsMoreState(false)
    setOpen(false)
  }

  const fetchData = async () => {
    setLoadingState(true)
    let event = await fetchEvent(props.id)
    if (event) {
      //Figure out the id's and types of the entities we wish to appear in the log
      let entitiesToFetch: Array<any> = [{ entityType: EntityType.EVENT, entityID: props.id as any }]
      entitiesToFetch.push(
        ...event.games.map((game) => ({
          entityType: EntityType.EVENT_GAME,
          entityID: `${props.id}:${game.gameId}`,
        }))
      )
      entitiesToFetch.push(
        ...event.sponsors.map((sponsor) => ({
          entityType: EntityType.EVENT_SPONSOR,
          entityID: `${props.id}:${sponsor.id}`,
        }))
      )
      event.dates.forEach((date) => {
        entitiesToFetch.push({ entityType: EntityType.DATE, entityID: date.id })
        date.platforms.forEach((platform) => {
          entitiesToFetch.push({ entityType: EntityType.DATE_PLATFORM, entityID: platform.id })
        })
      })
      entitiesToFetch.push(
        ...event.dates.map((date) => ({ entityType: EntityType.EVENT_GAME, entityID: date.id }))
      )

      fetchLogsForEntities(entitiesToFetch, LOG_PAGE_SIZE, logsState.length).then((response) => {
        const results: Array<any> = response.data.admin.logging.get.results
        setIsMoreState(response.data.admin.logging.get.results.length === LOG_PAGE_SIZE)
        fetchNamesForLogs([...logsState, ...results])
        setLoadingState(false)
      })
    }
  }

  //Turn every userId in the logs into a userId -> userName map by searching algolia for every userId
  const fetchNamesForLogs = (newState: Array<{ userId: string }>) => {
    const allRequiredUserIds = [...new Set(newState.map((log) => log.userId))]
    const userIdsToFetch = allRequiredUserIds.filter((userId: string) => !userNameMapState[userId])
    Promise.allSettled(userIdsToFetch.map((userId) => getUserNameFromId(userId))).then(
      (settledPromises: any) => {
        const mergedObject = Object.assign(
          userNameMapState,
          ...settledPromises.map((settledPromise) => settledPromise.value)
        )
        setUserNameMapState(mergedObject)
        setLogsState(newState)
      }
    )
    // eslint-disable-next-line
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <DialogContent>
          {loadingState && logsState.length === 0 && <CircularProgress />}
          {!loadingState && logsState.length === 0 && <p>No logs found.</p>}
          {logsState.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Who</TableCell>
                      <TableCell>Log</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logsState.map((log, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {LocalMomentFromUTCDateTime(log.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                        </TableCell>
                        <TableCell>{userNameMapState[log.userId]}</TableCell>
                        <TableCell>
                          {log.actionType === "replace" && (
                            <p>
                              On
                              <Tooltip title={log.entityId}>
                                <Link href="#">{" " + log.entityType + " "}</Link>
                              </Tooltip>
                              replaced
                              <Tooltip title={log.changes.oldMetaId}>
                                <Link href="#">{" " + log.changes.type + " "}</Link>
                              </Tooltip>
                              with
                              <Tooltip title={log.changes.newMetaId}>
                                <Link href="#">{" " + log.changes.type}</Link>
                              </Tooltip>
                            </p>
                          )}
                          {log.actionType !== "replace" &&
                            log.changes.map((change, changeIndex) => (
                              <React.Fragment key={index + "-change-" + changeIndex}>
                                {log.actionType === "add" && (
                                  <p>
                                    Added new
                                    <Tooltip title={JSON.stringify(Object.values(change.new)[0], null, 2)}>
                                      <Link href="#">{" " + Object.keys(change.new)[0] + " "}</Link>
                                    </Tooltip>
                                    to
                                    <Tooltip title={log.entityId}>
                                      <Link href="#">{" " + log.entityType + " "}</Link>
                                    </Tooltip>
                                  </p>
                                )}
                                {log.actionType === "create" && (
                                  <p>
                                    Created new
                                    <Tooltip title={JSON.stringify(change.new, null, 2)}>
                                      <Link href="#">{" " + log.entityType + " "}</Link>
                                    </Tooltip>
                                  </p>
                                )}
                                {log.actionType === "remove" && (
                                  <p>
                                    Removed
                                    <Tooltip title={JSON.stringify(Object.values(change.old)[0], null, 2)}>
                                      <Link href="#">{" " + Object.keys(change.old)[0] + " "}</Link>
                                    </Tooltip>
                                    from
                                    <Tooltip title={log.entityId}>
                                      <Link href="#">{" " + log.entityType + " "}</Link>
                                    </Tooltip>
                                  </p>
                                )}
                                {log.actionType === "update" && (
                                  <p>
                                    On
                                    <Tooltip title={log.entityId}>
                                      <Link href="#">{" " + log.entityType + " "}</Link>
                                    </Tooltip>
                                    updated {change.key} from {JSON.stringify(change.old)} to{" "}
                                    {JSON.stringify(change.new)}{" "}
                                  </p>
                                )}
                              </React.Fragment>
                            ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchData()}
                disabled={!isMoreState || loadingState}
              >
                Load more{" "}
                {loadingState && (
                  <CircularProgress
                    style={{ marginLeft: "6px" }}
                    size={14}
                  />
                )}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
      <Button
        sx={{
          marginRight: "8px",
        }}
        onClick={handleClick}
        variant="contained"
      >
        <FontAwesomeIcon icon={faHistory} />
        <span style={{ marginLeft: "8px" }}>{t("logs.view")}</span>
      </Button>
    </>
  )
}

export default ViewLogs
