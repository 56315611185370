import { useContext, useEffect, useMemo, useState } from "react"

import { Box, Button, Modal, Stack, Typography } from "@mui/material"
import { PickemFunctionsContext, PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import type { DailyPickemGroupAny, DailyPickemGroupStatus } from "services/Taiyoro/Pickem/daily"

import { DateControl } from "./Controls/DateControl"
import { NameControl } from "./Controls/NameControl"
import { PriorityControl } from "./Controls/PriorityControl"

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedGroup: DailyPickemGroupAny | null
  prefilledGroupData: {
    dateId?: string
    priority?: number
  } | null
}

export const DailyPickemGroupModal = ({ isOpen, onClose, selectedGroup, prefilledGroupData }: Props) => {
  const { t } = useTranslation("taiyoro")

  const { addGroup, updateGroup } = useContext(PickemFunctionsContext)

  const [status, setStatus] = useState<DailyPickemGroupStatus>("new")
  const [id, setId] = useState<string>("")
  const [dateId, setDateId] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [nameJa, setNameJa] = useState<string>("")
  const [priority, setPriority] = useState<number>(1)

  useEffect(() => {
    if (selectedGroup && !prefilledGroupData) {
      setStatus(selectedGroup.status)
      setId(selectedGroup.id)
      setDateId(selectedGroup.dateId)
      setName(selectedGroup.name ?? "")
      setNameJa(selectedGroup.nameJa)
      setPriority(selectedGroup.priority)
    }

    if (prefilledGroupData && !selectedGroup) {
      if (prefilledGroupData.dateId) setDateId(prefilledGroupData.dateId)
      if (prefilledGroupData.priority) setPriority(prefilledGroupData.priority)
    }
  }, [selectedGroup, prefilledGroupData])

  const clear = () => {
    setStatus("new")
    setId("")
    setDateId("")
    setName("")
    setNameJa("")
    setPriority(1)
  }

  const setStatusToModified = () => {
    setStatus("modified")
  }

  const saveGroup = () => {
    if (status === "new" && id === "") {
      const tempId = `${Date.now()}`

      addGroup({
        id: tempId,
        status,
        dateId,
        name,
        nameJa,
        priority,
      })

      return
    }

    if (status === "modified" || (status === "new" && id !== "")) {
      updateGroup({
        status,
        id,
        dateId,
        name,
        nameJa,
        priority,
      })
    }
  }

  const disableSave = dateId === "" || nameJa === ""

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        clear()
        onClose()
      }}
      aria-labelledby="daily-pickem-group-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "500px",
          maxWidth: "90%",
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Stack gap={2}>
          <Typography
            variant="h5"
            component="h4"
            id="daily-pickem-group-modal-title"
          >
            {selectedGroup && t("eventPickem.daily.modal.editGroup")}
            {!selectedGroup && t("eventPickem.daily.modal.newGroup")}
          </Typography>
          <DateControl
            value={dateId}
            updateValue={setDateId}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <NameControl
            value={{ name, nameJa }}
            updateValue={setName}
            updateValueJa={setNameJa}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <PriorityControl
            value={priority}
            updateValue={setPriority}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <Button
              variant="text"
              onClick={() => {
                clear()
                onClose()
              }}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                saveGroup()
                clear()
                onClose()
              }}
              disabled={disableSave}
            >
              {t("common:actions.save")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
