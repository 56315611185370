import React from "react"

import Auth0ProviderWithHistory from "auth0-provider-with-history"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { createStateContext } from "react-use"

import App from "./app"
import "./index.css"

export const [errorContext, ErrorContextProvider] = createStateContext([])

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
)
