import type { Tag, TagHierarchy } from "models/Taiyoro/Meta/Tag"

export type AddedAndRemoved<T> = {
  added: Array<T>
  removed: Array<T>
}

export type HasId = {
  id?: string
}

export const getAddedAndRemoved = <T extends HasId, R>(
  currentState: Array<T>,
  originalState: Array<T>,
  mapFn: (item: T) => R
) => {
  const added = currentState.filter((item) => !originalState.some((origItem) => origItem.id === item.id))

  const removed = originalState.filter((origItem) => !currentState.some((item) => item.id === origItem.id))

  return {
    added: added.map(mapFn),
    removed: removed.map(mapFn),
  }
}

export type Paginate<T> = Array<T>

export const paginate = <T>(
  data: Array<T>,
  pageSize: number,
  pageNumber: number
): Paginate<T> | undefined => {
  const start = (pageNumber - 1) * pageSize
  const pageData = data.slice(start, start + pageSize)

  return pageData.length > 0 ? pageData : undefined
}

export const getParentTagsForId = (tagHierarchy: TagHierarchy | null, id: string) => {
  if (!tagHierarchy) return []

  let parentTags: Array<Tag> = []

  for (const tagNode of tagHierarchy) {
    const { tag, children } = tagNode

    for (const child of children) {
      if (child.tag.id === id) {
        parentTags.push(tag)
        break
      }
    }

    if (children.length > 0) {
      const matchingParents = getParentTagsForId(children, id)
      parentTags = parentTags.concat(matchingParents)
    }
  }

  return parentTags
}
