export enum ContributionType {
  UNKNOWN = "UNKNOWN",
  MONEY = "MONEY",
  EQUIPMENT = "EQUIPMENT",
  PRIZES = "PRIZES",
  PERSONNEL = "PERSONNEL",
  OTHER = "OTHER",
}

export enum PriorityType {
  HIGH = "HIGH",
  LOW = "LOW",
}
export default class EventSponsor {
  id: string
  // eslint-disable-next-line
  name: string
  revenue: number
  currency: string
  contributionTypes: ContributionType[]
  priority: PriorityType
  allDates: number
  dateIds: String[]

  constructor(id, name, revenue, currency, contributionTypes, priority, allDates, dateIds) {
    this.id = id
    this.name = name
    this.revenue = revenue || null
    this.currency = currency
    this.contributionTypes = contributionTypes
    this.priority = priority
    this.allDates = allDates
    this.dateIds = dateIds
  }
}
