import { useContext, useState } from "react"

import {
  faList,
  faChevronDown,
  faChevronRight,
  faNewspaper,
  faFileInvoice,
  faWrench,
  faCalendars,
  faCalendarStar,
  faBracketsSquare,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Badge, ListItemIcon } from "@mui/material"
import { ArticlesTodoContext } from "contexts/articles"
import { useTranslation } from "react-i18next"
import { useLocation, matchPath } from "react-router"

import { ADMIN_ROLES, ANALYTICS_ROLES } from "../../../utils/roles"
import useRolesCanAccess from "../../../utils/useRolesCanAccess"
import { StyleNavLinkButton } from "../../PageLayout/Sidebar/styles"
import { StyleListItem, StyleListItemText, StyleListItemIcon, StyleCollapse } from "../../StyleMaterialUI"

const TaiyoroSidebarEntries = () => {
  const { t } = useTranslation(["taiyoro"])
  const location = useLocation()

  const [metaOpen, setMetaOpen] = useState(false)
  const [analyticsOpen, setAnalyticsOpen] = useState(false)

  const { articles } = useContext(ArticlesTodoContext)

  const toggleMeta = () => {
    setMetaOpen(!metaOpen)
  }

  const toggleAnalytics = () => {
    setAnalyticsOpen(!analyticsOpen)
  }

  const isTaiyoroRoute = matchPath(location.pathname, {
    path: "/taiyoro",
  })

  const adminAccess = useRolesCanAccess(ADMIN_ROLES)
  const analyticsAccess = useRolesCanAccess(ANALYTICS_ROLES)

  return (
    <>
      {isTaiyoroRoute && (
        <>
          <StyleNavLinkButton
            exact
            to="/taiyoro/event/list"
          >
            <StyleListItem selected={location.pathname === "/taiyoro/event/list"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faList} />
              </ListItemIcon>
              <StyleListItemText primary={t("list.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
          {/* Articles */}
          {adminAccess && (
            <StyleNavLinkButton
              exact
              to="/taiyoro/articles"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/articles"}>
                <ListItemIcon>
                  <Badge
                    color="error"
                    badgeContent={articles.length}
                  >
                    <FontAwesomeIcon icon={faNewspaper} />
                  </Badge>
                </ListItemIcon>
                <StyleListItemText primary={t("articles.pageTitle")} />
              </StyleListItem>
            </StyleNavLinkButton>
          )}
          {/* Posts */}
          {adminAccess && (
            <StyleNavLinkButton
              exact
              to="/taiyoro/post"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/post"}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileInvoice} />
                </ListItemIcon>
                <StyleListItemText primary={t("posts.pageTitleList")} />
              </StyleListItem>
            </StyleNavLinkButton>
          )}
          {/*Metas */}
          <StyleListItem onClick={toggleMeta}>
            <StyleListItemIcon>
              <FontAwesomeIcon
                icon={(metaOpen && faChevronDown) || faChevronRight}
                size="1x"
              />
            </StyleListItemIcon>
            <StyleListItemText primary={t("meta.pageTitle")} />
          </StyleListItem>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/casters"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/casters"}>
                <StyleListItemText primary={t("meta.pageTitles.casters")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/games"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/games"}>
                <StyleListItemText primary={t("meta.pageTitles.games")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/game-groups"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/game-groups"}>
                <StyleListItemText primary={t("meta.pageTitles.gameGroups")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/organizers"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/organizers"}>
                <StyleListItemText primary={t("meta.pageTitles.organizers")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/placements"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/placements"}>
                <StyleListItemText primary={t("meta.pageTitles.placements")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/platforms"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/platforms"}>
                <StyleListItemText primary={t("meta.pageTitles.platforms")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/players"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/players"}>
                <StyleListItemText primary={t("meta.pageTitles.players")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/producers"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/producers"}>
                <StyleListItemText primary={t("meta.pageTitles.producers")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/sponsors"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/sponsors"}>
                <StyleListItemText primary={t("meta.pageTitles.sponsors")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/tags"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/tags"}>
                <StyleListItemText primary={t("meta.pageTitles.tags")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/teams"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/teams"}>
                <StyleListItemText primary={t("meta.pageTitles.teams")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/team-organizations"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/team-organizations"}>
                <StyleListItemText primary={t("meta.pageTitles.teamOrganizations")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          <StyleCollapse in={metaOpen}>
            <StyleNavLinkButton
              exact
              to="/taiyoro/meta/venues"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/meta/venues"}>
                <StyleListItemText primary={t("meta.pageTitles.venues")} />
              </StyleListItem>
            </StyleNavLinkButton>
          </StyleCollapse>
          {/*Analytics */}
          {analyticsAccess && (
            <>
              <StyleListItem onClick={toggleAnalytics}>
                <StyleListItemIcon>
                  <FontAwesomeIcon
                    icon={(analyticsOpen && faChevronDown) || faChevronRight}
                    size="1x"
                  />
                </StyleListItemIcon>
                <StyleListItemText primary={t("analytics.routeTitle")} />
              </StyleListItem>
              <StyleCollapse in={analyticsOpen}>
                <StyleNavLinkButton
                  exact
                  to="/taiyoro/analytics/view"
                >
                  <StyleListItem selected={location.pathname === "/taiyoro/analytics/view"}>
                    <StyleListItemText primary={t("analytics.pageTitle")} />
                  </StyleListItem>
                </StyleNavLinkButton>
                <StyleNavLinkButton
                  exact
                  to="/taiyoro/discordReport"
                >
                  <StyleListItem selected={location.pathname === "/taiyoro/discordReport"}>
                    <StyleListItemText primary={t("discordReport.pageTitle")} />
                  </StyleListItem>
                </StyleNavLinkButton>
                <StyleNavLinkButton
                  exact
                  to="/taiyoro/analytics/heatmap"
                >
                  <StyleListItem selected={location.pathname === "/taiyoro/analytics/heatmap"}>
                    <StyleListItemText primary={t("analytics.heatmap.title")} />
                  </StyleListItem>
                </StyleNavLinkButton>
              </StyleCollapse>
            </>
          )}
          {/* Channel Repair */}
          {adminAccess && (
            <StyleNavLinkButton
              exact
              to="/taiyoro/channel-repair"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/channel-repair"}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faWrench} />
                </ListItemIcon>
                <StyleListItemText primary={t("channelRepair.title")} />
              </StyleListItem>
            </StyleNavLinkButton>
          )}
          {/* Calendar Embed */}
          {adminAccess && (
            <StyleNavLinkButton
              exact
              to="/taiyoro/calendar-embed"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/calendar-embed"}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCalendars} />
                </ListItemIcon>
                <StyleListItemText primary={t("calendarEmbed.title")} />
              </StyleListItem>
            </StyleNavLinkButton>
          )}
          {/* Featured Events */}
          {adminAccess && (
            <StyleNavLinkButton
              exact
              to="/taiyoro/featured-events"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/featured-events"}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCalendarStar} />
                </ListItemIcon>
                <StyleListItemText primary={t("featuredEvents.title")} />
              </StyleListItem>
            </StyleNavLinkButton>
          )}
          {/* Brackets */}
          {adminAccess && (
            <StyleNavLinkButton
              exact
              to="/taiyoro/brackets"
            >
              <StyleListItem selected={location.pathname === "/taiyoro/brackets"}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faBracketsSquare} />
                </ListItemIcon>
                <StyleListItemText primary={t("brackets.title")} />
              </StyleListItem>
            </StyleNavLinkButton>
          )}
        </>
      )}
    </>
  )
}

export default TaiyoroSidebarEntries
