import React from "react"
import type { ReactNode } from "react"

import { Container, Title } from "./styles"

interface Props {
  title: string
  children?: ReactNode
}

const Property = ({ title, children }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <div>{children}</div>
    </Container>
  )
}

export default Property
