import React from "react"

import { faLanguage } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Tooltip } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import i18n from "i18next"
import { useTranslation } from "react-i18next"

const SiteLanguage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { t } = useTranslation("common")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (lang) => {
    // Filter out other 'click' events
    if (["en", "ja"].includes(lang)) {
      localStorage.setItem("language", lang)
      i18n.changeLanguage(lang)
    }
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Tooltip title={t("language")}>
        <IconButton
          aria-controls="language-menu"
          color="inherit"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faLanguage} />
        </IconButton>
      </Tooltip>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleClose("en")}>English</MenuItem>
        <MenuItem onClick={(e) => handleClose("ja")}>日本語</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default SiteLanguage
