import GraphQL from "utils/graphql"

import type { APIResponse } from "./types/common"

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

const runQuery = async (query: string) =>
  await GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

export type PickemUser = {
  id: string
  username: string
  displayName: string
  email: string
  eventPoints: number
  seasonPoints: number
  totalPoints: number
  position: number
  accuracy: number
  participation: number
}

type FetchParticipantsParams = {
  eventId: string
  sort?: "default" | "position" | "username" | "season" | "total" | "accuracy" | "participation"
}

type FetchParticipantsResponse = APIResponse<Array<PickemUser>>

export const fetchParticipants = async ({
  eventId,
  sort = "position",
}: FetchParticipantsParams): Promise<FetchParticipantsResponse> => {
  try {
    const query = `query {
      pickem {
        getParticipatingPickemUsersForEvent(eventId: "${eventId}", sort: "${sort}") {
        id 
        username
        displayName
        email
        eventPoints
        seasonPoints
        totalPoints
        position
        accuracy
        participation
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.getParticipatingPickemUsersForEvent && {
        status: "success",
        data: response.data.pickem.getParticipatingPickemUsersForEvent,
      }) || {
        status: "error",
      }
    )
  } catch (error) {
    return {
      status: "error",
    }
  }
}
