import moment from "moment"

import type { RankedDataSort } from "../../components/Scouting/ScoutingTool"
import type { RankedStreamerResult, RankedStreamResult } from "../../models/Scouting"
import type Game from "../../models/Taiyoro/Meta/Game"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchScoutingDataStream = (
  platformName: string,
  from: moment.Moment,
  to: moment.Moment,
  sort: RankedDataSort,
  game: Game | undefined,
  keywords: string
) =>
  GraphQL(
    `query {
        scannedData {
          ranking(platformName: "${platformName}", fromDatetime: "${from.format()}", toDatetime: "${to.format()}", sort: ${sort}, limit: 100${
            game ? `, gameId: "${game.id}"` : ""
          }${keywords !== "" ? `, searchQuery: "${keywords}"` : ""}) {
            id
            taiyoroGameId
            gameName
            userName
            streamTitle
            platformName
            channelUrl
            videoUrl
            startDatetime
            endDatetime
            dateId
            viewsLiveMinutesWatched
            viewsLiveConcurrentAverage
            viewsLiveConcurrentPeak
            liveBroadcastDuration
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.scannedData.ranking as Array<RankedStreamResult>)

export const fetchScoutingDataStreamer = (
  platformName: string,
  from: moment.Moment,
  to: moment.Moment,
  sort: RankedDataSort,
  game: Game | undefined,
  keywords: string
) =>
  GraphQL(
    `query {
      scannedData {
        streamerRanking(platformName: "${platformName}", fromDatetime: "${from.format()}", toDatetime: "${to.format()}", sort: ${sort}, limit: 100${
          game ? `, gameId: "${game.id}"` : ""
        }${keywords !== "" ? `, searchQuery: "${keywords}"` : ""}) {
          gameName
          taiyoroGameId
          userName
          platformName
          channelUrl
          viewsLiveMinutesWatched
          viewsLiveConcurrentAverage
          viewsLiveConcurrentPeak
          liveBroadcastDuration
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.scannedData.streamerRanking as Array<RankedStreamerResult>)
