import React from "react"

import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import { Box, IconButton, Tooltip } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import { ColorModeContext } from "../../../globalState/themeContext"

const ThemeSelector = () => {
  const theme = useTheme()
  const colorMode = React.useContext(ColorModeContext)
  const { t } = useTranslation("common")

  return (
    <Box mr={"10px"}>
      <Tooltip title={t("theme")}>
        <IconButton
          color="inherit"
          sx={{ ml: 1 }}
          onClick={() => colorMode.toggleColorMode()}
        >
          {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default ThemeSelector
