import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import {
  createSignificantPlayer,
  deleteSignificantPlayer,
  fetchSignificantPlayers,
} from "../../../services/Taiyoro/significantPlayers"

const MetaPlayerPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.players")}
      fetchFunc={fetchSignificantPlayers}
      deleteFunc={deleteSignificantPlayer}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createSignificantPlayer(name, urlSlug, nameJa, sortJa, team, teamOrganizationId, primaryImage, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createSignificantPlayer(name, urlSlug, nameJa, sortJa, team, teamOrganizationId, primaryImage, id)}
      metaType={MetaType.SIGNIFICANT_PLAYER}
      slugFields
      teamOrganizationFields
      primaryImageFieldsAndRatio={1}
      allowNonUniqueNames
    />
  )
}

export default MetaPlayerPage
