import { useContext } from "react"

import { Box, Link, Stack, Typography } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import moment from "moment"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const data = useContext(PlayDataEventReportDataContext)
  const { t } = useTranslation("playdata")

  return (
    <Box
      px={6}
      pb={4}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          alignItems="flex-end"
        >
          <img
            src="/images/playdata-logo.png"
            height="43px"
            alt="PLAYDATA"
          />
          <Typography
            color="text.primary"
            fontSize="20px"
            fontWeight="100"
            lineHeight={1}
            ml={2}
          >
            {t("footer.contact")}
          </Typography>
          <Typography
            fontSize="20px"
            fontWeight="500"
            whiteSpace="pre-wrap"
            lineHeight={1}
          >
            {t("footer.seperator")}
          </Typography>
          <Link
            href="https://playdata.gg"
            target="_blank"
            fontSize="20px"
            fontWeight="500"
            color="text.primary"
            lineHeight={1}
          >
            PLAYDATA.GG
          </Link>
        </Stack>
        <Stack alignItems="flex-end">
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Box>
              <Typography
                fontSize="12px"
                fontWeight="300"
                component="span"
                textTransform="uppercase"
              >
                {t("footer.preparedFor") + " "}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="700"
                component="span"
              >
                {t("footer.customer", { customer: data.customer })}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize="12px"
                fontWeight="300"
                component="span"
                textTransform="uppercase"
              >
                {t("footer.generatedOn") + " "}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="700"
                component="span"
              >
                {moment().format("YYYY/MM/DD")}
              </Typography>
            </Box>
          </Stack>
          <Typography
            fontSize="12px"
            fontWeight="700"
            textTransform="uppercase"
          >
            {t("footer.nonDisclosure")}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default Footer
