import React from "react"

import moment from "moment-timezone"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"

import { fetchDatePlatformStreamStats, fetchEvent } from "../../../services/Taiyoro/event"
import { fetchPlatforms } from "../../../services/Taiyoro/platforms"
import { numberWithCommas } from "./metricsUtil"

export default (props) => {
  const [points, setPoints] = React.useState([])
  const [eventName, setEventName] = React.useState(null)
  const [dateName, setDateName] = React.useState(null)
  const [platformId, setPlatformId] = React.useState(null)
  const [platforms, setPlatforms] = React.useState({})
  React.useEffect(() => {
    if (props.match.params.datePlatformId) {
      fetchDatePlatformStreamStats(props.match.params.datePlatformId).then((dataArr) => {
        setPoints(dataArr)
      })
    }
    if (props.match.params.eventId) {
      fetchEvent(props.match.params.eventId).then((data) => {
        if (data) {
          setEventName(data.name)
          const dates = data ? data.dates : null
          if (dates) {
            for (let i = 0; i < dates.length; i++) {
              let found = false
              const date = dates[i]
              if (date.platforms) {
                for (let j = 0; j < date.platforms.length; j++) {
                  const dp = date.platforms[j]
                  if (dp.id === props.match.params.datePlatformId) {
                    found = true
                    setDateName(date.name)
                    setPlatformId(dp.platform)
                    break
                  }
                }
                if (found) break
              }
            }
          }
        }
      })
    }
  }, [props.match.params.datePlatformId, props.match.params.eventId])

  React.useEffect(() => {
    const process = async () => {
      const pforms = await fetchPlatforms()
      if (pforms) {
        setPlatforms(
          pforms.reduce((acc, platform) => {
            acc[platform.id] = platform.name
            return acc
          }, {})
        )
      }
    }
    process()
  }, [])

  const dataOld = React.useMemo(
    () => [
      {
        label: "Viewers",
        data: points
          .filter((_, index) => index % 5 === 0 || index === points.length - 1)
          .map((entry, index) => {
            const date = new Date(entry.datetime)
            date.setSeconds(0)
            date.setMilliseconds(0)

            return [date, entry.concurrentViewers]
          }),
      },
    ],
    [points]
  )

  const data = React.useMemo(
    () =>
      points
        .filter((_, index) => index % 5 === 0 || index === points.length - 1)
        .map((entry) => {
          const date = new Date(entry.datetime)
          date.setSeconds(0)
          date.setMilliseconds(0)
          return {
            date: moment.utc(date).tz("Asia/Tokyo").format("HH:mm"),
            dateString: moment.utc(date).tz("Asia/Tokyo").format("MM/DD Z"),
            timeString: moment.utc(date).tz("Asia/Tokyo").format("HH:mm"),
            viewers: entry.concurrentViewers,
          }
        }),
    [points]
  )

  const columnsPerRow = 20
  let iterations = dataOld[0].data && dataOld[0].data.length
  const rows = []
  if (iterations) {
    iterations = Math.ceil(iterations / columnsPerRow)
    for (let i = 0; i < iterations; i++) {
      rows.push(dataOld[0].data.slice(i * columnsPerRow, i * columnsPerRow + columnsPerRow))
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        WebkitPrintColorAdjust: "exact",
        justifyContent: "center",
        height: "200vh",
      }}
    >
      <div style={{ margin: "5% 5%" }}>
        <h1 style={{ width: "100%", textAlign: "center", margin: "auto", justifyContent: "center" }}>
          {eventName}
        </h1>
        <h1 style={{ width: "100%", textAlign: "center", margin: "auto", justifyContent: "center" }}>
          {dateName} ({platformId && platforms && platforms[platformId]})
        </h1>
        <div
          style={{
            width: "1100px",
            height: "619px",
            margin: "auto",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {points.length !== 0 && (
            <LineChart
              width={1000}
              height={600}
              data={data}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line
                type="monotone"
                dataKey="viewers"
                stroke="#f73365"
              />
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray="5 5"
              />
              <XAxis
                dataKey="date"
                padding={{ left: 30, right: 30 }}
              />
              <YAxis width={100} />
              <Tooltip />
            </LineChart>
          )}
        </div>

        <div style={{ marginTop: "150px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "1400px",
              marginTop: "7%",
              flexWrap: "wrap",
            }}
          >
            <p style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
              The below times are all in Asia/Tokyo (UTC +09:00) time.
            </p>
            {rows.map((rowOfData) => (
              <table
                style={{
                  border: "4px grey solid",
                  marginTop: "50px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th>Time</th>
                  {rowOfData.map((da: any) => (
                    <th style={{ border: "4px grey solid", padding: "0.5em" }}>
                      {moment(da[0].toISOString()).tz("Asia/Tokyo").format("MM/DD HH:mm")}
                    </th>
                  ))}
                </tr>
                <tr>
                  <td>Viewers</td>
                  {rowOfData.map((da: any) => (
                    <td style={{ border: "4px grey solid", padding: "0.5em" }}>{numberWithCommas(da[1])}</td>
                  ))}
                </tr>
              </table>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
