import { useContext } from "react"

import {
  faClockEight,
  faClockEightThirty,
  faClockEleven,
  faClockElevenThirty,
  faClockFive,
  faClockFiveThirty,
  faClockFour,
  faClockFourThirty,
  faClockNine,
  faClockNineThirty,
  faClockOne,
  faClockOneThirty,
  faClockSeven,
  faClockSevenThirty,
  faClockSix,
  faClockSixThirty,
  faClockTen,
  faClockTenThirty,
  faClockThree,
  faClockThreeThirty,
  faClockTwelve,
  faClockTwelveThirty,
  faClockTwo,
  faClockTwoThirty,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Stack, Typography, useTheme } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { useMeasure } from "react-use"
import { XAxis, Bar, ComposedChart, Rectangle, ResponsiveContainer } from "recharts"

import { MAX_BAR_WIDTH } from "."
import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage, LegendContainer } from "./styles"

const normalize = (val: number, min: number, max: number) => (val - min) / (max - min)

const CustomBar = (props) => {
  const { min, max, ...shapeProps } = props
  const theme = useTheme()

  const normalizedValue = Math.max(0, normalize(shapeProps.payload.watchtime, min, max))

  // Take the normalized value, and then figure out it's value
  // in a range between 0.25 and 1 for styling purposes.
  const getFillOpacity = () => {
    if (normalizedValue === 1 || shapeProps.payload.watchtime === 0) {
      return 1
    }
    return (1 / 0.75) * normalizedValue + 0.25
  }

  const getFillColor = () => {
    if (normalizedValue === 1) {
      return theme.palette.common.pink
    }
    if (shapeProps.payload.watchtime === 0) {
      return theme.palette.background.default
    }
    return theme.palette.common.blue
  }

  return (
    <Rectangle
      {...shapeProps}
      fill={getFillColor()}
      fillOpacity={getFillOpacity()}
    />
  )
}

const XAxisTick = (props) => {
  const { x, y, payload } = props

  const [ref, { width }] = useMeasure()

  const theme = useTheme()

  return (
    <>
      {payload.value.split && payload.value !== "auto" && (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dx={-(width / 2) - 5}
            dy={32}
            fill={theme.palette.common.blue}
            fontSize={24}
            className={props.className}
            textAnchor={props.textAnchor}
            ref={ref}
            fontWeight="300"
          >
            {payload.value.split(":")[0]}
          </text>
        </g>
      )}
      {/* The 24:00 label isn't a legit piece of data in the graph (ornamental), so we need to 
      fake the label */}
      {payload.value.split && payload.value !== "auto" && payload.value.split(":")[0] === "23" && (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dx={52}
            dy={32}
            fill={theme.palette.common.blue}
            fontSize={24}
            className={props.className}
            textAnchor={props.textAnchor}
            ref={ref}
            fontWeight="300"
          >
            24
          </text>
        </g>
      )}
    </>
  )
}

const XAxisTickTime = (props) => {
  const theme = useTheme()

  const getClockFromValue = () => {
    switch (props.payload.value) {
      case "00:00":
      case "12:00":
        return faClockTwelve
      case "00:30":
      case "12:30":
        return faClockTwelveThirty
      case "01:00":
      case "13:00":
        return faClockOne
      case "01:30":
      case "13:30":
        return faClockOneThirty
      case "02:00":
      case "14:00":
        return faClockTwo
      case "02:30":
      case "14:30":
        return faClockTwoThirty
      case "03:00":
      case "15:00":
        return faClockThree
      case "03:30":
      case "15:30":
        return faClockThreeThirty
      case "04:00":
      case "16:00":
        return faClockFour
      case "04:30":
      case "16:30":
        return faClockFourThirty
      case "05:00":
      case "17:00":
        return faClockFive
      case "05:30":
      case "17:30":
        return faClockFiveThirty
      case "06:00":
      case "18:00":
        return faClockSix
      case "06:30":
      case "18:30":
        return faClockSixThirty
      case "07:00":
      case "19:00":
        return faClockSeven
      case "07:30":
      case "19:30":
        return faClockSevenThirty
      case "08:00":
      case "20:00":
        return faClockEight
      case "08:30":
      case "20:30":
        return faClockEightThirty
      case "09:00":
      case "21:00":
        return faClockNine
      case "09:30":
      case "21:30":
        return faClockNineThirty
      case "10:00":
      case "22:00":
        return faClockTen
      case "10:30":
      case "22:30":
        return faClockTenThirty
      case "11:00":
      case "23:00":
        return faClockEleven
      case "11:30":
      case "23:30":
        return faClockElevenThirty
    }
  }

  return (
    <>
      {props.payload.value !== 0 && (
        <g>
          <foreignObject
            x={props.x - 28}
            y={props.y - 35}
            height={props.height}
            width={props.height}
          >
            <FontAwesomeIcon
              color={theme.palette.common.blue}
              icon={getClockFromValue()}
              size="xl"
            />
          </foreignObject>
        </g>
      )}
      {/* The 24:00 clock symbol isn't a legit piece of data in the graph, so we need to 
      fake the label */}
      {props.payload.value === "21:00" && (
        <g>
          <foreignObject
            x={props.x + 184}
            y={props.y - 35}
            height={props.height}
            width={props.height}
          >
            <FontAwesomeIcon
              color={theme.palette.common.blue}
              icon={faClockTwelve}
              size="xl"
            />
          </foreignObject>
        </g>
      )}
    </>
  )
}

// Generate all halfhour timestamps of the day from 00:00 to 23:30
const times = Array(48)
  .fill(0)
  .map((_value, index) => {
    const minutes = index % 2 !== 0 ? "30" : "00"
    const hours = Math.floor(index / 2)
      .toString()
      .padStart(2, "0")
    return {
      time: `${hours}:${minutes}`,
      watchtime: 0,
      fakeCount: 1,
    }
  })

const WatchtimeHeatmap = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  // The api only returns timestamps with watchtime data, so map our above timestamps
  // to values from the API.
  const data = times.map((time) => {
    const watchtimeData = playDataEventReportData.charts.watchtimeHeatmap.find((t) => t.time === time.time)
    if (!watchtimeData) {
      return time
    }
    return {
      ...time,
      watchtime: watchtimeData.watchtime,
    }
  })

  const { min, max } = data.reduce(
    (acc, curr) => {
      return {
        max: curr.watchtime !== 0 ? Math.max(acc.max, curr.watchtime) : acc.max,
        min: curr.watchtime !== 0 ? Math.min(acc.min, curr.watchtime) : acc.min,
      }
    },
    data[0] ? { min: data[0].watchtime, max: data[0].watchtime } : { min: Number.MAX_SAFE_INTEGER, max: 0 }
  )

  const { t } = useTranslation("playdata")

  return (
    <BorderedPage>
      <Stack
        height="100%"
        gap={6}
        justifyContent="space-between"
      >
        <Stack gap={4}>
          <Header title={t("sectionTitles.watchTimeHeatmap")} />
          <Typography
            fontSize="24px"
            fontWeight="300"
            px={6}
          >
            {t("watchTimeHeatmapInfo")}
          </Typography>
        </Stack>
        <Stack gap={4}>
          <LegendContainer>
            <Stack
              direction="row"
              gap={2}
            >
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
              >
                <Box
                  width="16px"
                  height="16px"
                  bgcolor="common.pink"
                />
                <Typography component="span">{t("legends.watchTimeHeatmapHighest")}</Typography>
              </Stack>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
              >
                <Box
                  width="16px"
                  height="16px"
                  bgcolor="background.default"
                  border="solid 1px"
                  borderColor="common.blue"
                />
                <Typography component="span">{t("legends.watchTimeHeatmapLowest")}</Typography>
              </Stack>
            </Stack>
          </LegendContainer>
          <Box
            height="450px"
            px={6}
            mt="auto"
            mb="auto"
          >
            <ResponsiveContainer
              height="100%"
              width="100%"
            >
              <ComposedChart
                data={data}
                margin={{
                  top: 50,
                  bottom: 50,
                  left: 24,
                  right: 24,
                }}
              >
                <Bar
                  dataKey="fakeCount"
                  fill="#00B3FF"
                  shape={(props) => (
                    <CustomBar
                      {...props}
                      min={min}
                      max={max}
                    />
                  )}
                  maxBarSize={MAX_BAR_WIDTH}
                  isAnimationActive={false}
                />
                <XAxis
                  xAxisId={0}
                  dataKey="time"
                  tickLine={false}
                  tickFormatter={(_value) => ""}
                  tick={(props) => <XAxisTick {...props} />}
                  ticks={times.map((t) => t.time).filter((_value, index) => index % 2 === 0)}
                  minTickGap={0}
                />
                <XAxis
                  xAxisId={1}
                  dataKey="time"
                  tickLine={false}
                  tickFormatter={(_value) => ""}
                  tick={(props) => <XAxisTickTime {...props} />}
                  ticks={[...times.map((t) => t.time).filter((_value, index) => index % 6 === 0), "24:00"]}
                  orientation="top"
                  minTickGap={0}
                  strokeWidth={0}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Stack>
        <Stack gap={4}>
          <Disclaimer>
            <Stack gap={1}>
              <Typography fontSize="14px">{t("watchTimeHeatmapDisclaimer")}</Typography>
              <Typography fontSize="14px">{t("watchTimeHeatmapDisclaimer2")}</Typography>
            </Stack>
          </Disclaimer>
          <Footer />
        </Stack>
      </Stack>
    </BorderedPage>
  )
}

export default WatchtimeHeatmap
