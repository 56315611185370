import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import { PAGE_COUNTER } from "."

export const BorderedPage = styled(Box)(({ theme }) => ({
  width: "1920px",
  height: "1080px",
  border: "16px solid",
  borderImage: `${theme.palette.background.border} 1`,
  backgroundImage: `url(/images/playdata-bg-1.svg)`,
  backgroundSize: "cover",
  breakBefore: "page",
  position: "relative",
  "&.cover-page": {
    backgroundImage: `url(/images/playdata-bg-2.svg)`,
    breakBefore: "initial",
  },
}))

export const PageNumber = styled(Typography)(({ theme }) => ({
  counterIncrement: PAGE_COUNTER,
  fontSize: "48px",
  fontWeight: "100",
  lineHeight: 1,
  "&:after": {
    content: `counters(${PAGE_COUNTER}, ".", decimal-leading-zero)`,
    position: "relative",
    top: "-4px",
  },
}))

export const LegendContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: "solid 1px",
  borderColor: theme.palette.grey[300],
  backgroundColor: theme.palette.background.default,
  alignSelf: "flex-start",
  margin: `0 138px`,
}))
