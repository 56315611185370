import React from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { faSignOutAlt } from "@fortawesome/pro-solid-svg-icons/faSignOutAlt"
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withRouter } from "react-router-dom"

import { StyleIconButton, StyleTypography, StyleMenu, StyleMenuItem } from "../../StyleMaterialUI"

const UserProfile = React.memo(() => {
  const { isAuthenticated, user, logout } = useAuth0()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  return (
    <div>
      <div>
        <StyleTypography component="span">{isAuthenticated && user.nickname}</StyleTypography>
        <StyleIconButton
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <FontAwesomeIcon
            icon={faUser}
            size="1x"
          />
        </StyleIconButton>
        <StyleMenu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <StyleMenuItem onClick={handleLogout}>
            <FontAwesomeIcon
              style={{ marginRight: "12px" }}
              icon={faSignOutAlt}
              size="1x"
            />
            <span>Log Out</span>
          </StyleMenuItem>
        </StyleMenu>
      </div>
    </div>
  )
})

export default withRouter(UserProfile)
