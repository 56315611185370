import React from "react"

import { Checkbox } from "@mui/material"
import type { CheckboxProps } from "@mui/material"
import { makeStyles, createStyles } from "@mui/styles"

const useStyles = makeStyles((theme) =>
  createStyles({
    white: {
      color: "#fff",
      "&$checked": {
        color: "#fff",
      },
    },
  })
)

interface Props extends CheckboxProps {
  monochrome?: boolean
}

const StyledCheckbox = (props: Props) => {
  const classes = useStyles()

  const filterProps = { ...props }
  delete filterProps.monochrome

  return (
    <Checkbox
      {...filterProps}
      color={props.monochrome ? "default" : props.color}
      className={props.monochrome ? classes.white : props.className}
    />
  )
}

export default StyledCheckbox
