import React, { useState } from "react"

import { editEvent } from "../../../../../services/Taiyoro/event"
import { signMediaS3 } from "../../../../../services/Taiyoro/media"
import MediaUpload from "../../../MediaUpload"

interface Props {
  label: string
  eventId: string
  initialValue: string
  field: string
}

const EventImage = (props: Props) => {
  const [updatedValueState, setUpdatedValueState] = useState(props.initialValue)

  const update = (value) => {
    editEvent(props.eventId, props.field, value === "" ? null : value)
      .then((response) => {
        setUpdatedValueState(value)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <MediaUpload
      key="event-image"
      url={updatedValueState}
      mediaType="event"
      onChange={update}
      editable
      buttonText={props.label}
      aspect={16 / 9}
    />
  )
}

export default EventImage
