import React, { useEffect, useState } from "react"

import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { checkUsage, replaceUsage } from "../../../services/Taiyoro/meta"
import DropdownSearchable from "../../Form/DropdownSearchable"

interface Props {
  meta: any
  type: MetaType
  replacementList: Array<any>
  deleteFunc: (id: string) => Promise<boolean>
  onDeleted: (meta: { id: string }) => void
  onModalClosed: () => void
}

interface Usages {
  events: {
    id: string
    name: string
    count: number
  }[]
  games: Array<string>
  groups: Array<string>
  significantPlayers: Array<string>
  teams: Array<string>
}

const DeleteMeta = (props: Props) => {
  const [loadingState, setLoadingState] = useState(false)
  const [successState, setSuccessState] = useState(false)
  const [errorState, setErrorState] = useState(null)
  const [metaState, setMetaState] = useState(null)
  const [usages, setUsages] = useState(null)
  const [replacementState, setReplacementState] = useState(null)
  const { t } = useTranslation(["taiyoro", "common"])

  const fetchUsages = () => {
    checkUsage(metaState.id, props.type)
      .then((response) => {
        try {
          const usages: Usages = {
            events: [],
            games: [],
            groups: [],
            significantPlayers: [],
            teams: [],
          }
          response.events.forEach((event) => {
            const existingUsage = usages.events.find((e) => e.id === event.id)
            if (existingUsage) {
              existingUsage.count++
            } else {
              usages.events.push({ id: event.id, name: event.name, count: 1 })
            }
          })
          props.type !== MetaType.SPONSOR &&
            response.dates.forEach((date) => {
              const existingUsage = usages.events.find((e) => e.id === date.eventId)
              if (existingUsage) {
                existingUsage.count++
              } else {
                usages.events.push({ id: date.eventId, name: date.eventName, count: 1 })
              }
            })
          response.eventGames.forEach((eventGame) => {
            const existingUsage = usages.events.find((e) => e.id === eventGame.eventId)
            if (existingUsage) {
              existingUsage.count++
            } else {
              usages.events.push({ id: eventGame.eventId, name: eventGame.eventName, count: 1 })
            }
          })
          response.datePlatforms.forEach((datePlatform) => {
            const existingUsage = usages.events.find((e) => e.id === datePlatform.eventId)
            if (existingUsage) {
              existingUsage.count++
            } else {
              usages.events.push({ id: datePlatform.eventId, name: datePlatform.eventName, count: 1 })
            }
          })
          usages.games = [...usages.games, ...response.games.map((game) => game.name)]
          usages.groups = [...usages.groups, ...response.gameGroups.map((gameGroup) => gameGroup.name)]
          usages.significantPlayers = [
            ...usages.significantPlayers,
            ...response.significantPlayers.map((significantPlayer) => significantPlayer.name),
          ]
          usages.teams = [...usages.teams, ...response.teams.map((team) => team.name)]

          setLoadingState(false)
          setUsages(usages)
        } catch (e) {
          throw new Error("Failed to fetch usages. Please inform the appropriate people of this error.")
        }
      })
      .catch(handleError)
  }

  useEffect(() => {
    setLoadingState(true)
    setMetaState(props.meta)
    if (metaState) {
      fetchUsages()
    }
  }, [props.meta, metaState])

  const handleClose = () => {
    setUsages(null)
    setMetaState(null)
    setReplacementState(null)
    setLoadingState(false)
    setErrorState(null)
    setSuccessState(false)
    props.onModalClosed()
  }

  const handleReplace = () => {
    setLoadingState(true)
    replaceUsage(props.type, metaState.id, replacementState.id)
      .then((success) => {
        if (success) {
          setReplacementState(null)
          fetchUsages()
        } else {
          throw new Error("Failed to perform replace. Please inform the appropriate people of this error.")
        }
      })
      .catch(handleError)
  }

  const handleDelete = () => {
    setLoadingState(true)
    props
      .deleteFunc(metaState.id)
      .then((success) => {
        if (success) {
          props.onDeleted(metaState)
          setLoadingState(false)
          setSuccessState(true)
        } else {
          throw new Error("Failed to perform delete. Please inform the appropriate people of this error.")
        }
      })
      .catch(handleError)
  }

  const handleError = (error) => {
    setErrorState(error)
    setLoadingState(false)
  }

  const usagesExist =
    usages &&
    usages.events.length +
      usages.games.length +
      usages.groups.length +
      usages.significantPlayers.length +
      usages.teams.length !==
      0

  return (
    <Dialog
      onClose={handleClose}
      open={metaState !== null}
      key="delete-meta-modal"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {successState
          ? `Successfully deleted ${metaState !== null ? metaState.name : ""}`
          : `Are you sure you want to delete ${metaState !== null ? metaState.name : ""}?`}
      </DialogTitle>
      <DialogContent>
        {loadingState && <CircularProgress />}
        {!loadingState && errorState && <Typography color="error">{errorState}</Typography>}
        {!loadingState && successState && (
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="green"
              size="5x"
            />
          </div>
        )}
        {!loadingState && !successState && !errorState && usagesExist && (
          <Typography>{t("meta.delete.itemsExist")}</Typography>
        )}
        {!loadingState && !successState && !errorState && !usagesExist && (
          <Typography>{t("meta.delete.itemsDontExist")}</Typography>
        )}
        {!loadingState && !successState && !errorState && (
          <>
            <ul>
              {usages &&
                usages.events.map((event) => (
                  <li key={event.id}>
                    <Typography>
                      <Link
                        href={`/taiyoro/event/${event.id}/edit`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {event.name}
                      </Link>
                      <Chip
                        style={{ marginLeft: "6px" }}
                        size="small"
                        label={event.count}
                      />
                    </Typography>
                  </li>
                ))}
              {usages &&
                usages.games &&
                usages.games.map((game) => (
                  <li key={game}>
                    <Typography>
                      <Link
                        href={`/taiyoro/meta/games`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {game}
                      </Link>
                    </Typography>
                  </li>
                ))}
              {usages &&
                usages.significantPlayers &&
                usages.significantPlayers.map((significantPlayer) => (
                  <li key={significantPlayer}>
                    <Typography>
                      <Link
                        href={`/taiyoro/meta/players`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {significantPlayer}
                      </Link>
                    </Typography>
                  </li>
                ))}
              {usages &&
                usages.groups &&
                usages.groups.map((group) => (
                  <li key={group}>
                    <Typography>
                      <Link
                        href={`/taiyoro/meta/game-groups`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {group}
                      </Link>
                    </Typography>
                  </li>
                ))}
              {usages &&
                usages.teams &&
                usages.teams.map((team) => (
                  <li key={team}>
                    <Typography>
                      <Link
                        href={`/taiyoro/meta/teams`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {team}
                      </Link>
                    </Typography>
                  </li>
                ))}
            </ul>

            {usagesExist && (
              <DropdownSearchable
                label={t("meta.delete.replace")}
                options={props.replacementList.filter((option) => option.id !== metaState.id)}
                value={replacementState}
                onChange={(option) => setReplacementState(option)}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {usages && !usagesExist && !successState && (
          <Button
            onClick={handleDelete}
            variant="outlined"
          >
            {t("meta.delete.label")}
          </Button>
        )}
        {usages && usagesExist && (
          <Button
            onClick={handleReplace}
            variant="outlined"
            disabled={!replacementState}
          >
            {t("meta.delete.replace")}
          </Button>
        )}
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          {successState ? t("common:actions.close") : t("common:actions.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteMeta
