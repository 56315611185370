import { useContext } from "react"

import { faArrowUp, faArrowDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TableList from "components/Table/TableList"
import { SortableTableCell, SortArrow } from "components/Taiyoro/Analytics/Table/styles"
import { CustomHeaderCell } from "components/Taiyoro/MetaPage/rewrite"
import type { MetaState, MetaImages, MetaFunctions, MetaOthers } from "components/Taiyoro/MetaPage/types"
import { MetaPageContext } from "contexts/meta-page"
import { useTranslation } from "react-i18next"

type Props = {
  metaState: MetaState
  metaImages: MetaImages
  metaFunctions: MetaFunctions
  metaOthers: MetaOthers
}

export const TableHeaders = ({ metaState, metaImages, metaFunctions, metaOthers }: Props) => {
  // -- React Contexts --
  const { sortParamsState, setSortParamsState } = useContext(MetaPageContext)

  // -- Declarations --
  const { t } = useTranslation(["taiyoro", "common"])

  const { idFields, slugFields, hashtagFields } = metaState

  const { primaryImageFieldsAndRatio, secondaryImageFieldsAndRatio, boxArtImageFields } = metaImages

  const { updateGameAlternativeNamesFunc, updateGroupsFunc, updateParentTagsFunc } = metaFunctions

  const {
    gameDeveloperFields,
    latLongFields,
    quantifiableFields,
    homepageUrlFields,
    isGenreFields,
    teamFields,
    gameFields,
    teamOrganizationFields,
  } = metaOthers

  // -- Handlers --
  const handleSortChange = (field: "updatedAt" | "en" | "ja") => {
    if (sortParamsState.field === field) {
      setSortParamsState({ ...sortParamsState, sort: sortParamsState.sort === "asc" ? "desc" : "asc" })
      return
    }
    setSortParamsState({ field: field, sort: "asc" })
  }

  return (
    <TableList.HeaderRow>
      <CustomHeaderCell
        text={t("meta.headers.id")}
        conditional={idFields}
      />

      <SortableTableCell onClick={() => handleSortChange("en")}>
        {t("meta.headers.name")}
        <SortArrow>
          {sortParamsState.field === "en" && (
            <FontAwesomeIcon
              color="#f30362"
              icon={sortParamsState.sort === "asc" ? faArrowUp : faArrowDown}
            />
          )}
        </SortArrow>
      </SortableTableCell>

      <CustomHeaderCell
        text={t("meta.headers.nameJa")}
        conditional={"none"}
      />

      <SortableTableCell onClick={() => handleSortChange("ja")}>
        {t("meta.headers.sortJa")}
        <SortArrow>
          {sortParamsState.field === "ja" && (
            <FontAwesomeIcon
              color="#f30362"
              icon={sortParamsState.sort === "asc" ? faArrowUp : faArrowDown}
            />
          )}
        </SortArrow>
      </SortableTableCell>

      <CustomHeaderCell
        text={t("meta.headers.slug")}
        conditional={slugFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.hashtag")}
        conditional={hashtagFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.alternativeNames")}
        conditional={updateGameAlternativeNamesFunc}
      />

      <CustomHeaderCell
        text={t("meta.headers.isGameDeveloper")}
        conditional={gameDeveloperFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.primaryImage")}
        conditional={primaryImageFieldsAndRatio}
      />

      <CustomHeaderCell
        text={t("meta.headers.secondaryImage")}
        conditional={secondaryImageFieldsAndRatio}
      />

      <CustomHeaderCell
        text={t("meta.headers.boxArtImage")}
        conditional={boxArtImageFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.game")}
        conditional={gameFields}
      />

      {latLongFields && (
        <>
          <CustomHeaderCell
            text={t("meta.headers.latitude")}
            conditional={"none"}
          />
          <CustomHeaderCell
            text={t("meta.headers.longitude")}
            conditional={"none"}
          />
        </>
      )}

      <CustomHeaderCell
        text={t("meta.headers.team")}
        conditional={teamFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.groups")}
        conditional={updateGroupsFunc}
      />

      <CustomHeaderCell
        text={t("meta.headers.teamOrganization")}
        conditional={teamOrganizationFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.quantifiable")}
        conditional={quantifiableFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.homepageUrl")}
        conditional={homepageUrlFields}
      />

      <CustomHeaderCell
        text={t("meta.headers.parentTags")}
        conditional={updateParentTagsFunc}
      />

      <CustomHeaderCell
        text={t("meta.headers.isGenre")}
        conditional={isGenreFields}
      />

      <SortableTableCell onClick={() => handleSortChange("updatedAt")}>
        {t("meta.headers.updatedAt")}
        <SortArrow>
          {sortParamsState.field === "updatedAt" && (
            <FontAwesomeIcon
              color="#f30362"
              icon={sortParamsState.sort === "asc" ? faArrowUp : faArrowDown}
            />
          )}
        </SortArrow>
      </SortableTableCell>
      <TableList.HeaderCell></TableList.HeaderCell>
    </TableList.HeaderRow>
  )
}
