import React, { useState } from "react"

import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { errorContext } from "../../.."
import { UserRole } from "../../../models/UserManagement"
import { ADMIN_ROLES, HIGH_ACCESS_ROLES } from "../../../utils/roles"
import useRolesCanAccess from "../../../utils/useRolesCanAccess"
import { PublishSwitch } from "./styles"

interface Props {
  published: number
  onPublishedChanged: (published: number) => void
  updateFunc: (value: any) => Promise<any>
}

const PublishToggle = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [errorState, setErrorState] = errorContext()
  const { t } = useTranslation(["taiyoro", "common"])

  const canEdit = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const handleClick = () => {
    if (props.published === 0) {
      setOpen(true)
      return
    }
    updateEvent(0)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlePublish = () => {
    updateEvent(1)
    handleClose()
  }

  const updateEvent = (publishValue) => {
    setLoadingState(true)
    props
      .updateFunc(publishValue)
      .then((_response) => {
        props.onPublishedChanged(publishValue)
        setLoadingState(false)
      })
      .catch((error) => {
        setLoadingState(false)
        setErrorState([...errorState, error])
      })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>{t("edit.publishConfirmation")}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            onClick={handlePublish}
            color="primary"
            variant="contained"
            autoFocus
          >
            {t("edit.publish")}
          </Button>
        </DialogActions>
      </Dialog>
      {canEdit && (
        <Tooltip title={props.published === 0 ? t("edit.publish") : t("edit.unpublish")}>
          <PublishSwitch
            checked={props.published === 1}
            onClick={handleClick}
          />
        </Tooltip>
      )}
      <Typography
        variant="body1"
        sx={{ marginRight: "12px" }}
        component="span"
      >
        {props.published === 0 ? t("edit.draftMode") : t("edit.publishedMode")}
        {loadingState && (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
          />
        )}
      </Typography>
    </>
  )
}

export default PublishToggle
