import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const saveEventGame = async (id, eventGame) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            saveEventGame (
              eventGame: {
                eventId: "${id}"
                gameId: "${eventGame.gameId}"
                prizePool: ${eventGame.prizePool ? `"${eventGame.prizePool}"` : null}
                participantCount: ${eventGame.participantCount ? eventGame.participantCount : null}
                currency: "${eventGame.currency}"
                resultsUrl: ${eventGame.resultsUrl ? `"${eventGame.resultsUrl}"` : `""`}
              }
            ) {
              eventId
              gameId
              prizePool
              currency
              resultsUrl
              participantCount
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.saveEventGame &&
      response.data.admin.event.saveEventGame[0]) ||
    null
  )
}

export const editEventGame = (eventId, gameId, updates) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            saveEventGame (
              eventGame: {
                eventId: ${JSON.stringify(eventId)}
                gameId: ${JSON.stringify(gameId)}
                ${Object.keys(updates).reduce(
                  (acc, curr: string) => `${acc} ${curr}: ${JSON.stringify(updates[curr])}`,
                  ""
                )}
              }
            ) {
              eventId
              gameId
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}

export const deleteEventGame = async (eventId, gameId) => {
  let response = await GraphQL(
    `mutation {
        admin {
          event {
            deleteEventGame (
              eventId: "${eventId}"
              gameId: "${gameId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.deleteEventGame &&
      response.data.admin.event.deleteEventGame[0]) ||
    null
  )
}

export const addPlayerPlacement = async (eventId, gameId, playerId, placementId, quantifier) => {
  return GraphQL(
    `mutation {
          admin {
            event {
              addEventGameSignificantPlayer (
                eventId: "${eventId}"
                gameId: "${gameId}"
                significantPlayerId: "${playerId}"
                placementId: ${placementId ? `"${placementId}"` : "null"}
                quantifier: ${quantifier ? `${quantifier}` : "null"}
              )
            }
          }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.event.addEventGameSignificantPlayer)
}

export const addTeamPlacement = (eventId, gameId, teamId, placementId, quantifier) => {
  return GraphQL(
    `mutation {
          admin {
            event {
              addEventGameTeam (
                eventId: "${eventId}"
                gameId: "${gameId}"
                teamId: "${teamId}"
                placementId: ${placementId ? `"${placementId}"` : "null"}
                quantifier: ${quantifier ? `${quantifier}` : "null"}
              )
            }
          }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.event.addEventGameTeam)
}

export const deletePlayerPlacement = (eventId, gameId, playerId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            deleteEventGameSignificantPlayer (
              eventId: "${eventId}"
              gameId: "${gameId}"
              significantPlayerId: "${playerId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.event.deleteEventGameSignificantPlayer)
}

export const deleteTeamPlacement = (eventId, gameId, teamId) => {
  return GraphQL(
    `mutation {
        admin {
          event {
            deleteEventGameTeam (
              eventId: "${eventId}"
              gameId: "${gameId}"
              teamId: "${teamId}"
            )
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => response.data.admin.event.deleteEventGameTeam)
}
