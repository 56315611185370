import { Platform } from "@taiyoro/parse-stream-url"
import EventStream, { RecoveryStatus } from "models/Taiyoro/Stream"
import moment from "moment-timezone"

import GraphQL from "../../utils/graphql"

export type DataCollectionResult = {
  id: string
  userName: string
  gameName: string
  streamTitle: string
  platformName: string
  channelUrl: string
  videoUrl: string
  startDatetime: string
  endDatetime: string
  viewerTimeline: string
  dateId: string
  viewsLiveMinutesWatched: string
  viewsLiveConcurrentAverage: number
  viewsLiveConcurrentPeak: number
  liveBroadcastDuration: number
}
export interface DataCollectionResponse {
  results: Array<DataCollectionResult>
  sort: DataCollectionSort
}

export type PlatformChoice = {
  platform: Platform
  label: string
}

export type PlatformGameMapping = {
  platformName: string
  platformGameId: string
  platformGameName: string
  gameId: string
}

export const PLATFORM_CHOICES: Array<PlatformChoice> = [
  {
    platform: Platform.YouTube,
    label: "YouTube",
  },
  {
    platform: Platform.Twitch,
    label: "Twitch",
  },
  {
    platform: Platform.Mildom,
    label: "Mildom",
  },
  {
    platform: Platform.OpenRec,
    label: "OpenRec",
  },
  {
    platform: Platform.Afreeca,
    label: "Afreeca",
  },
]

export const PAGE_SIZE = 25

export const MOMENT_FORMAT = "YYYY-MM-DDTHH:mm"

export enum DataCollectionSort {
  START_DATETIME_ASC = "START_DATETIME_ASC",
  START_DATETIME_DESC = "START_DATETIME_DESC",
}

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchDataCollection = async (
  type: Platform,
  sort: DataCollectionSort,
  offset: number,
  id?: string,
  fromDatetime?: moment.Moment,
  toDatetime?: moment.Moment,
  channelUrl?: string,
  videoUrl?: string,
  dateId?: string,
  pageSize?: number
): Promise<DataCollectionResponse> => {
  const platformName = type !== null ? (Platform[type] as string).toUpperCase() : null
  return GraphQL(
    `query {
          scannedData {
              list (
                  ${platformName ? `platformName: "${platformName}",` : ""}
                  sort: ${sort},
                  offset: ${offset},
                  limit: ${pageSize || PAGE_SIZE}
                  ${
                    fromDatetime
                      ? `, fromDatetime: "${fromDatetime.clone().utc().format(MOMENT_FORMAT)}"`
                      : ""
                  }
                  ${toDatetime ? `, toDatetime: "${toDatetime.clone().utc().format(MOMENT_FORMAT)}"` : ""}
                  ${channelUrl ? `, channelUrl: "${channelUrl}"` : ""}
                  ${dateId ? `, dateId: "${dateId}"` : ""}
                  ${videoUrl ? `, videoUrl: "${videoUrl}"` : ""}
              ) {
                  id
                  gameName
                  userName
                  streamTitle
                  platformName
                  channelUrl
                  videoUrl
                  startDatetime
                  endDatetime
                  viewerTimeline
                  dateId
                  viewsLiveMinutesWatched
                  viewsLiveConcurrentAverage
                  viewsLiveConcurrentPeak
                  liveBroadcastDuration
              }
          }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return {
      results: response.data && response.data.scannedData && response.data.scannedData.list,
      sort: sort,
    }
  })
}

export const fetchRecoverySuggestions = async (dateId: string): Promise<Array<DataCollectionResult>> => {
  return GraphQL(
    `query {
      scannedData {
        getRecoveryLinkSuggestion(dateId: "${dateId}") {
          id
          gameName
          userName
          streamTitle
          platformName
          channelUrl
          videoUrl
          startDatetime
          endDatetime
          viewerTimeline
          dateId
          viewsLiveMinutesWatched
          viewsLiveConcurrentAverage
          viewsLiveConcurrentPeak
          liveBroadcastDuration
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data && response.data.scannedData && response.data.scannedData.getRecoveryLinkSuggestion
  })
}

export const saveDatePlatform = async (
  dateId: string,
  platformId: string,
  streams: Array<DataCollectionResult>,
  datePlatformId?: string
): Promise<EventStream> => {
  return GraphQL(
    `mutation {
    admin {
      event {
        saveDatePlatform (
          datePlatform: {
            id: ${datePlatformId ? `"${datePlatformId}"` : null}
            dateId: "${dateId}"
            platformId: "${platformId}"
            isVod: 0
            recoveryStatus: ${RecoveryStatus.RECOVERED}
            viewsLiveUnique: null
            viewsLiveTotal: null
            viewsLiveConcurrentPeak: null
            viewsLiveMinutesWatched: null
            liveBroadcastDuration: null
            viewsLiveConcurrentAverage: null
            views1Week: null
            viewsWeek2: null
            viewsWeek3: null
            viewsWeek4: null
            views1Month: null
            language: "ja"
            viewsLiveConcurrentPeakManual: null
            viewsLiveMinutesWatchedManual: null
            viewsLiveConcurrentAverageManual: null
            views1WeekManual: null
            viewsWeek2Manual: null
            viewsWeek3Manual: null
            viewsWeek4Manual: null
          },
          scannedStreamDataLink: {
              useUrl: true
              scannedStreamDataIds: ${JSON.stringify(streams.map((stream) => stream.id))}
          }
        ) {
          id
          dateId
          platformId
          url
          viewsLiveUnique
          viewsLiveTotal
          viewsLiveConcurrentPeak
          viewsLiveMinutesWatched
          liveBroadcastDuration
          viewsLiveConcurrentAverage
          isVod
          views1Week
          viewsWeek2
          viewsWeek3
          viewsWeek4
          views1Month
          language
          dataCollectionError
          isDataCollectionScheduled
          viewsLiveConcurrentPeakManual
          viewsLiveMinutesWatchedManual
          viewsLiveConcurrentAverageManual
          views1WeekManual
          viewsWeek2Manual
          viewsWeek3Manual
          viewsWeek4Manual
          automaticallyGenerateVods
          automaticallyGenerated
          isSubStream
          recoveryStatus
        }
      }
    }
  }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    if (
      response.data &&
      response.data.admin &&
      response.data.admin.event &&
      response.data.admin.event.saveDatePlatform
    ) {
      const eventStream = response.data.admin.event.saveDatePlatform
      return new EventStream(
        eventStream.id,
        eventStream.platformId,
        eventStream.url,
        eventStream.isVod,
        eventStream.language,
        eventStream.isDataCollectionScheduled,
        eventStream.recoveryStatus,
        eventStream.viewsLiveUnique,
        eventStream.viewsLiveTotal,
        eventStream.viewsLiveConcurrentPeak,
        eventStream.viewsLiveMinutesWatched,
        eventStream.liveBroadcastDuration,
        eventStream.views1Week,
        eventStream.viewsWeek2,
        eventStream.viewsWeek3,
        eventStream.viewsWeek4,
        eventStream.views1Month,
        eventStream.viewsLiveConcurrentAverage,
        eventStream.dataCollectionErrored,
        eventStream.viewsLiveConcurrentPeakManual,
        eventStream.viewsLiveMinutesWatchedManual,
        eventStream.viewsLiveConcurrentAverageManual,
        eventStream.views1WeekManual,
        eventStream.viewsWeek2Manual,
        eventStream.viewsWeek3Manual,
        eventStream.viewsWeek4Manual,
        eventStream.automaticallyGenerateVods,
        eventStream.automaticallyGenerated,
        eventStream.isSubStream
      )
    }
  })
}

export const fetchPlatformGameMappings = async (
  type: Platform,
  id?: string
): Promise<Array<PlatformGameMapping>> => {
  const platformName = type !== null ? (Platform[type] as string).toUpperCase() : null
  return GraphQL(
    `query {
            scannedData {
                getPlatformGameMappings (
                    platformName: "${platformName}",
                    ${id ? `platformGameId: "${id}"` : ""}
                ) {
                    platformName
                    platformGameId
                    platformGameName
                    gameId
                }
            }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    try {
      return response.data.scannedData.getPlatformGameMappings
    } catch {
      return []
    }
  })
}

export const savePlatformGameMapping = async (
  type: Platform,
  id: string,
  gameId: string
): Promise<PlatformGameMapping> => {
  const platformName = type !== null ? (Platform[type] as string).toUpperCase() : null
  return GraphQL(
    `mutation {
                scannedData {
                    savePlatformGameMapping (
                        platformGameMapping: {
                            platformName: "${platformName}",
                            platformGameId: "${id}",
                            gameId: "${gameId}"
                        }
                    ) {
                        platformName
                        platformGameId
                        platformGameName
                        gameId
                    }
                }
            }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    try {
      return response.data.scannedData.savePlatformGameMapping
    } catch {
      return false
    }
  })
}

export const deletePlatformGameMapping = async (type: Platform, id: string): Promise<boolean> => {
  const platformName = type !== null ? (Platform[type] as string).toUpperCase() : null
  return GraphQL(
    `mutation {
                scannedData {
                    deletePlatformGameMapping (
                        platformName: "${platformName}",
                        platformGameId: "${id}"
                    )
                }
            }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    try {
      return response.data.scannedData.deletePlatformGameMapping
    } catch {
      return false
    }
  })
}

export const addToIgnoreList = async (
  platformName: string,
  username: string,
  channelUrl: string,
  adminUsername: string
): Promise<boolean> => {
  return GraphQL(
    `mutation {
      scannedData {
        addToIgnoreList (
            platformName: "${platformName}",
            userName: ${JSON.stringify(username)},
            channelUrl: "${channelUrl}"
            shouldIgnore: 1,
            adminUsername: "${adminUsername}"
        )
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    try {
      return response.data.scannedData.addToIgnoreList
    } catch {
      return false
    }
  })
}

export const removeFromIgnoreList = (
  platformName: string,
  username: string,
  channelUrl: string,
  adminUsername: string
): Promise<boolean> => {
  return GraphQL(
    `mutation {
      scannedData {
        addToIgnoreList (
            platformName: "${platformName}",
            userName: "${username}",
            channelUrl: "${channelUrl}"
            shouldIgnore: 0,
            adminUsername: "${adminUsername}"
        )
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    try {
      return response.data.scannedData.addToIgnoreList
    } catch {
      return false
    }
  })
}

export const fetchIgnoreList = (platformName: string, offset: number, limit: number): Promise<any> => {
  return GraphQL(
    `query {
      scannedData {
        getIgnoreList (
              platformName: "${platformName}",
              offset: ${offset},
              limit: ${limit},
              shouldIgnore: 1
          ) {
          results {
            platformName
            channelUrl
            userName
            adminUsername
            createdAt
            streamTitle
            channelDescription
          }
          totalCount
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.scannedData.getIgnoreList
  })
}

export const fetchSuggestions = (platformName: string, offset: number, limit: number): Promise<any> => {
  return GraphQL(
    `query {
      scannedData {
        getIgnoreListSuggestions(platformName: "${platformName}", offset: ${offset}, limit: ${limit}) {
          results {
            platformName
            channelUrl
            userName
            streamTitle
            channelDescription
          }
          totalCount
        }
      }
    }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.scannedData.getIgnoreListSuggestions
  })
}

export const generateTwitchChatAnalytics = ({
  twitchClientId,
  vodUrl,
}: {
  twitchClientId: string
  vodUrl: string
}) => {
  return GraphQL(
    `mutation {
      admin {
        datePlatform {
          generateTwitchChatAnalytics(vodUrl: "${vodUrl}", twitchClientId: "${twitchClientId}") {
            dateId
            eventId
            datePlatformId
            eventName
            url
          }
        }
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data.admin.datePlatform.generateTwitchChatAnalytics
  })
}

export type Density = {
  timestamp_utc: string
  vodtime_seconds: number
  chat_msg_count: number
}

export type AlgorithmType = "descendingDensity" | "movingAverage150" | "movingAverage300"

export type HighlightJobType = "youtube" | "twitch"

export type HighlightJobData = {
  chatLogFile: string
  densityFile: string
  highlightsFiles: Array<string>
}

interface HighlightJobDataResponse {
  data: {
    admin: {
      clips: {
        getHighlightsJobData: HighlightJobData
      }
    }
  }
}

export const getHighlightsJobData = (vodId: string, type: HighlightJobType): Promise<HighlightJobData> => {
  return GraphQL(
    `query {
      admin {
        clips {
          getHighlightsJobData(vodId: "${vodId}", type: "${type}") {
            chatLogFile
            densityFile
            highlightsFiles
          }
        }
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((response: HighlightJobDataResponse) => {
    return response.data.admin.clips.getHighlightsJobData
  })
}

export type ClipsJobStatus = {
  status: "queued" | "success" | "failed"
  datetime: string
  vodId: string
  type: HighlightJobType
}

interface HighlightJobsResponse {
  data: {
    admin: {
      clips: {
        getHighlightsJobs: Array<ClipsJobStatus>
      }
    }
  }
}

export const getHighlightsJobs = (): Promise<Array<ClipsJobStatus>> => {
  return GraphQL(
    `query {
      admin {
        clips {
          getHighlightsJobs {
            status
            datetime
            vodId
            type
          }
        }
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((response: HighlightJobsResponse) => {
    return response.data.admin.clips.getHighlightsJobs
  })
}

interface ScheduleHighlightJobsResponse {
  data: {
    admin: {
      clips: {
        scheduleHighlightsJob: ClipsJobStatus
      }
    }
  }
}
export const scheduleHighlightsJob = (vodId: string, type: HighlightJobType): Promise<ClipsJobStatus> => {
  return GraphQL(
    `mutation {
      admin {
        clips {
          scheduleHighlightsJob(vodId: "${vodId}", type: "${type}") {
            status
            datetime
            vodId
            type
          }
        }
      }
    }
  `,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((response: ScheduleHighlightJobsResponse) => {
    return response.data.admin.clips.scheduleHighlightsJob
  })
}
