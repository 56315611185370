import type Venue from "../../models/Taiyoro/Meta/Venue"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type VenuesResponse = {
  data?: {
    admin?: {
      venue?: {
        get?: Array<Venue>
      }
    }
  }
}

export const fetchVenues = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          venue {
            get {
              id
              name
              nameJa
              sortJa
              latitude
              longitude
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as VenuesResponse

    return response.data?.admin?.venue?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch venues")
  }
}

export type VenueResponse = {
  data?: {
    admin?: {
      venue?: {
        get?: Venue | null
      }
    }
  }
}

export const fetchVenue = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          venue {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              latitude
              longitude
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as VenueResponse

    return response.data?.admin?.venue?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch venue")
  }
}

export type CreateVenueResponse = {
  data?: {
    admin?: {
      venue?: {
        save?: Venue | null
      }
    }
  }
}

export const createVenue = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  id?: string | null
) => {
  try {
    const venueInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      `latitude: ${latitude === "" ? "null" : JSON.stringify(latitude)}`,
      `longitude: ${longitude === "" ? "null" : JSON.stringify(longitude)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
        admin {
          venue {
            save(venue: { ${venueInput} }) {
              id
              name
              nameJa
              sortJa
              latitude
              longitude
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateVenueResponse

    return response.data?.admin?.venue?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create venue")
  }
}

export type DeleteVenueResponse = {
  data?: {
    admin?: {
      venue?: {
        delete: Venue
      }
    }
  }
}

export const deleteVenue = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        venue {
          delete (
              venueId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteVenueResponse

    return response.data?.admin?.venue?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete venue")
  }
}
