import { useContext } from "react"

import { Box, Stack, Typography, useTheme } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { useMeasure } from "react-use"
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ReferenceLine,
  ComposedChart,
  ReferenceDot,
  ResponsiveContainer,
  Rectangle,
} from "recharts"
import useEvenSizedPages from "utils/useEvenSizedPages"

import { MAX_BAR_WIDTH, MAX_ITEMS_PER_GRAPH } from "."
import Footer from "./footer"
import Header from "./header"
import { BorderedPage, LegendContainer } from "./styles"
import SubPageCounter from "./sub-page-counter"
import XAxisTick from "./x-axis-tick"

const CustomLabel = (props) => {
  const { label } = props
  const theme = useTheme()
  const [ref, { width, height }] = useMeasure()

  return (
    <g>
      <rect
        x={props.viewBox.x - width / 2}
        y={props.viewBox.y - 40}
        fill={theme.palette.common.pink}
        width={width + 20}
        height={height + 10}
      />
      <text
        x={props.viewBox.x}
        y={props.viewBox.y - 40}
        dy={22}
        dx={10}
        fill={theme.palette.common.white}
        fontWeight="bold"
        ref={ref}
        textAnchor="middle"
      >
        {label}
      </text>
    </g>
  )
}

const CustomBar = (props) => {
  const { x, width, ...shapeProps } = props

  return (
    <>
      <Rectangle
        width={width / 2}
        x={x + width / 4}
        {...shapeProps}
      />
    </>
  )
}

const Concurrents = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const data = playDataEventReportData.charts.concurrents

  const dates = [...Array.from(new Set(data.map((d) => d.dateName)))]

  const { t } = useTranslation("playdata")

  const theme = useTheme()

  const maxACU = data.reduce(
    (acc, curr) => {
      return {
        x: curr.acu > acc.y ? curr.dateName : acc.x,
        y: Math.max(curr.acu, acc.y),
      }
    },
    data[0] ? { x: data[0].dateName, y: data[0].acu } : { x: "", y: 0 }
  )

  const maxPCU = data.reduce(
    (acc, curr) => {
      return {
        x: curr.pcu > acc.y ? curr.dateName : acc.x,
        y: Math.max(curr.pcu, acc.y),
      }
    },
    data[0] ? { x: data[0].dateName, y: data[0].pcu } : { x: "", y: 0 }
  )

  const pages = useEvenSizedPages(dates.length, MAX_ITEMS_PER_GRAPH)

  const filterDataPage = (page: number) => {
    let offset = 0
    for (let i = 0; i < page; i++) {
      offset = offset + pages[i]
    }
    const pageDates = dates.slice(offset, offset + pages[page])
    return data.filter((d) => pageDates.includes(d.dateName))
  }

  return (
    <>
      {pages.map((_itemsPerPage, page) => (
        <BorderedPage key={page}>
          <SubPageCounter
            page={page}
            pages={pages.length}
          />
          <Stack
            height="100%"
            gap={6}
            justifyContent="space-between"
          >
            <Stack gap={4}>
              <Header title={t("sectionTitles.concurrents")} />
              <Typography
                fontSize="24px"
                fontWeight="300"
                px={6}
              >
                {t("concurrentsInfo")}
              </Typography>
            </Stack>
            <Stack gap={4}>
              <LegendContainer>
                <Stack
                  direction="row"
                  gap={2}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      bgcolor="common.green"
                    />
                    <Typography component="span">{t("sectionTitles.pcu")}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      bgcolor="common.blue"
                    />
                    <Typography component="span">{t("sectionTitles.acu")}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="64px"
                      height="1px"
                      borderTop="2px dashed"
                      borderColor="common.green"
                    />
                    <Typography component="span">{t("legends.averagePcu")}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="64px"
                      height="1px"
                      borderTop="2px dashed"
                      borderColor="common.blue"
                    />
                    <Typography component="span">{t("legends.averageAcu")}</Typography>
                  </Stack>
                </Stack>
              </LegendContainer>
              <Box
                height="450px"
                px={6}
                mt="auto"
                mb="auto"
              >
                <ResponsiveContainer
                  height="100%"
                  width="100%"
                >
                  <ComposedChart
                    data={filterDataPage(page)}
                    margin={{
                      top: 50,
                      bottom: 50,
                      right: 100,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                    />
                    <YAxis
                      tickFormatter={(value) => Number(value).toLocaleString()}
                      tickLine={false}
                      tick={{ fill: theme.palette.text.primary, fontWeight: "bold" }}
                      tickCount={3}
                      stroke={theme.palette.text.primary}
                      width={90}
                    />
                    <Bar
                      dataKey="acu"
                      fill={theme.palette.common.blue}
                      xAxisId={0}
                      maxBarSize={MAX_BAR_WIDTH}
                      isAnimationActive={false}
                    />
                    <Bar
                      dataKey="pcu"
                      fill={theme.palette.common.green}
                      xAxisId={1}
                      maxBarSize={MAX_BAR_WIDTH}
                      shape={CustomBar}
                      isAnimationActive={false}
                    />
                    {/* Reference lines need to be at the end of of the chart definition to be rendered on top properly
          https://github.com/recharts/recharts/issues/1163#issuecomment-407824600 */}
                    <ReferenceLine
                      y={data.reduce((acc: number, curr) => acc + curr.pcu, 0) / data.length}
                      stroke={theme.palette.common.green}
                      strokeDasharray="3 3"
                      isFront
                    />
                    <ReferenceLine
                      y={data.reduce((acc: number, curr) => acc + curr.acu, 0) / data.length}
                      stroke={theme.palette.common.blue}
                      strokeDasharray="3 3"
                      isFront
                    />
                    <XAxis
                      dataKey="dateName"
                      xAxisId={0}
                      height={100}
                      tick={<XAxisTick />}
                      tickLine={false}
                      minTickGap={-1000}
                      ticks={filterDataPage(page).map((d) => d.dateName)}
                    />
                    <XAxis
                      dataKey="dateName"
                      xAxisId={1}
                      height={100}
                      hide
                      stroke={theme.palette.text.primary}
                      minTickGap={0}
                      ticks={filterDataPage(page).map((d) => d.dateName)}
                    />
                    <ReferenceDot
                      r={7}
                      fill={theme.palette.common.pink}
                      {...maxPCU}
                      stroke="none"
                      isFront
                      label={(props) => (
                        <CustomLabel
                          {...props}
                          label={t("legends.highestPcu")}
                        />
                      )}
                    />
                    <ReferenceDot
                      r={7}
                      fill={theme.palette.common.pink}
                      {...maxACU}
                      stroke="none"
                      label={(props) => (
                        <CustomLabel
                          {...props}
                          label={t("legends.highestAcu")}
                        />
                      )}
                      isFront
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Box>
            </Stack>
            <Footer />
          </Stack>
        </BorderedPage>
      ))}
    </>
  )
}

export default Concurrents
