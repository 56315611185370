import { FormControl } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  background-color: #333333;
  height: 350px;
  border-bottom: 3px solid #a9a9a9;
  overflow-y: scroll;
`
