import { Theme, darken, lighten, styled } from "@mui/material/styles"

export const GroupableTags = styled("li")(({ theme }: { theme: Theme }) => ({
  "&.option": {
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
  },
  "&.group": {
    position: "sticky",
    top: "-8px",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.primary.light, 0.9)
        : darken(theme.palette.primary.main, 0.9),
    "&.Mui-focused": {
      backgroundColor:
        theme.palette.mode === "light"
          ? lighten(theme.palette.primary.light, 0.8)
          : darken(theme.palette.primary.main, 0.8),
    },
    "&[aria-selected='true']": {
      backgroundColor:
        theme.palette.mode === "light"
          ? lighten(theme.palette.primary.light, 0.8)
          : darken(theme.palette.primary.main, 0.8),
      "&.Mui-focused": {
        backgroundColor:
          theme.palette.mode === "light"
            ? lighten(theme.palette.primary.light, 0.8)
            : darken(theme.palette.primary.main, 0.8),
      },
    },
  },
  "&.noGroup": {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}))
