import type GameGroup from "../../models/Taiyoro/Meta/GameGroup"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type GameGroupResponse = {
  data?: {
    admin?: {
      gameGroup?: {
        get?: Array<GameGroup>
      }
    }
  }
}

export const fetchGameGroups = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          gameGroup {
            get {
              id
              name
              nameJa
              sortJa
              primaryImage
              secondaryImage
              childGames {
                id
              }
              childGroups {
                id
              }
              parentGroups {
                id
              }
              isGenre
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as GameGroupResponse

    return response.data?.admin?.gameGroup?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch game group")
  }
}

export type CreateGameGroupResponse = {
  data?: {
    admin?: {
      gameGroup?: {
        save?: GameGroup | null
      }
    }
  }
}

export const createGameGroup = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  primaryImage?: string,
  secondaryImage?: string,
  isGenre?: number,
  id?: string
) => {
  const gameGroupInput = [
    ...(id ? [`id: "${id}"`] : []),
    `name: "${name}"`,
    `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
    `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
    `primaryImage: ${primaryImage === "" ? "null" : JSON.stringify(primaryImage)}`,
    `secondaryImage: ${secondaryImage === "" ? "null" : JSON.stringify(secondaryImage)}`,
    isGenre !== undefined ? `isGenre: ${isGenre ? 1 : 0}` : null,
  ].join(", ")

  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          gameGroup {
            save(gameGroup: {
              ${gameGroupInput}
            }) {
              id
              name
              nameJa
              sortJa
              primaryImage
              secondaryImage
              childGames {
                id
              }
              childGroups {
                id
              }
              parentGroups {
                id
              }
              isGenre
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateGameGroupResponse

    return response.data?.admin?.gameGroup?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create game group")
  }
}

export type LinkResponse = {
  data?: {
    admin?: {
      gameGroup?: {
        link: GameGroup
      }
    }
  }
}

export const link = async (type: string, refId: string, parentGroupId: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          gameGroup {
            link (
                type: ${type}
                refId: "${refId}"
                parentGroupId: "${parentGroupId}"
            )
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as LinkResponse

    return response.data?.admin?.gameGroup?.link ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to link")
  }
}

export type DeleteLinkResponse = {
  data?: {
    admin?: {
      gameGroup?: {
        deleteLink: GameGroup
      }
    }
  }
}

export const deleteLink = async (type: string, refId: string, parentGroupId: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
          admin {
            gameGroup {
              deleteLink (
                  type: ${type}
                  refId: "${refId}"
                  parentGroupId: "${parentGroupId}"
              )
            }
          }
        }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteLinkResponse

    return response.data?.admin?.gameGroup?.deleteLink ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete link")
  }
}

export type DeleteGameGroupResponse = {
  data?: {
    admin?: {
      gameGroup?: {
        delete: GameGroup
      }
    }
  }
}

export const deleteGameGroup = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          gameGroup {
            delete (
                gameGroupId: "${id}"
            )
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteGameGroupResponse

    return response.data?.admin?.gameGroup?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete game group")
  }
}
