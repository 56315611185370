import { type ReactNode, useContext } from "react"

import { Box, CircularProgress } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"

interface Props {
  children: ReactNode
}

export const SettingsWrapper = ({ children }: Props) => {
  const { loading } = useContext(PickemSettingsContext)

  return (
    <Box sx={{ display: "grid" }}>
      <Box sx={{ gridArea: "1 / -1 / 1 / -1", zIndex: 1, opacity: loading ? 0.25 : 1 }}>{children}</Box>
      {loading && (
        <Box
          sx={{
            gridArea: "1 / -1 / 1 / -1",
            zIndex: 2,
            display: "grid",
            placeItems: "start center",
            paddingTop: "55px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
