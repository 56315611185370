import { useContext, useEffect, useState } from "react"

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip, IconButton, Stack } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import { NotificationContext } from "contexts/notification"
import { useTranslation } from "react-i18next"
import { fetchBracketsList } from "services/Taiyoro/brackets"
import type { PaginatedBrackets } from "services/Taiyoro/brackets"
import { UTCDateInJST } from "utils/tools"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

const PAGE_SIZE = 20

export const BracketsPage = () => {
  const [loading, setLoading] = useState(true)
  const [queryParams, setQueryParams] = useState({ pageSize: PAGE_SIZE, page: 0 })
  const [paginatedBrackets, setPaginatedBrackets] = useState<PaginatedBrackets>({
    totalCount: 0,
    results: [],
  })
  const [error, setError] = useState(false)

  const { t, i18n } = useTranslation("taiyoro")
  const { setNotification } = useContext(NotificationContext)

  const localisedLabel = useLocalisedLabel()

  const columns = [
    {
      flex: 1,
      field: "eventName",
      sortable: false,
      headerName: t("brackets.tableHeaders.eventName"),
    },
    {
      flex: 1,
      field: "dateName",
      sortable: false,
      headerName: t("brackets.tableHeaders.dateName"),
    },
    {
      flex: 1,
      field: "name",
      sortable: false,
      headerName: t("brackets.tableHeaders.name"),
    },
    {
      flex: 1,
      field: "createdAt",
      sortable: false,
      headerName: t("brackets.tableHeaders.createdAt"),
      valueGetter: (params) => UTCDateInJST(params.value).locale(i18n.language).format("llll"),
    },
    {
      flex: 1,
      field: "linkedMeta",
      sortable: false,
      headerName: t("brackets.tableHeaders.linkedMeta"),
      renderCell: (params) => (
        <Stack
          direction="row"
          gap={0.5}
          flexWrap="wrap"
          p={1}
        >
          {Object.keys(params.value)
            .reduce((acc, curr) => {
              return [...acc, ...params.value[curr]]
            }, [])
            .map(localisedLabel)
            .map((localisedMeta) => (
              <Chip
                key={localisedMeta}
                label={localisedMeta}
                variant="outlined"
              />
            ))}
        </Stack>
      ),
    },
    {
      flex: 1,
      field: "viewsLiveConcurrentPeak",
      sortable: false,
      headerName: t("list.header.pcu"),
      valueGetter: (params) => params.value.toLocaleString(),
    },
    {
      flex: 1,
      field: "viewsLiveConcurrentAverage",
      sortable: false,
      headerName: t("list.header.acu"),
      valueGetter: (params) => params.value.toLocaleString(),
    },
    {
      flex: 1,
      field: "viewsLiveMinutesWatched",
      sortable: false,
      headerName: t("list.header.minutesWatched"),
      valueGetter: (params) => params.value.toLocaleString(),
    },
    {
      field: "actions",
      sortable: false,
      headerName: "",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <IconButton
              href={`/taiyoro/${params.row.eventId}/${params.row.dateId}/brackets`}
              target="_blank"
              size="medium"
            >
              <FontAwesomeIcon icon={faExternalLink} />
            </IconButton>
          }
          size="large"
          onClick={() => {}}
          label={""}
        />,
      ],
    },
  ]

  useEffect(() => {
    setLoading(true)
    fetchBracketsList(queryParams.pageSize, queryParams.page * queryParams.pageSize)
      .then((paginatedBrackets) => setPaginatedBrackets(paginatedBrackets))
      .catch((e) => {
        setNotification({
          message: e,
          severity: "error",
        })
        setError(true)
      })
      .finally(() => setLoading(false))
  }, [queryParams, setNotification])

  return (
    <DataGrid
      rows={paginatedBrackets.results}
      columns={columns}
      error={error}
      disableSelectionOnClick
      loading={loading}
      density="comfortable"
      disableColumnFilter
      disableDensitySelector
      disableColumnMenu
      page={queryParams.page}
      pageSize={queryParams.pageSize}
      onPageSizeChange={(pageSize) => setQueryParams({ page: 0, pageSize })}
      onPageChange={(page) => setQueryParams((oldQueryParams) => ({ ...oldQueryParams, page }))}
      paginationMode="server"
      sx={{ height: "calc(100% - 110px)" }}
      rowCount={paginatedBrackets.totalCount}
      rowsPerPageOptions={[PAGE_SIZE]}
      getRowHeight={() => "auto"}
    />
  )
}
