import { useContext } from "react"

import { Box, Stack, Typography } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { UTCDateInJST } from "utils/tools"

import { PageNumber } from "./styles"

interface Props {
  title: string
}

const Header = (props: Props) => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const { t } = useTranslation("playdata")

  const getEventDurationTimeRange = () => {
    const startTime = UTCDateInJST(playDataEventReportData.startDatetime)
    const endTime = UTCDateInJST(playDataEventReportData.lastStartDatetime)
    if (startTime.isSame(endTime, "day")) {
      return startTime.format("YYYY.MM.DD")
    }
    return `${startTime.format("YYYY.MM.DD")} ~ ${endTime.format("YYYY.MM.DD")}`
  }

  return (
    <Box
      bgcolor="common.white"
      px={6}
    >
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        gap={2}
      >
        <Typography
          fontSize="48px"
          fontWeight="900"
          textTransform="uppercase"
          mr="auto"
        >
          {props.title}
        </Typography>
        <Stack
          ml="auto"
          flex="1"
          gap={0.5}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Typography
            fontSize="20px"
            fontWeight="700"
            noWrap
          >
            {playDataEventReportData.name}
          </Typography>
          <Stack
            gap={1}
            direction="row"
          >
            <Typography
              fontSize="20px"
              fontWeight="300"
            >
              {getEventDurationTimeRange()}
            </Typography>
            {playDataEventReportData.summary.finishedDateCount !==
              playDataEventReportData.summary.dateCount.value && (
              <Typography
                fontSize="20px"
                fontWeight="300"
              >
                {t("ongoing")}
              </Typography>
            )}
          </Stack>
          {playDataEventReportData.summary.finishedDateCount !==
            playDataEventReportData.summary.dateCount.value && (
            <Box
              bgcolor="grey.200"
              width="300px"
              position="relative"
              height="8px"
              borderRadius="2px"
              overflow="hidden"
              mt="4px"
            >
              <Box
                position="absolute"
                width={`${
                  (playDataEventReportData.summary.finishedDateCount /
                    playDataEventReportData.summary.dateCount.value) *
                  100
                }%`}
                height="8px"
                bgcolor="common.green"
              />
            </Box>
          )}
        </Stack>
        <img
          src={playDataEventReportData.imageUrl || "/images/taiyoro-visual-fallback.png"}
          height="96px"
          alt={playDataEventReportData.name}
        />
        <Box ml={1}>
          <PageNumber />
        </Box>
      </Stack>
    </Box>
  )
}

export default Header
