import { useContext, useState } from "react"

import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material"
import { FeaturedEventsContext, TEMPORARY_ID } from "components/Taiyoro/FeaturedEvents/FeaturedEventsProvider"
import type { PendingChange } from "components/Taiyoro/FeaturedEvents/FeaturedEventsProvider"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { addFeaturedEvent } from "services/Taiyoro/featuredEvents"
import type { FeaturedEvent } from "services/Taiyoro/featuredEvents"
import { v4 as uuidv4 } from "uuid"

import { FeaturedEventForm } from "../FeaturedEventForm"

const defaultFeaturedEvent = {
  id: null,
  event: null,
  eventId: null,
  orderValue: 1,
  featuredStartDatetime: moment().format(),
  featuredEndDatetime: moment().format(),
  backgroundColor: "#FFFFFF",
  heroAssetUrl: null,
}

interface Props {
  open: boolean
  onClose: () => void
}

export const AddDialog = (props: Props) => {
  const [featuredEvent, setFeaturedEvent] = useState<FeaturedEvent>(defaultFeaturedEvent)

  const { setFeaturedEvents, setPendingApiChangesQueue } = useContext(FeaturedEventsContext)
  const { t } = useTranslation(["common", "taiyoro"])

  const handleSaveFeaturedEvent = () => {
    const temporaryId = `${TEMPORARY_ID}${uuidv4()}`
    const pendingChange: PendingChange = {
      type: "create",
      id: temporaryId,
      action: () => addFeaturedEvent(featuredEvent),
    }
    const newFeaturedEvent: FeaturedEvent = {
      ...featuredEvent,
      id: temporaryId,
    }

    setPendingApiChangesQueue((pendingApiChangesQueue) => [...pendingApiChangesQueue, pendingChange])
    setFeaturedEvents((featuredEvents) => [...featuredEvents, newFeaturedEvent])
    handleClose()
  }

  const handleClose = () => {
    setFeaturedEvent(defaultFeaturedEvent)
    props.onClose()
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <FeaturedEventForm
          featuredEvent={featuredEvent}
          onUpdate={setFeaturedEvent}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          gap={1}
        >
          <Button
            onClick={handleClose}
            color="primary"
            variant="text"
          >
            {t("actions.cancel")}
          </Button>
          <Button
            disabled={!featuredEvent.eventId}
            onClick={handleSaveFeaturedEvent}
            color="primary"
            variant="contained"
          >
            {t("actions.add")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
