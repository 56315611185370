import { Box, Grid, styled } from "@mui/material"

export const YearText = styled(Box)`
  padding: 2px 4px;
  border-radius: 2px;
`

export const YearFirst = styled(Grid)`
  text-align: right;
`

export const YearLast = styled(Grid)`
  text-align: left;
`

export const DayContainer = styled(Grid)`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`

export const DayText = styled(YearText)`
  border-radius: 4px;
`

export const DayFirst = styled(DayContainer)`
  text-align: right;
`

export const DayLast = styled(DayContainer)`
  text-align: left;
`
