import { useContext } from "react"

import { faClock } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Link, Stack, Typography, useTheme } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import moment from "moment"
import { Trans, useTranslation } from "react-i18next"
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  ReferenceArea,
  ResponsiveContainer,
} from "recharts"
import { UTCDateInJST } from "utils/tools"

import { LEGEND_COLORS, get24hrTimeRange } from "."
import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage, LegendContainer } from "./styles"

interface Props {
  competingEventDataForDate: any
  startDatetime: string
}

const getClosestEntryToTime = (time: string, data: Array<any>) => {
  const times = [...data].map((d) => d.time)
  const timeMarker = UTCDateInJST(time).format("YYYY-MM-DD HH:mm")
  times.push(timeMarker)
  times.sort()
  const index = times.lastIndexOf(timeMarker)
  return times[Math.max(index - 1, 0)]
}

// Filter data such that at the start of the viewership curve it will start from 0 or higher,
// and end after it hits 0 at the end.
const filter0s = (data: Array<any>) => {
  const uniqueChannels = data.reduce((acc, curr) => {
    for (const channel of Object.keys(curr)) {
      if (channel !== "time" && !acc.includes(channel)) {
        acc.push(channel)
      }
    }
    return acc
  }, [])

  const channelStartEnd = uniqueChannels.map((channel) => {
    const first = Math.max(data.findIndex((d) => d[channel] !== 0) - 1, 0)
    const last = Math.min(
      data.length - 1 - [...data].reverse().findIndex((d) => d[channel] !== 0) + 1,
      data.length - 1
    )
    return [first !== last ? first : 0, first !== last ? last : data.length - 1]
  })

  const newData = [...data].map((d, index) => {
    const e = { ...d }
    for (const channel of Object.keys(e)) {
      if (channel === "time") {
        continue
      }
      const startEnd = channelStartEnd[uniqueChannels.indexOf(channel)]
      if (index < startEnd[0] || index > startEnd[1]) {
        delete e[channel]
      }
    }
    return e
  })

  return newData
}

const CompetingEventsViewership = (props: Props) => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const competingEventsData = props.competingEventDataForDate

  const startTime = getClosestEntryToTime(competingEventsData.startDatetime, competingEventsData.data)

  const endTime = getClosestEntryToTime(competingEventsData.endDatetime, competingEventsData.data)

  const date = competingEventsData.dateName

  const theme = useTheme()

  const colors = LEGEND_COLORS.map((color) => theme.palette.common[color])

  const { t, i18n } = useTranslation("playdata")

  const getReorderedEvents = () => {
    const reordered = competingEventsData.events.filter(
      (event) => event.name !== playDataEventReportData.name
    )
    const current = competingEventsData.events.find((event) => event.name === playDataEventReportData.name)
    current && reordered.unshift(current)
    return reordered
  }

  const doesDataExistForCurrentEvent = () => {
    return competingEventsData.data.some((d) => {
      return Object.keys(d).some((key) => key === playDataEventReportData.name)
    })
  }

  const doesDataExistOnlyForCurrentEvent = () => {
    return doesDataExistForCurrentEvent() && getReorderedEvents().length === 1
  }

  return (
    <BorderedPage>
      <Stack
        height="100%"
        gap={6}
        justifyContent="space-between"
      >
        <Stack gap={3}>
          <Header title={t("sectionTitles.dateOverview")} />
          <Stack
            direction="row"
            gap={0.5}
            alignItems="flex-end"
            px={6}
            fontSize="32px"
          >
            <Typography
              fontSize="inherit"
              fontWeight="900"
            >
              {t("sectionTitles.competingEventsViewership")}
            </Typography>
            <Typography
              fontSize="inherit"
              fontWeight="300"
            >
              &mdash;
            </Typography>
            <Typography
              fontSize="inherit"
              fontWeight="900"
            >
              {date}
            </Typography>
            <Typography
              fontSize="inherit"
              fontWeight="300"
            >
              &mdash;
            </Typography>
            <Typography
              fontSize="inherit"
              fontWeight="300"
            >
              {UTCDateInJST(props.startDatetime).format("YYYY.MM.DD")}
            </Typography>
            <Box mx={1}>
              <FontAwesomeIcon
                icon={faClock}
                size="sm"
              />
            </Box>
            <Typography
              fontSize="inherit"
              fontWeight="300"
            >
              {get24hrTimeRange(competingEventsData.startDatetime, competingEventsData.endDatetime)}
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="300"
              pb={0.7}
            >
              {t("jst")}
            </Typography>
          </Stack>
          {!doesDataExistForCurrentEvent() && (
            <Stack
              direction="row"
              alignItems="center"
              mx={6}
              gap={1}
            >
              <svg
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4468 0.72394C11.0041 -0.241315 12.3973 -0.241313 12.9546 0.723942L23.2053 18.4786C23.7626 19.4439 23.0659 20.6504 21.9514 20.6504H1.45004C0.33546 20.6504 -0.36115 19.4439 0.19614 18.4786L10.4468 0.72394Z"
                  fill="#EEAAAA"
                />
                <path
                  d="M12.5248 13.5487L12.8817 8.91175L12.964 6.73047H10.5074L10.5898 8.91175L10.9466 13.5487H12.5248ZM11.7426 17.4448C12.5797 17.4448 13.1836 16.7726 13.1836 15.922C13.1836 15.0715 12.5797 14.413 11.7426 14.413C10.8917 14.413 10.2878 15.0715 10.2878 15.922C10.2878 16.7726 10.8917 17.4448 11.7426 17.4448Z"
                  fill="white"
                />
              </svg>
              <Typography
                fontSize="24px"
                fontWeight="300"
              >
                <Trans
                  i18nKey={"playdata:disclaimer.noViewershipData"}
                  components={[<em />]}
                  values={{
                    event: playDataEventReportData.name,
                  }}
                />
              </Typography>
            </Stack>
          )}
          {doesDataExistOnlyForCurrentEvent() && (
            <Stack
              direction="row"
              alignItems="center"
              mx={6}
              gap={1}
            >
              <svg
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4468 0.72394C11.0041 -0.241315 12.3973 -0.241313 12.9546 0.723942L23.2053 18.4786C23.7626 19.4439 23.0659 20.6504 21.9514 20.6504H1.45004C0.33546 20.6504 -0.36115 19.4439 0.19614 18.4786L10.4468 0.72394Z"
                  fill="#EEAAAA"
                />
                <path
                  d="M12.5248 13.5487L12.8817 8.91175L12.964 6.73047H10.5074L10.5898 8.91175L10.9466 13.5487H12.5248ZM11.7426 17.4448C12.5797 17.4448 13.1836 16.7726 13.1836 15.922C13.1836 15.0715 12.5797 14.413 11.7426 14.413C10.8917 14.413 10.2878 15.0715 10.2878 15.922C10.2878 16.7726 10.8917 17.4448 11.7426 17.4448Z"
                  fill="white"
                />
              </svg>
              <Typography
                fontSize="24px"
                fontWeight="300"
              >
                {t("disclaimer.noCompetingEvents")}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack gap={4}>
          <LegendContainer>
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
            >
              {getReorderedEvents().map((event, index) => (
                <Stack
                  key={event.name}
                  direction="row"
                  gap={1}
                  alignItems="center"
                >
                  <Box
                    width="16px"
                    height="16px"
                    bgcolor={colors[index % colors.length]}
                  />
                  {event.imageUrl && (
                    <img
                      alt={event.name}
                      src={event.imageUrl}
                      height="24px"
                    />
                  )}
                  <Link
                    noWrap
                    target="_blank"
                    href={`https://taiyoro.gg/${i18n.language}/event/${event.id}`}
                    underline="none"
                    color="text.primary"
                    textOverflow="ellipsis"
                    display="block"
                  >
                    {event.name}
                  </Link>
                </Stack>
              ))}
            </Stack>
          </LegendContainer>
          <Box
            height="450px"
            px={6}
            mt="auto"
            mb="auto"
          >
            <ResponsiveContainer
              height="100%"
              width="100%"
            >
              <ComposedChart
                data={filter0s(competingEventsData.data)}
                margin={{
                  top: 50,
                  bottom: 50,
                }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                />
                {getReorderedEvents()
                  .filter((event) => event.name !== playDataEventReportData.name)
                  .map((event, index) => (
                    <Line
                      key={event.name}
                      dataKey={event.name}
                      type="monotone"
                      stroke={colors[(index % colors.length) + 1]}
                      strokeWidth={2}
                      dot={false}
                      isAnimationActive={false}
                    />
                  ))}
                <Line
                  dataKey={playDataEventReportData.name}
                  type="monotone"
                  stroke={colors[0]}
                  strokeWidth={5}
                  dot={false}
                  isAnimationActive={false}
                />
                <ReferenceArea
                  x1={startTime}
                  x2={endTime}
                  fill={theme.palette.common.pink}
                  fillOpacity={0.07}
                  isAnimationActive={false}
                  ifOverflow="visible"
                />
                <YAxis
                  tickFormatter={(value) => Number(value).toLocaleString()}
                  tickLine={false}
                  tick={{ fill: theme.palette.text.primary, fontWeight: "bold" }}
                  stroke={theme.palette.text.primary}
                  width={90}
                />
                <XAxis
                  dataKey="time"
                  height={60}
                  tick={{ fontSize: "16px", fill: theme.palette.text.primary, dy: 20 }}
                  tickLine={false}
                  tickFormatter={(value) => moment(value).format("HH:mm")}
                  ticks={filter0s(competingEventsData.data)
                    .map((d) => d.time)
                    .filter((time) => ["00", "30"].includes(moment(time).format("mm")))}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Stack>
        <Stack gap={4}>
          <Disclaimer>
            <Stack gap={1}>
              <Typography fontSize="14px">{t("competingEventsViewershipDisclaimer.line1")}</Typography>
              <Typography fontSize="14px">{t("competingEventsViewershipDisclaimer.line2")}</Typography>
            </Stack>
          </Disclaimer>
          <Footer />
        </Stack>
      </Stack>
    </BorderedPage>
  )
}

export default CompetingEventsViewership
