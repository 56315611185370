import React, { useState, useEffect, useRef } from "react"

import { faFileImage, faTimes, faUpload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Paper,
  Stack,
  Typography,
} from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import ReactCrop, { makeAspectCrop, centerCrop, type PixelCrop, type Crop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { useDebounce } from "react-use"
import { signMediaS3 } from "services/Taiyoro/media"

// Copied helper function from react-crop example
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const TO_RADIANS = Math.PI / 180

// Copied helper functions from react-crop example
function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    throw new Error("No 2d context")
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = "high"

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  ctx.translate(-cropX, -cropY)
  ctx.translate(centerX, centerY)
  ctx.rotate(rotateRads)
  ctx.scale(scale, scale)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  )

  ctx.restore()
}

interface Props {
  key: string
  url: string
  mediaType: string
  editable: boolean
  onChange: (url: string) => void
  buttonText: string
  aspect?: number
}

const MediaUpload = (props: Props) => {
  const [urlState, setUrlState] = useState("")
  const [fileState, setFileState] = useState<File | null>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [loadingState, setLoadingState] = useState(false)
  const [dialogOpenState, setDialogOpenState] = useState(false)
  const [src, setSrc] = useState<string | null>(null)
  const [crop, setCrop] = useState<Crop | undefined>(undefined)
  const imgRef = useRef<HTMLImageElement>(null)
  const [errorDialogOpenState, setErrorDialogOpenState] = useState(false)
  const [errorMessageState, setErrorMessageState] = useState("")
  const [step, setStep] = useState("crop")
  const { t } = useTranslation(["taiyoro", "common"])
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [fileBlob, setFileBlob] = useState<Blob | null>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    setUrlState(props.url)
  }, [props.url])

  useEffect(() => {
    if (src === null) {
      setStep("crop")
      setFileState(null)
      setImageSrc(null)
      setFileBlob(null)
      setSrc(null)
      setCrop(undefined)
      setCompletedCrop(undefined)
    }
  }, [src])

  const validateFileSize = (file: File) => {
    return new Promise<File>((resolve, reject) => {
      // Convert file size in bytes to megabytes
      if (file.size / 1024 / 1024 > 10) {
        reject(t("dialogs.error.fileSizeTooLarge"))
      }
      resolve(file)
    })
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (props.aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, props.aspect))
    }
  }

  const onFileSelected = (file: File) => {
    setFileState(file)
    validateFileSize(file)
      .then((file) => setSrc(URL.createObjectURL(file)))
      .catch((reason: string) => {
        setErrorDialogOpenState(true)
        setErrorMessageState(reason)
      })
  }

  const handleUpload = () => {
    const file = new File([fileBlob!], fileState!.name, { type: fileState!.type })
    uploadMedia(file)
  }

  const handleUploadError = () => {
    setErrorDialogOpenState(true)
    setErrorMessageState(t("dialogs.error.s3UploadFailed"))
  }

  const uploadMedia = (file: File) => {
    setLoadingState(true)
    const extension = file.name.split(".").pop()
    signMediaS3(file.type, props.mediaType, extension!)
      .then((result) => {
        fetch(result.signedRequest, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        })
          .then(() => {
            props.onChange(result.url)
            setLoadingState(false)
            setSrc(null)
          })
          .catch(() => handleUploadError())
      })
      .catch(() => () => handleUploadError())
  }

  const imageElement = (
    <img
      src={urlState}
      alt="event-banner-preview"
      style={{ cursor: "pointer", maxWidth: "100px", maxHeight: "100px" }}
      onClick={() => setDialogOpenState(true)}
    />
  )

  const cropPreview = (
    <Box p={1}>
      {imageSrc && (
        <img
          src={imageSrc}
          style={{
            objectFit: "contain",
            margin: "0 auto",
            display: "block",
            maxHeight: "200px",
          }}
        />
      )}
    </Box>
  )

  const handleClose = () => {
    setFileBlob(null)
    setStep("crop")
    setErrorMessageState("")
    setErrorDialogOpenState(false)
    setCrop(undefined)
    setSrc(null)
    setFileState(null)
    setImageSrc("")
    setDialogOpenState(false)
    setCompletedCrop(undefined)
  }

  useDebounce(
    () => {
      if (completedCrop?.width && completedCrop.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0)
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen: OffscreenCanvas = new OffscreenCanvas(
          completedCrop.width * scaleX,
          completedCrop.height * scaleY
        )
        const ctx: OffscreenCanvasRenderingContext2D = offscreen.getContext("2d")!

        ctx.drawImage(
          previewCanvas,
          0,
          0,
          previewCanvas.width,
          previewCanvas.height,
          0,
          0,
          offscreen.width,
          offscreen.height
        )

        // (Roy) Unsure why, but the below is resulting in an "any typed" linting warning
        // however all variables are clearly typed correctly
        // eslint-disable-next-line
        offscreen
          .convertToBlob({
            type: "image/png",
            quality: 1,
          })
          // eslint-disable-next-line
          .then((blob: Blob) => {
            setFileBlob(blob)
            setImageSrc(window.URL.createObjectURL(blob))
          })
      }
    },
    100,
    [completedCrop, crop]
  )

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        open={dialogOpenState}
      >
        <img
          src={urlState}
          alt="event-banner-full-size"
        />
      </Dialog>
      <Dialog
        onClose={() => setErrorDialogOpenState(false)}
        open={errorDialogOpenState}
      >
        <DialogContent>
          <DialogContentText>{errorMessageState}</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="lg"
        onClose={() => setSrc(null)}
        open={src !== null}
      >
        <DialogContent>
          {step === "crop" && (
            <>
              {!!src && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={props.aspect}
                  minHeight={100}
                >
                  <img
                    ref={imgRef}
                    alt="crop-image"
                    src={src}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              <canvas
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                  display: "none",
                }}
              />
              <Box textAlign="right">
                <Button
                  color="primary"
                  onClick={() => setStep("upload")}
                  variant="contained"
                  component="span"
                  disabled={!fileBlob}
                >
                  {t("common:actions.next") + " →"}
                </Button>
              </Box>
            </>
          )}
          {step === "upload" && (
            <>
              <Typography variant="h6">{t("meta.controls.check")}</Typography>
              <Typography variant="body2">{t("meta.controls.checkSubtitle")}</Typography>
              <Stack
                direction="row"
                width="100%"
                gap={1}
                p={1}
                mt={2}
                mb={2}
              >
                <ThemeProvider
                  theme={(outerTheme) => {
                    return createTheme({ ...outerTheme, palette: { mode: "dark" } })
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{ flex: 1 }}
                  >
                    <Stack
                      alignItems="center"
                      gap={1}
                      p={1}
                    >
                      <Typography>{t("meta.controls.darkMode")}</Typography>
                    </Stack>
                    {cropPreview}
                  </Paper>
                </ThemeProvider>
                <ThemeProvider
                  theme={(outerTheme) => {
                    return createTheme({ ...outerTheme, palette: { mode: "light" } })
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{ flex: 1 }}
                  >
                    <Stack
                      alignItems="center"
                      gap={1}
                      p={1}
                    >
                      <Typography>{t("meta.controls.lightMode")}</Typography>
                    </Stack>
                    {cropPreview}
                  </Paper>
                </ThemeProvider>
              </Stack>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-end"
                width="100%"
              >
                <Button
                  color="inherit"
                  onClick={() => setStep("crop")}
                  variant="text"
                  component="span"
                >
                  {"← " + t("common:actions.back")}
                </Button>
                <Button
                  color="primary"
                  onClick={handleUpload}
                  variant="contained"
                  component="span"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faUpload}
                      size="1x"
                    />
                  }
                >
                  {t("meta.controls.upload")}
                </Button>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
      {loadingState && <CircularProgress />}
      {!urlState && !loadingState && props.editable && (
        <label>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                return
              }
              onFileSelected(e.target.files[0])
            }}
          />
          <Button
            color="primary"
            variant="contained"
            component="span"
            startIcon={
              <FontAwesomeIcon
                icon={faFileImage}
                size="1x"
              />
            }
          >
            {props.buttonText}
          </Button>
        </label>
      )}
      {urlState && !loadingState && props.editable && (
        <Badge
          badgeContent={
            <span onClick={() => props.onChange("")}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          }
          style={{ cursor: "pointer" }}
          color="error"
        >
          {imageElement}
        </Badge>
      )}
      {urlState && !loadingState && !props.editable && imageElement}
    </React.Fragment>
  )
}

export default MediaUpload
