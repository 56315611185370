import { faUsers } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon } from "@mui/material"
import { useLocation, matchPath } from "react-router"

import { StyleNavLinkButton } from "../../PageLayout/Sidebar/styles"
import { StyleListItem, StyleListItemText } from "../../StyleMaterialUI"

const UserManagementSidebarEntries = () => {
  const location = useLocation()

  const isUserRoute = matchPath(location.pathname, {
    path: "/users",
  })

  return (
    <>
      {isUserRoute && (
        <>
          <StyleNavLinkButton
            exact
            to="/users/PLAYBRAIN"
          >
            <StyleListItem selected={location.pathname === "/users/PLAYBRAIN"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUsers} />
              </ListItemIcon>
              <StyleListItemText primary="PlayBrain" />
            </StyleListItem>
          </StyleNavLinkButton>
          <StyleNavLinkButton
            exact
            to="/users/TAIYORO"
          >
            <StyleListItem selected={location.pathname === "/users/TAIYORO"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUsers} />
              </ListItemIcon>
              <StyleListItemText primary="TAIYORO" />
            </StyleListItem>
          </StyleNavLinkButton>
        </>
      )}
    </>
  )
}

export default UserManagementSidebarEntries
