import React from "react"

import { CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

import { Wrapper, Text } from "./styles"

const LoadingBox = () => {
  const { t } = useTranslation()

  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <Text>{t("loading")}</Text>
      <CircularProgress />
    </Wrapper>
  )
}

export default LoadingBox
