import type Producer from "../../models/Taiyoro/Meta/Producer"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type ProducersResponse = {
  data?: {
    admin?: {
      producer?: {
        get?: Array<Producer>
      }
    }
  }
}

export const fetchProducers = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          producer {
            get {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as ProducersResponse

    return response.data?.admin?.producer?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch producers")
  }
}

export type ProducerResponse = {
  data?: {
    admin?: {
      producer?: {
        get?: Producer | null
      }
    }
  }
}

export const fetchProducer = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          producer {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as ProducerResponse

    return response.data?.admin?.producer?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch producer")
  }
}

export type CreateProducerResponse = {
  data?: {
    admin?: {
      producer?: {
        save?: Producer | null
      }
    }
  }
}

export const createProducer = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  isGameDeveloper?: boolean,
  id?: string | null
) => {
  try {
    const producerInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      isGameDeveloper !== undefined ? `isGameDeveloper: ${isGameDeveloper ? 1 : 0}` : null,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
      admin {
        producer {
          save (producer: {
            ${producerInput}
          }) {
            id
            name
            nameJa
            sortJa
            isGameDeveloper
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateProducerResponse

    const savedProducer = response.data?.admin?.producer?.save

    if (savedProducer) {
      savedProducer.isGameDeveloper = savedProducer.isGameDeveloper === 1
    }

    return response.data?.admin?.producer?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create producer")
  }
}

export type DeleteProducerResponse = {
  data?: {
    admin?: {
      producer?: {
        delete: Producer
      }
    }
  }
}

export const deleteProducer = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        producer {
          delete (
              producerId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteProducerResponse

    return response.data?.admin?.producer?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete producer")
  }
}
