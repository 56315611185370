import { useState } from "react"

import { faCopy, faEdit, faPlus, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fab, Paper, Stack, Tooltip, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog/"
import { PostImageModal } from "components/Taiyoro/Posts/PostImageModal"
import { useTranslation } from "react-i18next"
import type { Post, PostImage } from "services/Taiyoro/posts"
import { deletePostImage, updatePostImage } from "services/Taiyoro/posts"
import { createPostImage } from "services/Taiyoro/posts"

interface Props {
  postId: Pick<Post, "id">["id"]
  images: Array<PostImage>
  refreshImages: (images: Array<PostImage>) => void
  ogImage: string
  clearOgImage: () => void
  setWaiting: (arg: boolean) => void
}

export type ImageState = Omit<PostImage, "id">

export const PostImages = ({ postId, images, refreshImages, ogImage, clearOgImage, setWaiting }: Props) => {
  const { t } = useTranslation("taiyoro")
  const [showAddImageModal, setShowAddImageModal] = useState(false)
  const [showEditImageModal, setShowEditImageModal] = useState<null | string>(null)
  const [showDeleteImageModal, setShowDeleteImageModal] = useState<null | string>(null)

  const [imageState, setImageState] = useState<ImageState>({
    postId,
    url: "",
    altText: "",
    altTextJa: "",
  })

  const resetImageState = () => {
    setImageState({ postId, url: "", altText: "", altTextJa: "" })
  }

  const handleCopyMarkdown = (locale: "ja" | "en", id: string) => {
    const image = images.find((img) => img.id === id)
    const altText = locale === "ja" ? image.altTextJa : image.altText
    let str = `![${altText}](${image.url})`
    navigator.clipboard.writeText(str)
  }

  const handleAddImage = () => {
    setWaiting(true)
    createPostImage(imageState)
      .then((res) => {
        if (res.status === "success") {
          refreshImages(res.data)
          resetImageState()
          setShowAddImageModal(false)
        }
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  const editImage = (image: PostImage) => {
    setShowEditImageModal(image.id)
    setImageState(image)
  }

  const handleEditImage = () => {
    setWaiting(true)
    updatePostImage({ ...imageState, id: showEditImageModal })
      .then((res) => {
        if (res.status === "success") {
          refreshImages(res.data)
          resetImageState()
          setShowEditImageModal(null)
        }
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  const handleDeleteImage = (id: string) => {
    setWaiting(true)
    deletePostImage({ id }).then((res) => {
      if (res.status === "success") {
        setShowDeleteImageModal(null)
        setWaiting(false)
        if (ogImage === id) {
          clearOgImage()
        }
        refreshImages(res.data)
      }
    })
  }

  const columns: GridColumns<PostImage> = [
    {
      flex: 1,
      field: "url",
      sortable: false,
      headerName: t("posts.detailsPage.tableHeaders.image"),
      renderCell: (param: GridRenderCellParams<string, PostImage>) => {
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              "& img": { maxWidth: "100%", maxHeight: "100%" },
            }}
          >
            <img
              src={param.value}
              alt=""
            />
          </Stack>
        )
      },
    },
    {
      flex: 2,
      field: "altTextJa",
      sortable: false,
      headerName: t("posts.detailsPage.tableHeaders.altTextJa"),
    },
    {
      flex: 2,
      field: "altText",
      sortable: false,
      headerName: t("posts.detailsPage.tableHeaders.altText"),
    },
    {
      flex: 1,
      field: "actions",
      sortable: false,
      headerName: t("posts.detailsPage.tableHeaders.actions"),
      type: "actions",
      minWidth: 130,
      getActions: (params) => [
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <Tooltip
              title={t("posts.detailsPage.actionCopyJa")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faCopy}
                style={{ fontSize: "1rem" }}
              />
            </Tooltip>
          }
          onClick={() => handleCopyMarkdown("ja", params.row.id)}
          label={t("posts.detailsPage.actionCopyJa")}
        />,
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <Tooltip
              title={t("posts.detailsPage.actionCopy")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faCopy}
                style={{ fontSize: "1rem" }}
              />
            </Tooltip>
          }
          onClick={() => handleCopyMarkdown("en", params.row.id)}
          label={t("posts.detailsPage.actionCopy")}
        />,
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <Tooltip
              title={t("posts.detailsPage.actionEdit")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ fontSize: "1rem" }}
              />
            </Tooltip>
          }
          onClick={() => editImage(params.row)}
          label={t("posts.detailsPage.actionEdit")}
        />,
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <Tooltip
              title={t("posts.detailsPage.actionDelete")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                style={{ fontSize: "1rem", color: "red" }}
              />
            </Tooltip>
          }
          onClick={() => setShowDeleteImageModal(params.row.id)}
          label={t("posts.detailsPage.actionDelete")}
        />,
      ],
    },
  ]

  return (
    <>
      <Paper sx={{ flex: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ m: 2 }}
        >
          <Typography variant="h6">Images</Typography>
          <Fab
            variant="extended"
            size="small"
            color="success"
            aria-label="add"
            onClick={() => setShowAddImageModal(true)}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: "0.5em" }}
            />
            {t("posts.detailsPage.addImage")}
          </Fab>
        </Stack>
        <DataGrid
          sx={{ ml: 2, mr: 2, height: "calc(100% - 85px)" }}
          columns={columns}
          rows={images}
          rowHeight={100}
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          hideFooter
          density="comfortable"
        />
      </Paper>
      <PostImageModal
        title={t("posts.detailsPage.addImage")}
        onSubmit={handleAddImage}
        onClose={() => {
          setShowAddImageModal(false)
          resetImageState()
        }}
        show={showAddImageModal}
        imageState={imageState}
        setImageState={setImageState}
      />
      <PostImageModal
        title={t("posts.detailsPage.editImage")}
        onSubmit={handleEditImage}
        onClose={() => {
          setShowEditImageModal(null)
          resetImageState()
        }}
        show={!!showEditImageModal}
        imageState={imageState}
        setImageState={setImageState}
      />
      <ConfirmationDialog
        title={t("posts.confirmDeletionImageTitle")}
        open={!!showDeleteImageModal}
        body={t("posts.confirmDeletionImageBody")}
        onCancel={() => setShowDeleteImageModal(null)}
        onConfirm={() => handleDeleteImage(showDeleteImageModal)}
        cancelText={t("posts.cancelDeletion")}
        confirmText={t("posts.confirmDeletion")}
      />
    </>
  )
}
