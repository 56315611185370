import { useContext } from "react"

import { Box, Slider, Stack, Typography } from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import moment from "moment"
import { UTCDateInJST } from "utils/tools"

export const Scrubber = () => {
  const { eventDate, streamIdColorMap, timeMarks, setTimeMarks, chartData } =
    useContext(EventDateBracketsContext)

  const canDisplayScrubber = timeMarks.start !== "" && timeMarks.end !== ""

  if (!canDisplayScrubber) {
    return <></>
  }

  return (
    <Slider
      value={[
        chartData.findIndex(
          (viewershipData) =>
            moment.utc(viewershipData.datetime).format() === moment.utc(timeMarks.start).format()
        ),
        chartData.findIndex(
          (viewershipData) =>
            moment.utc(viewershipData.datetime).format() === moment.utc(timeMarks.end).format()
        ),
      ]}
      min={0}
      max={chartData.length - 1}
      onChange={(_event, values, activeThumb) => {
        setTimeMarks({
          ...timeMarks,
          start: chartData[values[0] as number].datetime,
          end: chartData[values[1] as number].datetime,
          selected: activeThumb === 1 ? "end" : "start",
        })
      }}
      valueLabelDisplay="auto"
      valueLabelFormat={(value) => {
        if (value === -1) {
          return ""
        }
        return (
          <>
            <Typography>{UTCDateInJST(chartData[value].datetime).format("HH:mm:ss")}</Typography>
            {Object.keys(chartData[value])
              .filter((key) => key !== "datetime")
              .map((streamId) => {
                const stream = eventDate.platforms.find((datePlatform) => datePlatform.id === streamId)!
                return (
                  <Stack
                    direction="row"
                    gap={1}
                    key={streamId}
                    alignItems="center"
                  >
                    <Box
                      width="8px"
                      height="8px"
                      bgcolor={streamIdColorMap[streamId]}
                      borderRadius="50%"
                    />
                    <Typography>
                      {stream.channelDisplayName}: {chartData[value][streamId]}
                    </Typography>
                  </Stack>
                )
              })}
          </>
        )
      }}
    />
  )
}
