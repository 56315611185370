import { useEffect, useState } from "react"

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Box, LinearProgress, Link, Snackbar } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import type { MisconfiguredChannel } from "models/Taiyoro/Stream"
import { useTranslation } from "react-i18next"
import { fetchMisconfiguredChannels, repairMisconfiguredChannel } from "services/Taiyoro/channels"

const ChannelRepair = () => {
  const [misconfiguredChannels, setMisconfiguredChannels] = useState([])
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const { t } = useTranslation("taiyoro")

  const handleError = () => {
    setError(true)
  }

  const loadMisconfiguredChannels = async () => {
    setLoading(true)
    fetchMisconfiguredChannels()
      .then((misconfiguredChannels) => {
        setMisconfiguredChannels(misconfiguredChannels)
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadMisconfiguredChannels()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      flex: 1,
      field: "id",
      sortable: false,
      headerName: t("meta.headers.id"),
    },
    {
      field: "url",
      flex: 3,
      sortable: false,
      headerName: t("edit.dates.streams.channelUrl"),
      renderCell: (params) => (
        <Link
          href={params.value}
          target="_blank"
        >
          {params.value}
          <Box
            ml={1}
            component="span"
          >
            <FontAwesomeIcon icon={faExternalLink} />
          </Box>
        </Link>
      ),
    },
    {
      flex: 2,
      field: "channelDisplayName",
      sortable: false,
      editable: true,
      headerName: t("channelRepair.channelName"),
    },
    {
      flex: 1,
      field: "channelId",
      sortable: false,
      editable: true,
      headerName: t("channelRepair.channelId"),
    },
  ]

  const onCellEditCommit = async (params: any) => {
    const updates = {
      [params.field]: params.value,
    }
    const misconfiguredChannel = { ...(params.row as MisconfiguredChannel), ...updates }
    if (
      misconfiguredChannel.channelDisplayName &&
      misconfiguredChannel.channelDisplayName !== "Unknown" &&
      misconfiguredChannel.channelId
    ) {
      setUpdating(true)
      repairMisconfiguredChannel(misconfiguredChannel)
        .then((success) => {
          if (success) {
            setShowSuccess(true)
            loadMisconfiguredChannels()
          }
        })
        .catch(handleError)
        .finally(() => setUpdating(false))
    }
  }

  return (
    <Box
      width="100%"
      height="95%"
    >
      <DataGrid
        rows={misconfiguredChannels}
        columns={columns}
        disableSelectionOnClick
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading || updating}
        density="comfortable"
        disableColumnFilter
        disableDensitySelector
        disableColumnMenu
        error={error}
        onCellEditCommit={onCellEditCommit}
        hideFooter
      />
      <Snackbar
        open={showSuccess}
        autoHideDuration={3000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">{t("channelRepair.success")}</Alert>
      </Snackbar>
    </Box>
  )
}

export default ChannelRepair
