import { Box, styled } from "@mui/material"

import { StyleLink, StyleIconButton } from "../../StyleMaterialUI"

export const StylePageWrapper = styled(Box)`
  display: flex;
`

export const StyleEditWrapper = styled(Box)``

export const StyleTitleLink = styled(StyleLink)({})

export const StyleIconButtonMenuToggle = styled(StyleIconButton)({
  "&.hide": {
    display: "none",
  },
})

export const StyleMainContent = styled(`main`)`
  min-height: 100vh;
  padding: 24px;
  box-sizing: border-box;
  flex-grow: 1;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  margin-left: -240px;
  overflow: inherit;
  max-width: 100%;

  &.open {
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    margin-left: 0;
    max-width: calc(100% - 240px);
  }
`

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

export const StyleLanguageWrapper = styled(Box)`
  margin-right: 10px;
`
