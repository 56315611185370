import { useContext, useState } from "react"

import { Alert, Slide, Snackbar } from "@mui/material"
import { NotificationContext } from "contexts/notification"
import { useUpdateEffect } from "react-use"

function TransitionRight(props) {
  return (
    <Slide
      {...props}
      direction="right"
    />
  )
}

export const NotificationCenter = () => {
  const { notification } = useContext(NotificationContext)

  const [open, setOpen] = useState(false)

  useUpdateEffect(() => {
    setOpen(true)
    const timeout = setTimeout(() => setOpen(false), 6000)
    return () => {
      clearTimeout(timeout)
    }
  }, [notification])

  return (
    <Snackbar
      open={open}
      TransitionComponent={TransitionRight}
      autoHideDuration={6000}
    >
      <Alert severity={notification?.severity || "success"}>{notification?.message || ""}</Alert>
    </Snackbar>
  )
}
