import { CircularProgress } from "@mui/material"
import TableList from "components/Table/TableList"

export const LoadingProgress = () => {
  return (
    <TableList.BodyRow>
      <TableList.BodyCell>
        <CircularProgress />
      </TableList.BodyCell>
    </TableList.BodyRow>
  )
}
