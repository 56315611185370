import { useEffect, useState } from "react"

import { faSave, faSpinner, faUserPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import validator from "validator"

import { Products, UserRole } from "../../models/UserManagement"
import { createUser } from "../../services/UserManagement/user"

const CreateUser = () => {
  const { t } = useTranslation(["user-management", "common"])
  const history = useHistory()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [savingState, setSavingState] = useState(false)
  const [invalidState, setInvalidState] = useState(false)
  const [errorState, setErrorState] = useState(false)

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [product, setProduct] = useState(Products.PLAYBRAIN)
  const [role, setRole] = useState(UserRole.NONE)

  const handleOnAdd = () => {
    setDialogOpen(true)
  }

  const handleOnClose = () => {
    setDialogOpen(false)
    resetFields()
    setErrorState(false)
    setSavingState(false)
  }

  const handleOnCreate = () => {
    setSavingState(true)
    createUser(username, password, email, product, role)
      .then((newUserId) => {
        setSavingState(false)
        history.push(`/users/${product}/${newUserId}/edit`)
      })
      .catch(() => {
        setErrorState(true)
        setSavingState(false)
      })
  }

  const resetFields = () => {
    setUsername("")
    setPassword("")
    setEmail("")
    setProduct(Products.PLAYBRAIN)
    setRole(UserRole.NONE)
  }

  const isPasswordValid = () =>
    validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
    })

  useEffect(() => {
    const fields = [username, password, email, role, product]
    const isInvalid = fields.some((field) => !field || field === "") || !isPasswordValid()
    isInvalid !== invalidState && setInvalidState(isInvalid)
    // @ts-ignore
  }, [username, password, email, role, product])

  return (
    <>
      <Tooltip title={t("create")}>
        <Fab
          size="medium"
          color="success"
          aria-label="add"
          sx={{ position: "absolute", right: "24px" }}
          onClick={handleOnAdd}
        >
          <FontAwesomeIcon icon={faUserPlus} />
        </Fab>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={handleOnClose}
      >
        <DialogContent style={{ padding: "24px" }}>
          <Typography variant="h5">{t("create")}</Typography>
          <Box
            marginBottom="12px"
            marginTop="24px"
          >
            <TextField
              label={t("fields.username")}
              value={username}
              fullWidth
              onChange={(e) => setUsername(e.target.value || "")}
              autoComplete="off"
            />
          </Box>
          <Box marginBottom="12px">
            <TextField
              label={t("fields.email")}
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value || "")}
              type="email"
              autoComplete="off"
            />
          </Box>
          <Box marginBottom="12px">
            <TextField
              label={t("fields.password")}
              value={password}
              fullWidth
              onChange={(e) => setPassword(e.target.value || "")}
              type="password"
              error={!isPasswordValid()}
              helperText={t("passwordHelp")}
              autoComplete="new-password"
            />
          </Box>
          <Box marginBottom="12px">
            <TextField
              select
              label={t("product")}
              value={product}
              fullWidth
              onChange={(e) => setProduct(e.target.value as Products)}
            >
              {(Object.values(Products).filter((value) => typeof value === "string") as Array<string>).map(
                (product) => (
                  <MenuItem
                    key={product}
                    value={product}
                  >
                    {product}
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>
          <Box marginBottom="12px">
            <TextField
              select
              label={t("fields.role")}
              value={role}
              fullWidth
              onChange={(e) => setRole(e.target.value as UserRole)}
            >
              {(Object.values(UserRole).filter((value) => typeof value === "string") as Array<string>).map(
                (userRole) => (
                  <MenuItem
                    key={userRole}
                    value={userRole}
                  >
                    {userRole}
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>
          <Box marginBottom="12px">
            <Alert severity="info">{t(`user-management:roleDescriptions.${role}`)}</Alert>
          </Box>
          {errorState && <Alert severity="error">{t("apiError")}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>{t("common:actions.cancel")}</Button>
          <Button
            onClick={handleOnCreate}
            color="success"
            variant="contained"
            startIcon={
              <FontAwesomeIcon
                icon={savingState ? faSpinner : faSave}
                spin={savingState}
              />
            }
            disabled={savingState || invalidState}
          >
            {t("common:actions.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateUser
