import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

const runQuery = async (query: string) =>
  await GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

export type PostType = "press-release" | "news"

export const POST_TYPES: Array<PostType> = ["news", "press-release"]

export type Post = {
  id: string
  slug: string
  publicationDatetime: string
  published: boolean
  title: string | null
  titleJa: string
  body: string | null
  bodyJa: string
  ogImage: string | null
  metaDescription: string | null
  metaDescriptionJa: string | null
  type: PostType
}

export type PostListItem = Omit<
  Post,
  "slug" | "body" | "bodyJa" | "ogImage" | "metaDescription" | "published"
> & {
  published: 0 | 1
}

export type PostImage = {
  id: string
  postId: Pick<Post, "id">["id"]
  url: string
  altText: string | null
  altTextJa: string
}

export type PostFull = Post & {
  images: Array<PostImage>
}

export type PostList = {
  totalCount: number
  results: Array<PostListItem>
}

type Status<T> = {
  status: T
}

type Success = Status<"success">
type Error = Status<"error">

type PostAPIResponseNoData = Success | Error

type PostAPIResponse<T> =
  | (Success & {
      data: T
    })
  | Error

type FetchPostsParams = {
  page: number
  size: number
  type: PostType
}

type FetchPostsResponse = PostAPIResponse<PostList>

export const fetchPagedPosts = async ({
  page,
  size,
  type,
}: FetchPostsParams): Promise<FetchPostsResponse> => {
  const offset = page * size

  const query = `query {
    admin {
      post {
        list(limit: ${size}, offset: ${offset}, type: "${type}") {
          totalCount
          results {
            id
            slug
            publicationDatetime
            published
            title
            titleJa
          }
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.list && {
        status: "success",
        data: response.data.admin.post.list,
      }) || {
      status: "error",
    }
  )
}

type FetchPostParams = Pick<Post, "id">

type FetchPostResponse = PostAPIResponse<PostFull>

export const fetchPost = async ({ id }: FetchPostParams): Promise<FetchPostResponse> => {
  const query = `query {
    admin {
      post {
        get(postId: "${id}") {
          id
          slug
          publicationDatetime
          published
          title
          titleJa
          body
          bodyJa
          ogImage
          metaDescription
          metaDescriptionJa
          type
          images {
            id
            postId
            url
            altText
            altTextJa
          }
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.get && {
        status: "success",
        data: response.data.admin.post.get,
      }) || {
      status: "error",
    }
  )
}

type CreatePostParams = { post: Partial<Post> }

type CreatePostResponse = PostAPIResponse<Pick<Post, "id">>

export const createPost = async ({ post }: CreatePostParams): Promise<CreatePostResponse> => {
  const query = `mutation {
    admin {
      post {
        savePost (post: {
           slug: "${post.slug}"
           publicationDatetime: "${post.publicationDatetime}"
           type: "${post.type}"
           title: null
           titleJa: "${post.titleJa}"
           body: null
           bodyJa: "${post.bodyJa}"
           published: ${post.published === true ? 1 : 0}
           ogImage: null
           metaDescription: null
           metaDescriptionJa: null
        }){
          id
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.savePost &&
      response.data.admin.post.savePost.id && {
        status: "success",
        data: {
          id: response.data.admin.post.savePost.id,
        },
      }) || {
      status: "error",
    }
  )
}

type UpdatePostParams = Pick<Post, "id"> &
  Partial<Record<keyof Omit<Post, "id" | "published">, string>> &
  Partial<Pick<Post, "published">>

type UpdatePostResponse = PostAPIResponseNoData

export const updatePost = async ({ id, ...params }: UpdatePostParams): Promise<UpdatePostResponse> => {
  const query = `mutation {
    admin {
      post {
        savePost (post: {
          id: "${id}"
          ${params.slug !== undefined ? `slug: "${params.slug}"` : ""}
          ${
            params.publicationDatetime !== undefined
              ? `publicationDatetime: "${params.publicationDatetime}"`
              : ""
          }
          ${params.title !== undefined ? `title: "${params.title}"` : ""}
          ${params.type !== undefined ? `type: "${params.type}"` : ""}
          ${params.titleJa !== undefined ? `titleJa: "${params.titleJa}"` : ""}
          ${params.body !== undefined ? `body: ${JSON.stringify(params.body)}` : ""}
          ${params.bodyJa !== undefined ? `bodyJa: ${JSON.stringify(params.bodyJa)}` : ""}
          ${params.published !== undefined ? `published: ${params.published ? 1 : 0}` : ""}
          ${
            params.ogImage !== undefined
              ? params.ogImage === null
                ? `ogImage: null`
                : `ogImage: "${params.ogImage}"`
              : ""
          }
          ${params.metaDescription !== undefined ? `metaDescription: "${params.metaDescription}"` : ""}
          ${params.metaDescriptionJa !== undefined ? `metaDescriptionJa: "${params.metaDescriptionJa}"` : ""}
        }){
          id
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.savePost &&
      response.data.admin.post.savePost.id && {
        status: "success",
      }) || {
      status: "error",
    }
  )
}

type DeletePostParams = {
  id: Pick<Post, "id">["id"]
}

type DeletePostResponse = PostAPIResponseNoData

export const deletePost = async ({ id }: DeletePostParams): Promise<DeletePostResponse> => {
  const query = `mutation {
    admin {
      post {
        deletePost(postId: "${id}")
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.deletePost && {
        status: "success",
      }) || {
      status: "error",
    }
  )
}

type CreatePostImageParams = Pick<PostImage, "postId" | "url" | "altTextJa"> &
  Partial<Pick<PostImage, "altText">>

type CreatePostImageResponse = PostAPIResponse<Array<PostImage>>

export const createPostImage = async ({
  postId,
  url,
  altText,
  altTextJa,
}: CreatePostImageParams): Promise<CreatePostImageResponse> => {
  const query = `mutation {
    admin {
      post {
        savePostImage(postImage: {
          postId: "${postId}"
          url: "${url}"
          ${altText && altText !== "" ? `altText: "${altText}"` : `altText: null`}
          altTextJa: "${altTextJa && altTextJa !== "" ? altTextJa : "日本語の代替テキストを入力してください"}"
        }) {
          id
          postId
          url
          altText
          altTextJa
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.savePostImage && {
        status: "success",
        data: response.data.admin.post.savePostImage,
      }) || {
      status: "error",
    }
  )
}

type UpdatePostImageParams = Pick<PostImage, "id"> & Partial<Omit<PostImage, "id" | "postId">>

type UpdatePostImageResponse = PostAPIResponse<Array<PostImage>>

export const updatePostImage = async ({
  id,
  url,
  altText,
  altTextJa,
}: UpdatePostImageParams): Promise<UpdatePostImageResponse> => {
  const query = `mutation {
    admin {
      post {
        savePostImage(postImage: {
          id: "${id}"
          ${url && url !== "" ? `url: "${url}"` : ""}
          ${altText && altText !== "" ? `altText: "${altText}"` : ""}
          ${altTextJa && altTextJa !== "" ? `altTextJa: "${altTextJa}"` : ""}
        }) {
          id
          postId
          url
          altText
          altTextJa
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.savePostImage && {
        status: "success",
        data: response.data.admin.post.savePostImage,
      }) || {
      status: "error",
    }
  )
}

type DeletePostImageParams = Pick<PostImage, "id">

type DeletePostImageResponse = PostAPIResponse<Array<PostImage>>

export const deletePostImage = async ({ id }: DeletePostImageParams): Promise<DeletePostImageResponse> => {
  const query = `mutation {
    admin {
      post {
        deletePostImage(postImageId: "${id}") {
          id
          postId
          url
          altText
          altTextJa
        }
      }
    }
  }`

  const response = await runQuery(query)

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.post &&
      response.data.admin.post.deletePostImage && {
        status: "success",
        data: response.data.admin.post.deletePostImage,
      }) || {
      status: "error",
    }
  )
}

interface CreatePostPreviewResponse {
  data: {
    admin: {
      jsonDump: {
        save: string
      }
    }
  }
}

export const createPostPreview = async (object: Pick<Post, "id"> & Partial<Post>): Promise<string> => {
  const query = `mutation {
    admin {
      jsonDump {
        save(jsonDump: {
          type: "POST",
          data: ${JSON.stringify(JSON.stringify(object))}
        })
      }
    }
  }
 `

  return runQuery(query).then((response: CreatePostPreviewResponse) => {
    return response.data.admin.jsonDump.save
  })
}
