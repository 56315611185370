import React from "react"

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Collapse,
  Link,
  Container,
  Menu,
  MenuItem,
  Box,
  Breadcrumbs,
  Chip,
  lighten,
  ListItemButton,
} from "@mui/material"
import AccordionActions from "@mui/material/AccordionActions"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import Fab from "@mui/material/Fab"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Select from "@mui/material/Select"
import Step from "@mui/material/Step"
import StepButton from "@mui/material/StepButton"
import Stepper from "@mui/material/Stepper"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/styles"

import { fweight } from "../Styles/vars"

export const StyleButton = styled(Button)({
  border: 0,
  borderRadius: 3,
  height: 40,
  fontWeight: `${fweight.w6}`,
  padding: "0 15px",
  margin: "10px 20px",
})

export const StyleButtonExpansionPanelAction = styled(Button)({
  paddingRight: "20px",
})

export const StyleFabExtended = styled(Fab)({
  "&&:hover": {
    opacity: 0.8,
  },
  "& a ": {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
})

export const StyleGridCenter = styled(Grid)({
  textAlign: "center",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
})

export const StyleStepper = styled(Stepper)({
  width: "100%",
})

export const StyleStep = styled(Step)({
  display: "block",
  width: "100%",
})

export const StyleTextFields = styled(TextField)({
  width: "100%",
})

export const StyleSelectTextField = styled(TextField)({})

export const StyleCard = styled(({ error, ...other }) => <Card {...other} />)({
  margin: "auto",
  textAlign: "center",
  padding: "5px",
  borderRadius: "10px",
  border: (props) => (props.error ? "2px red solid" : "none"),
})

export const StylePaper = styled(Paper)({
  padding: "16px",
})

export const StyleBox = styled(Box)({
  padding: "16px",
})

export const StyleSwitch = styled(Switch)({
  maxWidth: "200px",
  cursor: "pointer",
  margin: "0 auto",
})

export const StyleSelect = styled(Select)({})

export const StyleSelectMultiple = styled(Select)({
  minWidth: "300px",
})

export const StyleStepButton = styled(StepButton)({})
export const StyleExpansionPanelActions = styled(AccordionActions)({
  backgroundColor: "#EFEFEF",
})

export const StyleDivider = styled(Divider)({
  margin: "10px 0",
})

export const StyleChip = styled(Chip)({})

export const StyleContainer = styled(Container)({})

export const StyleTooltip = styled(Tooltip)({})

export const StyleAppBar = styled(AppBar)({
  transition: "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  "&.shift": {
    width: "calc(100% - 240px)",
    transition: "margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    marginLeft: "240px",
  },
})

export const StyleAppBarTitle = styled(`div`)({
  flexGrow: "1",
})

export const StyleToolbar = styled(Toolbar)({})

export const StyleIconButton = styled(IconButton)({
  margin: "2px",
  backgroundColor: (props) => props.bgcolor || undefined,
  "&:hover": {
    backgroundColor: (props) => (props.bgcolor && lighten(props.bgcolor, 0.2)) || undefined,
  },
})

export const StyleTypography = styled(Typography)({})

export const StyleMenu = styled(Menu)({})

export const StyleMenuItem = styled(MenuItem)({
  pointerEvents: (props) => (props.disabled ? "none" : "auto"),
})

export const StyleDrawer = styled(Drawer)({
  width: "240px",
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    width: "240px",
    zIndex: "auto",
  },
})

export const StyleList = styled(List)({})

export const StyleListItem = styled(ListItemButton)({})

export const StyleListItemText = styled(ListItemText)({})

export const StyleListItemIcon = styled(ListItemIcon)({})

export const StyleCssBaseline = CssBaseline

export const StyleCollapse = styled(Collapse)({})

export const StyleLink = styled(Link)({})

export const StyleBreadcrumbs = styled(Breadcrumbs)({})

export const StyleCircularProgress = styled(CircularProgress)({})
