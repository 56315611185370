import { useContext, useState } from "react"

import { faCalculator } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material"
import LoadingBox from "components/LoadingBox"
import { NotificationContext } from "contexts/notification"
import { UserRole } from "models/UserManagement"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { recalculateTaiyoroScores } from "services/Taiyoro/event"
import { HIGH_ACCESS_ROLES } from "utils/roles"
import useRolesCanAccess from "utils/useRolesCanAccess"

interface Props {
  eventId: string
}

export const RecalculateTaiyoroScore = (props: Props) => {
  const canRecalculate = useRolesCanAccess([...HIGH_ACCESS_ROLES, UserRole.EDITOR])

  const [showDialog, setShowDialog] = useState(false)

  const [loading, setLoading] = useState(false)

  const { setNotification } = useContext(NotificationContext)

  const history = useHistory()

  const handleRecalculate = () => {
    setLoading(true)
    recalculateTaiyoroScores(props.eventId)
      .then((success) => {
        if (!success) {
          throw new Error()
        }
        setNotification({
          message: t("common:dataUpdateSuccess"),
          severity: "success",
        })
        setTimeout(() => history.go(0), 3000)
      })
      .catch((error: Error) => {
        console.log(error)
        setNotification({
          message: error.message,
          severity: "error",
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { t } = useTranslation(["common", "taiyoro"])

  return (
    <>
      {canRecalculate && (
        <Tooltip title={t("taiyoro:recalculateTaiyoroScore.button")}>
          <IconButton
            onClick={() => setShowDialog(true)}
            sx={{ width: "32px", height: "32px" }}
          >
            <FontAwesomeIcon icon={faCalculator} />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>{t("taiyoro:recalculateTaiyoroScore.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("taiyoro:recalculateTaiyoroScore.event.description")}</DialogContentText>
          {loading && <LoadingBox />}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setShowDialog(false)}
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            disabled={loading}
            onClick={(e) => {
              e.stopPropagation()
              handleRecalculate()
            }}
          >
            {t("taiyoro:recalculateTaiyoroScore.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
