import { styled } from "@mui/styles"
import { NavLink } from "react-router-dom"

import { StyleDivider, StyleBox } from "../../StyleMaterialUI"

export const StyleNavLinkButton = styled(NavLink)({
  color: "inherit",
  textDecoration: "none",
  "&:hover": {
    color: "inherit",
    textDecoration: "none",
  },
  "&:visited": {
    color: "inherit",
  },
  "&.disabled": {
    pointerEvents: "none",
    color: "gray",
  },
})

export const StyleImg = styled("img")({
  width: "40px",
  height: "40px",
  marginRight: "auto",
})

export const StyleNavDivider = styled(StyleDivider)({
  margin: "0",
})

export const NavBox = styled(StyleBox)({
  display: "flex",
  padding: "0px 8px",
  minHeight: "64px",
  alignItems: "center",
  flexWrap: "nowrap",
})
