import { Stack, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  value: { prompt: string; promptJa: string }
  updateValue: (value: string) => void
  updateValueJa: (value: string) => void
  updateStatus?: () => void
}

export const PromptControl = ({ value, updateValue, updateValueJa, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  return (
    <Stack gap={2}>
      <TextField
        minRows={4}
        multiline
        placeholder={t("eventPickem.daily.modal.promptJa")}
        value={value.promptJa}
        onChange={(event) => {
          updateValueJa(event.target.value)
          updateStatus?.()
        }}
        fullWidth
      />
      <TextField
        minRows={4}
        multiline
        placeholder={t("eventPickem.daily.modal.promptEn")}
        value={value.prompt}
        onChange={(event) => {
          updateValue(event.target.value)
          updateStatus?.()
        }}
        fullWidth
      />
    </Stack>
  )
}
