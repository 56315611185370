import type TeamOrganization from "../../models/Taiyoro/Meta/TeamOrganization"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type TeamOrganizationResponse = {
  data?: {
    admin?: {
      teamOrganization?: {
        get?: Array<TeamOrganization>
      }
    }
  }
}

export const fetchTeamOrganizations = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
            teamOrganization {
            get {
              id
              name
              nameJa
              sortJa
              urlSlug,
              primaryImage,
              secondaryImage,
              homepageUrl
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as TeamOrganizationResponse

    return response.data?.admin?.teamOrganization?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch Team Organization")
  }
}

export type CreateTeamOrganizationResponse = {
  data?: {
    admin?: {
      teamOrganization?: {
        save?: TeamOrganization | null
      }
    }
  }
}

export const createTeamOrganization = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  primaryImage?: string,
  secondaryImage?: string,
  urlSlug?: string,
  homepageUrl?: string,
  id?: string
) => {
  try {
    const teamOrganizationInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      `primaryImage: ${primaryImage === "" ? "null" : JSON.stringify(primaryImage)}`,
      `secondaryImage: ${secondaryImage === "" ? "null" : JSON.stringify(secondaryImage)}`,
      `urlSlug: ${urlSlug === "" ? "null" : JSON.stringify(urlSlug)}`,
      `homepageUrl: ${homepageUrl === "" ? "null" : JSON.stringify(homepageUrl)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
      admin {
        teamOrganization {
          save (teamOrganization: {
            ${teamOrganizationInput}
          }) {
            id
            name
            nameJa
            sortJa
            urlSlug
            primaryImage
            secondaryImage
            homepageUrl
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateTeamOrganizationResponse

    return response.data?.admin?.teamOrganization?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create Team Organization")
  }
}

export type DeleteTeamOrganizationResponse = {
  data?: {
    admin?: {
      teamOrganization?: {
        delete: TeamOrganization
      }
    }
  }
}

export const deleteTeamOrganization = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          teamOrganization {
            delete (
                teamOrganizationId: "${id}"
            )
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteTeamOrganizationResponse

    return response.data?.admin?.teamOrganization?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete Team Organization")
  }
}
