import { useContext } from "react"

import { Box, Link, Stack, Typography } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import moment from "moment"
import { Trans, useTranslation } from "react-i18next"

import Footer from "./footer"
import { BorderedPage, PageNumber } from "./styles"

const ReportDisclaimer = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const { t } = useTranslation("playdata")

  return (
    <BorderedPage>
      <Stack
        width="100%"
        height="100%"
      >
        <Stack px={6}>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
          >
            <Stack
              mr="auto"
              gap={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                pt={3}
              >
                <img
                  src="/images/playdata-logo.png"
                  height="24px"
                  alt="PLAYDATA"
                />
                <Typography
                  fontSize="32px"
                  fontWeight="900"
                >
                  {t("reportType")}
                </Typography>
              </Stack>
              <Box
                bgcolor="text.primary"
                height="16px"
                width="224px"
              />
            </Stack>
            <PageNumber />
          </Stack>
        </Stack>
        <Box px={6}>
          <Typography
            fontSize="96px"
            lineHeight="96px"
            fontWeight="900"
            mt={2}
          >
            {playDataEventReportData.name}
          </Typography>
          <Typography
            fontSize="32px"
            fontWeight="300"
            mt={8}
          >
            <Trans
              i18nKey="playdata:disclaimer.dating"
              components={[<strong />]}
              values={{
                customer: playDataEventReportData.customer,
                date: moment().format("YYYY/MM/DD"),
              }}
            />
          </Typography>
          <Typography
            fontSize="32px"
            fontWeight="300"
            mb={8}
          >
            {t("footer.nonDisclosure")}
          </Typography>
          <Typography
            fontSize="32px"
            fontWeight="300"
            mb={8}
          >
            <Trans
              i18nKey="playdata:disclaimer.link"
              components={[
                <Link
                  fontWeight="700"
                  color="common.green"
                  href="https://playdata.gg"
                  target="_blank"
                  underline="none"
                />,
              ]}
            />
          </Typography>
        </Box>
        <Box mt="auto">
          <Footer />
        </Box>
      </Stack>
    </BorderedPage>
  )
}

export default ReportDisclaimer
