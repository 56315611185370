import type Game from "../../models/Taiyoro/Meta/Game"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

const checkFieldExists = async (fieldName: string, typeName: string): Promise<boolean> => {
  const introspectionQuery = `{
    __type(name: "${typeName}") {
      fields {
        name
      }
    }
  }`

  try {
    const response = await GraphQL(introspectionQuery, undefined, undefined, undefined, undefined, endpoint)
    const fields = response.data.__type.fields.map((field: { name: string }) => field.name)
    return fields.includes(fieldName)
  } catch (err) {
    console.error("Introspection Error:", err)
    throw new Error("Failed to check schema")
  }
}

export type GamesResponse = {
  data?: {
    admin?: {
      game?: {
        get?: Array<Game>
      }
    }
  }
}

export const fetchGames = async () => {
  const hasBoxArtImage = await checkFieldExists("boxArtImage", "Game")

  const boxArtImageField = hasBoxArtImage ? "boxArtImage" : ""

  try {
    const response = (await GraphQL(
      `query {
        admin {
          game {
            get {
              id
              name
              nameJa
              sortJa
              hashtag
              urlSlug
              primaryImage
              secondaryImage
              ${boxArtImageField}
              alternativeNames
              parentGroups {
                id
              }
              updatedAt
            }
          }
        }
      }`
        .replace(/\s+/g, " ")
        .trim(),
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as GamesResponse

    return response.data?.admin?.game?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch games")
  }
}

export type GameResponse = {
  data?: {
    admin?: {
      game?: {
        get?: Array<Game>
      }
    }
  }
}

export const fetchGame = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          game {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              hashtag
              urlSlug
              primaryImage
              secondaryImage
              alternativeNames
              boxArtImage
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as GameResponse

    return response.data?.admin?.game?.get?.[0] ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch game")
  }
}

export type CreateGameResponse = {
  data?: {
    admin?: {
      game?: {
        save?: Game | null
      }
    }
  }
}

export const createGame = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  hashtag?: string,
  urlSlug?: string,
  primaryImage?: string,
  secondaryImage?: string,
  boxArtImage?: string,
  id?: string
) => {
  try {
    const gameInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      `hashtag: ${hashtag === "" ? "null" : JSON.stringify(hashtag)}`,
      `urlSlug: ${urlSlug === "" ? "null" : JSON.stringify(urlSlug)}`,
      `primaryImage: ${primaryImage === "" ? "null" : JSON.stringify(primaryImage)}`,
      `secondaryImage: ${secondaryImage === "" ? "null" : JSON.stringify(secondaryImage)}`,
      `boxArtImage: ${boxArtImage === "" ? "null" : JSON.stringify(boxArtImage)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
        admin {
          game {
            save(game: {
              ${gameInput}
            }) {
              id
              name
              nameJa
              sortJa
              hashtag
              urlSlug
              primaryImage
              secondaryImage
              boxArtImage
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateGameResponse

    return response.data?.admin?.game?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create game")
  }
}

export type DeleteGameResponse = {
  data?: {
    admin?: {
      game?: {
        delete: boolean
      }
    }
  }
}

export const deleteGame = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          game {
            delete(gameId: "${id}")
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteGameResponse

    return response.data?.admin?.game?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete game")
  }
}

export type AddGameAlternativeNameResponse = {
  data?: {
    admin?: {
      game?: {
        addGameAlternativeName: {
          gameId: string
          name: string
        } | null
      }
    }
  }
}

export const addGameAlternativeName = async (gameId: string, name: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          game {
            addGameAlternativeName(
              gameId: "${gameId}",
              name: "${name}"
            ) {
              gameId
              name
            }
          }
        }
      }`
    )) as AddGameAlternativeNameResponse

    return response.data?.admin?.game?.addGameAlternativeName ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to add game alternative name")
  }
}

export type DeleteGameAlternativeNameResponse = {
  data?: {
    admin?: {
      game?: {
        deleteGameAlternativeName: boolean
      }
    }
  }
}

export const deleteGameAlternativeName = async (gameId: string, name: string): Promise<boolean | null> => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          game {
            deleteGameAlternativeName(
              gameId: "${gameId}",
              name: "${name}"
            )
          }
        }
      }`
    )) as DeleteGameAlternativeNameResponse

    return response.data?.admin?.game?.deleteGameAlternativeName ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete game alternative name")
  }
}
