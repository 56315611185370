import React from "react"

import type { AlertColor } from "@mui/material"

export type Notification = {
  message: string
  severity: AlertColor
}

type NotificationContextType = {
  notification: Notification | null
  setNotification: (notification: Notification) => void
}

export const NotificationContext = React.createContext<NotificationContextType>({
  notification: null,
  setNotification: (_notification: Notification) => {},
})
