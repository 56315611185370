import { useContext, useRef, useState } from "react"

import { Stack, Typography, Button, TextField, CircularProgress } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { NotificationContext } from "contexts/notification"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useUpdateEffect } from "react-use"
import { fetchHeatmapCSVData } from "services/Taiyoro/Analytics"
import { convertAndDownloadStringAsCSV } from "utils/tools"

export const HeatMapCSVDownloadPage = () => {
  const [from, setFrom] = useState(moment())
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(false)
  const csvData = useRef("")
  const { t } = useTranslation(["common", "taiyoro"])
  const { setNotification } = useContext(NotificationContext)

  const handleFetch = () => {
    setFetching(true)
    setPage(1)
  }

  const handleError = () => {
    setNotification({
      severity: "error",
      message: t("common:errorLoadingData"),
    })
    setFetching(false)
    setPage(1)
  }

  useUpdateEffect(() => {
    if (fetching) {
      fetchHeatmapCSVData(from.format(), page)
        .then((response) => {
          //We've reached EOF if the response is empty
          if (response === "") {
            setFetching(false)
            setPage(1)
            // CSV contents break on the '#' symbol.
            // See more here: https://stackoverflow.com/questions/63924635/js-built-csv-breaking-after-in-excel
            const encodedContents = encodeURI(csvData.current).replaceAll("#", "%23")
            convertAndDownloadStringAsCSV(encodedContents, t("taiyoro:analytics.heatmap.fileName"))
            return
          }
          const [header] = response.split("\n", 1)

          if (page === 1) {
            csvData.current += header
          }

          const [, body] = response.split(`${header}`, 2)

          // The body can be undefined, as header rows without anything else is possible when data is missing in the DB.
          // For example, dev environment or data collection errors.
          if (body) {
            csvData.current += `${body}`
          }
          setPage((p) => p + 1)
        })
        .catch(handleError)
    }
  }, [fetching, page])

  return (
    <Stack gap={2}>
      <Typography>{t("taiyoro:analytics.heatmap.title")}</Typography>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
      >
        <DateTimePicker
          label={t("taiyoro:analytics.heatmap.from")}
          value={moment(from)}
          onChange={setFrom}
          renderInput={(params) => <TextField {...params} />}
          disabled={fetching}
        />
        <Button
          onClick={handleFetch}
          disabled={fetching}
          variant="contained"
        >
          {t("common:actions.fetch")}
        </Button>
      </Stack>
      {fetching && (
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
        >
          <CircularProgress />
          <Typography>{t("taiyoro:analytics.heatmap.fetching", { page })}</Typography>
        </Stack>
      )}
    </Stack>
  )
}
