import { useContext } from "react"

import { Avatar, Dialog, DialogContent, Link, List, ListItemButton, Stack, Typography } from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import type EventStream from "models/Taiyoro/Stream"

interface Props {
  eventStream: EventStream | null
  onClose: () => void
}

export const StreamVodConnectionManagement = (props: Props) => {
  const { eventDate, streamVodMap, setStreamVodMap } = useContext(EventDateBracketsContext)

  const vodCandidates = eventDate.platforms.filter(
    (datePlatform) => datePlatform.isVod && !Object.values(streamVodMap).includes(datePlatform.id)
  )

  const connectVodWithStream = (eventStream: EventStream) => {
    setStreamVodMap((oldStreamVodMap) => ({ ...oldStreamVodMap, [props.eventStream!.id]: eventStream.id }))
    props.onClose()
  }

  return (
    <Dialog
      open={props.eventStream !== null}
      onClose={() => props.onClose()}
    >
      <DialogContent>
        <List>
          {vodCandidates.map((vodCandidate) => (
            <ListItemButton
              key={vodCandidate.id}
              onClick={() => connectVodWithStream(vodCandidate)}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Avatar src={vodCandidate.channelIconUrl ?? undefined} />
                <Typography>{vodCandidate.channelDisplayName}</Typography>
                <Link
                  target="_black"
                  href={vodCandidate.url ?? "#"}
                  onClick={(e) => e.stopPropagation()}
                >
                  {vodCandidate.url}
                </Link>
              </Stack>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}
