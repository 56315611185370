import { useState } from "react"

import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, DialogContent, DialogActions, Button, Typography, Alert, Box } from "@mui/material"
import { useTranslation } from "react-i18next"

import { Products } from "../../models/UserManagement"
import { deletePlayBrainUser, deleteTaiyoroUser } from "../../services/UserManagement/user"

const UserDelete = ({ product, user, onCancel }) => {
  const { t } = useTranslation(["user-management", "common"])

  const [loadingState, setLoadingState] = useState(false)
  const [errorState, setErrorState] = useState(false)

  const handleDeleteUser = async () => {
    const deleteFunc = product === Products.TAIYORO ? deleteTaiyoroUser : deletePlayBrainUser
    setLoadingState(true)
    deleteFunc(user.id)
      .then((success) => {
        setLoadingState(false)
        if (success !== true) {
          throw new Error()
        }
        window.location.reload()
      })
      .catch(() => {
        setLoadingState(false)
        setErrorState(true)
      })
  }

  return (
    <Dialog
      open
      onClose={onCancel}
    >
      <DialogContent>
        <Typography>{t("deleteConfirmation", { name: user.username })}</Typography>
        {errorState && (
          <Box mt="24px">
            <Alert severity="error">
              <Typography>{t("apiError")}</Typography>
            </Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDeleteUser}
          autoFocus
          variant="outlined"
          color="error"
          disabled={loadingState || errorState}
        >
          {t("common:actions.delete")}
          {loadingState && (
            <FontAwesomeIcon
              icon={faSpinner}
              transform="right-6"
              spin
            />
          )}
        </Button>
        <Button
          onClick={onCancel}
          color="primary"
          autoFocus
          variant="contained"
        >
          {t("common:actions.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserDelete
