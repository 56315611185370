import { useEffect, useRef, useState } from "react"

import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Link as MuiLink,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListItem,
  List,
  Typography,
  useTheme,
  Grid,
} from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import { type OnProgressProps } from "react-player/base"
import { Link, useParams } from "react-router-dom"
import {
  ComposedChart,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts"
import {
  type AlgorithmType,
  type Density,
  getHighlightsJobData,
  type HighlightJobType,
} from "services/Taiyoro/dataCollection"
import TinySegmenter from "tiny-segmenter"

// eslint-disable-next-line
const segmenter = new TinySegmenter()

type WordFrequency = { word: string; frequency: number }

type ChatDensityData = {
  chats: Array<Chat>
  density: Array<Density>
  algorithms: Partial<
    Record<
      AlgorithmType,
      { topPoints: Array<Density>; topChatsAtPoint: Record<string, Array<WordFrequency>> }
    >
  >
}

type Chat = {
  timestamp_utc: string
  name: string
  message: string
}

const mostFrequentWords = (passage: string): Array<WordFrequency> => {
  // eslint-disable-next-line
  const words: Array<string> = segmenter.segment(passage)

  // Count word frequencies
  const wordFreq: Record<string, number> = words.reduce(
    (acc, word) => {
      if (word === "" || word === " ") return acc
      acc[word] = (acc[word] || 0) + 1
      return acc
    },
    {} as Record<string, number>
  )

  // Sort words by frequency
  const sortedWords = Object.keys(wordFreq).sort((a, b) => wordFreq[b] - wordFreq[a])

  // Get the top 5 words
  const top5Words = sortedWords.slice(0, 5).map(
    (word) =>
      ({
        word: word,
        frequency: wordFreq[word],
      }) as WordFrequency
  )

  return top5Words
}

const indexToAlgorithmMap = {
  0: "descendingDensity" as AlgorithmType,
  1: "movingAverage150" as AlgorithmType,
  2: "movingAverage300" as AlgorithmType,
}

const ChatDensityVisualizer = () => {
  const { type, vodId } = useParams<{ vodId: string; type: HighlightJobType }>()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ChatDensityData | null>(null)
  const [algorithmType, setAlgorithmType] = useState<AlgorithmType>("descendingDensity")
  const [selectedTopPoint, setSelectedTopPoint] = useState<Density | null>(null)
  const [playhead, setPlayhead] = useState<string | null>(null)

  const { t } = useTranslation(["data-collection", "common"])

  const ref = useRef<ReactPlayer>(null)

  const theme = useTheme()

  const getChatsForSelectedTopPoint = () => {
    if (!data || !selectedTopPoint) return []
    const highlightZoneStart = moment.utc(selectedTopPoint.timestamp_utc).subtract("20", "seconds")
    const highlightZoneEnd = moment.utc(selectedTopPoint.timestamp_utc).add("30", "seconds")
    return data.chats.filter((chat) => {
      const chatTime = moment.utc(chat.timestamp_utc)
      return chatTime.isSameOrAfter(highlightZoneStart) && chatTime.isSameOrBefore(highlightZoneEnd)
    })
  }

  const fetchData = async () => {
    setError(false)

    setLoading(true)

    try {
      const { chatLogFile, densityFile, highlightsFiles } = await getHighlightsJobData(vodId, type)
      const chats = await fetch(chatLogFile).then((resp) => resp.json() as Promise<Array<Chat>>)

      const density = await fetch(densityFile).then((resp) => resp.json() as Promise<Array<Density>>)

      const algorithms: ChatDensityData["algorithms"] = {}

      for (const [index, value] of highlightsFiles.entries()) {
        const highlights = await fetch(value).then((resp) => resp.json() as Promise<Array<Density>>)
        const topChatsAtPoint = highlights.reduce(
          (acc: Record<string, Array<WordFrequency>>, densityHighlight: Density) => {
            const highlightZoneStart = moment.utc(densityHighlight.timestamp_utc).subtract("20", "seconds")
            const highlightZoneEnd = moment.utc(densityHighlight.timestamp_utc).add("30", "seconds")
            const allChatsInHighlightZone = chats.filter((chat) => {
              const chatTime = moment.utc(chat.timestamp_utc)
              return chatTime.isSameOrAfter(highlightZoneStart) && chatTime.isSameOrBefore(highlightZoneEnd)
            })
            const allWordsInHighlightZone = allChatsInHighlightZone.reduce(
              (acc: string, curr: Chat) => acc + " " + curr.message,
              ""
            )
            const top5Words = mostFrequentWords(allWordsInHighlightZone)
            acc[densityHighlight.timestamp_utc] = top5Words
            return acc
          },
          {} as Record<string, Array<WordFrequency>>
        )
        const algorithmType = indexToAlgorithmMap[index] as AlgorithmType
        algorithms[algorithmType] = {
          topPoints: highlights,
          topChatsAtPoint,
        }
      }

      setData({
        chats,
        density,
        algorithms,
      })
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchData()
  }, [])

  const handlePointClick = (point: Density) => {
    setSelectedTopPoint(point)
    if (!ref.current) return
    ref.current.seekTo(point.vodtime_seconds - 10, "seconds")
  }

  const handleAlgorithmChange = (algorithmType: AlgorithmType) => {
    setAlgorithmType(algorithmType)
    setSelectedTopPoint(null)
  }

  const handleProgress = (progress: OnProgressProps) => {
    if (!data) return
    const index = data.density.findIndex((cd) => cd.vodtime_seconds > progress.playedSeconds)
    if (data.density[Math.max(index - 1, 0)].timestamp_utc !== playhead) {
      setPlayhead(data.density[index].timestamp_utc)
    }
  }

  const getVodLink = () => {
    if (type === "twitch") {
      return `https://twitch.tv/videos/${vodId}`
    }
    return `https://youtube.com/watch?v=${vodId}`
  }

  return (
    <Stack gap={2}>
      <MuiLink
        to="/data-collection/chat-density"
        component={Link}
      >
        {t("common:actions.back")}
      </MuiLink>
      {error && <Alert severity="error"> {t("common:errorLoadingData")}</Alert>}
      {loading && <CircularProgress />}

      {!loading && data && (
        <>
          <FormControl>
            <InputLabel id="algorithmType">{t("chatDensity.algorithmType")}</InputLabel>

            <Select
              value={algorithmType}
              labelId="algorithType"
              onChange={(event) => handleAlgorithmChange(event.target.value as AlgorithmType)}
            >
              <MenuItem value={"descendingDensity" as AlgorithmType}>
                {t("chatDensity.descendingDensity")}
              </MenuItem>
              <MenuItem value={"movingAverage150" as AlgorithmType}>
                {t("chatDensity.movingAverage150")}
              </MenuItem>
              <MenuItem value={"movingAverage300" as AlgorithmType}>
                {t("chatDensity.movingAverage300")}
              </MenuItem>
            </Select>
          </FormControl>
          <Grid container>
            <Grid
              item
              lg={8}
              xs={12}
              order={{ xs: 1, lg: "unset" }}
            >
              <Box
                width="100%"
                sx={{ aspectRatio: "16/9" }}
              >
                <ReactPlayer
                  ref={ref}
                  width="100%"
                  height="100%"
                  url={getVodLink()}
                  playing={true}
                  loop={true}
                  controls
                  onProgress={handleProgress}
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
              order={{ xs: 3, lg: "unset" }}
            >
              {selectedTopPoint && (
                <Box
                  maxHeight="475px"
                  overflow="scroll"
                >
                  <List>
                    {getChatsForSelectedTopPoint().map((chat) => (
                      <ListItem
                        key={chat.timestamp_utc + chat.name}
                        dense
                      >
                        <Typography>
                          {chat.name}: {chat.message}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Grid>
            <Grid
              item
              lg={8}
              xs={12}
              order={{ xs: 2, lg: "unset" }}
            >
              {data.algorithms[algorithmType] && (
                <Box
                  sx={{
                    height: {
                      xs: "150px",
                      xl: "200px",
                    },
                  }}
                  width="100%"
                >
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                  >
                    <ComposedChart data={data.density}>
                      <Line
                        type="monotone"
                        dataKey="chat_msg_count"
                        dot={false}
                        connectNulls
                      />
                      <XAxis
                        dataKey="timestamp_utc"
                        hide
                      />
                      {data.algorithms[algorithmType].topPoints.map((topPoint) => (
                        <ReferenceDot
                          key={algorithmType + topPoint.timestamp_utc}
                          onClick={() => handlePointClick(topPoint)}
                          y={topPoint.chat_msg_count}
                          x={topPoint.timestamp_utc.replace("Z", "")}
                          r={7}
                          fill={topPoint === selectedTopPoint ? "#FF0000" : theme.palette.secondary.light}
                        />
                      ))}
                      {playhead && (
                        <ReferenceLine
                          x={playhead.replace("Z", "")}
                          stroke={type === "twitch" ? "#6441a5" : "#FF0000"}
                        />
                      )}
                      <Tooltip />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Box>
              )}
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
              order={{ xs: 4, lg: "unset" }}
            >
              {selectedTopPoint && (
                <List>
                  {data.algorithms[algorithmType]!.topChatsAtPoint[selectedTopPoint.timestamp_utc].map(
                    (wordFreq) => (
                      <ListItem
                        key={JSON.stringify(wordFreq)}
                        dense
                      >
                        <Stack
                          width="100%"
                          direction="row"
                          gap={2}
                          flexWrap="nowrap"
                          justifyContent="space-between"
                        >
                          <Typography>{wordFreq.word}</Typography>
                          <Typography>{wordFreq.frequency}</Typography>
                        </Stack>
                      </ListItem>
                    )
                  )}
                </List>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  )
}

export default ChatDensityVisualizer
