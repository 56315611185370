import { useContext } from "react"

import { Box, Link, Stack, Typography, useTheme } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { CartesianGrid, XAxis, YAxis, Bar, Cell, ResponsiveContainer, ComposedChart } from "recharts"
import useEvenSizedPages from "utils/useEvenSizedPages"

import { getIconFromChannelUrl } from "."
import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage, LegendContainer } from "./styles"
import SubPageCounter from "./sub-page-counter"

const XAxisTick = (props) => {
  const { x, y, payload, watchTime, channelUrl } = props
  const theme = useTheme()

  return (
    <g transform={`translate(${x},${y})`}>
      <g>
        <foreignObject
          x={-12}
          y={15}
          height={24}
          width={30}
        >
          {getIconFromChannelUrl(channelUrl)}
        </foreignObject>
        <foreignObject
          y={50}
          x={-140}
          height={28}
          width={275}
        >
          <Link
            fontWeight="300"
            noWrap
            fontSize="20px"
            textAlign="center"
            target="_blank"
            href={channelUrl}
            underline="none"
            color="text.primary"
            textOverflow="ellipsis"
            display="block"
          >
            {payload.value}
          </Link>
        </foreignObject>
      </g>
      <text
        x={0}
        y={0}
        dy={114}
        textAnchor="middle"
        fill={theme.palette.text.primary}
        fontSize={21}
        fontWeight="bold"
      >
        {watchTime.toLocaleString()}
      </text>
    </g>
  )
}

const ChannelWatchtime = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const data = playDataEventReportData.charts.channelWatchtime

  const theme = useTheme()

  const maxWatchTime = Math.max(...data.map((d) => d.watchtime))

  const { t } = useTranslation("playdata")

  const pages = useEvenSizedPages(data.length, 5)

  const filterDataPage = (page: number) => {
    let offset = 0
    for (let i = 0; i < page; i++) {
      offset = offset + pages[i]
    }
    const pageChannels = data.map((d) => d.name).slice(offset, offset + pages[page])
    return data.filter((d) => pageChannels.includes(d.name))
  }

  const roundToClosestPowerOf10 = (number: number) => {
    if (number === 0) {
      return 0
    }

    const magnitude = Math.floor(Math.log10(Math.abs(number)))
    const powerOf10 = Math.pow(10, magnitude)
    const roundedNumber = Math.ceil(number / powerOf10) * powerOf10

    return roundedNumber
  }

  const domainMax = roundToClosestPowerOf10(maxWatchTime)

  return (
    <>
      {pages.map((_itemsPerPage, page) => (
        <BorderedPage key={page}>
          <SubPageCounter
            page={page}
            pages={pages.length}
          />
          <Stack
            height="100%"
            gap={6}
            justifyContent="space-between"
          >
            <Header title={t("sectionTitles.channelWatchTime")} />
            <Stack gap={4}>
              <LegendContainer>
                <Stack
                  direction="row"
                  gap={2}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      bgcolor="common.blue"
                    />
                    <Typography component="span">{t("legends.watchTime")}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      bgcolor="common.pink"
                    />
                    <Typography component="span">{t("legends.channelWatchTime")}</Typography>
                  </Stack>
                </Stack>
              </LegendContainer>
              <Box
                height="540px"
                px={6}
                mt="auto"
                mb="auto"
              >
                <ResponsiveContainer
                  height="100%"
                  width="100%"
                >
                  <ComposedChart
                    data={data}
                    margin={{
                      top: 50,
                      bottom: 50,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                    />
                    <YAxis
                      tickFormatter={(value) => Number(value).toLocaleString()}
                      tickLine={false}
                      tick={{ fill: theme.palette.text.primary, fontWeight: "bold" }}
                      stroke={theme.palette.text.primary}
                      width={90}
                      ticks={[0, domainMax / 2, domainMax]}
                      domain={[0, domainMax]}
                    />
                    <Bar
                      dataKey="watchtime"
                      fill={theme.palette.common.blue}
                      maxBarSize={300}
                      data={filterDataPage(page)}
                      isAnimationActive={false}
                      minPointSize={2}
                    >
                      {filterDataPage(page).map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            entry.watchtime === maxWatchTime
                              ? theme.palette.common.pink
                              : theme.palette.common.blue
                          }
                        />
                      ))}
                    </Bar>
                    <XAxis
                      dataKey="name"
                      height={150}
                      tick={(props) => {
                        return props.payload.value !== 0 && props.payload.value !== "auto" ? (
                          <XAxisTick
                            {...props}
                            watchTime={filterDataPage(page)[props.index].watchtime}
                            channelUrl={filterDataPage(page)[props.index].url}
                          />
                        ) : (
                          <></>
                        )
                      }}
                      stroke={theme.palette.text.primary}
                      tickLine={false}
                      minTickGap={-1000}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Box>
            </Stack>
            <Stack gap={4}>
              <Disclaimer>
                <Typography fontSize="14px">{t("watchTimeDisclaimer")}</Typography>
              </Disclaimer>
              <Footer />
            </Stack>
          </Stack>
        </BorderedPage>
      ))}
    </>
  )
}

export default ChannelWatchtime
