import React, { useState, useEffect } from "react"

import { Grid } from "@mui/material"

import { LocalMomentFromUTCDateTime } from "../../../../../../models/Taiyoro/eventDate"
import { YearText, YearFirst, YearLast, DayContainer, DayFirst, DayLast, DayText } from "./styles"

interface Props {
  dates: any[]
}

const Duration = ({ dates }: Props) => {
  const [startYearTextState, setStartYearTextState] = useState("")
  const [startDayTextState, setStartDayTextState] = useState("")
  const [endYearTextState, setEndYearTextState] = useState("")
  const [endDayTextState, setEndDayTextState] = useState("")

  useEffect(() => {
    if (dates.length === 0) {
      // This shouldnt happen, but it'll prevent errors
      return
    }
    let first = LocalMomentFromUTCDateTime(dates[0].startDatetime)
    let last = LocalMomentFromUTCDateTime(dates[0].endDatetime)

    for (let i = 1; i < dates.length; i++) {
      if (LocalMomentFromUTCDateTime(dates[i].startDatetime).isBefore(first)) {
        first = LocalMomentFromUTCDateTime(dates[i].startDatetime).clone()
      }
      if (LocalMomentFromUTCDateTime(dates[i].endDatetime).isAfter(last)) {
        last = LocalMomentFromUTCDateTime(dates[i].endDatetime).clone()
      }
    }

    setStartYearTextState(first.locale("en").format("YYYY"))
    setStartDayTextState(first.format("MMM DD"))
    setEndYearTextState(last.locale("en").format("YYYY"))
    setEndDayTextState(last.format("MMM DD"))

    // eslint-disable-next-line
  }, [dates])

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        style={{ minWidth: "192px" }}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={0}
          >
            <DayFirst
              item
              xs={5}
            >
              <DayText component="span">{startDayTextState}</DayText>
            </DayFirst>
            <DayContainer
              item
              xs={2}
            >
              ~
            </DayContainer>
            <DayLast
              item
              xs={5}
            >
              <DayText component="span">{endDayTextState}</DayText>
            </DayLast>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={0}
          >
            <YearFirst
              item
              xs={5}
            >
              <YearText component="span">{startYearTextState}</YearText>
            </YearFirst>
            <DayContainer
              item
              xs={2}
            ></DayContainer>
            <YearLast
              item
              xs={5}
            >
              <YearText component="span">{endYearTextState}</YearText>
            </YearLast>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Duration
