import { useCallback, useContext, useState } from "react"

import { faCheck, faExternalLink, faList, faMagnifyingGlass, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, InputAdornment, Link, Paper, Stack, TextField, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ArticleLogs from "components/Taiyoro/Articles/ArticleLogs"
import { OGImage } from "components/Taiyoro/Articles/ArticlesTodo/styles"
import { ArticlesTodoContext } from "contexts/articles"
import type { Article } from "models/Taiyoro/article"
import moment from "moment-timezone"
import "moment/locale/ja"
import { useTranslation } from "react-i18next"
import { useDebounce, useUpdateEffect } from "react-use"
import {
  approveArticle,
  fetchReviewedArticles,
  rejectArticle,
  searchReviewedArticles,
} from "services/Taiyoro/articles"
import { TAIYORO_DOMAIN } from "utils/taiyoro"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

const ReviewedArticles = () => {
  const { loading: refreshingPage } = useContext(ArticlesTodoContext)

  const [articles, setArticles] = useState([])

  const [totalCount, setTotalCount] = useState(0)

  const [logState, setLogState] = useState<Article | null>(null)

  const [loading, setLoading] = useState(false)

  const [errorState, setErrorState] = useState(false)

  const [pendingAction, setPendingAction] = useState(false)

  const [queryParams, setQueryParams] = useState({ search: "", size: 5, page: 0 })

  const { t } = useTranslation("taiyoro")

  const localisedLabel = useLocalisedLabel()

  useUpdateEffect(() => {
    // A change to the ArticlesTodo list has occured, meaning
    // a change to this list will also occur.
    if (refreshingPage === true) {
      load()
    }
  }, [refreshingPage])

  const handleAction = (article: Article, action: "reject" | "approve") => {
    const actionFunction = action === "reject" ? rejectArticle : approveArticle
    setPendingAction(true)
    actionFunction(article.id)
      .then((res) => {
        if (res !== true) {
          throw new Error("An action on an article failed")
        }
        load()
      })
      .catch(() => {
        setErrorState(true)
      })
      .finally(() => {
        setPendingAction(false)
      })
  }

  const load = useCallback(() => {
    setLoading(true)
    const fetchOrSearch =
      queryParams.search !== ""
        ? () => searchReviewedArticles(queryParams.page, queryParams.size, queryParams.search)
        : () => fetchReviewedArticles(queryParams.page, queryParams.size)
    fetchOrSearch()
      .then((response) => {
        setTotalCount(response.totalCount)
        setArticles(response.results)
      })
      .catch(() => {
        setErrorState(true)
      })
      .finally(() => setLoading(false))
  }, [queryParams])

  useDebounce(
    () => {
      load()
    },
    500,
    [queryParams]
  )

  const columns = [
    {
      flex: 1,
      field: "entity",
      sortable: false,
      headerName: t("articles.tableHeaders.entity"),
      renderCell: (params) => {
        const getUrl = () => {
          if (params.value === "event") {
            return `${TAIYORO_DOMAIN}/event/${params.row.entityId}/${params.row.entityData.urlSlug}`
          }
          return `${TAIYORO_DOMAIN}/${params.value}/${params.row.entityData.urlSlug}`
        }

        return (
          <Link
            href={getUrl()}
            target="_blank"
          >
            {localisedLabel(params.row.entityData)}
          </Link>
        )
      },
    },
    {
      flex: 4,
      field: "title",
      sortable: false,
      headerName: t("articles.tableHeaders.title"),
    },
    {
      flex: 3,
      field: "url",
      sortable: false,
      headerName: t("articles.tableHeaders.article"),
      renderCell: (params) => {
        const url = new URL(params.value)
        return (
          <Link
            href={params.value}
            target="_blank"
          >
            {url.hostname}
            <Box
              ml={1}
              component="span"
            >
              <FontAwesomeIcon icon={faExternalLink} />
            </Box>
          </Link>
        )
      },
    },
    {
      flex: 2,
      field: "imageUrl",
      sortable: false,
      headerName: t("articles.tableHeaders.image"),
      renderCell: (params) => <OGImage src={params.value} />,
    },
    {
      flex: 2,
      field: "username",
      sortable: false,
      headerName: t("articles.tableHeaders.user"),
    },
    {
      flex: 2,
      field: "submissionDatetime",
      sortable: false,
      headerName: t("articles.tableHeaders.submissionDate"),
      valueGetter: (params) =>
        moment
          .utc(params.value)
          .tz("Asia/Tokyo")
          .locale(localStorage.getItem("language") || "en")
          .fromNow(),
    },
    {
      flex: 1,
      field: "status",
      sortable: false,
      headerName: t("articles.tableHeaders.status"),
      renderCell: (params) => <FontAwesomeIcon icon={params.row.status === 1 ? faCheck : faTimes} />,
    },
    {
      flex: 1,
      field: "visits",
      sortable: false,
      type: "number",
      headerName: t("articles.tableHeaders.visits"),
    },
    {
      flex: 2,
      field: "actions",
      sortable: false,
      headerName: t("articles.tableHeaders.actions"),
      type: "actions",
      getActions: (params) => {
        const actions = []
        if (params.row.status === 1) {
          actions.push(
            <GridActionsCellItem
              {...({} as any)}
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => handleAction(params.row, "reject")}
              label="reject"
            />
          )
        }
        if (params.row.status === 0) {
          actions.push(
            <GridActionsCellItem
              {...({} as any)}
              icon={<FontAwesomeIcon icon={faCheck} />}
              onClick={() => handleAction(params.row, "approve")}
              label="approve"
            />
          )
        }
        actions.push(
          <GridActionsCellItem
            {...({} as any)}
            icon={<FontAwesomeIcon icon={faList} />}
            onClick={() => setLogState(params.row)}
            label="logs"
          />
        )
        return actions
      },
    },
  ]

  return (
    <>
      <Paper sx={{ m: 1, p: 1, boxSizing: "border-box", height: "100%" }}>
        <Stack
          height="100%"
          gap={1}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">{t("articles.reviewedArticles")}</Typography>
            <TextField
              label={t("page.search.placeholder")}
              sx={{ width: "250px" }}
              value={queryParams.search}
              onChange={(event) =>
                setQueryParams({ ...queryParams, page: 0, search: event.target.value || "" })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box sx={{ width: "100%", flex: "1" }}>
            <DataGrid
              rows={articles}
              columns={columns}
              disableSelectionOnClick
              loading={loading || pendingAction}
              density="comfortable"
              disableColumnFilter
              disableDensitySelector
              disableColumnMenu
              page={queryParams.page}
              pageSize={queryParams.size}
              rowsPerPageOptions={[5]}
              rowCount={totalCount}
              onPageSizeChange={(size) => setQueryParams({ ...queryParams, size })}
              onPageChange={(page) => setQueryParams({ ...queryParams, page })}
              error={errorState}
              paginationMode="server"
            />
          </Box>
        </Stack>
      </Paper>
      <ArticleLogs
        article={logState}
        onClose={() => setLogState(null)}
      />
    </>
  )
}

export default ReviewedArticles
