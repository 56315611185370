import React, { useEffect, useState } from "react"

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LoadingButton } from "@mui/lab"
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { EventSearchAutocomplete } from "components/EventSearchAutocomplete"
import i18n from "i18next"
import type Event from "models/Taiyoro/event"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useSearchParam } from "react-use"
import { downloadPlayDataReport } from "services/PlayData"
import { fetchEvent } from "services/Taiyoro/event"

const PlayData = () => {
  const [value, setValue] = useState(null)
  const [customerName, setCustomerName] = useState("")
  const [event, setEvent] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [missingDataDates, setMissingDataDates] = useState([])
  const [generatingReport, setGeneratingReport] = useState(false)
  const [language, setLanguage] = useState(i18n.language)
  const [loadingEvent, setLoadingEvent] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const { t } = useTranslation(["playdata", "taiyoro"])
  const eventId = useSearchParam("eventId")

  const handleError = () => {
    setErrorState(true)
  }

  const handleGenerateReport = async () => {
    setGeneratingReport(true)
    const url = new URL(window.location.href)

    const reportURL = await downloadPlayDataReport(
      url.origin + `/playdata/generate?eventId=${event.id}&customer=${customerName}&language=${language}`,
      event.name
    ).catch(handleError)

    const link = document.createElement("a")
    link.href = reportURL
    link.setAttribute("target", "_blank")

    document.body.appendChild(link)

    link.click()

    link.parentNode.removeChild(link)
    setGeneratingReport(false)
    setShowConfirmation(false)
  }

  // When the event is loaded, analyse and create a iterable list of dates with
  // missing data
  useEffect(() => {
    const now = moment()
    if (event) {
      setMissingDataDates(
        event.dates.filter(
          (date) =>
            now.diff(date.endTime) > 0 &&
            date.platforms.some(
              (platform) =>
                platform.isVod === 0 &&
                !platform.viewsLiveConcurrentAverage &&
                !platform.viewsLiveConcurrentAverageManual &&
                !platform.viewsLiveConcurrentPeak &&
                !platform.viewsLiveConcurrentPeakManual &&
                !platform.viewsLiveMinutesWatched &&
                !platform.viewsLiveMinutesWatchedManual
            )
        )
      )
    }
  }, [event])

  const loadEventData = async () => {
    setLoadingEvent(true)
    const event = await fetchEvent(value.id).catch(handleError)
    setEvent(event)
    setShowConfirmation(true)
    setLoadingEvent(false)
  }

  return (
    <>
      <Stack gap={2}>
        <Typography variant="h5">{t("generate.title")}</Typography>
        <Box width="600px">
          <EventSearchAutocomplete
            preloadEventId={eventId || undefined}
            onEventSelected={(event: Event) => setValue(event)}
          />
        </Box>
        <TextField
          value={customerName}
          sx={{ width: 600 }}
          onChange={(event) => setCustomerName(event.target.value)}
          placeholder={t("generate.customerPlaceholder")}
          inputProps={{
            autoComplete: "off",
          }}
        />
        <Select
          value={language}
          onChange={(event) => setLanguage(event.target.value)}
          sx={{ width: 600 }}
        >
          {["ja", "en"].map((lang) => (
            <MenuItem
              key={lang}
              value={lang}
            >
              {t(`generate.${lang}`)}
            </MenuItem>
          ))}
        </Select>
        <LoadingButton
          onClick={loadEventData}
          variant="contained"
          disabled={customerName === "" || !value}
          sx={{ width: "600px" }}
          loading={loadingEvent}
        >
          {t("generate.load")}
        </LoadingButton>
      </Stack>
      {errorState && (
        <Snackbar open>
          <Alert severity="error">{t("taiyoro:meta.errors.loading")}</Alert>
        </Snackbar>
      )}
      {event && showConfirmation && !errorState && (
        <Dialog
          open
          onClose={() => setShowConfirmation(false)}
          maxWidth="xl"
        >
          <DialogContent>
            <Stack gap={2}>
              <Link
                variant="h5"
                href={`/taiyoro/event/${event.id}`}
                target="_blank"
                color="text.primary"
                underline="hover"
              >
                {event.name}
                <FontAwesomeIcon
                  icon={faExternalLink}
                  transform="right-5"
                  size="sm"
                />
              </Link>
              <Typography
                color="text.secondary"
                variant="subtitle1"
              >
                {t("footer.customer", { customer: customerName })}
              </Typography>
              {missingDataDates.length > 0 && (
                <>
                  <Alert severity="warning">{t("generate.missingData")}</Alert>
                  <Box
                    borderRadius={1}
                    bgcolor="action.focus"
                  >
                    <List>
                      {missingDataDates.map((missingDataDate, index) => (
                        <React.Fragment key={missingDataDate.id}>
                          <ListItem>
                            <Stack>
                              <Typography>{missingDataDate.name}</Typography>
                              <Stack
                                direction="row"
                                alignItems="baseline"
                                gap={2}
                              >
                                <Box>
                                  <Typography
                                    color="text.secondary"
                                    variant="subtitle1"
                                  >
                                    {t("generate.start")}
                                  </Typography>
                                  <Typography noWrap>
                                    {missingDataDate.startTime.format("YYYY/MM/DD HH:ss")}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    color="text.secondary"
                                    variant="subtitle1"
                                  >
                                    {t("generate.end")}
                                  </Typography>
                                  <Typography noWrap>
                                    {missingDataDate.endTime.format("YYYY/MM/DD HH:ss")}
                                  </Typography>
                                </Box>
                                <List>
                                  {missingDataDate.platforms
                                    .filter(
                                      (platform) =>
                                        platform.isVod === 0 &&
                                        !platform.viewsLiveConcurrentAverage &&
                                        !platform.viewsLiveConcurrentAverageManual &&
                                        !platform.viewsLiveConcurrentPeak &&
                                        !platform.viewsLiveConcurrentPeakManual &&
                                        !platform.viewsLiveMinutesWatched &&
                                        !platform.viewsLiveMinutesWatchedManual
                                    )
                                    .map((missingDataStream) => (
                                      <ListItem key={missingDataStream.url}>
                                        <Link
                                          href={missingDataStream.url}
                                          target="_blank"
                                          fontWeight="700"
                                          color="text.primary"
                                          underline="hover"
                                        >
                                          {missingDataStream.url}
                                          <FontAwesomeIcon
                                            icon={faExternalLink}
                                            transform="right-5"
                                          />
                                        </Link>
                                      </ListItem>
                                    ))}
                                </List>
                              </Stack>
                            </Stack>
                          </ListItem>
                          {index !== missingDataDates.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                      ))}
                    </List>
                  </Box>
                </>
              )}
              {missingDataDates.length === 0 && (
                <Alert severity="success">{t("generate.noMissingData")}</Alert>
              )}
              <DialogActions sx={{ padding: 0 }}>
                <LoadingButton
                  onClick={handleGenerateReport}
                  variant="contained"
                  loading={generatingReport}
                >
                  {t("generate.generateButton")}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default PlayData
