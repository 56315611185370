import { useState } from "react"

import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fab, Stack, Tooltip, Typography } from "@mui/material"
import { AddDialog } from "components/Taiyoro/FeaturedEvents/AddDialog"
import { DiscardChanges } from "components/Taiyoro/FeaturedEvents/DiscardChanges"
import { FeaturedEventsProvider } from "components/Taiyoro/FeaturedEvents/FeaturedEventsProvider"
import { FeaturedEventsTimeRangesList } from "components/Taiyoro/FeaturedEvents/FeaturedEventsTimeRangesList"
import { SaveChanges } from "components/Taiyoro/FeaturedEvents/SaveChanges"
import { useTranslation } from "react-i18next"

export const FeaturedEventsPage = () => {
  const { t } = useTranslation(["common", "taiyoro"])

  const [showAddDialog, setShowAddDialog] = useState(false)

  return (
    <FeaturedEventsProvider>
      <Stack gap={1}>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
        >
          <Typography
            variant="h4"
            mr="auto"
          >
            {t("taiyoro:featuredEvents.title")}
          </Typography>
          <DiscardChanges />
          <SaveChanges />
          <Tooltip title={t("actions.add")}>
            <Fab
              size="medium"
              color="success"
              aria-label="add"
              onClick={() => setShowAddDialog(true)}
              sx={{
                flexShrink: 0,
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Fab>
          </Tooltip>
        </Stack>
        <FeaturedEventsTimeRangesList />
      </Stack>
      <AddDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
      />
    </FeaturedEventsProvider>
  )
}
