import React from "react"

import type EventStream from "models/Taiyoro/Stream"
import type EventDate from "models/Taiyoro/eventDate"
import type { DataCollectionResult } from "services/Taiyoro/dataCollection"

export enum RecoveryStep {
  INITIAL = "INITIAL",
  SUGGESTIONS = "SUGGESTIONS",
  VOD_FLOW = "VOD_FLOW",
  ERROR_VOD = "ERROR_VOD",
  CHANNEL_FLOW = "CHANNEL_FLOW",
  ERROR_CHANNEL = "ERROR_CHANNEL",
  EXISTING_STREAM_RECOVER = "EXISTING_STREAM_RECOVER",
  ERROR_EXISTING = "ERROR_EXISTING",
  SUCCESS = "SUCCESS",
}

type DataRecoveryContextType = {
  selectedEventDate: EventDate | null
  setSelectedEventDate: (_selectedEventDate: EventDate | null) => void
  selectedStreams: Array<DataCollectionResult>
  setSelectedStreams: (_selectedStreams: Array<DataCollectionResult>) => void
  step: RecoveryStep
  setStep: (_step: RecoveryStep) => void
  error: boolean
  setError: (_error: boolean) => void
  handleNewRecover: () => void
  handleExistingRecover: () => void
  resetDialog: () => void
  recoveryLoading: boolean
  setRecoveryLoading: (_recoveryLoading: boolean) => void
  selectedExistingStream: EventStream
  setSelectedExistingStream: (_selectedExistingStream: EventStream | null) => void
}

export const DataRecoveryContext = React.createContext<DataRecoveryContextType>({
  selectedEventDate: null,
  setSelectedEventDate: (_selectedEventDate: EventDate | null) => {},
  selectedStreams: [],
  setSelectedStreams: (_selectedStreams: Array<DataCollectionResult>) => {},
  step: RecoveryStep.INITIAL,
  setStep: (_step: RecoveryStep) => {},
  error: false,
  setError: (_error: boolean) => {},
  handleNewRecover: () => {},
  handleExistingRecover: () => {},
  resetDialog: () => {},
  recoveryLoading: false,
  setRecoveryLoading: (_recoveryLoading: boolean) => {},
  selectedExistingStream: null,
  setSelectedExistingStream: (_selectedExistingStream: EventStream | null) => {},
})
