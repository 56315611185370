import { useContext } from "react"

import { Box, Link, Stack, Typography, useTheme } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { CartesianGrid, XAxis, YAxis, Bar, ComposedChart, ResponsiveContainer } from "recharts"
import useEvenSizedPages from "utils/useEvenSizedPages"

import { MAX_ITEMS_PER_GRAPH, MAX_BAR_WIDTH, LEGEND_COLORS, getIconFromChannelUrl } from "."
import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage, LegendContainer } from "./styles"
import SubPageCounter from "./sub-page-counter"
import XAxisTick from "./x-axis-tick"

const ChannelDateWatchtime = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const channelWatchData = playDataEventReportData.charts.channelDateWatchtime

  const dates = [...Array.from(new Set(channelWatchData.data.map((d) => d.dateName)))]

  const { t } = useTranslation("playdata")

  const theme = useTheme()

  const colors = LEGEND_COLORS.map((color) => theme.palette.common[color])

  const pages = useEvenSizedPages(dates.length, MAX_ITEMS_PER_GRAPH)

  const filterDataPage = (page: number) => {
    let offset = 0
    for (let i = 0; i < page; i++) {
      offset = offset + pages[i]
    }
    const pageDates = dates.slice(offset, offset + pages[page])
    return channelWatchData.data.filter((d) => pageDates.includes(d.dateName))
  }

  const orderedChannels = [...channelWatchData.channels].sort((a, b) => a.isSubStream - b.isSubStream)

  return (
    <>
      {pages.map((_itemsPerPage, page) => (
        <BorderedPage key={page}>
          <SubPageCounter
            page={page}
            pages={pages.length}
          />
          <Stack
            height="100%"
            gap={6}
            justifyContent="space-between"
          >
            <Stack gap={4}>
              <Header title={t("sectionTitles.channelWatchTimeByDate")} />
              <Typography
                fontSize="24px"
                fontWeight="300"
                px={6}
              >
                {t("channelWatchTimeInfo")}
              </Typography>
            </Stack>
            <Stack gap={4}>
              {orderedChannels.length > 0 && (
                <LegendContainer>
                  <Stack
                    direction="row"
                    gap={2}
                    flexWrap="wrap"
                  >
                    {orderedChannels.map((channel, index) => (
                      <Stack
                        key={channel.name + index}
                        direction="row"
                        gap={1}
                        alignItems="center"
                      >
                        <Box
                          width="16px"
                          height="16px"
                          bgcolor={colors[index % colors.length]}
                        />
                        {getIconFromChannelUrl(channel.url)}
                        <Link
                          noWrap
                          textAlign="center"
                          target="_blank"
                          href={channel.url}
                          underline="none"
                          color="text.primary"
                          textOverflow="ellipsis"
                          display="block"
                        >
                          {channel.name}
                        </Link>
                      </Stack>
                    ))}
                  </Stack>
                </LegendContainer>
              )}
              <Box
                height="450px"
                px={6}
                mt="auto"
                mb="auto"
              >
                <ResponsiveContainer
                  height="100%"
                  width="100%"
                >
                  <ComposedChart
                    data={filterDataPage(page)}
                    margin={{
                      top: 50,
                      bottom: 50,
                      right: 100,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                    />
                    {orderedChannels.map((channel, index) => (
                      <Bar
                        key={channel.url}
                        dataKey={channel.name}
                        fill={colors[index % colors.length]}
                        stackId="a"
                        maxBarSize={MAX_BAR_WIDTH}
                        isAnimationActive={false}
                      />
                    ))}
                    <XAxis
                      dataKey="dateName"
                      xAxisId={0}
                      height={100}
                      tick={<XAxisTick />}
                      tickLine={false}
                      minTickGap={-1000}
                    />
                    <YAxis
                      tickFormatter={(value) => Number(value).toLocaleString()}
                      tickLine={false}
                      tick={{ fill: theme.palette.text.primary, fontWeight: "bold" }}
                      tickCount={3}
                      stroke={theme.palette.text.primary}
                      width={90}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Box>
            </Stack>
            <Stack gap={4}>
              <Disclaimer>
                <Typography fontSize="14px">{t("watchTimeDisclaimer")}</Typography>
              </Disclaimer>
              <Footer />
            </Stack>
          </Stack>
        </BorderedPage>
      ))}
    </>
  )
}

export default ChannelDateWatchtime
