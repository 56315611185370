import { useState } from "react"

import { faCheck, faEdit, faFileInvoice, faPlus, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { usePosts } from "components/Taiyoro/Posts/usePosts"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { POST_TYPES, createPost, deletePost } from "services/Taiyoro/posts"
import type { PostListItem, PostType } from "services/Taiyoro/posts"

const PostsListPage = () => {
  const { loading, totalCount, posts, queryParams, setPage, setSize, setType, fetchPosts } = usePosts()
  const [showDeletePostModal, setShowDeletePostModal] = useState<null | string>(null)

  const [waiting, setWaiting] = useState(false)

  const history = useHistory()

  const { t } = useTranslation("taiyoro")

  const handleAdd = () => {
    setWaiting(true)

    const date = new Date()

    const emptyPost = {
      slug: "slug",
      publicationDatetime: moment(date).format("YYYY-MM-DDTHH:mm:ssZ"),
      published: false,
      title: null,
      titleJa: `新しいお知らせ（${moment(date).format("YYYY-MM-DD")}）`,
      body: null,
      bodyJa: "Body",
      ogImage: null,
      metaDescription: null,
      metaDescriptionJa: null,
      type: queryParams.type,
    }

    createPost({ post: emptyPost })
      .then((res) => {
        if (res.status === "success") {
          history.push("/taiyoro/post/" + res.data.id)
        }
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  const handleEdit = (id: string) => {
    history.push("/taiyoro/post/" + id)
  }

  const handleDelete = (id: string) => {
    setWaiting(true)

    deletePost({ id })
      .then((res) => {
        if (res.status === "success") {
          setShowDeletePostModal(null)
          fetchPosts()
        }
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  const columns: GridColumns<PostListItem> = [
    {
      flex: 4,
      field: "titleJa",
      sortable: true,
      headerName: t("posts.listPage.tableHeaders.titleJa"),
    },
    {
      flex: 4,
      field: "title",
      sortable: true,
      headerName: t("posts.listPage.tableHeaders.title"),
    },
    {
      flex: 2,
      field: "publicationDatetime",
      sortable: true,
      headerName: t("posts.listPage.tableHeaders.publicationDate"),
      renderCell: (param: GridRenderCellParams<string, PostListItem>) => {
        return moment(param.value).format("YYYY-MM-DD")
      },
    },
    {
      flex: 1,
      field: "published",
      sortable: true,
      headerName: t("posts.listPage.tableHeaders.published"),
      renderCell: (param: GridRenderCellParams<0 | 1, PostListItem>) => {
        return (
          <Box sx={{ fontSize: "1.2rem" }}>{param.value === 1 && <FontAwesomeIcon icon={faCheck} />}</Box>
        )
      },
    },
    {
      flex: 1,
      field: "actions",
      sortable: false,
      headerName: t("posts.listPage.tableHeaders.actions"),
      type: "actions",
      minWidth: 65,
      getActions: (params) => [
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <Tooltip
              title={t("posts.listPage.actionEdit")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => handleEdit(params.row.id)}
          label={t("posts.listPage.actionEdit")}
          disabled={waiting}
        />,
        <GridActionsCellItem
          {...({} as any)}
          icon={
            <Tooltip
              title={t("posts.listPage.actionDelete")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                style={{ fontSize: "1.2rem", color: "red" }}
              />
            </Tooltip>
          }
          onClick={() => setShowDeletePostModal(params.row.id)}
          label={t("posts.listPage.actionDelete")}
          disabled={waiting}
        />,
      ],
    },
  ]

  return (
    <>
      <Stack
        direction="row"
        sx={{ mb: 2 }}
        alignItems="center"
        gap={2}
      >
        <Typography variant="h5">
          <FontAwesomeIcon
            icon={faFileInvoice}
            style={{ marginRight: "10px" }}
          />
          {t("posts.pageTitleList")}
        </Typography>
        <Select
          value={queryParams.type}
          onChange={(e) => {
            setType(e.target.value as PostType)
          }}
        >
          {POST_TYPES.map((option) => (
            <MenuItem
              key={`report-${option}`}
              value={option}
            >
              {t(`posts.types.${option}`)}
            </MenuItem>
          ))}
        </Select>
        <Fab
          size="medium"
          color="success"
          aria-label="add"
          onClick={handleAdd}
          disabled={waiting}
          sx={{ marginLeft: "auto" }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Fab>
      </Stack>
      <DataGrid
        rows={posts}
        columns={columns}
        disableColumnSelector
        density="comfortable"
        page={queryParams.page}
        sx={{ height: "calc(100% - 110px)" }}
        loading={loading}
        rowCount={totalCount}
        onPageSizeChange={(size) => setSize(size)}
        onPageChange={(page) => setPage(page)}
        paginationMode="server"
        rowsPerPageOptions={[100, 250, 500]}
      />
      <ConfirmationDialog
        title={t("posts.confirmDeletionTitle")}
        open={!!showDeletePostModal}
        body={t("posts.confirmDeletionBody")}
        onCancel={() => setShowDeletePostModal(null)}
        onConfirm={() => handleDelete(showDeletePostModal!)}
        cancelText={t("posts.cancelDeletion")}
        confirmText={t("posts.confirmDeletion")}
      />
      <Dialog open={waiting}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PostsListPage
