import React, { useContext } from "react"

import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Radio, Stack, DialogContentText } from "@mui/material/"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import type EventStream from "models/Taiyoro/Stream"
import { LocalMomentFromUTCDateTime } from "models/Taiyoro/eventDate"
import { useTranslation } from "react-i18next"
import type { DataCollectionResult } from "services/Taiyoro/dataCollection"

import { StyledFormControlLabel, Wrapper, StyledLink } from "./styles"

interface Props {
  data: EventStream | DataCollectionResult
  streamSelection?: boolean
}

const RecoverElement = (props: Props) => {
  const { t } = useTranslation(["taiyoro", "common"])

  const captionStyles = { lineHeight: 1, pt: 1 }
  const infoStyles = { color: "text.primary" }

  const {
    step,
    selectedStreams,
    setSelectedStreams,
    recoveryLoading,
    selectedExistingStream,
    setSelectedExistingStream,
  } = useContext(DataRecoveryContext)

  const checked = () => {
    if ((props.data as DataCollectionResult).streamTitle) {
      return selectedStreams.includes(props.data as DataCollectionResult)
    }
    return selectedExistingStream === (props.data as EventStream)
  }

  return (
    <>
      {step === RecoveryStep.EXISTING_STREAM_RECOVER && props.streamSelection && (
        <StyledFormControlLabel
          checked={selectedExistingStream?.id === (props.data as EventStream).id}
          disabled={recoveryLoading}
          sx={recoveryLoading && { opacity: 0.5 }}
          onChange={() => {
            setSelectedExistingStream(props.data as EventStream)
          }}
          className={checked() ? "checked" : ""}
          control={<Radio />}
          label={
            <Wrapper>
              <Stack>
                <DialogContentText
                  variant="caption"
                  sx={captionStyles}
                >
                  {t("edit.dates.streams.url")}
                </DialogContentText>
                <StyledLink
                  href={(props.data as EventStream).url}
                  target="_blank"
                  rel="noopener"
                >
                  <span>{(props.data as EventStream).url}</span>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </StyledLink>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mr: 1 }}
              >
                <Stack>
                  <DialogContentText
                    variant="caption"
                    sx={captionStyles}
                  >
                    {t("list.header.pcu")}
                  </DialogContentText>
                  <DialogContentText sx={infoStyles}>
                    {Number((props.data as EventStream).viewsLiveConcurrentPeak).toLocaleString() ||
                      Number((props.data as EventStream).viewsLiveConcurrentPeakManual).toLocaleString()}
                  </DialogContentText>
                </Stack>
                <Stack>
                  <DialogContentText
                    variant="caption"
                    sx={captionStyles}
                  >
                    {t("list.header.acu")}
                  </DialogContentText>
                  <DialogContentText sx={infoStyles}>
                    {Number((props.data as EventStream).viewsLiveConcurrentAverage).toLocaleString() ||
                      Number((props.data as EventStream).viewsLiveConcurrentAverageManual).toLocaleString()}
                  </DialogContentText>
                </Stack>
                <Stack>
                  <DialogContentText
                    variant="caption"
                    sx={captionStyles}
                  >
                    {t("list.header.minutesWatched")}
                  </DialogContentText>
                  <DialogContentText sx={infoStyles}>
                    {Number((props.data as EventStream).viewsLiveMinutesWatched).toLocaleString() ||
                      Number((props.data as EventStream).viewsLiveMinutesWatchedManual).toLocaleString()}
                  </DialogContentText>
                </Stack>
              </Stack>
            </Wrapper>
          }
        />
      )}
      {((step === RecoveryStep.EXISTING_STREAM_RECOVER && !props.streamSelection) ||
        step !== RecoveryStep.EXISTING_STREAM_RECOVER) && (
        <StyledFormControlLabel
          checked={checked()}
          disabled={recoveryLoading}
          sx={recoveryLoading && { opacity: 0.5 }}
          onChange={(event: any) => {
            if (event.target.checked) {
              setSelectedStreams([...selectedStreams, props.data as DataCollectionResult])
            } else if (!event.target.checked) {
              setSelectedStreams(selectedStreams.filter((value) => value !== props.data))
            }
          }}
          className={checked() ? "checked" : ""}
          control={<Checkbox />}
          label={
            <Wrapper>
              <>
                <Stack>
                  <DialogContentText
                    variant="caption"
                    sx={captionStyles}
                  >
                    {t("edit.dates.streams.types.vod")}
                  </DialogContentText>
                  <StyledLink
                    className={recoveryLoading ? "loading" : ""}
                    href={!recoveryLoading ? (props.data as DataCollectionResult).videoUrl : undefined}
                    target="_blank"
                    rel="noopener"
                  >
                    <span>{(props.data as DataCollectionResult).streamTitle}</span>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </StyledLink>
                  <Stack
                    direction="row"
                    spacing={2}
                  >
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("edit.dates.startTime")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {LocalMomentFromUTCDateTime(
                          (props.data as DataCollectionResult).startDatetime
                        ).format("DD/MM/YYYY HH:mm")}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("edit.dates.endTime")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {LocalMomentFromUTCDateTime((props.data as DataCollectionResult).endDatetime).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </DialogContentText>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack sx={{ mr: 1 }}>
                  <DialogContentText
                    variant="caption"
                    sx={captionStyles}
                  >
                    {t("list.summary.dateList.streamList.channel")}
                  </DialogContentText>
                  <StyledLink
                    className={recoveryLoading ? "loading" : ""}
                    href={!recoveryLoading ? (props.data as DataCollectionResult).channelUrl : undefined}
                    target="_blank"
                    rel="noopener"
                  >
                    <span>{(props.data as DataCollectionResult).channelUrl}</span>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </StyledLink>
                  <Stack
                    direction="row"
                    spacing={2}
                  >
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.pcu")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(
                          (props.data as DataCollectionResult).viewsLiveConcurrentPeak
                        ).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.acu")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(
                          (props.data as DataCollectionResult).viewsLiveConcurrentAverage
                        ).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.minutesWatched")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(
                          (props.data as DataCollectionResult).viewsLiveMinutesWatched
                        ).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            </Wrapper>
          }
        />
      )}
    </>
  )
}

export default RecoverElement
