import React from "react"

import TextField from "@mui/material/TextField"

const DateTextField = (props) => (
  <TextField
    type="datetime-local"
    {...props}
  />
)

export default DateTextField
