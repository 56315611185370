import { useContext, useEffect } from "react"

import { Box, MenuItem, TextField } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import moment from "moment"
import { useTranslation } from "react-i18next"

interface Props {
  value: string
  updateValue: (value: string) => void
  setGameId: (gameId: string) => void
  updateStatus?: () => void
}

export const DateControl = ({ value, updateValue, setGameId, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")
  const { eventState } = useContext(PickemSettingsContext)

  if (!eventState) return null

  const eventDateList = eventState.dates.map((value) => ({
    dateId: value.id,
    gameId: value.game,
    name: value.name,
    date: moment(value.startTime).format("YYYY/MM/DD HH:mm"),
  }))

  const eventDateOptions = eventDateList.map((eventDate) => (
    <MenuItem
      key={eventDate.dateId}
      value={eventDate.dateId}
    >
      {eventDate.name} ({eventDate.date})
    </MenuItem>
  ))

  useEffect(() => {
    setGameId(eventDateList.find((eventDate) => eventDate.dateId === value)?.gameId ?? "")
  }, [eventDateList, value])

  return (
    <Box>
      <TextField
        select
        label={t("eventPickem.daily.modal.eventDate")}
        value={value}
        onChange={(event) => {
          updateValue(event.target.value)
          updateStatus?.()
        }}
        fullWidth
      >
        {eventDateOptions}
      </TextField>
    </Box>
  )
}
