import { useContext } from "react"
import React from "react"

import { Avatar, Box, Grid, Paper, Stack, Typography } from "@mui/material"
import Footer from "components/PlayData/EventReport/footer"
import Header from "components/PlayData/EventReport/header"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

import { BorderedPage } from "./styles"

const EventDetails = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)
  const { t } = useTranslation("playdata")
  const localisedLabel = useLocalisedLabel()

  const detailTitleProps = {
    fontSize: "32px",
    fontWeight: "900" as "900",
    textTransform: "uppercase" as "uppercase",
    lineHeight: 1,
  }

  return (
    <BorderedPage>
      <Stack
        height="100%"
        gap={4}
      >
        <Header title={t("sectionTitles.details")} />
        <Stack
          gap={6}
          direction="row"
          width="100%"
          flex={1}
          px={6}
          sx={{ minHeight: "0px" }}
        >
          <Stack
            justifyContent="space-between"
            height="100%"
            flex={1}
            sx={{ minHeight: "0px" }}
          >
            <Box overflow="hidden">
              <Typography {...detailTitleProps}>{t(`sectionTitles.description`)}</Typography>
              <Typography
                fontWeight="300"
                fontSize="24px"
                mt={2}
                lineHeight={2}
                textOverflow="ellipsis"
              >
                {playDataEventReportData.description || t("notRegistered")}
              </Typography>
            </Box>
            <Stack gap={2}>
              {["organizers", "producers", "casters", "sponsors"].map((entity) => (
                <React.Fragment key={entity}>
                  <Typography {...detailTitleProps}>{t(`sectionTitles.${entity}`)}</Typography>
                  {playDataEventReportData[entity].length > 0 && (
                    <Typography
                      fontWeight="300"
                      fontSize="16px"
                    >
                      {playDataEventReportData[entity].map(localisedLabel).join(t("delimiter"))}
                    </Typography>
                  )}
                  {playDataEventReportData[entity].length === 0 && (
                    <Typography
                      fontWeight="300"
                      fontSize="16px"
                    >
                      {t("notRegistered")}
                    </Typography>
                  )}
                </React.Fragment>
              ))}
            </Stack>
          </Stack>
          <Stack
            height="100%"
            justifyContent="flex-end"
            flex={1}
          >
            <Paper
              elevation={0}
              sx={{ padding: 2 }}
            >
              <Stack gap={2}>
                {["teams", "players"].map((entity) => (
                  <React.Fragment key={entity}>
                    <Typography {...detailTitleProps}>{t(`sectionTitles.${entity}`)}</Typography>
                    {playDataEventReportData[entity].length > 0 && (
                      <>
                        <Stack
                          direction="row"
                          gap={2}
                          flexWrap="wrap"
                        >
                          {playDataEventReportData[entity]
                            .filter((participant) =>
                              entity === "teams" ? participant.secondaryImage : participant.primaryImage
                            )
                            .map((participant) => (
                              <Avatar
                                variant="square"
                                src={
                                  entity === "teams" ? participant.secondaryImage : participant.primaryImage
                                }
                                key={participant.id}
                                sx={{ width: "32px", height: "32px" }}
                              />
                            ))}
                        </Stack>
                        <Grid
                          container
                          spacing={2}
                        >
                          {playDataEventReportData[entity].map((participant) => (
                            <Grid
                              item
                              xs={3}
                              key={participant.id}
                            >
                              <Typography
                                fontWeight="300"
                                fontSize="12px"
                              >
                                {localisedLabel(participant)}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                    {playDataEventReportData[entity].length === 0 && (
                      <Typography
                        fontWeight="300"
                        fontSize="16px"
                      >
                        {t("notRegistered")}
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
        <Footer />
      </Stack>
    </BorderedPage>
  )
}

export default EventDetails
