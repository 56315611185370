import { useEffect, useState } from "react"

import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, DialogContent, Stack, Typography } from "@mui/material"
import LoadingBox from "components/LoadingBox"
import type { Article, ArticleLog } from "models/Taiyoro/article"
import moment from "moment-timezone"
import { fetchArticleLogs } from "services/Taiyoro/articles"

interface Props {
  article?: Article
  onClose: () => void
}

const ArticleLogs = (props: Props) => {
  const [logs, setLogs] = useState<Array<ArticleLog>>([])
  const [loading, setLoading] = useState(false)

  const fetchLogs = (article: Article) => {
    fetchArticleLogs(article.id)
      .then((logs) => {
        setLogs(logs)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLogs([])
    setLoading(true)
    if (props.article !== null) {
      fetchLogs(props.article)
    }
  }, [props.article])

  return (
    <Dialog
      open={props.article !== null}
      onClose={props.onClose}
      maxWidth="md"
    >
      <DialogContent>
        {loading && <LoadingBox />}
        <Stack gap={1}>
          {!loading &&
            logs.map((log, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  justifyItems="space-between"
                  gap={3}
                >
                  <Typography>{log.username}</Typography>
                  <Typography>
                    {moment
                      .utc(log.datetime)
                      .tz("Asia/Tokyo")
                      .locale(localStorage.getItem("language") || "en")
                      .toLocaleString()}
                  </Typography>
                  <Typography>
                    <FontAwesomeIcon icon={log.status === 1 ? faCheck : faTimes} />
                  </Typography>
                </Stack>
              )
            })}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ArticleLogs
