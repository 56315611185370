import { Box, Stack } from "@mui/material"

const Disclaimer = ({ children }) => {
  return (
    <Stack
      sx={{ marginTop: "auto" }}
      width="100%"
      px={6}
    >
      <Box
        height="1px"
        width="450px"
        bgcolor="grey.500"
        mb={1}
      />
      <Box>{children}</Box>
    </Stack>
  )
}

export default Disclaimer
