export enum RecoveryStatus {
  NOT_SET = "NOT_SET",
  RECOVERED = "RECOVERED",
  NOT_RECOVERED = "NOT_RECOVERED",
}

export default class EventStream {
  id: string
  platform: string
  url: string | null
  isVod: number | null
  language: string | null
  isDataCollectionScheduled: number | null
  viewsLiveUnique: number | null
  viewsLiveTotal: number | null
  viewsLiveConcurrentPeak: number | null
  viewsLiveMinutesWatched: number | null
  liveBroadcastDuration: number | null
  views1Week: number | null
  viewsWeek2: number | null
  viewsWeek3: number | null
  viewsWeek4: number | null
  views1Month: number | null
  viewsLiveConcurrentAverage: number | null
  dataCollectionErrored: number | null
  viewsLiveConcurrentPeakManual: number | null
  viewsLiveMinutesWatchedManual: number | null
  viewsLiveConcurrentAverageManual: number | null
  views1WeekManual: number | null
  viewsWeek2Manual: number | null
  viewsWeek3Manual: number | null
  viewsWeek4Manual: number | null
  views1MonthManual: number | null
  automaticallyGenerated: number | null
  automaticallyGenerateVods: number | null
  isSubStream: number | null
  recoveryStatus: RecoveryStatus
  channelId: string | null
  channelIconUrl: string | null
  channelDisplayName: string | null
  liveBroadcastStartDatetime: string | null
  sourceStreamDatePlatformId: string | null

  constructor(
    id,
    platform,
    url,
    isVod,
    language,
    isDataCollectionScheduled,
    recoveryStatus,
    viewsLiveUnique?,
    viewsLiveTotal?,
    viewsLiveConcurrentPeak?,
    viewsLiveMinutesWatched?,
    liveBroadcastDuration?,
    views1Week?,
    viewsWeek2?,
    viewsWeek3?,
    viewsWeek4?,
    views1Month?,
    viewsLiveConcurrentAverage?,
    dataCollectionErrored?,
    viewsLiveConcurrentPeakManual?,
    viewsLiveMinutesWatchedManual?,
    viewsLiveConcurrentAverageManual?,
    views1WeekManual?,
    viewsWeek2Manual?,
    viewsWeek3Manual?,
    viewsWeek4Manual?,
    automaticallyGenerateVods?,
    automaticallyGenerated?,
    isSubStream?,
    channelId?,
    channelIconUrl?,
    channelDisplayName?,
    liveBroadcastStartDatetime?,
    sourceStreamDatePlatformId?
  ) {
    this.id = id || null
    this.platform = platform
    this.url = url
    this.isVod = isVod
    this.language = language
    this.isDataCollectionScheduled = isDataCollectionScheduled !== null ? isDataCollectionScheduled : 1
    this.recoveryStatus = recoveryStatus
    this.viewsLiveUnique = viewsLiveUnique || null
    this.viewsLiveTotal = viewsLiveTotal || null
    this.viewsLiveConcurrentPeak = viewsLiveConcurrentPeak || null
    this.viewsLiveMinutesWatched = viewsLiveMinutesWatched || null
    this.viewsLiveConcurrentAverage = viewsLiveConcurrentAverage || null
    this.liveBroadcastDuration = liveBroadcastDuration || null
    this.views1Week = views1Week || null
    this.viewsWeek2 = viewsWeek2 || null
    this.viewsWeek3 = viewsWeek3 || null
    this.viewsWeek4 = viewsWeek4 || null
    this.views1Month = views1Month || null
    this.dataCollectionErrored = dataCollectionErrored || null
    this.viewsLiveConcurrentPeakManual = viewsLiveConcurrentPeakManual
    this.viewsLiveMinutesWatchedManual = viewsLiveMinutesWatchedManual
    this.viewsLiveConcurrentAverageManual = viewsLiveConcurrentAverageManual
    this.views1WeekManual = views1WeekManual
    this.viewsWeek2Manual = viewsWeek2Manual
    this.viewsWeek3Manual = viewsWeek3Manual
    this.viewsWeek4Manual = viewsWeek4Manual
    this.automaticallyGenerateVods = automaticallyGenerateVods
    this.automaticallyGenerated = automaticallyGenerated
    this.isSubStream = isSubStream
    this.channelDisplayName = channelDisplayName
    this.channelIconUrl = channelIconUrl
    this.channelId = channelId
    this.liveBroadcastStartDatetime = liveBroadcastStartDatetime
    this.sourceStreamDatePlatformId = sourceStreamDatePlatformId
  }

  cloneAsNew(): EventStream {
    return new EventStream(
      null,
      this.platform,
      this.url,
      this.isVod,
      this.language,
      this.isDataCollectionScheduled,
      this.recoveryStatus,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }
}

export type MisconfiguredChannel = {
  id: string
  channelDisplayName: string
  channelId: string
  url: string
}
