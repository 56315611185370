import { useContext } from "react"

import { Autocomplete, Stack, TextField } from "@mui/material"
import PlacementEntry from "components/Taiyoro/CreateEvent/EventGames/EventGameEntry/EventGamePlacements/PlacementEntry"
import { EventContext } from "contexts/event"
import type Placement from "models/Taiyoro/Meta/Placement"
import type SignificantPlayer from "models/Taiyoro/Meta/SignificantPlayer"
import type Team from "models/Taiyoro/Meta/Team"
import type { EventGame } from "models/Taiyoro/eventGame"
import { useTranslation } from "react-i18next"
import { addPlayerPlacement, addTeamPlacement } from "services/Taiyoro/eventGame"
import { localisedLabel } from "utils/i18n"

interface Props {
  eventGame: EventGame
  type: "team" | "player"
  options: Array<SignificantPlayer | Team>
  availablePlacements: Array<Placement>
  canDelete: boolean
}

const EventGamePlacements = (props: Props) => {
  const [event, setEvent] = useContext(EventContext)

  const { t } = useTranslation("taiyoro")

  const handleCreate = async (_e, value) => {
    if (!value) {
      return
    }
    const createFunc = props.type === "team" ? addTeamPlacement : addPlayerPlacement
    const success = await createFunc(event.id, props.eventGame.gameId, value.id, null, null)
    if (!success) {
      return
    }
    const placement = {
      [`${props.type}`]: {
        id: value.id,
      },
      placement: null,
      quantifier: null,
    }

    const newEvent = Object.assign(Object.create(Object.getPrototypeOf(event)), event)

    newEvent.games
      .find((game) => game.gameId === props.eventGame.gameId)
      .participants[`${props.type}s`].push(placement)

    setEvent(newEvent)
  }

  return (
    <Stack gap={2}>
      <Autocomplete
        freeSolo
        value={null}
        options={props.options.filter(
          (o) => !props.eventGame.participants[`${props.type}s`].some((p) => o.id === p[props.type].id)
        )}
        getOptionLabel={(option: any) => `${localisedLabel(option)} ・ ${option.urlSlug}`}
        clearOnBlur={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(`edit.games.${props.type}s`)}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onChange={handleCreate}
      />
      {props.eventGame.participants[`${props.type}s`].map((placement) => (
        <PlacementEntry
          key={placement[props.type].id}
          {...props}
          placement={placement}
        />
      ))}
    </Stack>
  )
}

export default EventGamePlacements
