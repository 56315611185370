import React, { useState } from "react"

import { Popper } from "@mui/material"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import TextField from "@mui/material/TextField"
import type { TextFieldVariants } from "@mui/material/TextField"
import type { CSSProperties } from "@mui/styles"
import { withStyles } from "@mui/styles"

const filterOptions = createFilterOptions({
  stringify: (option) => JSON.stringify(option),
})

interface Props {
  id?: string
  multiple?: boolean
  clearOnBlur?: boolean
  value?: any
  createFunc?: (value: string) => void
  options: any
  optionLabel?: (option: any) => string
  isOptionEqualToValue?: (option: any, value: any) => boolean
  style?: CSSProperties
  onChange?: (value: any) => any
  onBlur?: (value: any) => void
  noOptionsText?: string
  disableClearable?: boolean
  label: React.JSX.Element | string | undefined
  disabled?: boolean
  renderOption?: any
  renderTags?: any
  indicateChanges?: any
  variant?: TextFieldVariants | undefined
  filterSelectedOptions?: any
  required?: boolean
}

const DirtyDetectingTextField = withStyles({
  root: {
    "&.Updated": {
      "& label": {
        color: "green",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "green",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "green",
        },
        "&:hover fieldset": {
          borderColor: "green",
        },
        "&.Mui-focused fieldset": {
          borderColor: "green",
        },
      },
    },
  },
})(TextField)

const DropdownSearchable = ({
  id,
  multiple,
  clearOnBlur,
  value,
  createFunc,
  options,
  optionLabel,
  isOptionEqualToValue,
  style,
  onChange,
  onBlur,
  noOptionsText,
  disableClearable,
  label,
  disabled,
  renderOption,
  renderTags,
  indicateChanges,
  variant,
  filterSelectedOptions,
  required,
}: Props) => {
  const [searchState, setSearchState] = useState("")
  const [creatingState, setCreatingState] = useState(false)

  const noOptions = (
    <div>
      <span>No results for "{searchState}" </span>
      <Button
        variant="contained"
        color="primary"
        onMouseDown={async (e) => {
          e.preventDefault()
          setCreatingState(true)
          // eslint-disable-next-line
          await createFunc(searchState)
          setCreatingState(false)
        }}
      >
        {creatingState ? <CircularProgress /> : "Create"}
      </Button>
    </div>
  )

  //Supporting an array of strings, as well normal expected objects with English and Japanese names
  const getItemLabel = (option) => {
    if (!option) return ""
    if (typeof option === "string") {
      return option
    }
    return `${option.name ? option.name : ""}${option.nameJa ? " / " + option.nameJa : ""}` || ""
  }

  return (
    <Autocomplete
      id={id}
      multiple={multiple || false}
      options={options}
      value={value}
      inputValue={getItemLabel(value) || searchState}
      PopperComponent={FitContentPopper}
      getOptionLabel={optionLabel || ((option) => getItemLabel(option))}
      isOptionEqualToValue={isOptionEqualToValue || undefined}
      style={style || { width: "100%" }}
      filterSelectedOptions={filterSelectedOptions !== undefined ? filterSelectedOptions : true}
      onChange={(_e, value) => {
        if (clearOnBlur) {
          setSearchState("")
        }
        onChange(value)
      }}
      onBlur={onBlur}
      noOptionsText={(createFunc && searchState.length > 0 && noOptions) || noOptionsText || null}
      filterOptions={filterOptions}
      disableClearable={disableClearable}
      clearOnBlur={clearOnBlur}
      selectOnFocus={clearOnBlur}
      renderOption={renderOption ?? undefined}
      renderTags={renderTags ?? undefined}
      renderInput={(params) => (
        <DirtyDetectingTextField
          {...params}
          label={label}
          className={indicateChanges ? "Updated" : ""}
          variant={variant ?? "outlined"}
          fullWidth
          onChange={(e) => setSearchState(e.target.value)}
          required={required ?? false}
        />
      )}
      disabled={disabled}
    />
  )
}

const FitContentPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{
        width: "fit-content",
      }}
      placement="bottom-start"
    />
  )
}

export default DropdownSearchable
