import getPlatformFromUrl from "@taiyoro/parse-stream-url"

export const isValidUrl = (url: string): boolean => {
  try {
    getPlatformFromUrl(url)
    return true
  } catch (e) {
    return false
  }
}

export default isValidUrl
