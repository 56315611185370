export type User = {
  id: string
  extendedId: string
  email: string
  username: string
  profileImageUrl: string
  role: string
  created: string
}

export enum Products {
  PLAYBRAIN = "PLAYBRAIN",
  TAIYORO = "TAIYORO",
}
export enum UserRole {
  NONE = "none",
  ADMIN = "admin",
  ANALYST = "analyst",
  PUBLISHER = "publisher",
  EDITOR = "editor",
  VIEWER = "viewer",
  PRODUCER = "producer",
  BOT = "bot",
}
