import React, { useState, useContext } from "react"

import { faSpinner, faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Stack,
  Box,
  Button,
} from "@mui/material/"
import LoadingBox from "components/LoadingBox"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import { useTranslation } from "react-i18next"
import { fetchDataCollection, DataCollectionSort } from "services/Taiyoro/dataCollection"
import type { DataCollectionResult } from "services/Taiyoro/dataCollection"
import isValidUrl from "utils/taiyoro/validators/streamUrl"

import RecoverElement from "../../RecoverElement"
import StreamResults from "../../StreamResults"
import { StyledLink, ExistingStreamWrapper, StyledStreamLink } from "../../styles"

const VodFlow = () => {
  const { t } = useTranslation(["taiyoro", "common"])

  const [inputValue, setInputValue] = useState("")
  const [inputError, setInputError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [streamResults, setStreamResults] = useState<Array<DataCollectionResult>>([])
  const {
    selectedEventDate,
    selectedStreams,
    selectedExistingStream,
    setSelectedStreams,
    handleNewRecover,
    handleExistingRecover,
    setError,
    setStep,
    resetDialog,
    recoveryLoading,
  } = useContext(DataRecoveryContext)

  const noStreamResults = streamResults.length === 0

  const getStreamData = () => {
    if (isValidUrl(inputValue)) {
      setLoading(true)
      fetchDataCollection(
        null,
        DataCollectionSort.START_DATETIME_ASC,
        0,
        null,
        selectedEventDate.startTime,
        selectedEventDate.endTime,
        null,
        inputValue, //VOD URL
        null,
        10000
      )
        .then((fetchedResponse) => {
          setStreamResults(fetchedResponse.results)
          setShowResults(true)
        })
        .catch(() => {
          setError(true)
          resetDialog()
        })
        .finally(() => setLoading(false))
    } else {
      setInputError(true)
    }
  }

  const resetFlow = () => {
    setStreamResults([])
    setSelectedStreams([])
    setShowResults(false)
  }

  const captionStyles = { lineHeight: 1, pt: 1 }
  const infoStyles = { color: "text.primary" }

  return (
    <>
      {loading && (
        <DialogContent sx={{ mb: 3 }}>
          <LoadingBox />
        </DialogContent>
      )}
      {!loading && !showResults && (
        <>
          <DialogContent>
            {selectedExistingStream && (
              <>
                <DialogContentText sx={{ color: "text.primary" }}>
                  {t("dialogs.recover.existingVodInput")}
                </DialogContentText>
                <ExistingStreamWrapper>
                  <Stack>
                    <DialogContentText
                      variant="caption"
                      sx={captionStyles}
                    >
                      {t("edit.dates.streams.url")}
                    </DialogContentText>
                    <StyledStreamLink
                      href={selectedExistingStream.url}
                      target="_blank"
                      rel="noopener"
                    >
                      <span>{selectedExistingStream.url}</span>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </StyledStreamLink>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ mr: 1 }}
                  >
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.pcu")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(selectedExistingStream.viewsLiveConcurrentPeak).toLocaleString() ||
                          Number(selectedExistingStream.viewsLiveConcurrentPeakManual).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.acu")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(selectedExistingStream.viewsLiveConcurrentAverage).toLocaleString() ||
                          Number(selectedExistingStream.viewsLiveConcurrentAverageManual).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.minutesWatched")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(selectedExistingStream.viewsLiveMinutesWatched).toLocaleString() ||
                          Number(selectedExistingStream.viewsLiveMinutesWatchedManual).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                  </Stack>
                </ExistingStreamWrapper>
              </>
            )}
            {!selectedExistingStream && (
              <DialogContentText sx={{ pb: 2, color: "text.primary" }}>
                {t("dialogs.recover.enterVod")}
              </DialogContentText>
            )}
            <TextField
              sx={selectedExistingStream && { mt: 4 }}
              fullWidth
              id="vodUrl"
              label={t("edit.dates.streams.vodUrl")}
              variant="standard"
              value={inputValue}
              onChange={(event: any) => {
                setInputValue(event.target.value)
              }}
              error={inputError}
              helperText={inputError ? t("dialogs.recover.urlInputError") : ""}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: 1 }}
            >
              <StyledLink onClick={() => setStep(RecoveryStep.CHANNEL_FLOW)}>
                {t("dialogs.recover.noVodUrl")}
              </StyledLink>

              <Stack
                direction="row"
                alignItems="center"
              >
                <StyledLink
                  onClick={() =>
                    selectedExistingStream
                      ? setStep(RecoveryStep.EXISTING_STREAM_RECOVER)
                      : setStep(RecoveryStep.SUGGESTIONS)
                  }
                >
                  {t("common:actions.back")}
                </StyledLink>
                <Button
                  sx={{ ml: 3 }}
                  onClick={() => getStreamData()}
                  color="primary"
                  autoFocus
                  variant="contained"
                  disabled={inputValue ? false : true}
                >
                  {t("dialogs.recover.loadData")}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </>
      )}
      {!loading && showResults && (
        <>
          <DialogContent>
            {selectedExistingStream && (
              <>
                <DialogContentText sx={{ color: "text.primary" }}>
                  {t("dialogs.recover.existingStreamRecover")}
                </DialogContentText>
                <ExistingStreamWrapper>
                  <Stack>
                    <DialogContentText
                      variant="caption"
                      sx={captionStyles}
                    >
                      {t("edit.dates.streams.url")}
                    </DialogContentText>
                    <StyledStreamLink
                      href={selectedExistingStream.url}
                      target="_blank"
                      rel="noopener"
                    >
                      <span>{selectedExistingStream.url}</span>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </StyledStreamLink>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ mr: 1 }}
                  >
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.pcu")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(selectedExistingStream.viewsLiveConcurrentPeak).toLocaleString() ||
                          Number(selectedExistingStream.viewsLiveConcurrentPeakManual).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.acu")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(selectedExistingStream.viewsLiveConcurrentAverage).toLocaleString() ||
                          Number(selectedExistingStream.viewsLiveConcurrentAverageManual).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                    <Stack>
                      <DialogContentText
                        variant="caption"
                        sx={captionStyles}
                      >
                        {t("list.header.minutesWatched")}
                      </DialogContentText>
                      <DialogContentText sx={infoStyles}>
                        {Number(selectedExistingStream.viewsLiveMinutesWatched).toLocaleString() ||
                          Number(selectedExistingStream.viewsLiveMinutesWatchedManual).toLocaleString()}
                      </DialogContentText>
                    </Stack>
                  </Stack>
                </ExistingStreamWrapper>
              </>
            )}

            <DialogContentText sx={{ color: "text.primary", mt: selectedExistingStream ? 4 : 0 }}>
              {t("dialogs.recover.vodResults")}
            </DialogContentText>
            <DialogContentText sx={{ pt: 3, pb: 2, color: "text.primary" }}>
              {t("dialogs.recover.selectStream")}
            </DialogContentText>
            <StreamResults>
              {noStreamResults && (
                <DialogContentText sx={{ p: 3, color: "text.primary" }}>
                  {t("dialogs.recover.noVodResults")}
                </DialogContentText>
              )}
              {!noStreamResults &&
                streamResults.map((stream) => (
                  <RecoverElement
                    data={stream}
                    key={stream.id}
                  />
                ))}
            </StreamResults>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            {noStreamResults && (
              <Button
                sx={{ ml: 3 }}
                onClick={() => setStep(RecoveryStep.INITIAL)}
                color="primary"
                autoFocus
                variant="contained"
                disabled={
                  !noStreamResults && (selectedStreams === undefined || selectedStreams.length === 0)
                    ? true
                    : false
                }
              >
                {t("dialogs.recover.tryAgain")}
              </Button>
            )}
            {!noStreamResults && (
              <Stack
                direction="row"
                alignItems="center"
              >
                <StyledLink
                  disabled={recoveryLoading}
                  onClick={() => resetFlow()}
                >
                  {t("common:actions.back")}
                </StyledLink>
                <Button
                  sx={{ ml: 3 }}
                  onClick={() => setSelectedStreams(streamResults)}
                  color="primary"
                  variant="contained"
                  disabled={recoveryLoading}
                >
                  {t("dialogs.recover.selectAll")}
                </Button>
                <Button
                  sx={{ ml: 3 }}
                  onClick={
                    recoveryLoading
                      ? () => {}
                      : selectedExistingStream
                        ? () => handleExistingRecover()
                        : () => handleNewRecover()
                  }
                  color="primary"
                  autoFocus
                  variant="contained"
                  disabled={
                    !noStreamResults && (selectedStreams === undefined || selectedStreams.length === 0)
                      ? true
                      : false
                  }
                >
                  {recoveryLoading && (
                    <Box
                      component="span"
                      sx={{ pr: 1 }}
                    >
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spinPulse
                      />
                    </Box>
                  )}
                  {t("dialogs.recover.recover")}
                </Button>
              </Stack>
            )}
          </DialogActions>
        </>
      )}
    </>
  )
}

export default VodFlow
