import React from "react"

import { Paper } from "@mui/material"
import moment from "moment"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"

import type EventStream from "../../../models/Taiyoro/Stream"
import type Event from "../../../models/Taiyoro/event"
import type EventDate from "../../../models/Taiyoro/eventDate"

const generateLine = (
  dates: EventDate[],
  platform: string,
  label: string,
  fieldName: string,
  fieldNameBackup: string = null
) => {
  const getValueOfField = (streams: EventStream[]) => {
    let total = undefined
    for (let i = 0; i < streams.length; i++) {
      const stream = streams[i]
      let valueToAdd
      if (fieldNameBackup) {
        valueToAdd = stream[fieldName] || stream[fieldNameBackup]
      } else {
        valueToAdd = stream[fieldName]
      }
      if (valueToAdd !== undefined && valueToAdd !== null) {
        if (!total) {
          total = valueToAdd
        } else {
          total += valueToAdd
        }
      }
    }
    return total
  }
  return {
    label,
    data: dates
      .filter((date) => date.platforms.find((p) => p.platform === platform))
      .filter((date) => {
        var val = getValueOfField(date.platforms.filter((p) => p.platform === platform))
        return val != null && val != undefined
      })
      .map((date, index) => {
        const d = date.startTime.toDate()
        // d.setSeconds(0);
        // d.setMilliseconds(0);
        // d.setHours(0);
        // d.setMinutes(0);
        return [d, getValueOfField(date.platforms.filter((p) => p.platform === platform))]
      }),
  }
}

export const RenderEventGraphTable = (
  event: Event,
  platforms: any[],
  platformsMap: any,
  dateLabelMap: any,
  valueLabel: string,
  fieldName: string,
  fieldNameBackup: string = null
) => {
  const eventDates = event ? event.dates : null
  const data = React.useMemo(
    () =>
      eventDates
        ? platforms.map((platform) => ({
            platformName: platformsMap && platformsMap[platform] && platformsMap[platform].name,
            data: generateLine(
              eventDates,
              platform,
              (platformsMap[platform] && platformsMap[platform].name) || platform,
              fieldName,
              fieldNameBackup
            ),
          }))
        : [],
    [event, eventDates, fieldName, fieldNameBackup, platforms]
  )

  const dateMap: any = {}
  data.forEach((d) => {
    d.data.data.forEach((da) => {
      dateMap[`${d.platformName}:${da[0].toISOString()}`] = da[1]
    })
  })

  const getValueOfField = (stream: EventStream) => {
    if (fieldNameBackup) {
      return stream[fieldName] || stream[fieldNameBackup]
    }
    return stream[fieldName]
  }
  let dates = null
  if (eventDates) {
    let startIndex = 0
    for (let i = 0; i < eventDates.length; i++) {
      let useThis = false
      eventDates[i].platforms.forEach((stream) => {
        const val = getValueOfField(stream)
        if (val !== null && val !== undefined) {
          useThis = true
        }
      })
      if (useThis) {
        startIndex = i
        break
      }
    }

    let endIndex = eventDates.length
    for (let i = eventDates.length - 1; i >= 0; i--) {
      let useThis = false
      eventDates[i].platforms.forEach((stream) => {
        const val = getValueOfField(stream)
        if (val !== null && val !== undefined) {
          useThis = true
        }
      })
      if (useThis) {
        endIndex = i + 1
        break
      }
    }

    dates = eventDates.slice(startIndex, endIndex)
  }

  const columnsPerRow = 15
  let iterations = dates && dates.length
  const rows = []
  if (iterations) {
    iterations = Math.ceil(iterations / columnsPerRow)
    for (let i = 0; i < iterations; i++) {
      rows.push(dates.slice(i * columnsPerRow, i * columnsPerRow + columnsPerRow))
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "1400px",
        marginTop: "7%",
        flexWrap: "wrap",
      }}
    >
      <div>
        <p style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
          The below times are all in Asia/Tokyo (UTC +09:00) time.
        </p>
        {dates &&
          rows.map((rowOfDates) => (
            <table style={{ border: "4px grey solid", marginTop: "50px" }}>
              <tr>
                <th>Platform</th>
                {rowOfDates.map((d: EventDate) => (
                  <th style={{ border: "4px grey solid", padding: "0.5em" }}>
                    {d.startTime.format("MM/DD")}
                  </th>
                ))}
              </tr>
              {platforms.map((platform) => (
                <tr>
                  <td>{platformsMap && platformsMap[platform] && platformsMap[platform].name}</td>
                  {rowOfDates.map((d: EventDate) => (
                    <td style={{ border: "4px grey solid", padding: "0.5em" }}>
                      {dateMap[
                        `${platformsMap && platformsMap[platform] && platformsMap[platform].name}:${d.startTime.toDate().toISOString()}`
                      ]
                        ? numberWithCommas(
                            dateMap[
                              `${platformsMap && platformsMap[platform] && platformsMap[platform].name}:${d.startTime.toDate().toISOString()}`
                            ]
                          )
                        : " "}
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          ))}
      </div>
    </div>
  )
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const RenderGraph = (
  eventDates: EventDate[],
  platforms: any[],
  platformsMap: any,
  dateLabelMap: any,
  valueLabel: string,
  fieldName: string,
  fieldNameBackup: string = null
) => {
  const dates = eventDates

  const data = React.useMemo(
    () =>
      dates
        ? platforms.map((platform) =>
            generateLine(
              dates,
              platform,
              (platformsMap[platform] && platformsMap[platform].name) || platform,
              fieldName,
              fieldNameBackup
            )
          )
        : [],
    [eventDates, dates, fieldName, fieldNameBackup, platforms]
  )

  /**
   * data:
   *    [[date,value],[date,value],...]
   */

  const tooltip = React.useMemo(
    () => <Tooltip content={CustomTooltip(valueLabel, dateLabelMap) as any} />,
    [dateLabelMap]
  )

  const data2 = React.useMemo(
    () =>
      data.reduce((acc, curr) => {
        const newAcc = [...acc]
        curr.data.forEach((d) => {
          const date = moment(d[0]).tz("Asia/Tokyo").local().format("MM/DD")
          const existing = newAcc.find((n) => n.date === date)
          if (existing) {
            existing[curr.label] = d[1]
          } else {
            const x: any = {
              date: date,
            }
            x[curr.label] = d[1]
            newAcc.push(x)
          }
        })
        return newAcc
      }, []),
    [data]
  )

  console.log("Data1", data)

  console.log("Data2", data2)

  return (
    <div style={{ textAlign: "center", marginRight: "2%" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "1100px",
            height: "619px",
          }}
        >
          <LineChart
            width={1000}
            height={600}
            data={data2}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            {platforms.map((platform, index) => (
              <Line
                type="monotone"
                dataKey={platformsMap[platform].name}
                stroke={defaultColors[index]}
              />
            ))}
            <CartesianGrid
              stroke="#ccc"
              strokeDasharray="5 5"
            />
            <XAxis
              dataKey="date"
              padding={{ left: 30, right: 30 }}
            />
            <YAxis width={100} />
            {tooltip}
          </LineChart>
        </div>
      </div>
    </div>
  )
}

export const RenderLegend = (data: { name: string }[]) => {
  return (
    <div>
      {data.map((item, index) => (
        <p key={`legend-${index}`}>
          <svg
            width="16"
            height="16"
          >
            <circle
              cx="8"
              cy="8"
              r="7"
              style={{
                stroke: defaultColors[index],
                fill: defaultColors[index],
                strokeWidth: 2,
              }}
            ></circle>
          </svg>
          {item.name}
        </p>
      ))}
    </div>
  )
}

const CustomTooltip =
  (valueLabel, dateLabelMap) =>
  ({ payload, label, active }) => {
    return <CustomTooltipBase {...{ payload, label, active, valueLabel, dateLabelMap }} />
  }

const CustomTooltipBase = ({ payload, label, active, valueLabel, dateLabelMap }) => {
  console.log("payload", payload)
  if (!payload) return null
  return active ? (
    <Paper>
      <div style={{ marginBottom: "3px", textAlign: "center" }}>
        <strong>{`${payload[0].payload.date} +09:00`}</strong>
      </div>
      <div style={{ marginBottom: "3px", textAlign: "center" }}>
        <strong>{dateLabelMap[payload[0].payload.date] && dateLabelMap[payload[0].payload.date].name}</strong>
      </div>
      {payload.map((p, index) => (
        <table style={{ whiteSpace: "nowrap" }}>
          <tbody>
            <tr style={{ opacity: 1, fontWeight: "bold" }}>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5px",
                }}
              >
                <svg
                  width="16"
                  height="16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="7"
                    style={{
                      stroke: defaultColors[index],
                      fill: defaultColors[index],
                      strokeWidth: 2,
                    }}
                  ></circle>
                </svg>
              </td>
              <td>
                {p.name} {valueLabel}:
              </td>
              <td style={{ textAlign: "right" }}>{p.value}</td>
            </tr>
          </tbody>
        </table>
      ))}
    </Paper>
  ) : null
}

var defaultColors = [
  "#ff3465",
  "#4287f5",
  "#42f56c",
  "#FAA43A",
  "#ffd000",
  "#C8DE2B",
  "#97d114",
  "#34ce70",
  "#3cada4",
  "#14b4d3",
  "#048cd4",
  "#3447f1",
  "#734fe9",
  "#cd82ad",
  "#c63b89",
]
