import type Caster from "../../models/Taiyoro/Meta/Caster"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type CastersResponse = {
  data?: {
    admin?: {
      caster?: {
        get?: Array<Caster>
      }
    }
  }
}

export const fetchCasters = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          caster {
            get {
              id
              name
              nameJa
              sortJa
              primaryImage
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CastersResponse

    return response.data?.admin?.caster?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch casters")
  }
}

export type CasterResponse = {
  data?: {
    admin?: {
      caster?: {
        get?: Caster | null
      }
    }
  }
}

export const fetchCaster = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          caster {
            get(id: "${id}") {
              id
              name
              nameJa
              sortJa
              primaryImage
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CasterResponse

    return response.data?.admin?.caster?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch caster")
  }
}

export type CreateCasterResponse = {
  data?: {
    admin?: {
      caster?: {
        save?: Caster | null
      }
    }
  }
}

export const createCaster = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  primaryImage?: string,
  id?: string
) => {
  try {
    const casterInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      `primaryImage: ${primaryImage === "" ? "null" : JSON.stringify(primaryImage)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
        admin {
          caster {
            save(caster: {
              ${casterInput}
            }) {
              id
              name
              nameJa
              sortJa
              primaryImage
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateCasterResponse

    return response.data?.admin?.caster?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create casters")
  }
}

export type DeleteCasterResponse = {
  data?: {
    admin?: {
      caster?: {
        delete: boolean | null
      }
    }
  }
}

export const deleteCaster = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          caster {
            delete(casterId: "${id}")
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteCasterResponse

    return response.data?.admin?.caster?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete casters")
  }
}
