import { styled, Box } from "@mui/material"

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const Title = styled(Box)`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.9px;
  text-align: left;
`
