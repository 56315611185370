// Temporary File
import React from "react"

import TableList from "components/Table/TableList"

import type { UpdateGameAlternativeNamesFunc, UpdateGroupsFunc, UpdateParentTagsFunc } from "./types"

type CustomHeaderCellProps = {
  text: string
  conditional?:
    | "none"
    | boolean
    | number
    | UpdateGameAlternativeNamesFunc
    | UpdateGroupsFunc
    | UpdateParentTagsFunc
}

export const CustomHeaderCell: React.FC<CustomHeaderCellProps> = ({ text, conditional }) => {
  if (conditional === "none") {
    return <TableList.HeaderCell>{text}</TableList.HeaderCell>
  } else if (conditional) {
    return <TableList.HeaderCell>{text}</TableList.HeaderCell>
  } else {
    return null
  }
}
