import { useTranslation } from "react-i18next"

interface LocalisedEntity {
  name?: string | null
  nameJa?: string | null
}

export const useLocalisedLabel = () => {
  const { i18n } = useTranslation()

  return (entity: LocalisedEntity): string => {
    if (!entity) {
      return ""
    }

    const { name, nameJa } = entity

    if (i18n.language === "en") {
      return name ?? nameJa ?? ""
    }

    if (i18n.language === "ja") {
      return nameJa ?? name ?? ""
    }

    return ""
  }
}
