import { useState } from "react"

import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { EventSearchAutocomplete } from "components/EventSearchAutocomplete"
import MediaUpload from "components/Taiyoro/MediaUpload"
import type Event from "models/Taiyoro/event"
import moment from "moment"
import { HexAlphaColorPicker } from "react-colorful"
import { useTranslation } from "react-i18next"
import type { FeaturedEvent } from "services/Taiyoro/featuredEvents"
import { signMediaS3 } from "services/Taiyoro/media"
import validator from "validator"

interface Props {
  featuredEvent: FeaturedEvent
  onUpdate: (featuredEvent: FeaturedEvent) => void
}

export const FeaturedEventForm = (props: Props) => {
  const { t } = useTranslation(["common", "taiyoro"])

  const [showColorPicker, setShowColorPicker] = useState(false)

  const updateFeaturedEventBaseData = (data: any) => {
    props.onUpdate({ ...props.featuredEvent, ...data })
  }

  const handleEventSelected = (event: Event) => {
    if (!event) {
      props.onUpdate({ ...props.featuredEvent, event: null, eventId: null })
      return
    }
    props.onUpdate({
      ...props.featuredEvent,
      eventId: event.id,
      event: {
        id: event.id,
        name: event.name,
        imageUrl: event.imageUrl,
        urlSlug: event.urlSlug,
      },
    })
  }

  return (
    <Stack gap={2}>
      <EventSearchAutocomplete
        preloadEventId={props.featuredEvent.eventId}
        onEventSelected={handleEventSelected}
      />
      <TextField
        type="number"
        value={props.featuredEvent.orderValue}
        onChange={(event) => updateFeaturedEventBaseData({ orderValue: Number(event.target.value || 0) })}
        label={t("taiyoro:featuredEvents.orderValue")}
      />
      <DateTimePicker
        disablePast
        label={t("taiyoro:featuredEvents.featuredStartDatetime")}
        value={moment(props.featuredEvent.featuredStartDatetime)}
        onChange={(newValue: moment.Moment) => {
          updateFeaturedEventBaseData({ featuredStartDatetime: newValue.utc().format() })
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <DateTimePicker
        minDate={moment(props.featuredEvent.featuredStartDatetime)}
        label={t("taiyoro:featuredEvents.featuredEndDatetime")}
        value={moment(props.featuredEvent.featuredEndDatetime)}
        onChange={(newValue: moment.Moment) => {
          updateFeaturedEventBaseData({ featuredEndDatetime: newValue.utc().format() })
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <Box>
        <TextField
          label={t("taiyoro:featuredEvents.backgroundColor")}
          fullWidth
          error={!validator.isHexColor(props.featuredEvent.backgroundColor)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: props.featuredEvent.backgroundColor,
                    border: "1px solid #818181",
                    minWidth: "auto",
                    "&:hover": {
                      backgroundColor: props.featuredEvent.backgroundColor,
                    },
                  }}
                  onClick={() => setShowColorPicker(true)}
                  onBlur={() => setShowColorPicker(false)}
                ></Button>
              </InputAdornment>
            ),
          }}
          onFocus={() => setShowColorPicker(true)}
          onBlur={() => setShowColorPicker(false)}
          value={props.featuredEvent.backgroundColor}
          onChange={(event) => updateFeaturedEventBaseData({ backgroundColor: event.target.value })}
        />
        <Box sx={{ position: "relative" }}>
          <HexAlphaColorPicker
            color={props.featuredEvent.backgroundColor}
            onChange={(newColor) => updateFeaturedEventBaseData({ backgroundColor: newColor })}
            onFocus={() => setShowColorPicker(true)}
            onBlur={() => setShowColorPicker(false)}
            style={{
              visibility: showColorPicker ? "visible" : "hidden",
              opacity: showColorPicker ? "100%" : "0%",
              position: "absolute",
              right: "48px",
              top: "-175px",
              transition: "0.3s ease",
            }}
          />
        </Box>
      </Box>
      <Box sx={props.featuredEvent.heroAssetUrl ? { maxHeight: "100px", maxWidth: "100px" } : {}}>
        <MediaUpload
          key="featured-event-asset"
          url={props.featuredEvent.heroAssetUrl}
          mediaType="featured_event_asset"
          onChange={(url: string) => updateFeaturedEventBaseData({ heroAssetUrl: url })}
          editable
          buttonText={t("taiyoro:featuredEvents.heroAssetUrl")}
        />
      </Box>
    </Stack>
  )
}
