import React, { useContext } from "react"

import { FormGroup, RadioGroup } from "@mui/material/"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"

import { StyledFormControl } from "./styles"

interface Props {
  children: React.ReactNode
}

const StreamResults = (props: Props) => {
  const { step, selectedExistingStream } = useContext(DataRecoveryContext)
  return (
    <StyledFormControl>
      {step === RecoveryStep.EXISTING_STREAM_RECOVER && (
        <RadioGroup
          name="existingStream"
          value={selectedExistingStream}
        >
          {props.children}
        </RadioGroup>
      )}
      {step !== RecoveryStep.EXISTING_STREAM_RECOVER && <FormGroup>{props.children}</FormGroup>}
    </StyledFormControl>
  )
}

export default StreamResults
