import React from "react"

import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Tooltip } from "@mui/material"
import type { MetaItem } from "components/Taiyoro/MetaPage/types"

type Props = {
  text: string
  setDeleteMetaObjectState: (meta: MetaItem) => void
  meta: MetaItem
}

export const DeleteRecord = ({ text, setDeleteMetaObjectState, meta }: Props) => {
  return (
    <Tooltip title={text}>
      <IconButton
        color="error"
        sx={{ width: "32px", height: "32px" }}
        onClick={() => setDeleteMetaObjectState(meta)}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </IconButton>
    </Tooltip>
  )
}
