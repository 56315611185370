import React from "react"

import type EventStream from "models/Taiyoro/Stream"
import type Event from "models/Taiyoro/event"
import type EventDate from "models/Taiyoro/eventDate"
import type { EventDateBracket } from "services/Taiyoro/brackets"
import type { StreamStat } from "services/Taiyoro/event"

export type TimeMarks = { start: string; end: string; selected: "start" | "end" }

type EventDateBracketsContextType = {
  event: Event
  eventDate: EventDate
  loading: boolean
  streamViewershipData: Record<string, Array<StreamStat>>
  selectedStream: EventStream
  setSelectedStream: React.Dispatch<React.SetStateAction<EventStream>>
  streamIdColorMap: Record<string, string>
  timeMarks: TimeMarks
  setTimeMarks: React.Dispatch<React.SetStateAction<TimeMarks>>
  brackets: Array<EventDateBracket>
  setBrackets: React.Dispatch<React.SetStateAction<Array<EventDateBracket>>>
  playhead: string
  setPlayhead: React.Dispatch<React.SetStateAction<string>>
  chartData: Array<{ datetime: string } & Record<string, number>>
  streamVodMap: Record<string, string>
  setStreamVodMap: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

export const EventDateBracketsContext = React.createContext<EventDateBracketsContextType>({
  event: {} as Event,
  loading: true,
  eventDate: {} as EventDate,
  streamViewershipData: {},
  selectedStream: {} as EventStream,
  setSelectedStream: () => undefined,
  streamIdColorMap: {},
  timeMarks: { start: "", end: "", selected: "start" },
  setTimeMarks: () => undefined,
  brackets: [],
  setBrackets: () => undefined,
  playhead: "",
  setPlayhead: () => undefined,
  chartData: [],
  streamVodMap: {},
  setStreamVodMap: () => undefined,
})
