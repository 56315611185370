import { useContext, useState } from "react"

import { Stack, Skeleton, Typography, Chip, Box, Paper, useTheme, styled } from "@mui/material"
import clsx from "clsx"
import { EditDialog } from "components/Taiyoro/FeaturedEvents/EditDialog"
import moment from "moment"
import { useUpdateEffect } from "react-use"
import { deleteFeaturedEvent } from "services/Taiyoro/featuredEvents"
import type { FeaturedEvent } from "services/Taiyoro/featuredEvents"
import { UTCDateInJST } from "utils/tools"

import { FeaturedEventsContext, PendingChange, TEMPORARY_ID, TimeRange } from "../FeaturedEventsProvider"

const calculateTimeRangeSpacingToShowRange = (featuredEvent: FeaturedEvent, timeRange: TimeRange) => {
  const timeRangeHours = moment.duration(timeRange.end.diff(timeRange.start)).asHours()
  const start = UTCDateInJST(featuredEvent.featuredStartDatetime)
  const end = UTCDateInJST(featuredEvent.featuredEndDatetime)
  const marginLeft = (moment.duration(start.diff(timeRange.start)).asHours() / timeRangeHours) * 100
  const width = (moment.duration(end.diff(start)).asHours() / timeRangeHours) * 100
  return {
    width: `${width}%`,
    marginLeft: `${marginLeft}%`,
  }
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  "&.error": {
    border: `solid 1px ${theme.palette.error.main}`,
    boxShadow: theme.shadows[15],
  },
}))

const timeRangeHasError = (timeRange: TimeRange) => {
  const allUniqueEvents =
    Array.from(new Set(timeRange.featuredEvents.map((featuredEvent) => featuredEvent.orderValue))).length ===
    timeRange.featuredEvents.length
  const allUniqueSorts =
    Array.from(new Set(timeRange.featuredEvents.map((featuredEvent) => featuredEvent.eventId))).length ===
    timeRange.featuredEvents.length
  return !allUniqueEvents || !allUniqueSorts
}

export const FeaturedEventsTimeRangesList = () => {
  const { loading, featuredEvents, setFeaturedEvents, setPendingApiChangesQueue, timeRanges } =
    useContext(FeaturedEventsContext)

  const [eventColorMap, setEventColorMap] = useState({})

  const [featuredEventForEdit, setFeaturedEventForEdit] = useState<FeaturedEvent | null>(null)

  const theme = useTheme()

  const colors = [
    theme.palette.primary.main,
    theme.palette.success.light,
    theme.palette.warning.light,
    theme.palette.error.light,
    theme.palette.secondary.light,
    theme.palette.info.light,
  ]

  const handleDelete = (featuredEvent: FeaturedEvent) => {
    if (featuredEvent.id.startsWith(TEMPORARY_ID)) {
      setPendingApiChangesQueue((oldPendingApiChangesQueue) =>
        oldPendingApiChangesQueue.filter((pendingChange) => pendingChange.id !== featuredEvent.id)
      )
    } else {
      const pendingChange: PendingChange = {
        id: featuredEvent.id,
        type: "delete",
        action: () => deleteFeaturedEvent(featuredEvent.id),
      }
      setPendingApiChangesQueue((oldPendingApiChangesQueue) => [...oldPendingApiChangesQueue, pendingChange])
    }
    setFeaturedEvents((oldFeaturedEvents) => oldFeaturedEvents.filter((fe) => fe !== featuredEvent))
  }

  useUpdateEffect(() => {
    const uniqueEvents = Array.from(new Set(featuredEvents.map((featuredEvent) => featuredEvent.eventId)))
    setEventColorMap(
      uniqueEvents.reduce((acc, curr, index) => {
        acc[curr] = colors[index % colors.length]
        return acc
      }, {})
    )
  }, [featuredEvents])

  const handleFeaturedEventClick = (featuredEvent: FeaturedEvent) => {
    setFeaturedEventForEdit(featuredEvent)
  }

  return (
    <>
      <Stack
        gap={2}
        mt={2}
      >
        {loading && (
          <>
            {Array.from("x".repeat(5)).map((_item, index) => (
              <Skeleton
                key={`loading-${index}`}
                variant="rounded"
                height="75px"
                width="100%"
                animation="wave"
              />
            ))}
          </>
        )}
        {!loading && (
          <>
            {timeRanges.map((timeRange) => (
              <StyledPaper
                className={clsx(timeRangeHasError(timeRange) && "error")}
                key={`${timeRange.start.format()}${timeRange.end.format()}`}
              >
                <Stack
                  p={2}
                  gap={1}
                  width="100%"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>{timeRange.start.format("YYYY/MM/DD HH:mm")} (JST)</Typography>
                    <Typography>{timeRange.end.format("YYYY/MM/DD HH:mm")} (JST)</Typography>
                  </Stack>
                  <Box>
                    {timeRange.featuredEvents.map((featuredEvent) => (
                      <Box
                        key={featuredEvent.id}
                        height="12px"
                        bgcolor={eventColorMap[featuredEvent.eventId]}
                        {...calculateTimeRangeSpacingToShowRange(featuredEvent, timeRange)}
                      />
                    ))}
                  </Box>

                  <Stack gap={1}>
                    {timeRange.featuredEvents.map((featuredEvent) => (
                      <Stack
                        direction="row"
                        key={featuredEvent.id}
                        gap={1}
                        alignItems="center"
                      >
                        <Typography>{featuredEvent.orderValue}.</Typography>
                        <Chip
                          label={
                            <Stack
                              gap={1}
                              alignItems="center"
                              direction="row"
                            >
                              <Box
                                width={12}
                                height={12}
                                sx={{ borderRadius: "50%" }}
                                bgcolor={eventColorMap[featuredEvent.eventId]}
                              />
                              <Typography>{featuredEvent.event.name}</Typography>
                            </Stack>
                          }
                          onClick={() => handleFeaturedEventClick(featuredEvent)}
                          onDelete={() => handleDelete(featuredEvent)}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </StyledPaper>
            ))}
          </>
        )}
      </Stack>
      <EditDialog
        featuredEvent={featuredEventForEdit}
        onClose={() => setFeaturedEventForEdit(null)}
      />
    </>
  )
}
