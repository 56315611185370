import { useEffect, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { faMagnifyingGlass, faSearch, faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { Trans, useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { useSearchParam } from "react-use"
import { addToIgnoreList, PLATFORM_CHOICES } from "services/Taiyoro/dataCollection"

import type { RankedStreamerResult, RankedStreamResult } from "../../../models/Scouting"
import type Game from "../../../models/Taiyoro/Meta/Game"
import { fetchScoutingDataStream, fetchScoutingDataStreamer } from "../../../services/Scouting"
import { localisedLabel } from "../../../utils/i18n"
import LoadingBox from "../../LoadingBox"
import RankedStreamResults from "./RankedStreamResults"
import RankedStreamerResults from "./RankedStreamerResults"

interface Props {
  games: Array<Game>
}

const PLATFORMS_WHICH_CANT_SELECT_GAMES = ["YouTube", "Afreeca"]

export enum RankedDataSort {
  PCU_DESC = "PCU_DESC",
  ACU_DESC = "ACU_DESC",
  MINUTES_WATCHED_DESC = "MINUTES_WATCHED_DESC",
}

const ScoutingTool = (props: Props) => {
  const { t } = useTranslation(["scouting", "common"])
  const history = useHistory()
  const location = useLocation()
  const { user } = useAuth0()

  const defaultType = useSearchParam("type")
  const defaultPlatform = useSearchParam("platform")
  const defaultFrom = useSearchParam("from")
  const defaultTo = useSearchParam("to")
  const defaultGameId = useSearchParam("gameId")
  const defaultSort = useSearchParam("sort")
  const defaultKeywords = useSearchParam("keywords")

  const initiallySelectedGame = defaultGameId ? props.games.find((game) => game.id === defaultGameId) : null

  const [loadingState, setLoadingState] = useState(false)
  const [errorState, setErrorState] = useState<string | null>(null)
  const [type, setType] = useState(defaultType || "stream")
  const [platform, setPlatform] = useState(defaultPlatform || "YouTube")
  const [from, setFrom] = useState(
    defaultFrom ? moment(defaultFrom) : moment().clone().tz("Asia/Tokyo").subtract(1, "day").startOf("day")
  )
  const [to, setTo] = useState(defaultTo ? moment(defaultTo) : moment().clone().tz("Asia/Tokyo").endOf("day"))
  const [game, setGame] = useState(initiallySelectedGame || null)
  const [gameInputValue, setGameInputValue] = useState(
    initiallySelectedGame ? localisedLabel(initiallySelectedGame) : ""
  )
  const [sort, setSort] = useState<RankedDataSort>(
    defaultSort ? (defaultSort as RankedDataSort) : RankedDataSort.PCU_DESC
  )
  const [keywords, setKeywords] = useState(defaultKeywords || "")
  const [results, setResults] = useState<Array<RankedStreamerResult> | Array<RankedStreamResult> | null>(null)
  const [resultsParams, setResultsParams] = useState<any | null>(null)
  const [ignoreListItem, setIgnoreListItem] = useState<RankedStreamResult | RankedStreamerResult | null>(null)
  const [addedToIgnoreListResult, setAddedToIgnoreListResult] = useState<null | boolean>(null)
  const [addingToIgnoreList, setAddingToIgnoreList] = useState(false)

  const handleTypeChange = (_event, value) => {
    setType(value)
  }

  const handleSearch = () => {
    setLoadingState(true)
    setErrorState(null)
    setResults(null)
    setResultsParams({
      type: type,
      platform: platform,
      from: from.format(),
      to: to.format(),
      sort: sort,
      keywords: keywords,
      ...(game && { gameId: game.id }),
    })
    const fetchFunc = type === "streamer" ? fetchScoutingDataStreamer : fetchScoutingDataStream
    fetchFunc(platform, from, to, sort, game, keywords)
      .then((results) => {
        setResults(results)
        setLoadingState(false)
      })
      .catch((error) => {
        setErrorState(error)
        setLoadingState(false)
      })
  }

  useEffect(() => {
    handleSearch()
    // @ts-ignore
  }, [sort])

  useEffect(() => {
    if (resultsParams) {
      const params = new URLSearchParams(resultsParams)
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      })
    }
    // @ts-ignore
  }, [resultsParams])

  const handleAddToIgnoreList = async () => {
    setAddingToIgnoreList(true)
    addToIgnoreList(
      ignoreListItem.platformName,
      ignoreListItem.userName,
      ignoreListItem.channelUrl,
      user.nickname
    )
      .then((response) => {
        const success = response === true
        setAddedToIgnoreListResult(success)
        success && setResults(results.filter((result) => result !== ignoreListItem))
      })
      .catch(() => setAddedToIgnoreListResult(false))
      .finally(() => {
        setAddingToIgnoreList(false)
        setIgnoreListItem(null)
      })
  }

  const enableDaySelectionOnly = (enabledDay: number) => {
    return type === "streamer" ? { shouldDisableDate: (day) => day.day() !== enabledDay } : {}
  }

  return (
    <>
      <Stack spacing={1}>
        <Box>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={type}
            onChange={handleTypeChange}
          >
            <ToggleButton value="stream">{t("stream")}</ToggleButton>
            <ToggleButton value="streamer">{t("streamer")}</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Stack
          spacing={1}
          direction="row"
        >
          <Box>
            <Select
              required
              value={platform}
              onChange={(e) => {
                setPlatform(e.target.value)
                game && PLATFORMS_WHICH_CANT_SELECT_GAMES.includes(e.target.value) && setGame(null)
              }}
            >
              {PLATFORM_CHOICES.map((platformChoice) => (
                <MenuItem
                  key={platformChoice.label}
                  value={platformChoice.label}
                >
                  {platformChoice.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box minWidth="300px">
            <Autocomplete
              options={props.games}
              getOptionLabel={(option) => localisedLabel(option)}
              value={game}
              fullWidth
              disabled={PLATFORMS_WHICH_CANT_SELECT_GAMES.includes(platform)}
              inputValue={gameInputValue}
              onInputChange={(_event, newInputValue) => {
                setGameInputValue(newInputValue)
              }}
              clearOnBlur
              onChange={(_event, newValue) => {
                if (typeof newValue === "string") {
                  setGame(null)
                  return
                }
                setGame(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("game")}
                />
              )}
            />
          </Box>
          <Box>
            <DatePicker
              disableFuture
              label={t("from")}
              value={from}
              onChange={(newValue: moment.Moment) => {
                setFrom(newValue.clone().startOf("day"))
              }}
              renderInput={(params) => <TextField {...params} />}
              {...enableDaySelectionOnly(0)}
            />
          </Box>
          <Box>
            <DatePicker
              disableFuture
              label={t("to")}
              value={to}
              onChange={(newValue: moment.Moment) => {
                setTo(newValue.clone().endOf("day"))
              }}
              renderInput={(params) => <TextField {...params} />}
              minDate={from}
              {...enableDaySelectionOnly(6)}
            />
          </Box>
          <Box>
            <TextField
              label={t("keywords")}
              value={keywords}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setKeywords(event.target.value)}
            />
          </Box>
        </Stack>
        <Box>
          <Button
            onClick={handleSearch}
            variant="contained"
            color="primary"
            startIcon={
              <FontAwesomeIcon
                icon={faSearch}
                size="1x"
              />
            }
          >
            {t("common:actions.fetch")}
          </Button>
        </Box>
        {errorState && (
          <Box>
            <Alert severity="error">{errorState}</Alert>
          </Box>
        )}
        {loadingState && (
          <Box>
            <LoadingBox />
          </Box>
        )}
        {!loadingState && results && !errorState && (
          <>
            {resultsParams.type === "stream" && (
              <RankedStreamResults
                games={props.games}
                results={results as Array<RankedStreamResult>}
                onSortChange={setSort}
                sort={sort}
                onAddToIgnoreList={setIgnoreListItem}
              />
            )}
            {resultsParams.type === "streamer" && (
              <RankedStreamerResults
                games={props.games}
                results={results as Array<RankedStreamerResult>}
                onSortChange={setSort}
                sort={sort}
                onAddToIgnoreList={setIgnoreListItem}
              />
            )}
          </>
        )}
      </Stack>
      {ignoreListItem && (
        <Dialog
          open
          onClose={() => setIgnoreListItem(null)}
        >
          <DialogContent>
            <Trans
              t={t}
              i18nKey="scouting:ignoreAddConfirmation"
              values={{ channel: ignoreListItem.userName }}
              components={[
                <Link
                  href="/data-collection/ignore"
                  target="_blank"
                />,
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAddToIgnoreList}
              autoFocus
              variant="outlined"
              color="error"
              disabled={addingToIgnoreList}
            >
              {t("scouting:ignoreAdd")}
              {addingToIgnoreList && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  transform="right-6"
                  spin
                />
              )}
            </Button>
            <Button
              onClick={() => setIgnoreListItem(null)}
              color="primary"
              autoFocus
              variant="contained"
              disabled={addingToIgnoreList}
            >
              {t("common:actions.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={addedToIgnoreListResult !== null}
        onClose={() => setAddedToIgnoreListResult(null)}
      >
        <Alert severity={addedToIgnoreListResult === true ? "success" : "error"}>
          {addedToIgnoreListResult === true ? t("scouting:ignoreAddSuccess") : t("scouting:ignoreAddFail")}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ScoutingTool
