import React, { useState } from "react"

import { Grid, Button } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"

import ErrorDialog from "../../../components/Dialogs/ErrorDialog"
import LoadingBox from "../../../components/LoadingBox"
import { StylePaper } from "../../../components/StyleMaterialUI"
import Options from "../../../components/Taiyoro/Analytics/Options"
import AnalyticsTable from "../../../components/Taiyoro/Analytics/Table"
import fetchAnalytics, { AnalyticEntity } from "../../../services/Taiyoro/Analytics"
import type { AnalyticsResponse, ReportEntity } from "../../../services/Taiyoro/Analytics"
import {
  getColumnsFromType,
  getDefaultSortForAnalyticType,
  getEntityFromReport,
  mapResultsToData,
  SortField,
} from "./entities"
import type { SortState } from "./entities"

export const TABLE_IDENTIFIER = "analytics-table"
const ITEMS_PER_PAGE = 30

const EventReports = () => {
  const [analyticsResultState, setAnalyticsResultState] = useState<AnalyticsResponse | null>()
  const [tableResults, setTableResults] = useState([])
  const [tableColumns, setTableColumns] = useState([])

  const [isLoadingState, setIsLoadingState] = useState(false)
  const [errorState, setErrorState] = useState<string | null>(null)
  const [sortState, setSortState] = useState<SortState | null>({ field: SortField.EVENT_NAME, order: "ASC" })
  const [entityState, setEntityState] = useState<AnalyticEntity | ReportEntity>(AnalyticEntity.EVENTS)
  const [startDatetimeState, setStartDatetimeState] = useState<moment.Moment>()
  const [endDatetimeState, setEndDatetimeState] = useState<moment.Moment>()
  const { t } = useTranslation("taiyoro")

  const getAnalytics = async (
    entity: AnalyticEntity | ReportEntity,
    sort: SortState,
    startDatetime: moment.Moment,
    endDatetime: moment.Moment,
    offset: number,
    limit: number
  ) => {
    try {
      setIsLoadingState(true)
      const result = await fetchAnalytics(
        getEntityFromReport(entity),
        sort,
        startDatetime,
        endDatetime,
        offset,
        limit
      )
      setAnalyticsResultState(result)
      setTableResults(mapResultsToData(entity, result))
      setTableColumns(getColumnsFromType(entity))
      setIsLoadingState(false)
      setEntityState(entity)
    } catch (err) {
      setErrorState(JSON.stringify(err))
      setIsLoadingState(false)
    }
  }

  const loadMore = (): void => {
    getAnalytics(
      entityState,
      sortState,
      startDatetimeState,
      endDatetimeState,
      0,
      analyticsResultState.limit + ITEMS_PER_PAGE
    )
  }

  const loadAll = async (sort?) => {
    //Copy our current analytics state and then reset all analytics data to an empty list
    setIsLoadingState(true)

    const newAnalyticsResult = { ...analyticsResultState }
    const keys = ["platforms", "events", "sponsors", "dates", "organizers", "games", "casters"]
    for (let key of keys) {
      newAnalyticsResult[key] = []
    }
    //Query every page, and combine all of the results
    for (let offset = 0; offset <= analyticsResultState.totalCount / ITEMS_PER_PAGE; offset++) {
      const result = await fetchAnalytics(
        getEntityFromReport(entityState),
        sort || sortState,
        startDatetimeState,
        endDatetimeState,
        offset * ITEMS_PER_PAGE,
        ITEMS_PER_PAGE
      )
      for (let key of keys) {
        if (result[key]) {
          newAnalyticsResult[key].push(...result[key])
        }
      }
    }
    setAnalyticsResultState(newAnalyticsResult)
    setTableResults(mapResultsToData(entityState, newAnalyticsResult))
    setIsLoadingState(false)
  }

  const isMaximumLoaded = (): boolean => {
    if (!tableResults) {
      return true
    }
    return tableResults.length === analyticsResultState.totalCount
  }

  const copyToClipboard = () => {
    const table = document.getElementById(TABLE_IDENTIFIER)
    table && navigator.clipboard.writeText(table.innerText).catch((error) => console.log(error))
  }

  const handleQueryChange = (entity, startDatetime, endDatetime) => {
    const sort = { field: getDefaultSortForAnalyticType(entity), order: "DESC" }
    setStartDatetimeState(startDatetime)
    setEndDatetimeState(endDatetime)
    setSortState(sort)
    getAnalytics(entity, sort, startDatetime, endDatetime, 0, ITEMS_PER_PAGE)
  }

  const handleSortChange = (sort: SortState) => {
    setSortState(sort)
    if (isMaximumLoaded()) {
      loadAll(sort)
      return
    }
    getAnalytics(entityState, sort, startDatetimeState, endDatetimeState, 0, analyticsResultState.limit)
  }

  return (
    <>
      <StylePaper>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Options
              onChange={handleQueryChange}
              copyToClipboard={copyToClipboard}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            {analyticsResultState && !isLoadingState && (
              <AnalyticsTable
                fields={tableColumns}
                data={tableResults}
                sort={sortState}
                onSortChange={handleSortChange}
              />
            )}
            {isLoadingState && <LoadingBox />}
            {analyticsResultState && !isLoadingState && !isMaximumLoaded() && (
              <Grid
                container
                justifyContent="flex-start"
                spacing={3}
                style={{ marginTop: "12px" }}
              >
                <Grid item>
                  <Button
                    onClick={() => {
                      loadMore()
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {t("analytics.form.loadMore")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      loadAll()
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {t("analytics.form.loadAll")} {analyticsResultState.totalCount}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <ErrorDialog
          open={errorState !== null}
          message={errorState || ""}
          onClose={() => setErrorState(null)}
        />
      </StylePaper>
    </>
  )
}

export default EventReports
