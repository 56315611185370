import { useContext, useState } from "react"

import { faFloppyDisk } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, Dialog, DialogContent, Fab, Tooltip } from "@mui/material"
import { NotificationContext } from "contexts/notification"
import { useTranslation } from "react-i18next"

import { FeaturedEventsContext } from "../FeaturedEventsProvider"

export const SaveChanges = () => {
  const [saving, setSaving] = useState(false)

  const { pendingApiChangesQueue, reload, timeRanges } = useContext(FeaturedEventsContext)

  const { setNotification } = useContext(NotificationContext)

  const { t } = useTranslation("common")

  const handleProcessApiChangesQueue = async () => {
    setSaving(true)
    try {
      for (const apiChange of pendingApiChangesQueue) {
        await apiChange.action()
      }
      setNotification({
        message: t("dataUpdateSuccess"),
        severity: "success",
      })
      setSaving(false)
      // Reloading after a save is neccessary to turn temporary IDs into real IDs.
      reload()
    } catch (e) {
      setNotification({
        message: JSON.stringify(e),
        severity: "error",
      })
      setSaving(false)
    }
  }

  const allTimeRangesHaveUniqueEvents = !timeRanges.some(
    (timeRange) =>
      Array.from(new Set(timeRange.featuredEvents.map((featuredEvent) => featuredEvent.eventId))).length !==
      timeRange.featuredEvents.length
  )

  const allTimeRangesHaveUniqueOrderValues = !timeRanges.some(
    (timeRange) =>
      Array.from(new Set(timeRange.featuredEvents.map((featuredEvent) => featuredEvent.orderValue)))
        .length !== timeRange.featuredEvents.length
  )

  const errorState = !allTimeRangesHaveUniqueEvents || !allTimeRangesHaveUniqueOrderValues

  return (
    <>
      <Tooltip title={t("actions.save")}>
        <span>
          <Fab
            size="medium"
            color="primary"
            onClick={handleProcessApiChangesQueue}
            disabled={pendingApiChangesQueue.length === 0 || saving || errorState}
            sx={{
              flexShrink: 0,
            }}
          >
            <FontAwesomeIcon icon={faFloppyDisk} />
          </Fab>
        </span>
      </Tooltip>
      <Dialog open={saving}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )
}
