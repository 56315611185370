import { useEffect, useState } from "react"

import { ArticlesTodoContext } from "contexts/articles"
import { fetchArticlesTodo } from "services/Taiyoro/articles"
import { ADMIN_ROLES } from "utils/roles"
import useRolesCanAccess from "utils/useRolesCanAccess"

const ArticlesTodoProvider = ({ children }) => {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(false)
  const isAdmin = useRolesCanAccess(ADMIN_ROLES)

  const reload = async () => {
    setLoading(true)
    fetchArticlesTodo()
      .then(setArticles)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    isAdmin && reload()
  }, [])

  return (
    <ArticlesTodoContext.Provider
      value={{
        articles,
        loading,
        reload,
      }}
    >
      {children}
    </ArticlesTodoContext.Provider>
  )
}

export default ArticlesTodoProvider
