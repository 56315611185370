import type { RefObject } from "react"
import React, { useContext, useEffect, useRef } from "react"

import { Box, Stack, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Editor } from "@toast-ui/react-editor"
import MediaUpload from "components/Taiyoro/MediaUpload"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import type { Settings } from "services/Taiyoro/Pickem/settings"

export const PrizeSettings = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  const { settings, setSettings } = useContext(PickemSettingsContext)

  const {
    palette: { mode },
  } = useTheme()

  const editorBannerRefJa = useRef<Editor | null>(null)
  const editorBannerRefEn = useRef<Editor | null>(null)
  const editorPageRefJa = useRef<Editor | null>(null)
  const editorPageRefEn = useRef<Editor | null>(null)

  const handleBlur = (ref: RefObject<Editor>, name: keyof Settings) => {
    const editor = ref.current

    if (editor) {
      const instance = editor.getInstance()
      const value = instance.getMarkdown()

      setSettings((prevSettings) => {
        const updatedSettings: Partial<Settings> = {
          [name]: value || "",
        }
        return { ...prevSettings, ...updatedSettings }
      })
    }
  }

  const handleAddImage = (
    field: keyof Pick<Settings, "prizeBannerImageUrl" | "prizePageImageUrl">,
    url: string
  ) => {
    setSettings((prevSettings) => {
      const updatedSettings: Partial<Settings> = {
        [field]: url,
      }
      return { ...prevSettings, ...updatedSettings }
    })
  }

  useEffect(() => {
    if (editorBannerRefJa.current && settings.prizeBannerCopyJa) {
      const editor = editorBannerRefJa.current.getInstance()
      editor.setMarkdown(settings.prizeBannerCopyJa)
    }
  }, [settings.prizeBannerCopyJa])

  useEffect(() => {
    if (editorBannerRefEn.current && settings.prizeBannerCopy) {
      const editor = editorBannerRefEn.current.getInstance()
      editor.setMarkdown(settings.prizeBannerCopy)
    }
  }, [settings.prizeBannerCopy])

  useEffect(() => {
    if (editorPageRefJa.current && settings.prizePageCopyJa) {
      const editor = editorPageRefJa.current.getInstance()
      editor.setMarkdown(settings.prizePageCopyJa)
    }
  }, [settings.prizePageCopyJa])

  useEffect(() => {
    if (editorPageRefEn.current && settings.prizePageCopy) {
      const editor = editorPageRefEn.current.getInstance()
      editor.setMarkdown(settings.prizePageCopy)
    }
  }, [settings.prizePageCopy])

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h5"
        component="h3"
        mt={3}
        mb={3}
      >
        {t("eventPickem.prizes.subtitle")}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        mt={2}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            component="h4"
            mb={2}
          >
            {t("eventPickem.prizes.prizeBannerJa")}
          </Typography>
          <Editor
            initialValue={settings.prizeBannerCopyJa}
            height="200px"
            initialEditType="markdown"
            useCommandShortcut={true}
            theme={mode}
            toolbarItems={[["heading", "bold", "italic", "link", "quote", "ul", "ol", "table"]]}
            language={language}
            previewStyle={"tab"}
            ref={editorBannerRefJa}
            onBlur={() => handleBlur(editorBannerRefJa, "prizeBannerCopyJa")}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            component="h4"
            mb={2}
          >
            {t("eventPickem.prizes.prizeBannerEn")}
          </Typography>
          <Editor
            initialValue={settings.prizeBannerCopy}
            height="200px"
            initialEditType="markdown"
            useCommandShortcut={true}
            theme={mode}
            toolbarItems={[["heading", "bold", "italic", "link", "quote", "ul", "ol", "table"]]}
            language={language}
            previewStyle={"tab"}
            ref={editorBannerRefEn}
            onBlur={() => handleBlur(editorBannerRefEn, "prizeBannerCopy")}
          />
        </Box>
      </Stack>
      <Stack
        spacing={2}
        mt={2}
        width={"50%"}
      >
        <Typography
          variant="h6"
          component="h4"
          mb={2}
        >
          {t("eventPickem.prizes.prizeBannerImage")}
        </Typography>
        <MediaUpload
          key="pickem-prize-banner"
          url={settings.prizeBannerImageUrl}
          mediaType="pickem_prize_asset"
          onChange={(url: string) => handleAddImage("prizeBannerImageUrl", url)}
          editable
          buttonText={t("eventPickem.prizes.upload")}
          aspect={4 / 1}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        mt={2}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            component="h4"
            mb={2}
          >
            {t("eventPickem.prizes.prizePageJa")}
          </Typography>
          <Editor
            initialValue={settings.prizePageCopyJa}
            height="200px"
            initialEditType="markdown"
            useCommandShortcut={true}
            theme={mode}
            toolbarItems={[["heading", "bold", "italic", "link", "quote", "ul", "ol", "table"]]}
            language={language}
            previewStyle={"tab"}
            ref={editorPageRefJa}
            onBlur={() => handleBlur(editorPageRefJa, "prizePageCopyJa")}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            component="h4"
            mb={2}
          >
            {t("eventPickem.prizes.prizePageEn")}
          </Typography>
          <Editor
            initialValue={settings.prizePageCopy}
            height="200px"
            initialEditType="markdown"
            useCommandShortcut={true}
            theme={mode}
            toolbarItems={[["heading", "bold", "italic", "link", "quote", "ul", "ol", "table"]]}
            language={language}
            previewStyle={"tab"}
            ref={editorPageRefEn}
            onBlur={() => handleBlur(editorPageRefEn, "prizePageCopy")}
          />
        </Box>
      </Stack>
      <Stack
        spacing={2}
        mt={2}
        width={"50%"}
      >
        <Typography
          variant="h6"
          component="h4"
          mb={2}
        >
          {t("eventPickem.prizes.prizePageImage")}
        </Typography>
        <MediaUpload
          key="pickem-prize-page"
          url={settings.prizePageImageUrl}
          mediaType="pickem_prize_asset"
          onChange={(url: string) => handleAddImage("prizePageImageUrl", url)}
          editable
          buttonText={t("eventPickem.prizes.upload")}
          aspect={2.4 / 1}
        />
      </Stack>
    </Box>
  )
}
