import { type ChangeEvent, useMemo } from "react"

import { faClose } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material"
import type { Player, Team } from "models/Taiyoro/Meta/Placement"
import { useTranslation } from "react-i18next"
import type { ParticipantAnswerOptions } from "services/Taiyoro/Pickem/daily"

interface Props {
  value: ParticipantAnswerOptions
  updateValue: (value: ParticipantAnswerOptions) => void
  updateStatus?: () => void
  availableParticipants: Map<string, (Player & { type: "player" }) | (Team & { type: "team" })>
}

export const ParticipantAnswerOptionsControl = ({
  value,
  updateValue,
  updateStatus,
  availableParticipants,
}: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  const menuItems = useMemo(
    () =>
      Array.from(availableParticipants.keys()).map((participantKey) => {
        const participant = availableParticipants.get(participantKey)
        if (!participant) return null

        const existingIds = value.data.map((participant) => participant.id)
        if (existingIds.includes(participantKey)) return null

        return (
          <MenuItem
            key={participantKey}
            value={participantKey}
          >
            {language === "ja" && participant.nameJa && participant.nameJa !== ""
              ? participant.nameJa
              : participant.name}
          </MenuItem>
        )
      }),
    [availableParticipants, value, language]
  )

  const addParticipant = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const participant = availableParticipants.get(event.target.value)
    if (!participant) return
    const newValue = { ...value }
    newValue.data.push({ id: event.target.value, type: participant.type })
    updateValue(newValue)
    updateStatus?.()
  }

  const removeParticipant = ({ id }: { id: string }) => {
    const newValue = { ...value }
    newValue.data = value.data.filter((participant) => participant.id !== id)
    updateValue(newValue)
    updateStatus?.()
  }

  return (
    <Stack
      gap={2}
      mt={2}
    >
      <TextField
        select
        fullWidth
        value=""
        label={t("eventPickem.daily.modal.answers.pickTeam")}
        onChange={addParticipant}
      >
        {menuItems}
      </TextField>
      {value.data.length > 0 && (
        <Stack
          component="ul"
          gap={1}
          sx={{ listStyle: "disc", pl: 2, m: 0 }}
        >
          {value.data.map((participant) => {
            const participantData = availableParticipants.get(participant.id)
            if (!participantData) return null

            return (
              <li key={participant.id}>
                <Stack
                  direction="row"
                  key={participant.id}
                  gap={1}
                >
                  <Typography>
                    {language === "ja" && participantData.nameJa && participantData.nameJa !== ""
                      ? participantData.nameJa
                      : participantData.name}
                  </Typography>
                  <Button
                    size="small"
                    color="error"
                    aria-label="remove"
                    onClick={() => {
                      removeParticipant({ id: participant.id })
                      updateStatus?.()
                    }}
                    sx={{
                      alignSelf: "stretch",
                      flexShrink: 0,
                      minWidth: "24px",
                      marginLeft: "auto",
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                </Stack>
              </li>
            )
          })}
        </Stack>
      )}
    </Stack>
  )
}
