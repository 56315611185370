import { useContext } from "react"

import {
  faEdit,
  faRotateRight,
  faTrashCan,
  faDown,
  faUp,
  faCirclePlus,
  faFolderOpen,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip, Stack, Tooltip, Typography } from "@mui/material"
import { green, red, blue, orange, brown, pink, cyan, purple, lime, grey } from "@mui/material/colors"
import { DataGrid, GridActionsCellItem, type GridColumns } from "@mui/x-data-grid"
import { PickemFunctionsContext, PickemModalContext, PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import { type DailyPickemGroupStatus, type DailyPickemGroupAny } from "services/Taiyoro/Pickem/daily"

interface Props {
  groups: Array<DailyPickemGroupAny> | undefined
}

export const groupColors: Array<string> = [
  green[400],
  red[400],
  blue[400],
  orange[400],
  purple[400],
  brown[400],
  cyan[400],
  pink[400],
  lime[400],
  grey[400],
]

export const GroupSection = ({ groups }: Props) => {
  const { t } = useTranslation("taiyoro")

  const { loading, dailyPickemList } = useContext(PickemSettingsContext)

  const { editGroup, deleteGroup, restoreGroup, moveGroupUp, moveGroupDown } =
    useContext(PickemFunctionsContext)

  const { setSelectedDailyPickem, setPrefilledData, setIsPickemModalOpen } = useContext(PickemModalContext)

  const hasGroups = groups !== undefined && groups.length > 0

  const columns: GridColumns<DailyPickemGroupAny> = [
    {
      flex: 2,
      field: "status",
      sortable: true,
      headerName: t("eventPickem.daily.groupTableHeaders.status"),
      renderCell: (params) => {
        const status = params.value as DailyPickemGroupStatus
        const statusToColor: Record<
          DailyPickemGroupStatus,
          "success" | "warning" | "info" | "error" | "default"
        > = {
          saved: "default",
          modified: "warning",
          new: "success",
          deletedFromSaved: "error",
          deletedFromModified: "error",
        }
        return (
          <Tooltip title={t(`eventPickem.daily.status.${status}`)}>
            <Chip
              label={t(`eventPickem.daily.status.${status}`)}
              color={statusToColor[status]}
              size="small"
              sx={{ fontSize: "0.75rem" }}
            />
          </Tooltip>
        )
      },
    },
    {
      flex: 5,
      field: "nameJa",
      sortable: true,
      headerName: t("eventPickem.daily.groupTableHeaders.nameJa"),
      renderCell: (params) => {
        const name = params.value as string
        return (
          <Tooltip title={name}>
            <>
              <FontAwesomeIcon
                color={groupColors[params.row.priority]}
                icon={faFolderOpen}
                style={{ marginRight: "0.5em" }}
              />
              <Typography sx={{ fontSize: "0.875rem" }}>{name}</Typography>
            </>
          </Tooltip>
        )
      },
    },
    {
      flex: 5,
      field: "name",
      sortable: true,
      headerName: t("eventPickem.daily.groupTableHeaders.name"),
      renderCell: (params) => {
        const name = params.value as string
        return (
          <Tooltip title={name}>
            <>
              <FontAwesomeIcon
                color={groupColors[params.row.priority]}
                icon={faFolderOpen}
                style={{ marginRight: "0.5em" }}
              />
              <Typography sx={{ fontSize: "0.875rem" }}>{name}</Typography>
            </>
          </Tooltip>
        )
      },
    },
    {
      flex: 2,
      field: "priority",
      sortable: true,
      headerName: t("eventPickem.daily.groupTableHeaders.priority"),
      renderCell: (params) => {
        const priority = params.value as number
        return (
          <Tooltip title={priority}>
            <Typography sx={{ fontSize: "0.875rem" }}>{priority}</Typography>
          </Tooltip>
        )
      },
    },
    {
      flex: 2,
      field: "actions",
      sortable: false,
      type: "actions",
      headerName: t("eventPickem.daily.groupTableHeaders.actions"),
      minWidth: 65,
      getActions: (params) => [
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.actions.moveDown")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faDown}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => moveGroupDown(params.row.id)}
          label={t("eventPickem.daily.actions.moveDown")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.actions.moveUp")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faUp}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => moveGroupUp(params.row.id)}
          label={t("eventPickem.daily.actions.moveUp")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.newPickemForGroup")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                color={green[400]}
                icon={faCirclePlus}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => {
            const highestPriorityInGroup = dailyPickemList
              .filter((pickem) => pickem.groupId === params.row.id)
              .reduce((acc, curr) => (curr.priority !== null && curr.priority > acc ? curr.priority : acc), 0)

            setSelectedDailyPickem(null)
            setPrefilledData({
              dateId: groups ? groups[0].dateId : undefined,
              groupId: params.row.id,
              priority: highestPriorityInGroup + 1,
            })
            setIsPickemModalOpen(true)
          }}
          label={t("eventPickem.daily.newPickemForGroup")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.actions.edit")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => editGroup(params.row.id)}
          label={t("eventPickem.daily.actions.edit")}
        />,
        params.row.status !== "deletedFromSaved" && params.row.status !== "deletedFromModified" ? (
          <GridActionsCellItem
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...({} as any)}
            icon={
              <Tooltip
                title={t("eventPickem.daily.actions.delete")}
                enterDelay={0}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  style={{ fontSize: "1.2rem", color: "red" }}
                />
              </Tooltip>
            }
            onClick={() => deleteGroup(params.row.id)}
            label={t("eventPickem.daily.actions.delete")}
          />
        ) : (
          <GridActionsCellItem
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...({} as any)}
            icon={
              <Tooltip
                title={t("eventPickem.daily.actions.restore")}
                enterDelay={0}
              >
                <FontAwesomeIcon
                  icon={faRotateRight}
                  style={{ fontSize: "1.2rem", color: "limegreen" }}
                />
              </Tooltip>
            }
            onClick={() => restoreGroup(params.row.id)}
            label={t("eventPickem.daily.actions.restore")}
          />
        ),
      ],
    },
  ]

  return (
    <Stack gap={1}>
      <Typography
        component="h4"
        sx={{ fontWeight: "bold" }}
      >
        {t("eventPickem.daily.groups")}
      </Typography>
      {!hasGroups && <Typography sx={{ opacity: 0.75 }}>{t("eventPickem.daily.noGroups")}</Typography>}
      {hasGroups && (
        <>
          <DataGrid
            rows={groups}
            columns={columns}
            disableColumnSelector
            disableColumnMenu
            disableVirtualization
            autoHeight={true}
            getRowHeight={() => "auto"}
            density="comfortable"
            loading={loading}
            rowCount={groups.length}
            hideFooter
            initialState={{
              sorting: {
                sortModel: [{ field: "priority", sort: "asc" }],
              },
            }}
          />
        </>
      )}
    </Stack>
  )
}
