const useEvenSizedPages = (itemsCount: number, pageSizeLimit: number) => {
  const numberOfPages = Math.ceil(itemsCount / pageSizeLimit)
  const itemsPerPage = Math.floor(itemsCount / numberOfPages)
  const remainder = itemsCount % numberOfPages

  const pages = []

  for (let i = 0; i < numberOfPages; i++) {
    if (remainder > i) {
      pages[i] = itemsPerPage + 1
      continue
    }

    pages[i] = itemsPerPage
  }
  return pages
}

export default useEvenSizedPages
