import { useEffect, useRef, useState } from "react"

import { faTwitch, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight, faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Stack,
  TextField,
  Button,
  List,
  CircularProgress,
  Alert,
  ListItem,
  Link as MuiLink,
  Typography,
  Select,
  MenuItem,
} from "@mui/material"
import i18n from "i18next"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
  type ClipsJobStatus,
  getHighlightsJobs,
  type HighlightJobType,
  scheduleHighlightsJob,
} from "services/Taiyoro/dataCollection"

const getExternalLinkForJob = (clipsJob: ClipsJobStatus) => {
  if (clipsJob.type === "twitch") {
    return `https://twitch.tv/videos/${clipsJob.vodId}`
  }
  return `https://youtube.com/watch?v=${clipsJob.vodId}`
}

const twitchOrYouTubeVideoIdPattern = /^[a-zA-Z0-9_-]+$/

export const ChatDensityRequestlist = () => {
  const [vodId, setVodId] = useState("")
  const [type, setType] = useState<HighlightJobType>("twitch")
  const [loading, setLoading] = useState(false)
  const [polling, setPolling] = useState(false)
  const [error, setError] = useState(false)
  const [clipsJobs, setClipsJobs] = useState<Array<ClipsJobStatus>>([])
  const pollingInterval = useRef<NodeJS.Timeout | null>(null)
  const pollingStart = useRef<moment.Moment | null>(null)

  const { t } = useTranslation(["data-collection", "common"])

  const fetchData = () => {
    setLoading(true)
    getHighlightsJobs()
      .then((response) => {
        setClipsJobs(response)
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }

  const requestData = async () => {
    setPolling(true)
    pollingStart.current = moment()
    const schedule = await scheduleHighlightsJob(vodId, type)
    if (schedule.status === "failed") {
      setError(true)
      setPolling(false)
      return
    }
    setClipsJobs((oldClipsJobs) => [schedule, ...oldClipsJobs])
    pollingInterval.current = setInterval(() => {
      getHighlightsJobs()
        .then((response) => {
          setClipsJobs(response)
          const statusConfirmed = response.some(
            (job) => job.vodId === vodId && ["success", "failed"].includes(job.status)
          )
          const lambdaTimeout = moment().diff(pollingStart.current, "minutes") >= 15
          if (statusConfirmed || lambdaTimeout) {
            setPolling(false)
            pollingInterval.current && clearInterval(pollingInterval.current)
            pollingStart.current = null
          }
        })
        .catch(() => {
          setError(true)
          setPolling(false)
          pollingInterval.current && clearInterval(pollingInterval.current)
          pollingStart.current = null
        })
    }, 10000)
  }

  useEffect(() => {
    fetchData()
    return () => {
      pollingInterval.current && clearInterval(pollingInterval.current)
    }
  }, [])

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
      >
        <TextField
          label={t("chatDensity.vodId")}
          value={vodId}
          onChange={(event) => setVodId(event.target.value)}
          disabled={polling}
          autoComplete="off"
        />
        <Select
          value={type}
          onChange={(event) => setType(event.target.value as HighlightJobType)}
        >
          <MenuItem value="youtube">YouTube</MenuItem>
          <MenuItem value="twitch">Twitch</MenuItem>
        </Select>
        <Button
          variant="contained"
          onClick={() => void requestData()}
          disabled={polling || vodId === "" || !twitchOrYouTubeVideoIdPattern.test(vodId)}
        >
          {t("chatDensity.retrieve")}
        </Button>
        {polling && <CircularProgress />}
      </Stack>
      {loading && <CircularProgress />}
      {error && !loading && <Alert severity="error">{t("common:errorLoadingData")}</Alert>}
      {!loading && !error && (
        <List>
          {clipsJobs
            .sort((a, b) => b.datetime.localeCompare(a.datetime))
            .map((clipsJob) => (
              <ListItem key={clipsJob.vodId}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                >
                  <MuiLink
                    href={getExternalLinkForJob(clipsJob)}
                    target="_blank"
                  >
                    <Stack
                      direction="row"
                      gap={0.5}
                      alignItems="center"
                    >
                      {clipsJob.type === "twitch" && (
                        <FontAwesomeIcon
                          icon={faTwitch}
                          color="#9146FF"
                        />
                      )}
                      {clipsJob.type === "youtube" && (
                        <FontAwesomeIcon
                          icon={faYoutube}
                          color="#FF0000"
                        />
                      )}
                      <Typography>
                        {clipsJob.type === "twitch" ? ".tv" : ".com"}/{clipsJob.vodId}
                      </Typography>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Stack>
                  </MuiLink>
                  <Typography>{moment.utc(clipsJob.datetime).locale(i18n.language).fromNow()}</Typography>
                  <Typography>{clipsJob.status}</Typography>
                  {clipsJob.status === "success" && (
                    <Link to={`/data-collection/chat-density/${clipsJob.type}/${clipsJob.vodId}`}>
                      <Button
                        variant="contained"
                        size="small"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                        >
                          {t("chatDensity.seeData")}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Stack>
                      </Button>
                    </Link>
                  )}
                </Stack>
              </ListItem>
            ))}
        </List>
      )}
    </Stack>
  )
}
