import React from "react"

import { ThemeProvider, createTheme } from "@mui/material/styles"
import { StyledEngineProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { NotificationContext } from "contexts/notification"

import { ColorModeContext } from "./themeContext"

const ProviderComposer = ({ contexts, children }) => {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  )
}

const ContextProvider = ({ children }) => {
  const [mode, setMode] = React.useState(localStorage.getItem("theme") || "dark")

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((oldMode) => {
          const newMode = oldMode === "light" ? "dark" : "light"
          localStorage.setItem("theme", newMode)
          return newMode
        })
      },
    }),
    []
  )

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: localStorage.getItem("theme") || "dark",
        },
        typography: {
          fontFamily: `"Noto Sans JP", "Roboto", "Helvetica", "Arial", sans-serif`,
        },
      }),
    [mode]
  )

  const [notification, setNotification] = React.useState(null)

  return (
    <ProviderComposer
      contexts={[
        <LocalizationProvider dateAdapter={AdapterMoment} />,
        <StyledEngineProvider injectFirst />,
        <ColorModeContext.Provider value={colorMode} />,
        <ThemeProvider theme={theme}></ThemeProvider>,
        <NotificationContext.Provider value={{ notification, setNotification }} />,
      ]}
    >
      {children}
    </ProviderComposer>
  )
}

export { ContextProvider }
