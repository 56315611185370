import type Organizer from "../../models/Taiyoro/Meta/Organizer"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"
export type OrganizersResponse = {
  data?: {
    admin?: {
      organizer?: {
        get?: Array<Organizer>
      }
    }
  }
}
export const fetchOrganizers = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          organizer {
            get {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as OrganizersResponse
    return response.data?.admin?.organizer?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch organizers")
  }
}
export type OrganizerResponse = {
  data?: {
    admin?: {
      organizer?: {
        get?: Organizer | null
      }
    }
  }
}
export const fetchOrganizer = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          organizer {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as OrganizerResponse
    return response.data?.admin?.organizer?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error", err)
    throw new Error("Failed to fetch organizer")
  }
}
export type CreateOrganizerResponse = {
  data?: {
    admin?: {
      organizer?: {
        save?: Organizer | null
      }
    }
  }
}
export const createOrganizer = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  isGameDeveloper?: boolean | null,
  id?: string | null
) => {
  const organizerInput = [
    ...(id ? [`id: "${id}"`] : []),
    `name: "${name}"`,
    `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
    `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
    isGameDeveloper !== undefined ? `isGameDeveloper: ${isGameDeveloper ? 1 : 0}` : null,
  ].join(", ")

  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          organizer {
            save (organizer: {
              ${organizerInput}
            }) {
              id
              name
              nameJa
              sortJa
              isGameDeveloper
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateOrganizerResponse

    const savedOrganizer = response.data?.admin?.organizer?.save

    if (savedOrganizer) {
      savedOrganizer.isGameDeveloper = savedOrganizer.isGameDeveloper === 1
    }

    return response.data?.admin?.organizer?.save ?? null
  } catch (err) {
    console.error(
      "Error while creating organizer:",
      err instanceof Error ? err.message : "Unexpected error",
      err
    )
    throw new Error("Failed to create organizer")
  }
}
export type DeleteGameGroupResponse = {
  data?: {
    admin?: {
      organizer?: {
        delete: Organizer
      }
    }
  }
}
export const deleteOrganizer = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        organizer {
          delete (
              organizerId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteGameGroupResponse
    return response.data?.admin?.organizer?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete organizer")
  }
}
