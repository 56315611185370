import { Route, Switch } from "react-router-dom"

import EventReport from "./EventReport"
import PlayDataEventReportDataProvider from "./provider"

const PlayDataPuppeteer = () => {
  return (
    <PlayDataEventReportDataProvider>
      <Switch>
        <Route
          exact
          path="/playdata/generate"
          component={EventReport}
        />
      </Switch>
    </PlayDataEventReportDataProvider>
  )
}

export default PlayDataPuppeteer
