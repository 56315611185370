import { styled, Typography, Stack } from "@mui/material"

export const Wrapper = styled(Stack)`
  width: 100%;
  height: 100%;
  padding: 8px;
`

export const Text = styled(Typography)`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`
