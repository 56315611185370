/* eslint-disable no-use-before-define */
import React, { useState } from "react"

import { faSpinner, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { withStyles } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"
import { useTranslation } from "react-i18next"
import { useDebounce } from "react-use"

import { countriesIncludingInternational, internationalOption } from "../../constants/countries"
import { editEvent } from "../../services/Taiyoro/event"

export const countryToFlag = (isoCode: string) => {
  if (isoCode === internationalOption.code) {
    return "🌎"
  }
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
})

const DirtyDetectingTextField = withStyles({
  root: {
    "&.Updated": {
      "& label": {
        color: "green",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "green",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "green",
        },
        "&:hover fieldset": {
          borderColor: "green",
        },
        "&.Mui-focused fieldset": {
          borderColor: "green",
        },
      },
    },
  },
})(TextField)

interface Props {
  value
  label
  eventId
}

export default function CountrySelect(props: Props) {
  const [valueState, setValueState] = useState(props.value)
  const [updatingState, setUpdatingState] = useState(false)
  const [successState, setSuccessState] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const { t } = useTranslation("taiyoro")

  const label = (
    <React.Fragment>
      <span>{props.label} &nbsp;</span>
      {!successState && updatingState && (
        <FontAwesomeIcon
          icon={faSpinner}
          spin
        />
      )}
      {successState && !updatingState && <FontAwesomeIcon icon={faCheck} />}
      {errorState && !updatingState && <FontAwesomeIcon icon={faTimes} />}
    </React.Fragment>
  )

  const update = (value) => {
    editEvent(props.eventId, "origin", value)
      .then((response) => {
        setSuccessState(true)
        setUpdatingState(false)
      })
      .catch((error) => {
        console.error(error)
        setErrorState(true)
        setUpdatingState(false)
      })
  }

  const classes = useStyles()

  useDebounce(
    () => {
      valueState !== props.value && update(valueState)
    },
    700,
    [valueState]
  )

  return (
    <Autocomplete
      id="country-select"
      style={{ width: 300 }}
      options={countriesIncludingInternational}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      value={countriesIncludingInternational.find((c) => c.code === valueState)}
      getOptionLabel={(option: any) => option.label}
      // renderOption={(option) => (
      //   <React.Fragment>
      //     <span>{countryToFlag(option.)}</span>
      //     {option.label} ({option.code})
      //   </React.Fragment>
      // )}
      onChange={(e, country: any) => {
        setUpdatingState(true)
        setSuccessState(false)
        setErrorState(false)
        const nullableCountry = country ? country.code : "JP"
        setValueState(nullableCountry)
      }}
      renderInput={(params) => (
        <DirtyDetectingTextField
          {...params}
          label={label}
          placeholder={t("edit.origin.chooseCountry")}
          variant="outlined"
          className={props.value !== valueState ? "Updated" : ""}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}
