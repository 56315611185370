import React, { useContext } from "react"

import { Grid } from "@mui/material"
import { EventContext } from "contexts/event"
import type { EventGame } from "models/Taiyoro/eventGame"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { deleteEventGame, saveEventGame } from "services/Taiyoro/eventGame"
import { localisedLabel } from "utils/i18n"

import DropdownSearchable from "../../../Form/DropdownSearchable"
import { StyleTypography } from "../../../StyleMaterialUI"
import { HelperText } from "../Information/styles"
import EventGameEntry from "./EventGameEntry"

const EventGames = (props) => {
  const { t } = useTranslation("taiyoro")

  const [event, setEvent] = useContext(EventContext)

  const onCreate = (model: EventGame) => {
    if (props.onSave(() => saveEventGame(event.id, model))) {
      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(event)), event)
      newEvent.games = [...event.games, model]
      setEvent(newEvent)
    }
  }

  const onDelete = (id: string) => {
    if (props.onDelete(id, () => deleteEventGame(event.id, id))) {
      const newEvent = Object.assign(Object.create(Object.getPrototypeOf(event)), event)
      newEvent.games = event.games.filter((g) => g.gameId !== id)
      setEvent(newEvent)
    }
  }

  return (
    <Grid
      container
      style={{ marginBottom: "15px", marginTop: "15px" }}
    >
      <Grid
        item
        xs={12}
      >
        <StyleTypography
          variant="h5"
          mb="12px"
        >
          {t("edit.games.title")}
        </StyleTypography>

        {event.games.map((model) => (
          <EventGameEntry
            key={`game-${model.gameId}`}
            eventPublishedState={props.loadedModel.published}
            eventId={event.id}
            gameName={localisedLabel(props.availableGames.find((game) => game.id === model.gameId))}
            model={model}
            onDelete={(gameId) => onDelete(gameId)}
            availableCurrencies={props.availableCurrencies}
            availablePlayers={props.availablePlayers}
            availableTeams={props.availableTeams}
            availablePlacements={props.availablePlacements}
          />
        ))}

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={7}
          >
            <DropdownSearchable
              id="eventGamesAddGameSelect"
              label={t("edit.games.addGame")}
              clearOnBlur
              options={props.availableGames.filter((g) => !event.games.some((g2) => g.id === g2.gameId))}
              onChange={(value) => {
                if (!value) {
                  return
                }
                onCreate({
                  gameId: value.id,
                  name: value.name,
                  participants: {
                    players: [],
                    teams: [],
                  },
                  prizePool: null,
                  resultsUrl: null,
                  currency: "JPY",
                  participantCount: null,
                })
                document.getElementById("eventGamesAddGameSelect").blur()
              }}
              createFunc={props.onCreateGame}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <HelperText>{t("edit.help.games")}</HelperText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

EventGames.propTypes = {
  models: PropTypes.array,
  onChange: PropTypes.func,
  teams: PropTypes.array,
  games: PropTypes.array,
  players: PropTypes.array,
  currencies: PropTypes.array,

  loadedModel: PropTypes.any,
  availableCurrencies: PropTypes.any,
  availableGames: PropTypes.any,
  availablePlayers: PropTypes.any,
  availableTeams: PropTypes.any,
  availablePlacements: PropTypes.any,
  onSave: PropTypes.any,
  onUpdate: PropTypes.any,
  onDelete: PropTypes.any,
  onCreateGame: PropTypes.any,
}

export default EventGames
