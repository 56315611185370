import React, { useState } from "react"

import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Stack } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { deleteEvent } from "../../../services/Taiyoro/event"

interface Props {
  open: boolean
  onClose: () => void
  model: {
    id: string
    name?: string
  } | null
}

const DeleteEventDialog = ({ open, onClose, model }: Props) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation(["taiyoro", "common"])

  const history = useHistory()

  const handleDelete = async () => {
    setLoading(true)
    const success = await deleteEvent(model.id)
    setLoading(false)
    if (!success) {
      return
    }
    history.push(`/taiyoro/event/list`)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t("dialogs.delete.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("dialogs.delete.message1")}
          {model && model.name}? {t("dialogs.delete.message2")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDelete}
          autoFocus
          variant="outlined"
          color="error"
          disabled={loading}
        >
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
            height="100%"
          >
            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
              />
            )}
            <>{t("common:actions.delete")}</>
          </Stack>
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          autoFocus
          variant="contained"
        >
          {t("common:actions.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteEventDialog.propTypes = {
  open: PropTypes.bool,
  model: PropTypes.object,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
}

export default DeleteEventDialog
