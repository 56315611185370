import { useContext, useState } from "react"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { EventContext } from "../../../../contexts/event"
import { RegistrationType } from "../../../../models/Taiyoro/event"
import { editEvent } from "../../../../services/Taiyoro/event"
import { StyleTypography } from "../../../StyleMaterialUI"
import RealTimeUpdateField from "../../RealTimeUpdateField"

const Registration = () => {
  const { t } = useTranslation("taiyoro")

  const [event] = useContext(EventContext)

  const [expandedState, setExpandedState] = useState(event.registrationEnabled === 1)

  const typeChoices = [
    {
      id: RegistrationType.Player,
      name: t("edit.registration.types.player"),
    },
    {
      id: RegistrationType.Team,
      name: t("edit.registration.types.team"),
    },
  ]

  const [typeValue, setTypeValue] = useState(
    typeChoices.find((typeChoice) => typeChoice.id === event.registrationType)
  )

  return (
    <>
      <StyleTypography
        variant="h5"
        mt="24px"
        mb="12px"
      >
        {t("edit.registration.title")}
      </StyleTypography>
      <Accordion
        expanded={expandedState}
        onChange={(_event, expanded) => setExpandedState(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box onClick={(e) => e.stopPropagation()}>
            <RealTimeUpdateField
              type="switch"
              label={t("edit.registration.display")}
              updateFunc={(value) => {
                setExpandedState(value === 1)
                editEvent(event.id, "registrationEnabled", value)
              }}
              initialValue={event.registrationEnabled}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb="24px">
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <RealTimeUpdateField
                  type="datetime-local"
                  label={t("edit.registration.open")}
                  variant="standard"
                  updateFunc={(value) => {
                    const clearableStartMoment =
                      value && value.length > 0
                        ? moment(value, "YYYY/MM/DDTHH:mm").clone().utc().format("YYYY-MM-DDTHH:mm:ss")
                        : null
                    return editEvent(event.id, "registrationStartDatetime", clearableStartMoment)
                  }}
                  initialValue={
                    event.registrationStartDatetime
                      ? event.registrationStartDatetime.format("YYYY-MM-DDTHH:mm")
                      : null
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <RealTimeUpdateField
                  type="datetime-local"
                  variant="standard"
                  label={t("edit.registration.close")}
                  updateFunc={(value) => {
                    const clearableEndMoment =
                      value && value.length > 0
                        ? moment(value, "YYYY/MM/DDTHH:mm").clone().utc().format("YYYY-MM-DDTHH:mm:ss")
                        : null
                    return editEvent(event.id, "registrationEndDatetime", clearableEndMoment)
                  }}
                  initialValue={
                    event.registrationEndDatetime
                      ? event.registrationEndDatetime.format("YYYY-MM-DDTHH:mm")
                      : null
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb="24px">
            <RealTimeUpdateField
              type="text"
              variant="standard"
              label={t("edit.registration.url")}
              updateFunc={(value) => editEvent(event.id, "registrationUrl", value)}
              initialValue={event.registrationUrl}
            />
          </Box>
          <Box mb="12px">
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <RealTimeUpdateField
                  type="select"
                  variant="standard"
                  label={t("edit.registration.types.label")}
                  disableClearable
                  updateFunc={(type) => {
                    editEvent(event.id, "registrationType", type.id, true)
                    setTypeValue(type)
                  }}
                  initialValue={typeValue}
                  options={typeChoices}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <RealTimeUpdateField
                  type="number-format"
                  variant="standard"
                  label={t("edit.registration.limit")}
                  updateFunc={(value) => editEvent(event.id, "registrationLimit", parseInt(value))}
                  initialValue={event.registrationLimit}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Registration
