import { useContext, useEffect, useState } from "react"

import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Card, CardContent, CardMedia, Skeleton, Stack, Tooltip, Typography } from "@mui/material"
import Property from "components/Taiyoro/EventList/EventInfo/Property"
import { NotificationContext } from "contexts/notification"
import { useTranslation } from "react-i18next"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from "react-window"
import { fetchTwitchClipsForEvent } from "services/Taiyoro/event"
import type { TwitchClip } from "services/Taiyoro/event"
import { UTCDateInJST } from "utils/tools"

interface Props {
  eventId: string
}

const TWITCH_EMBED_MIN_SIZE = {
  width: "533.33px",
  height: "300px",
}

const getStandardDeviation = (values: Array<number>) => {
  const n = values.length
  const mean = values.reduce((a, b) => a + b) / n
  return Math.sqrt(values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

const TrendingArrows = ({ score }: { score: number }) => {
  const { t } = useTranslation(["taiyoro", "common"])

  if (score < 0) {
    return <></>
  }

  const roundedScore = Math.ceil(score)

  return (
    <Tooltip title={t("list.summary.clipAboveAverage")}>
      <Stack
        color="success.main"
        gap={0.5}
        direction="row"
      >
        {Array.from("x".repeat(roundedScore)).map((_arrow, index) => (
          <FontAwesomeIcon
            key={index}
            icon={faArrowUp}
            color="inherit"
            size="xs"
          />
        ))}
      </Stack>
    </Tooltip>
  )
}

type ScoredTwitchClip = TwitchClip & {
  zScore: number
}

export const TwitchClips = (props: Props) => {
  const [loading, setLoading] = useState(true)

  const { setNotification } = useContext(NotificationContext)

  const { t, i18n } = useTranslation("taiyoro")

  const [scoredTwitchClips, setScoredTwitchClips] = useState<Array<ScoredTwitchClip>>([])

  useEffect(() => {
    const load = () => {
      setLoading(true)
      fetchTwitchClipsForEvent(props.eventId)
        .then((unscoredTwitchClips) => {
          const dataSet = unscoredTwitchClips.reduce((acc, curr) => {
            if (acc[curr.datePlatformId]) {
              acc[curr.datePlatformId].push(curr.viewCount)
              return acc
            }
            return {
              ...acc,
              [curr.datePlatformId]: [curr.viewCount],
            }
          }, {})
          const getZScore = (clip: TwitchClip) => {
            const dateMean =
              dataSet[clip.datePlatformId].reduce((acc, curr) => acc + curr) /
              dataSet[clip.datePlatformId].length
            const standardDeviation = getStandardDeviation(dataSet[clip.datePlatformId])
            return (clip.viewCount - dateMean) / standardDeviation
          }
          const unsortedScoredTwitchClips = unscoredTwitchClips.map((unscoredTwitchClip) => ({
            ...unscoredTwitchClip,
            zScore: getZScore(unscoredTwitchClip),
          }))
          setScoredTwitchClips(unsortedScoredTwitchClips.sort((a, b) => b.zScore - a.zScore))
        })
        .catch(() => {
          setNotification({
            message: t("common:errorLoadingData"),
            severity: "error",
          })
        })
        .finally(() => setLoading(false))
    }
    load()
  }, [props.eventId, setNotification, t])

  return (
    <Property title={t("list.summary.clips")}>
      {!loading && scoredTwitchClips.length === 0 && (
        <Typography variant="body2">{t("list.summary.noneRegistered")}</Typography>
      )}
      {loading && (
        <Stack
          my={2}
          direction="row"
          gap={1}
          flexWrap="nowrap"
          overflow="scroll"
        >
          {Array(5)
            .fill(0)
            .map((_event, index) => (
              <Skeleton
                variant="rounded"
                key={index}
                sx={{ flexShrink: 0 }}
                {...TWITCH_EMBED_MIN_SIZE}
              />
            ))}
        </Stack>
      )}
      {!loading && scoredTwitchClips.length > 0 && (
        <Box
          width="100%"
          height="400px" //Twitch clip height + estimate 100px for text + padding
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={scoredTwitchClips.length}
                itemSize={549.33} //Twitch clip width + fake 8px x 2 item padding
                width={width}
                layout="horizontal"
              >
                {({ index, style }) => {
                  const clip = scoredTwitchClips[index]
                  return (
                    <Box
                      style={style}
                      key={clip.id}
                      padding="8px"
                    >
                      <Card
                        sx={{
                          flexShrink: 0,
                          width: TWITCH_EMBED_MIN_SIZE.width,
                        }}
                      >
                        <CardMedia>
                          <iframe
                            title={clip.title}
                            src={`${clip.embedUrl}&parent=${window.location.hostname}`}
                            {...TWITCH_EMBED_MIN_SIZE}
                            allowFullScreen
                            style={{
                              border: "none",
                            }}
                          ></iframe>
                        </CardMedia>
                        <CardContent>
                          <Stack
                            gap={1}
                            direction="row-reverse"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Tooltip
                              title={UTCDateInJST(clip.clipCreatedAt)
                                .locale(i18n.language)
                                .format("DD MMM YYYY")}
                            >
                              <Typography color="text.secondary">
                                {UTCDateInJST(clip.clipCreatedAt).fromNow()}
                              </Typography>
                            </Tooltip>
                            <TrendingArrows score={clip.zScore} />
                          </Stack>
                        </CardContent>
                      </Card>
                    </Box>
                  )
                }}
              </List>
            )}
          </AutoSizer>
        </Box>
      )}
    </Property>
  )
}
