import moment from "moment-timezone"

export const isLocalEnvironment = () => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return true
  }
  return false
}

export const convertAndDownloadBlobAsCSV = (blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)
}

export const convertAndDownloadStringAsCSV = (csvData: string, fileName: string) => {
  const url = `data:text/csv;charset=utf-8,${csvData}`
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)
}

export const downloadFileFromUrl = (url: string) => {
  const link = document.createElement("a")
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)
}

export const openLinkInNewTab = (url: string) => {
  const link = document.createElement("a")
  link.href = url
  link.target = "_blank"
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)
}

export const UTCDateInJST = (dateTimeString: string) => {
  return moment.utc(dateTimeString).tz("Asia/Tokyo")
}
