import { useContext, useState } from "react"

import { Avatar, Chip, Paper, Stack } from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import type EventStream from "models/Taiyoro/Stream"

import { StreamVodConnectionManagement } from "./stream-vod-connection-management"

export const StreamList = () => {
  const { eventDate, selectedStream, setSelectedStream, streamIdColorMap, streamVodMap } =
    useContext(EventDateBracketsContext)

  const [selectedStreamWithoutVod, setSelectedStreamWithoutVod] = useState<EventStream | null>(null)

  const handleClick = (stream: EventStream) => {
    if (streamVodMap[stream.id]) {
      setSelectedStream(stream)
      return
    }
    setSelectedStreamWithoutVod(stream)
  }

  const handleConnectionManagerClose = () => {
    setSelectedStreamWithoutVod(null)
  }

  return (
    <>
      <Paper>
        <Stack
          p={2}
          gap={1}
          direction="row"
          flexWrap="wrap"
        >
          {eventDate.platforms
            .filter((datePlatform) => !datePlatform.isVod)
            .map((stream) => {
              const matchingVodOfStream = eventDate.platforms.find(
                (datePlatform) => datePlatform.id === streamVodMap[stream.id]
              )
              return (
                <Chip
                  avatar={
                    <Avatar
                      alt={stream.channelDisplayName ?? stream.id + " icon"}
                      src={
                        matchingVodOfStream?.channelIconUrl ? matchingVodOfStream.channelIconUrl : undefined
                      }
                    />
                  }
                  key={stream.id}
                  label={matchingVodOfStream?.channelDisplayName ?? stream.channelDisplayName ?? stream.url}
                  variant={stream === selectedStream ? "filled" : "outlined"}
                  sx={{
                    border: matchingVodOfStream ? `solid 1px ${streamIdColorMap[stream.id]}` : undefined,
                    opacity: !matchingVodOfStream ? "0.7" : undefined,
                  }}
                  onClick={() => handleClick(stream)}
                />
              )
            })}
        </Stack>
      </Paper>
      <StreamVodConnectionManagement
        eventStream={selectedStreamWithoutVod}
        onClose={handleConnectionManagerClose}
      />
    </>
  )
}
