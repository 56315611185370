import type { MetaType } from "models/Taiyoro/Meta/MetaType"
import type { Tag, TagHierarchy } from "models/Taiyoro/Meta/Tag"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type TagsResponse = {
  data?: {
    admin?: {
      tag?: {
        get?: Array<Tag>
      }
    }
  }
}

export const fetchTags = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          tag {
            get {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as TagsResponse

    return response.data?.admin?.tag?.get ?? null
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to fetch tags")
  }
}

export type TagResponse = {
  data?: {
    admin?: {
      tag?: {
        get?: Tag | null
      }
    }
  }
}

export const fetchTag = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          tag {
            get(id: "${id}") {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as TagResponse

    return response.data?.admin?.tag?.get ?? null
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to fetch tag")
  }
}

export type CreateTagResponse = {
  data?: {
    admin?: {
      tag?: {
        save?: Tag | null
      }
    }
  }
}

export const createTag = async (
  name: string,
  nameJa?: string | null,
  sortJa?: string | null,
  id?: string | null
) => {
  try {
    const tagInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
    ].join(", ")

    const response = (await GraphQL(
      `mutation {
      admin {
        tag {
          save(tag: { ${tagInput} }) {
            id
            name
            nameJa
            sortJa
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateTagResponse

    return response.data?.admin?.tag?.save ?? null
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to create tag")
  }
}

export type DeleteTagResponse = {
  data?: {
    admin?: {
      tag?: {
        delete?: boolean
      }
    }
  }
}

export const deleteTag = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          tag {
            delete(tagId: "${id}")
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteTagResponse

    return response.data?.admin?.tag?.delete ?? null
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to delete tag")
  }
}

export type LinkToMetaResponse = {
  data?: {
    admin?: {
      tag?: {
        linkToMeta?: unknown
      }
    }
  }
}

export const linkToMeta = async (tagId: string, metaId: string, metaType: MetaType) => {
  const tagIdInput = `"${tagId}"`
  const metaIdInput = `"${metaId}"`
  const metaTypeInput = `${metaType}`

  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          tag {
            linkToMeta(tagId: ${tagIdInput}, metaId: ${metaIdInput}, metaType: ${metaTypeInput})
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as LinkToMetaResponse

    if (response.data?.admin?.tag?.linkToMeta) return response.data.admin.tag.linkToMeta
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to link")
  }
}

export type DeleteLinkToMetaResponse = {
  data?: {
    admin?: {
      tag?: {
        deleteLinkToMeta?: unknown
      }
    }
  }
}

export const deleteLinkToMeta = async (tagId: string, metaId: string, metaType: MetaType) => {
  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          tag {
            deleteLinkToMeta(tagId: "${tagId}", metaId: "${metaId}", metaType: ${metaType})
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteLinkToMetaResponse

    if (response.data?.admin?.tag?.deleteLinkToMeta) return response.data.admin.tag.deleteLinkToMeta
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to delete")
  }
}

export type FetchTagHierarchyResponse = {
  data?: {
    admin?: {
      tag?: {
        getHierarchy?: TagHierarchy
      }
    }
  }
}

export const fetchTagHierarchy = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          tag {
            getHierarchy
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as FetchTagHierarchyResponse

    if (response.data?.admin?.tag?.getHierarchy) return response.data.admin.tag.getHierarchy

    throw new Error("Failed to fetch tag hierarchy.")
  } catch (err) {
    if (err instanceof Error) {
      console.error("Error:", err.message)
    } else {
      console.error("Unexpected Error:", err)
    }
    throw new Error("Failed to fetch tag hierarchy")
  }
}
