import type { ChangeEvent } from "react"

import { faClose, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Fab, Stack, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import type { FreetextAnswerOptions } from "services/Taiyoro/Pickem/daily"

interface Props {
  value: FreetextAnswerOptions
  updateValue: (value: FreetextAnswerOptions) => void
  updateStatus?: () => void
}

export const FreetextAnswerOptionsControl = ({ value, updateValue, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  const update = ({
    id,
    field,
    event,
  }: {
    id: string
    field: "content" | "contentJa"
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  }) => {
    const answerIndex = value.data.findIndex((answer) => answer.id === id)
    const newAnswer = { ...value.data[answerIndex], [field]: event.target.value }
    const newValue = { ...value }
    newValue.data[answerIndex] = newAnswer
    updateValue(newValue)
    updateStatus?.()
  }

  const add = () => {
    const newAnswer = {
      id: Date.now().toString(),
      content: "",
      contentJa: "",
    }

    const newValue: FreetextAnswerOptions = { ...value, data: [...value.data, newAnswer] }
    updateValue(newValue)
    updateStatus?.()
  }

  const remove = ({ id }: { id: string }) => {
    const newValue = { ...value }
    newValue.data = value.data.filter((answer) => answer.id !== id)
    updateValue(newValue)
    updateStatus?.()
  }

  return (
    <>
      <Stack
        gap={2}
        mt={2}
      >
        {value.data.map((answer) => (
          <Stack
            direction="row"
            key={answer.id}
            gap={1}
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              marginRight: -3,
            }}
          >
            <TextField
              label={t("eventPickem.daily.modal.answers.freetextAnswerJa")}
              onChange={(event) => update({ id: answer.id, field: "contentJa", event })}
              sx={{ flexGrow: 1 }}
              value={answer.contentJa}
            />
            <TextField
              label={t("eventPickem.daily.modal.answers.freetextAnswerEn")}
              onChange={(event) => update({ id: answer.id, field: "content", event })}
              sx={{ flexGrow: 1 }}
              value={answer.content}
            />
            <Button
              size="small"
              color="error"
              aria-label="remove"
              onClick={() => {
                remove({ id: answer.id })
                updateStatus?.()
              }}
              sx={{
                alignSelf: "stretch",
                flexShrink: 0,
                minWidth: "24px",
                marginLeft: "auto",
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </Stack>
        ))}
        <Stack alignItems="center">
          <Fab
            size="medium"
            color="success"
            aria-label="add"
            onClick={() => {
              add()
              updateStatus?.()
            }}
            sx={{
              flexShrink: 0,
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Fab>
        </Stack>
      </Stack>
    </>
  )
}
