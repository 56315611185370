import React, { useContext } from "react"

import { DialogContent, DialogContentText, Button, DialogActions, Stack } from "@mui/material/"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import { useTranslation } from "react-i18next"

import { StyledLink } from "../../styles"

const Initial = () => {
  const { t } = useTranslation(["taiyoro", "common"])
  const { selectedEventDate, setStep } = useContext(DataRecoveryContext)

  const noExistingStream = selectedEventDate?.platforms?.length === 0

  return (
    <>
      <DialogContent>
        <DialogContentText sx={{ color: "text.primary" }}>{t("dialogs.recover.initial")}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: 1 }}
        >
          <StyledLink
            disabled={noExistingStream}
            onClick={() => setStep(RecoveryStep.EXISTING_STREAM_RECOVER)}
          >
            {t("dialogs.recover.recoverExisting")}
          </StyledLink>

          <Button
            sx={{ ml: 3 }}
            onClick={() => setStep(RecoveryStep.SUGGESTIONS)}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t("dialogs.recover.newRecover")}
          </Button>
        </Stack>
      </DialogActions>
    </>
  )
}

export default Initial
