import { Checkbox, FormControlLabel, FormLabel } from "@mui/material"
import { withStyles } from "@mui/styles"

export const StyledFormLabel = withStyles({
  root: {
    "&.Updated": {
      color: "green !important",
    },
    "&.Error": {
      color: "red !important",
    },
  },
})(FormLabel)

export const StyledFormControlLabel = withStyles({
  root: {
    fontSize: "11px",
    color: "rgba(255, 255, 255, 0.7) !important",
    "&.Updated": {
      color: "green !important",
    },
    "&.Error": {
      color: "red !important",
    },
  },
})(FormControlLabel)

export const StyledCheckbox = withStyles({
  root: {
    "&$checked": {
      "&.Updated": {
        color: "green",
      },
      "&.Error": {
        color: "red",
      },
    },
  },
  checked: {},
})(Checkbox)
