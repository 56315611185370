import { useContext, useMemo } from "react"

import {
  faCommentsQuestion,
  faEdit,
  faRotateRight,
  faSwords,
  faTrashCan,
  faUp,
  faDown,
  faFolderOpen,
  faArrowDownWideShort,
  type IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Chip, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { DataGrid, GridActionsCellItem, type GridColumns } from "@mui/x-data-grid"
import { PickemFunctionsContext, PickemSettingsContext } from "contexts/pickem"
import moment from "moment"
import { useTranslation } from "react-i18next"
import type {
  DailyPickemStatus,
  DailyPickemAny,
  DailyPickemType,
  AnswerOptions,
} from "services/Taiyoro/Pickem/daily"

import { groupColors } from "../GroupSection"

interface Props {
  pickems: Array<DailyPickemAny> | undefined
}

export const PickemSection = ({ pickems }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  const { eventState, loading, dailyPickemGroupList } = useContext(PickemSettingsContext)

  const parsedGroupsPickems: Array<DailyPickemAny & { groupAndPriority: number }> | undefined =
    useMemo(() => {
      return pickems?.map((pickem) => {
        const group = dailyPickemGroupList.find((group) => group.id === pickem.groupId)

        return { ...pickem, groupAndPriority: (group?.priority ?? 0) * 1000000 + (pickem.priority ?? 0) }
      })
    }, [pickems, dailyPickemGroupList])

  const players =
    eventState?.games
      .flatMap((game) => game.participants.players.map((playerData) => playerData.player))
      .filter((player) => !!player) ?? []
  const teams =
    eventState?.games
      .flatMap((game) => game.participants.teams.map((teamData) => teamData.team))
      .filter((team) => !!team) ?? []

  const {
    editDailyPickem,
    deleteDailyPickem,
    restoreDailyPickem,
    movePickemDown,
    movePickemUp,
    stageCorrectAnswer,
    stageAwardPoints,
  } = useContext(PickemFunctionsContext)

  const hasPickems = parsedGroupsPickems !== undefined && parsedGroupsPickems.length > 0

  const columns: GridColumns<DailyPickemAny & { groupAndPriority: number }> = [
    {
      flex: 1.5,
      field: "status",
      sortable: true,
      headerName: t("eventPickem.daily.tableHeaders.status"),
      renderCell: (params) => {
        const status = params.value as DailyPickemStatus
        const statusToColor: Record<DailyPickemStatus, "success" | "warning" | "info" | "error" | "default"> =
          {
            saved: "default",
            modified: "warning",
            new: "success",
            deletedFromSaved: "error",
            deletedFromModified: "error",
          }
        return (
          <Tooltip title={t(`eventPickem.daily.status.${status}`)}>
            <Chip
              label={t(`eventPickem.daily.status.${status}`)}
              color={statusToColor[status]}
              size="small"
              sx={{ fontSize: "0.75rem" }}
            />
          </Tooltip>
        )
      },
    },
    {
      flex: 3,
      field: "groupAndPriority",
      sortable: true,
      headerName: t("eventPickem.daily.tableHeaders.group"),
      renderCell: (params) => {
        const groupId = params.row.groupId
        const group = dailyPickemGroupList.find((group) => group.id === groupId)
        const groupName =
          group !== undefined ? (language === "ja" || !group.name ? group.nameJa : group.name) : ""

        return (
          <Tooltip
            title={`${groupName}${groupName !== "" && params.row.priority !== null ? " ・ " : ""}${params.row.priority !== null ? `${params.row.priority}` : ""}`}
          >
            <Stack gap={0.25}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                {groupName !== "" && (
                  <FontAwesomeIcon
                    color={groupColors[((params.value as number) - (params.row.priority ?? 0)) / 1000000]}
                    icon={faFolderOpen}
                    style={{ marginRight: "0.5em" }}
                  />
                )}
                {groupName}
              </Typography>
              <Typography sx={{ fontSize: "0.875rem" }}>
                {params.row.priority !== null && (
                  <FontAwesomeIcon
                    icon={faArrowDownWideShort}
                    style={{ marginRight: "0.5em" }}
                  />
                )}
                {params.row.priority !== null ? `${params.row.priority}` : ""}
              </Typography>
            </Stack>
          </Tooltip>
        )
      },
    },
    {
      flex: 1,
      field: "type",
      sortable: false,
      headerName: t("eventPickem.daily.tableHeaders.type"),
      renderCell: (params) => {
        const type = params.value as DailyPickemType
        const typeToIcon: Record<DailyPickemType, IconDefinition> = {
          match: faSwords,
          quiz: faCommentsQuestion,
        }
        return (
          <Tooltip title={t(`eventPickem.daily.type.${type}`)}>
            <FontAwesomeIcon icon={typeToIcon[type]} />
          </Tooltip>
        )
      },
    },
    {
      flex: 5,
      field: "promptJa",
      sortable: false,
      headerName: t("eventPickem.daily.tableHeaders.promptJa"),
      renderCell: (params) => {
        const prompt = params.value as string
        return (
          <Tooltip title={prompt}>
            <Typography sx={{ fontSize: "0.875rem" }}>{prompt}</Typography>
          </Tooltip>
        )
      },
    },
    {
      flex: 5,
      field: "prompt",
      sortable: false,
      headerName: t("eventPickem.daily.tableHeaders.prompt"),
      renderCell: (params) => {
        const prompt = params.value as string
        return (
          <Tooltip title={prompt}>
            <Typography sx={{ fontSize: "0.875rem" }}>{prompt}</Typography>
          </Tooltip>
        )
      },
    },
    {
      flex: 3,
      field: "deadline",
      sortable: true,
      headerName: t("eventPickem.daily.tableHeaders.deadline"),
      renderCell: (params) => {
        const deadline = params.value as string
        const deadlineAsDate = moment.utc(deadline, "YYYY-MM-DD hh:mm:ss").tz("Asia/Tokyo")
        const deadlineDateString = deadlineAsDate.format(language === "ja" ? "YYYY年MM月DD日" : "YYYY/MM/DD")
        const deadlineTimeString = deadlineAsDate.format("HH:mm")
        return (
          <Tooltip title={`${deadlineDateString} ${deadlineTimeString}`}>
            <Stack>
              <Typography sx={{ fontSize: "0.75rem" }}>{deadlineDateString}</Typography>
              <Typography sx={{ fontSize: "0.75rem" }}>{deadlineTimeString}</Typography>
            </Stack>
          </Tooltip>
        )
      },
    },
    {
      flex: 2,
      field: "reward",
      sortable: true,
      headerName: t("eventPickem.daily.tableHeaders.reward"),
      renderCell: (params) => {
        const reward = params.value as number
        return (
          <Tooltip title={reward}>
            <Typography sx={{ fontSize: "0.875rem" }}>{reward}</Typography>
          </Tooltip>
        )
      },
    },
    {
      flex: 4,
      field: "answerOptions",
      sortable: false,
      headerName: t("eventPickem.daily.tableHeaders.answers"),
      renderCell: (params) => {
        const ANSWER_MAX_LENGTH = 15
        const answerOptions = params.value as AnswerOptions

        if (answerOptions.type === "participant") {
          return (
            <Stack gap={1}>
              {answerOptions.data.map((value) => {
                const participant =
                  value.type === "player"
                    ? players.find((player) => player.id === value.id)
                    : teams.find((team) => team.id === value.id)
                if (!participant) return null

                const name =
                  language === "ja" && participant.nameJa && participant.nameJa !== ""
                    ? participant.nameJa
                    : participant.name

                return (
                  <Stack
                    key={value.id}
                    gap={0}
                    alignItems="stretch"
                    sx={{ "&:not(:last-child)": { borderBottom: "1px solid #ccc", pb: 1 } }}
                  >
                    <Tooltip
                      title={name}
                      enterDelay={0}
                    >
                      <Typography sx={{ fontSize: "0.75rem" }}>{name}</Typography>
                    </Tooltip>
                  </Stack>
                )
              })}
            </Stack>
          )
        }

        return (
          <Stack gap={1}>
            {answerOptions.data.map((value) => (
              <Stack
                key={value.id}
                gap={0}
                alignItems="stretch"
                sx={{ "&:not(:last-child)": { borderBottom: "1px solid #ccc", pb: 1 } }}
              >
                <Tooltip title={value.contentJa}>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    {value.contentJa.substring(0, ANSWER_MAX_LENGTH)}
                    {value.contentJa.length > ANSWER_MAX_LENGTH && "..."}
                  </Typography>
                </Tooltip>
                <Tooltip title={value.content}>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    {value.content.substring(0, ANSWER_MAX_LENGTH)}
                    {value.content.length > ANSWER_MAX_LENGTH && "..."}
                  </Typography>
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        )
      },
    },
    {
      flex: 3,
      field: "correctAnswer",
      sortable: false,
      headerName: t("eventPickem.daily.tableHeaders.correctAnswer"),
      renderCell: (params) => {
        const deadlineHasPassed = moment.utc(params.row.deadline).tz("Asia/Tokyo").isBefore(moment())

        if (!deadlineHasPassed)
          return <Typography>{t("eventPickem.daily.tooEarlyToSetCorrectAnswer")}</Typography>

        if (params.row.status !== "saved") return <Typography>{t("eventPickem.daily.saveFirst")}</Typography>

        const { pointsHaveBeenAwarded } = params.row

        if (pointsHaveBeenAwarded) {
          return <Typography sx={{ fontSize: "0.875rem" }}>{t("eventPickem.daily.pointsAwarded")}</Typography>
        }

        const { answerOptions } = params.row
        const answerOptionsType = answerOptions.type

        const correctAnswer = params.row.correctAnswer ?? ""

        if (answerOptionsType === "freetext") {
          return (
            <TextField
              select
              fullWidth
              value={correctAnswer}
              label={t("eventPickem.daily.tableHeaders.correctAnswer")}
              onChange={(event) => {
                const { content, contentJa } = answerOptions.data.find(
                  (value) => value.id === event.target.value
                )!
                stageCorrectAnswer(params.row.id, { id: event.target.value, content, contentJa })
              }}
            >
              {answerOptions.data.map((value) => (
                <MenuItem
                  key={value.id}
                  value={value.id}
                >
                  {language === "ja" && value.contentJa && value.contentJa !== ""
                    ? value.contentJa
                    : value.content}
                </MenuItem>
              ))}
            </TextField>
          )
        }

        const participantType = answerOptions.data[0].type

        return (
          <TextField
            select
            fullWidth
            value={correctAnswer}
            label={t("eventPickem.daily.tableHeaders.correctAnswer")}
            onChange={(event) => {
              const { name, nameJa } =
                participantType === "player"
                  ? players.find((player) => player.id === event.target.value)!
                  : teams.find((team) => team.id === event.target.value)!
              stageCorrectAnswer(params.row.id, { id: event.target.value, content: name, contentJa: nameJa })
            }}
          >
            {answerOptions.data.map((value) => {
              const participant =
                value.type === "player"
                  ? players.find((player) => player.id === value.id)
                  : teams.find((team) => team.id === value.id)
              if (!participant) return null
              return (
                <MenuItem
                  key={value.id}
                  value={value.id}
                >
                  {language === "ja" && participant.nameJa && participant.nameJa !== ""
                    ? participant.nameJa
                    : participant.name}
                </MenuItem>
              )
            })}
          </TextField>
        )
      },
    },
    {
      flex: 3,
      field: "pointsHaveBeenAwarded",
      sortable: false,
      headerName: t("eventPickem.daily.tableHeaders.awardPoints"),
      renderCell: (params) => {
        const deadlineHasPassed = moment.utc(params.row.deadline).tz("Asia/Tokyo").isBefore(moment())

        if (!deadlineHasPassed) return <Typography>{t("eventPickem.daily.tooEarlyToAwardPoints")}</Typography>

        if (params.row.status !== "saved") return <Typography>{t("eventPickem.daily.saveFirst")}</Typography>

        const { pointsHaveBeenAwarded } = params.row

        if (pointsHaveBeenAwarded) {
          return <Typography sx={{ fontSize: "0.875rem" }}>{t("eventPickem.daily.pointsAwarded")}</Typography>
        }

        if (!params.row.correctAnswer || params.row.correctAnswer === "")
          return <Typography>{t("eventPickem.daily.setCorrectFirst")}</Typography>

        return (
          <Button
            variant="contained"
            onClick={() => stageAwardPoints(params.row.id)}
          >
            {t("eventPickem.daily.tableHeaders.awardPoints")}
          </Button>
        )
      },
    },
    {
      flex: 3,
      field: "actions",
      sortable: false,
      type: "actions",
      headerName: t("eventPickem.daily.tableHeaders.actions"),
      minWidth: 65,
      getActions: (params) => [
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.actions.moveDown")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faDown}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => movePickemDown(params.row.id)}
          label={t("eventPickem.daily.actions.moveDown")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.actions.moveUp")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faUp}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => movePickemUp(params.row.id)}
          label={t("eventPickem.daily.actions.moveUp")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("eventPickem.daily.actions.edit")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => editDailyPickem(params.row.id)}
          label={t("eventPickem.daily.actions.edit")}
        />,
        params.row.status !== "deletedFromSaved" && params.row.status !== "deletedFromModified" ? (
          <GridActionsCellItem
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...({} as any)}
            icon={
              <Tooltip
                title={t("eventPickem.daily.actions.delete")}
                enterDelay={0}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  style={{ fontSize: "1.2rem", color: "red" }}
                />
              </Tooltip>
            }
            onClick={() => deleteDailyPickem(params.row.id)}
            label={t("eventPickem.daily.actions.delete")}
          />
        ) : (
          <GridActionsCellItem
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...({} as any)}
            icon={
              <Tooltip
                title={t("eventPickem.daily.actions.restore")}
                enterDelay={0}
              >
                <FontAwesomeIcon
                  icon={faRotateRight}
                  style={{ fontSize: "1.2rem", color: "limegreen" }}
                />
              </Tooltip>
            }
            onClick={() => restoreDailyPickem(params.row.id)}
            label={t("eventPickem.daily.actions.restore")}
          />
        ),
      ],
    },
  ]

  return (
    <Stack gap={1}>
      <Typography
        component="h4"
        sx={{ fontWeight: "bold" }}
      >
        {t("eventPickem.daily.pickemsForDate")}
      </Typography>
      {!hasPickems && (
        <Typography sx={{ opacity: 0.75 }}>{t("eventPickem.daily.noPickemsForDate")}</Typography>
      )}
      {hasPickems && (
        <DataGrid
          // rows={pickems}
          rows={parsedGroupsPickems}
          columns={columns}
          disableColumnSelector
          disableColumnMenu
          disableVirtualization
          autoHeight={true}
          getRowHeight={() => "auto"}
          density="comfortable"
          loading={loading}
          // rowCount={pickems.length}
          rowCount={parsedGroupsPickems.length}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: "groupAndPriority", sort: "asc" }],
            },
          }}
        />
      )}
    </Stack>
  )
}
