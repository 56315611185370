import { Dialog, Button, Box, Link } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    min-width: 730px;
  }
`
export const CloseButton = styled(Button)`
  width: 40px;
  height: 40px;
  min-width: auto;
  padding: 0px;
  position: absolute;
  right: 0px;
`

export const StyledLink = styled(Button)`
  font-weight: 700;
  text-decoration: none;
  text-transform: none;
  &:hover {
    cursor: pointer;
  }
`

export const ExistingStreamWrapper = styled(Box)(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
  padding: 0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)};
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px solid #333333;
`
)

export const StyledStreamLink = styled(Link)(
  ({ theme }) => `
    font-weight: 700;
    color: ${theme.palette.text.primary};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    span {
      overflow: hidden;
      max-width: 275px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
    svg {
      margin-left: ${theme.spacing(1)};
    }
    &:hover {
      text-decoration: underline;
    }
    &.loading:hover {
      text-decoration: none;
    }
`
)
