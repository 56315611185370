const endpoint = process.env.REACT_APP_GRAPHQL || "http://localhost:3001/api"
const getEndpoint = process.env.REACT_APP_QUERYAPI || "http://localhost:3001/api/query"

export const ID_TOKEN_KEY = "idToken"

export const toEnum = (str) => (str ? str.toUpperCase().split("-").join("_") : undefined)
export const fromEnum = (str) => (str ? str.toLowerCase().split("_").join("-") : undefined)

const handleFault = ({ message }, onError) => {
  // this probably only happens when there's a 500
  console.error("GraphQL", message)
  if (message) {
    switch (message) {
      case "spam-detected":
        // dialog(ERROR(message));
        break
      case "email-not-verified":
        //    dialog(ERROR_UNVERIFIED_EMAIL());
        break
      default:
        if (message.indexOf && message.indexOf("ailed to fetch") > -1) return {}
        break
    }
  }
  // return { error: message }
  if (onError) {
    onError(message)
    return { error: message }
  } else {
    throw new Error(message)
  }
}

const GraphQL = async (
  request,
  apiResource = undefined,
  doGet = undefined,
  auth = undefined,
  onError = undefined,
  customEndpoint,
  signal = undefined
) => {
  let useEndpoint = customEndpoint || endpoint
  const contentType = {}
  const body = {}
  if (doGet) {
    useEndpoint = `${getEndpoint}?${request}`
  } else {
    contentType["content-type"] = "application/json"
    body["body"] = JSON.stringify({ query: request })
  }
  const call = (auth) =>
    fetch(
      apiResource
        ? process.env[`REACT_APP_GRAPHQL_${apiResource.toUpperCase()}`] || useEndpoint
        : useEndpoint,
      {
        ...body,
        method: doGet ? "GET" : "POST",
        headers: {
          ...contentType,
          ...auth,
        },
        signal,
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.errors && json.errors[0].message !== "forbidden") {
          throw new Error(json.errors[0].message)
        } else return json
      })
      .catch((error) => handleFault(error, onError))
  if (auth) {
    return call(auth)
  }
  const authHeader = { Authorization: `Bearer ${localStorage.getItem(ID_TOKEN_KEY)}` }
  return call(authHeader)
}

export const stringifyObjectForPayload = (object) =>
  Object.keys(object)
    .map((key) => `${key}: ${JSON.stringify(object[key])}`)
    .join(", ")

export default GraphQL
