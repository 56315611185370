import React, { useContext, useState } from "react"

import { TextField, Checkbox, CircularProgress } from "@mui/material"
import Switch from "@mui/material/Switch"
import DropdownSearchable from "components/Form/DropdownSearchable"
import { StyleButton } from "components/StyleMaterialUI"
import TableList from "components/Table/TableList"
import MediaUpload from "components/Taiyoro/MediaUpload"
import type {
  MetaState,
  MetaImages,
  MetaFunctions,
  MetaOthers,
  AddNewFunc,
} from "components/Taiyoro/MetaPage/types"
import { MetaPageContext } from "contexts/meta-page"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type Game from "models/Taiyoro/Meta/Game"
import { MetaType } from "models/Taiyoro/Meta/MetaType"
import type Team from "models/Taiyoro/Meta/Team"
import type TeamOrganization from "models/Taiyoro/Meta/TeamOrganization"
import { useTranslation } from "react-i18next"
import sortByEnglishNameOrJapaneseSort from "utils/sortByEnglishNameOrJapaneseSort"

type Props = {
  metaState: MetaState
  metaImages: MetaImages
  metaFunctions: MetaFunctions
  allowNonUniqueNames: boolean | undefined
  metaOthers: MetaOthers
  addNewFunc: AddNewFunc
}

export const CreateAndEditMeta = ({
  metaState,
  metaImages,
  metaFunctions,
  allowNonUniqueNames,
  metaOthers,
  addNewFunc,
}: Props) => {
  // -- React Contexts --
  const {
    // Data
    metaListState,
    setMetaListState,
    setPreserveMetaListState,
    // Utility
    setErrorState,
    isLoadingState,
    isSavingState,
    setIsSavingState,
  } = useContext(MetaPageContext)

  const { refreshTags } = useContext(TaiyoroDataContext)

  // -- React States --
  const [newMetaIdState, setNewMetaIdState] = useState("")
  const [newMetaNameState, setNewMetaNameState] = useState("")
  const [newMetaNameJaState, setNewMetaNameJaState] = useState("")
  const [newMetaSortJaState, setNewMetaSortJaState] = useState("")
  const [newMetaIsGameDeveloperState, setNewMetaIsGameDeveloperState] = useState(false)
  const [newMetaPrimaryImageState, setNewMetaPrimaryImageState] = useState("")
  const [newMetaSecondaryImageState, setNewMetaSecondaryImageState] = useState("")
  const [newMetaBoxArtImageState, setNewMetaBoxArtImageState] = useState("")
  const [newMetaLatitudeState, setNewMetaLatitudeState] = useState("")
  const [newMetaLongitudeState, setNewMetaLongitudeState] = useState("")
  const [newMetaTeamState, setNewMetaTeamState] = useState<null | string>(null)
  const [newMetaTeamOrganizationState, setNewMetaTeamOrganizationState] = useState<null | string>(null)
  const [newMetaHashtagState, setNewMetaHashtagState] = useState("")
  const [newMetaGameIdState, setNewMetaGameIdState] = useState<null | string>(null)
  const [newMetaSlugState, setNewMetaSlugState] = useState("")
  const [newMetaQuantifiableState, setNewMetaQuantifiableState] = useState(0)
  const [newMetaHomepageUrlState, setNewMetaHomepageUrlState] = useState("")
  const [newMetaIsGenreState, setNewMetaIsGenreState] = useState(0)

  // -- Declarations --
  const { t } = useTranslation(["taiyoro", "common"])

  const { idFields, slugFields, hashtagFields } = metaState

  const { primaryImageFieldsAndRatio, secondaryImageFieldsAndRatio, boxArtImageFields } = metaImages

  const { updateGameAlternativeNamesFunc, updateGroupsFunc, updateParentTagsFunc, metaTypeToMediaType } =
    metaFunctions

  const {
    gameDeveloperFields,
    metaType,
    latLongFields,
    quantifiableFields,
    homepageUrlFields,
    isGenreFields,
    teamFields,
    gameFields,
    availableTeamsState,
    teamOrganizationFields,
    availableGamesState,
    availableTeamOrganizationsState,
  } = metaOthers

  // -- Handlers --
  const saveNewMeta = async () => {
    try {
      const result = await addNewFunc(
        newMetaNameState,
        newMetaNameJaState,
        newMetaSortJaState,
        newMetaIsGameDeveloperState,
        newMetaPrimaryImageState,
        newMetaSecondaryImageState,
        newMetaBoxArtImageState,
        newMetaLatitudeState,
        newMetaLongitudeState,
        newMetaTeamState,
        newMetaTeamOrganizationState,
        newMetaHashtagState,
        newMetaGameIdState,
        newMetaSlugState,
        newMetaQuantifiableState,
        newMetaHomepageUrlState,
        newMetaIsGenreState,
        newMetaIdState !== "" ? newMetaIdState : null
      )

      const newMetaList = [...metaListState, result]

      setMetaListState(newMetaList.sort(sortByEnglishNameOrJapaneseSort))
      setPreserveMetaListState(newMetaList.sort(sortByEnglishNameOrJapaneseSort))
      setNewMetaNameState("")
      setNewMetaNameJaState("")
      setNewMetaSortJaState("")
      setNewMetaIsGameDeveloperState(false)
      setNewMetaPrimaryImageState("")
      setNewMetaSecondaryImageState("")
      setNewMetaBoxArtImageState("")
      setNewMetaLatitudeState("")
      setNewMetaLongitudeState("")
      setNewMetaTeamState(null)
      setNewMetaTeamOrganizationState(null)
      setNewMetaHashtagState("")
      setNewMetaSlugState("")
      setNewMetaQuantifiableState(0)
      setNewMetaHomepageUrlState("")
      setNewMetaIsGenreState(0)
      setNewMetaIdState("")
    } catch (err) {
      if (err instanceof Error) setErrorState(err)
    } finally {
      setIsSavingState(false)
      if (updateParentTagsFunc) {
        refreshTags()
      }
    }
  }

  const handleSaveClick = async () => {
    setIsSavingState(true)
    try {
      await saveNewMeta()
    } catch (error) {
      console.error("Failed to save new meta:", error)
    } finally {
      setIsSavingState(false)
    }
  }

  return (
    <TableList.BodyRow>
      {idFields && (
        <TableList.BodyCell>
          <TextField
            required
            label={t("meta.headers.id")}
            variant="outlined"
            value={newMetaIdState}
            onChange={(event) => setNewMetaIdState(event.target.value)}
            style={{ margin: "10px 0" }}
            disabled={isSavingState}
          />
        </TableList.BodyCell>
      )}
      <TableList.BodyCell>
        <TextField
          required
          label={t("meta.headers.name")}
          variant="outlined"
          value={newMetaNameState}
          onChange={(event) => setNewMetaNameState(event.target.value)}
          style={{ margin: "10px 0" }}
          disabled={isSavingState}
        />
      </TableList.BodyCell>
      <TableList.BodyCell>
        <TextField
          label={t("meta.headers.nameJa")}
          variant="outlined"
          value={newMetaNameJaState}
          onChange={(event) => setNewMetaNameJaState(event.target.value)}
          style={{ margin: "10px 0" }}
        />
      </TableList.BodyCell>
      <TableList.BodyCell>
        <TextField
          label={t("meta.headers.sortJa")}
          variant="outlined"
          value={newMetaSortJaState}
          onChange={(event) => setNewMetaSortJaState(event.target.value)}
          style={{ margin: "10px 0" }}
        />
      </TableList.BodyCell>
      {slugFields && (
        <TableList.BodyCell>
          <TextField
            required
            label={t("meta.headers.slug")}
            variant="outlined"
            value={newMetaSlugState}
            onChange={(event) => setNewMetaSlugState(event.target.value)}
            style={{ margin: "10px 0" }}
          />
        </TableList.BodyCell>
      )}
      {hashtagFields && (
        <TableList.BodyCell>
          <TextField
            label={t("meta.headers.hashtag")}
            variant="outlined"
            value={newMetaHashtagState}
            onChange={(event) => setNewMetaHashtagState(event.target.value)}
            style={{ margin: "10px 0" }}
          />
        </TableList.BodyCell>
      )}
      {updateGameAlternativeNamesFunc && <TableList.BodyCell></TableList.BodyCell>}
      {gameDeveloperFields && (
        <TableList.BodyCell>
          <Switch
            color="primary"
            name="newSwitch"
            checked={newMetaIsGameDeveloperState}
            onChange={() => setNewMetaIsGameDeveloperState(!newMetaIsGameDeveloperState)}
          />
        </TableList.BodyCell>
      )}
      {primaryImageFieldsAndRatio && (
        <TableList.BodyCell>
          <MediaUpload
            key="add-primary"
            url={newMetaPrimaryImageState}
            mediaType={metaTypeToMediaType(metaType)}
            onChange={(url) => setNewMetaPrimaryImageState(url)}
            aspect={primaryImageFieldsAndRatio}
            editable
            buttonText={t("meta.controls.chooseFile")}
          />
        </TableList.BodyCell>
      )}
      {secondaryImageFieldsAndRatio && (
        <TableList.BodyCell>
          <MediaUpload
            key="add-secondary"
            url={newMetaSecondaryImageState}
            mediaType={metaTypeToMediaType(metaType)}
            onChange={(url) => setNewMetaSecondaryImageState(url)}
            aspect={secondaryImageFieldsAndRatio}
            editable
            buttonText={t("meta.controls.chooseFile")}
          />
        </TableList.BodyCell>
      )}
      {boxArtImageFields && (
        <TableList.BodyCell>
          <MediaUpload
            key="add-boxArt"
            url={newMetaBoxArtImageState}
            mediaType={metaTypeToMediaType(metaType)}
            onChange={(url) => setNewMetaBoxArtImageState(url)}
            aspect={3 / 4}
            editable
            buttonText={t("meta.controls.chooseFile")}
          />
        </TableList.BodyCell>
      )}
      {latLongFields && (
        <>
          <TableList.BodyCell>
            <TextField
              label="GPS Latitude"
              variant="outlined"
              value={newMetaLatitudeState}
              onChange={(event) => setNewMetaLatitudeState(event.target.value)}
              style={{ margin: "10px 0" }}
              disabled={isSavingState}
            />
          </TableList.BodyCell>
          <TableList.BodyCell>
            <TextField
              label="GPS Longitude"
              variant="outlined"
              value={newMetaLongitudeState}
              onChange={(event) => setNewMetaLongitudeState(event.target.value)}
              style={{ margin: "10px 0" }}
              disabled={isSavingState}
            />
          </TableList.BodyCell>
        </>
      )}
      {quantifiableFields && (
        <TableList.BodyCell>
          <Checkbox
            checked={newMetaQuantifiableState === 1}
            onChange={(_event, checked) => setNewMetaQuantifiableState(checked ? 1 : 0)}
          />
        </TableList.BodyCell>
      )}
      {homepageUrlFields && (
        <TableList.BodyCell>
          <TextField
            label={t("meta.headers.homepageUrl")}
            variant="outlined"
            value={newMetaHomepageUrlState}
            onChange={(event) => setNewMetaHomepageUrlState(event.target.value)}
            style={{ margin: "10px 0" }}
            disabled={isSavingState}
          />
        </TableList.BodyCell>
      )}
      {updateGroupsFunc && <TableList.BodyCell></TableList.BodyCell>}
      {updateParentTagsFunc && <TableList.BodyCell></TableList.BodyCell>}
      {isGenreFields && (
        <TableList.BodyCell>
          <Checkbox
            checked={newMetaIsGenreState === 1}
            onChange={(_event, checked) => setNewMetaIsGenreState(checked ? 1 : 0)}
          />
        </TableList.BodyCell>
      )}
      {teamFields && (
        <TableList.BodyCell>
          <DropdownSearchable
            label="Team"
            value={availableTeamsState?.find((team) => team.id === newMetaTeamState)}
            options={availableTeamsState}
            onChange={(e?: Team) => setNewMetaTeamState(e ? e.id : "")}
            disabled={isSavingState}
          />
        </TableList.BodyCell>
      )}
      {gameFields && (
        <TableList.BodyCell>
          <DropdownSearchable
            label="Game"
            value={availableGamesState?.find((game) => game.id === newMetaGameIdState)}
            options={availableGamesState}
            onChange={(e?: Game) => setNewMetaGameIdState(e ? e.id : "")}
            disabled={isSavingState}
          />
        </TableList.BodyCell>
      )}
      {teamOrganizationFields && (
        <TableList.BodyCell>
          <DropdownSearchable
            label="Team organization"
            value={availableTeamOrganizationsState?.find(
              (teamOrganization) => teamOrganization.id === newMetaTeamOrganizationState
            )}
            options={availableTeamOrganizationsState}
            onChange={(e?: TeamOrganization) => setNewMetaTeamOrganizationState(e ? e.id : "")}
            disabled={isSavingState}
            required={metaType === MetaType.TEAM}
          />
        </TableList.BodyCell>
      )}

      <TableList.BodyCell>
        <StyleButton
          variant="contained"
          onClick={() => void handleSaveClick()}
          disabled={
            newMetaNameState === "" ||
            isLoadingState ||
            isSavingState ||
            (slugFields && newMetaSlugState === "") ||
            (metaType === MetaType.TEAM && !newMetaTeamOrganizationState) ||
            (!allowNonUniqueNames && metaListState.some((meta) => meta.name === newMetaNameState))
          }
        >
          {isSavingState ? <CircularProgress /> : t("common:actions.save")}
        </StyleButton>
      </TableList.BodyCell>
    </TableList.BodyRow>
  )
}
