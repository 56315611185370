import React, { useContext } from "react"

import { faXmark, faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DialogTitle, DialogContent, DialogContentText, Snackbar, Alert } from "@mui/material/"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import { useTranslation } from "react-i18next"

import ChannelFlow from "./Flows/ChannelFlow"
import ExistingRecover from "./Flows/ExistingRecover"
import Initial from "./Flows/Initial"
import Suggestions from "./Flows/Suggestions"
import VodFlow from "./Flows/VodFlow"
import { StyledDialog, CloseButton } from "./styles"

const DataRecoveryDialog = () => {
  const { t } = useTranslation(["taiyoro", "common"])

  const { selectedEventDate, step, resetDialog, error, setError } = useContext(DataRecoveryContext)

  return (
    <>
      <StyledDialog
        open={selectedEventDate ? true : false}
        onClose={() => resetDialog()}
      >
        <CloseButton onClick={() => resetDialog()}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
        <DialogTitle sx={{ pb: 1 }}>{t("dialogs.recover.title")}</DialogTitle>
        {selectedEventDate && (
          <>
            {step === RecoveryStep.INITIAL && <Initial />}
            {step === RecoveryStep.EXISTING_STREAM_RECOVER && <ExistingRecover />}
            {step === RecoveryStep.SUGGESTIONS && <Suggestions />}
            {step === RecoveryStep.VOD_FLOW && <VodFlow />}
            {step === RecoveryStep.CHANNEL_FLOW && <ChannelFlow />}
            {step === RecoveryStep.SUCCESS && (
              <>
                <DialogContent sx={{ textAlign: "center", mt: 3 }}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="2xl"
                  />
                  <DialogContentText sx={{ color: "text.primary", pt: 2 }}>
                    {t("dialogs.recover.success")}
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          </>
        )}
      </StyledDialog>
      <Snackbar
        open={error}
        onClose={() => setError(false)}
      >
        <Alert severity="error">{t("meta.errors.loading")}</Alert>
      </Snackbar>
    </>
  )
}

export default DataRecoveryDialog
