import { UserRole } from "../models/UserManagement"

export const ADMIN_ROLES = [UserRole.ADMIN]

export const NON_ADMIN_ROLES = [
  UserRole.PUBLISHER,
  UserRole.ANALYST,
  UserRole.EDITOR,
  UserRole.VIEWER,
  UserRole.PRODUCER,
]

export const ALL_ROLES = [...ADMIN_ROLES, ...NON_ADMIN_ROLES]

export const ANALYTICS_ROLES = [...ADMIN_ROLES, UserRole.ANALYST]

export const HIGH_ACCESS_ROLES = [...ADMIN_ROLES, UserRole.PUBLISHER, UserRole.PRODUCER, UserRole.ANALYST]
