import React, { useEffect, useState } from "react"

import { faCheck, faSpinner, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip, FormControl, Stack, Switch, TextField } from "@mui/material"
import type { Theme } from "@mui/material"
import { withStyles } from "@mui/styles"

import { errorContext } from "../../.."
import DropdownSearchable from "../../Form/DropdownSearchable"
import NumberFormatCustom from "./NumberFormatCustom"
import { StyledFormLabel, StyledCheckbox, StyledFormControlLabel } from "./styles"

interface Props {
  type: string
  label: string
  initialValue: any
  options?: Array<any>
  createFunc?: any
  updateFunc?: any
  deleteFunc?: any
  addFunc?: any
  variant?: any
  disableClearable?: boolean
  getOptionLabel?: any
  disabled?: boolean
  onFocus?: any
  multiline?: boolean
  validationRule?: (value: any) => Promise<any>
}

const DirtyDetectingTextField = withStyles((theme: Theme) => ({
  root: {
    "& .MuiInput-input": {
      "&::-webkit-calendar-picker-indicator": {
        cursor: "pointer",
        filter:
          theme.palette.mode === "dark"
            ? "invert(72%) sepia(38%) saturate(657%) hue-rotate(181deg) brightness(101%) contrast(96%)"
            : "invert(42%) sepia(31%) saturate(4480%) hue-rotate(199deg) brightness(89%) contrast(83%)",
      },
    },
    "&.Updated": {
      "& label": {
        color: "green",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "green",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "green",
        },
        "&:hover fieldset": {
          borderColor: "green",
        },
        "&.Mui-focused fieldset": {
          borderColor: "green",
        },
      },
    },
    "&.Error": {
      "& label": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "red",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "red",
        },
        "&:hover fieldset": {
          borderColor: "red",
        },
        "&.Mui-focused fieldset": {
          borderColor: "red",
        },
      },
    },
  },
}))(TextField)

const RealTimeUpdateField = (props: Props) => {
  const [updatedValueState, setUpdatedValueState] = useState(props.initialValue)
  const [currentValueState, setCurrentValueState] = useState(props.initialValue)
  const [pendingChangesState, setPendingChangesState] = useState(null)
  const [updatingState, setUpdatingState] = useState(false)
  const [successState, setSuccessState] = useState(false)
  const [errorDataState, setErrorDataState] = useState(null)
  const [errorState, setErrorState] = errorContext()

  const classes = `
    ${successState ? "Updated" : ""} 
    ${errorDataState ? "Error" : ""}
  `

  const handleError = (error) => {
    let newErrorState = [...errorState]
    if (errorDataState) {
      newErrorState = newErrorState.filter((error) => error !== errorDataState)
    }
    const errorMessage = `${props.label}: ${error}`
    newErrorState.push(errorMessage)
    setErrorDataState(errorMessage)
    setErrorState(newErrorState)
    setUpdatingState(false)
  }

  useEffect(() => {
    if (pendingChangesState) {
      setUpdatingState(true)
      setSuccessState(false)
      const doChanges = async (changes: any) => {
        for (let pendingChange of changes) {
          await pendingChange()
        }
      }
      doChanges(pendingChangesState)
        .then(() => {
          setSuccessState(true)
          setUpdatingState(false)
          setPendingChangesState(null)
          setErrorState(errorState.filter((error) => error !== errorDataState))
          setErrorDataState(null)
        })
        .catch(handleError)
    }
  }, [pendingChangesState])

  const label = (
    <React.Fragment>
      <span>{props.label} &nbsp;</span>
      {!successState && updatingState && (
        <FontAwesomeIcon
          title="Updating"
          icon={faSpinner}
          spin
        />
      )}
      {successState && !updatingState && (
        <FontAwesomeIcon
          title="Updated"
          icon={faCheck}
        />
      )}
      {errorDataState && !updatingState && (
        <FontAwesomeIcon
          title="Error updating"
          color="red"
          icon={faTimes}
        />
      )}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {(props.type === "text" || props.type === "number" || props.type === "datetime-local") && (
        <DirtyDetectingTextField
          label={label}
          variant={props.variant || "outlined"}
          type={props.type}
          value={updatedValueState}
          className={classes}
          disabled={props.disabled}
          multiline={props.multiline || undefined}
          onFocus={props.onFocus || null}
          InputLabelProps={props.type === "datetime-local" ? { shrink: true } : {}}
          onBlur={() => {
            if (updatedValueState === currentValueState) {
              setPendingChangesState(null)
              return
            }
            const validation = () =>
              props.validationRule
                ? props.validationRule(updatedValueState)
                : Promise.resolve(updatedValueState)
            validation()
              .then((validValue) => {
                const changesAsPromises = [() => props.updateFunc(validValue)]
                setPendingChangesState(changesAsPromises)
                setUpdatedValueState(validValue)
                setCurrentValueState(validValue)
              })
              .catch(handleError)
          }}
          onChange={(event) => {
            const value = event.target.value.length > 0 ? event.target.value : null
            setUpdatedValueState(value)
          }}
          fullWidth
        />
      )}
      <React.Fragment>
        {props.type === "number-format" && (
          <DirtyDetectingTextField
            label={label}
            variant={props.variant || "outlined"}
            value={updatedValueState}
            className={classes}
            disabled={props.disabled}
            inputProps={{ style: { textAlign: "right" } }}
            InputProps={{ inputComponent: NumberFormatCustom }}
            onBlur={() => {
              if (updatedValueState === currentValueState) {
                setPendingChangesState(null)
                return
              }
              const changesAsPromises = [() => props.updateFunc(updatedValueState)]
              setPendingChangesState(changesAsPromises)
              setCurrentValueState(updatedValueState)
            }}
            onChange={(event) => {
              const value = event.target.value.length > 0 ? event.target.value : null
              setUpdatedValueState(value)
            }}
            onFocus={props.onFocus}
            fullWidth
          />
        )}
        {props.type === "select" && (
          <DropdownSearchable
            options={props.options}
            label={label}
            disableClearable={props.disableClearable || undefined}
            value={updatedValueState}
            indicateChanges={successState}
            variant={props.variant || "outlined"}
            disabled={props.disabled}
            isOptionEqualToValue={(option) => false}
            optionLabel={props.getOptionLabel || undefined}
            onChange={(event) => {
              setUpdatedValueState(event)
            }}
            onBlur={() => {
              if (updatedValueState === currentValueState) {
                setPendingChangesState(null)
                return
              }
              setPendingChangesState([() => props.updateFunc(updatedValueState)])
              setCurrentValueState(updatedValueState)
            }}
          />
        )}
        {props.type === "searchable" && (
          <DropdownSearchable
            value={updatedValueState}
            options={props.options}
            label={label}
            variant={props.variant || "outlined"}
            multiple
            clearOnBlur
            disabled={props.disabled}
            indicateChanges={successState}
            optionLabel={props.getOptionLabel || undefined}
            createFunc={props.createFunc}
            onChange={(event) => {
              setUpdatedValueState(event)
            }}
            renderTags={(value, getTagProps, ownerState) => {
              return (
                <Stack
                  direction="row"
                  gap={0.5}
                  flexWrap="wrap"
                >
                  {value.map((option, index) => {
                    const { chipProps, onDelete } = getTagProps(index)
                    return (
                      <Chip
                        key={index}
                        {...(ownerState.focused ? { onDelete } : {})}
                        label={ownerState.getOptionLabel(option)}
                        {...chipProps}
                      />
                    )
                  })}
                </Stack>
              )
            }}
            onBlur={() => {
              const removed = currentValueState.filter(
                (entity) => !updatedValueState.some((e) => e === entity)
              )
              const added = updatedValueState.filter((e) => !currentValueState.some((entity) => entity === e))
              const changes = [
                ...removed.map((r) => () => props.deleteFunc(r)),
                ...added.map((a) => () => props.addFunc(a)),
              ]
              if (changes.length > 0) {
                setPendingChangesState(changes)
                setCurrentValueState(updatedValueState)
                if (props.updateFunc) {
                  props.updateFunc(updatedValueState)
                }
              }
            }}
          />
        )}
      </React.Fragment>
      {props.type === "checkbox" && (
        <React.Fragment>
          <FormControl style={{ flexDirection: "row-reverse", alignItems: "center" }}>
            <StyledFormLabel
              sx={{ whiteSpace: "nowrap", marginLeft: "6px" }}
              className={classes + " " + (successState ? "MuiInputLabel-shrink" : "MuiInputLabel-shrink")}
            >
              {label}
            </StyledFormLabel>
            <StyledCheckbox
              className={classes}
              checked={updatedValueState === 1}
              disabled={props.disabled}
              onChange={(e) => {
                const checkedValue = e.target.checked ? 1 : 0
                setUpdatingState(true)
                setSuccessState(false)
                setPendingChangesState([() => props.updateFunc(checkedValue)])
                setUpdatedValueState(checkedValue)
                setCurrentValueState(checkedValue)
              }}
              style={{ padding: "4px 0" }}
            />
          </FormControl>
        </React.Fragment>
      )}
      {props.type === "switch" && (
        <StyledFormControlLabel
          value={label}
          className={classes}
          control={
            <Switch
              checked={updatedValueState === 1}
              disabled={props.disabled}
              onChange={() => {
                const checkedValue = updatedValueState ? 0 : 1
                setUpdatingState(true)
                setSuccessState(false)
                setPendingChangesState([() => props.updateFunc(checkedValue)])
                setUpdatedValueState(checkedValue)
                setCurrentValueState(checkedValue)
              }}
            />
          }
          label={label}
          labelPlacement="top"
        />
      )}
    </React.Fragment>
  )
}

export default RealTimeUpdateField
