import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const fetchLogsForEntities = (entities: any, limit: number, offset: number): Promise<any | null> => {
  const entitiesString = `[
        ${entities
          .map((entity) => {
            return `{entityType: ${entity.entityType}, entityID: ${JSON.stringify(entity.entityID)}}`
          })
          .join(", ")}
    ]`

  const query = `query {
        admin {
          logging {
             get(entities: ${entitiesString} limit: ${limit} offset: ${offset}) {
              datetimeFrom
              datetimeTo
              offset
              limit
              results {
                entityType
                entityId
                actionType
                changes
                userId
                username
                createdAt
              }
            }
          }
        }
      }
    `

  return GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )
}
