import React, { useContext, useEffect, useState } from "react"

import { faPlusSquare, faWindowClose, faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  TextField,
  Chip,
  InputAdornment,
  Checkbox,
  Autocomplete,
  Stack,
  CircularProgress,
  IconButton,
  Switch,
  Link,
  Tooltip,
} from "@mui/material"
import DropdownSearchable from "components/Form/DropdownSearchable"
import { StyleButton } from "components/StyleMaterialUI"
import TableList from "components/Table/TableList"
import MediaUpload from "components/Taiyoro/MediaUpload"
import { getParentTagsForId } from "components/Taiyoro/MetaPage/helpers"
import type { MetaItem } from "components/Taiyoro/MetaPage/types"
import type { MetaState, MetaImages, MetaFunctions, MetaOthers } from "components/Taiyoro/MetaPage/types"
import { MetaPageContext } from "contexts/meta-page"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type Game from "models/Taiyoro/Meta/Game"
import type GameGroup from "models/Taiyoro/Meta/GameGroup"
import { MetaType } from "models/Taiyoro/Meta/MetaType"
import type { Tag } from "models/Taiyoro/Meta/Tag"
import type Team from "models/Taiyoro/Meta/Team"
import type TeamOrganization from "models/Taiyoro/Meta/TeamOrganization"
import { LocalMomentFromUTCDateTime } from "models/Taiyoro/eventDate"
import { UserRole } from "models/UserManagement"
import { useTranslation } from "react-i18next"
import Scroll from "react-scroll"
import { localisedLabel } from "utils/i18n"
import { HIGH_ACCESS_ROLES, ADMIN_ROLES } from "utils/roles"
import sortByEnglishNameOrJapaneseSort from "utils/sortByEnglishNameOrJapaneseSort"
import useRolesCanAccess from "utils/useRolesCanAccess"

import type { UpdateFunc } from "../../../types"
import { DeleteRecord } from "../DeleteRecord"

type Props = {
  metaState: MetaState
  metaImages: MetaImages
  metaFunctions: MetaFunctions
  metaOthers: MetaOthers
  focusIdState: string | null
  availableGameGroupsState: Array<GameGroup> | null
  updateFunc: UpdateFunc
}

export const TableBody = ({
  metaState,
  metaImages,
  metaFunctions,
  metaOthers,
  focusIdState,
  availableGameGroupsState,
  updateFunc,
}: Props) => {
  // -- React Contexts --
  const {
    // Data
    metaListState,
    setMetaListState,
    paginatedMetaListData,
    setPreserveMetaListState,
    // Utility
    setErrorState,
    isSavingState,
    setIsSavingState,
    // Table Header Utility
    sortParamsState,
    setDeleteMetaObjectState,
  } = useContext(MetaPageContext)

  const { tagHierarchy, refreshTags } = useContext(TaiyoroDataContext)

  // -- React States --
  const [editIdState, setEditIdState] = useState<string | null>(null)
  const [editTextState, setEditTextState] = useState("")
  const [editNameJaState, setEditNameJaState] = useState("")
  const [editSortJaState, setEditSortJaState] = useState("")
  const [editIsGameDeveloperState, setEditIsGameDeveloperState] = useState<boolean>(false)
  const [editPrimaryImageState, setEditPrimaryImageState] = useState<string>("")
  const [editSecondaryImageState, setEditSecondaryImageState] = useState<string>("")
  const [editBoxArtImageState, setEditBoxArtImageState] = useState("")
  const [editGameGroupsState, setEditGameGroupsState] = useState<Array<GameGroup>>([])
  const [editGameGroupsStateOriginal, setEditGameGroupsStateOriginal] = useState<Array<GameGroup>>([])
  const [editLatitudeState, setEditLatitudeState] = useState<string>("")
  const [editLongitudeState, setEditLongitudeState] = useState<string>("")
  const [editTeamState, setEditTeamState] = useState<string | null>(null)
  const [editTeamOrganizationState, setEditTeamOrganizationState] = useState<string | null>(null)
  const [editGameAlternativeNamesState, setEditGameAlternativeNamesState] = useState<Array<string>>([])
  const [editGameAlternativeNamesOriginalState, setEditGameAlternativeNamesOriginalState] = useState<
    Array<string>
  >([])
  const [editGameAlternativeNameInputState, setEditGameAlternativeNameInputState] = useState("")
  const [editGameIdState, setEditGameIdState] = useState<string | null>(null)
  const [editHashtagState, setEditHashtagState] = useState<string>("")
  const [editSlugState, setEditSlugState] = useState<string>("")
  const [editQuantifiableState, setEditQuantifiableState] = useState<number>(0)
  const [editHomepageUrlState, setEditHomepageUrlState] = useState<string>("")
  const [editIsGenreState, setEditIsGenreState] = useState<number>(0)

  const [editParentTagsState, setEditParentTagsState] = useState<Array<Tag>>([])
  const [editParentTagsStateOriginal, setEditParentTagsStateOriginal] = useState<Array<Tag>>([])

  // -- Declarations --
  const { t } = useTranslation(["taiyoro", "common"])

  const { idFields, slugFields, hashtagFields } = metaState

  const { primaryImageFieldsAndRatio, secondaryImageFieldsAndRatio, boxArtImageFields } = metaImages

  const { updateGameAlternativeNamesFunc, updateGroupsFunc, updateParentTagsFunc, metaTypeToMediaType } =
    metaFunctions

  const {
    gameDeveloperFields,
    metaType,
    latLongFields,
    quantifiableFields,
    homepageUrlFields,
    isGenreFields,
    teamFields,
    gameFields,
    availableTeamsState,
    teamOrganizationFields,
    availableGamesState,
    availableTeamOrganizationsState,
  } = metaOthers

  const canDeleteMeta = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const canCreateAndEditMeta = useRolesCanAccess([
    ...ADMIN_ROLES,
    UserRole.PRODUCER,
    UserRole.PUBLISHER,
    UserRole.ANALYST,
    UserRole.EDITOR,
  ])

  // -- Handlers --
  const updateMeta = async (id: string) => {
    try {
      if (updateGroupsFunc) {
        const addedGroups = editGameGroupsState
          .filter((gs) => !editGameGroupsStateOriginal.some((g) => gs.id === g.id))
          .map((group) => ({ refId: id, parentGroupId: group.id }))
        const removedGroups = editGameGroupsStateOriginal
          .filter((g) => !editGameGroupsState.some((gs) => gs.id === g.id))
          .map((group) => ({ refId: id, parentGroupId: group.id }))

        await updateGroupsFunc(addedGroups, removedGroups)
      }

      if (updateGameAlternativeNamesFunc) {
        const addedNames = editGameAlternativeNamesState
          .filter((entity) => !editGameAlternativeNamesOriginalState.some((e) => e === entity))
          .map((name) => ({ gameId: editIdState, name: name }))
        const removedNames = editGameAlternativeNamesOriginalState
          .filter((e) => !editGameAlternativeNamesState.some((entity) => entity === e))
          .map((name) => ({ gameId: editIdState, name: name }))

        await updateGameAlternativeNamesFunc(addedNames, removedNames)
      }

      if (updateParentTagsFunc) {
        const addedTags = editParentTagsState
          .filter((entity) => !editParentTagsStateOriginal.some((e) => e === entity))
          .map((parentTag) => ({ refId: editIdState, parentGroupId: parentTag.id }))
        const removedTags = editParentTagsStateOriginal
          .filter((e) => !editParentTagsState.some((entity) => entity === e))
          .map((parentTag) => ({ refId: editIdState, parentGroupId: parentTag.id }))
        await updateParentTagsFunc(addedTags, removedTags)
      }

      const result = await updateFunc(
        editTextState,
        editNameJaState,
        editSortJaState,
        editIsGameDeveloperState,
        editPrimaryImageState,
        editSecondaryImageState,
        editBoxArtImageState,
        editLatitudeState,
        editLongitudeState,
        editTeamState,
        editTeamOrganizationState,
        editHashtagState,
        editGameIdState,
        editSlugState,
        editQuantifiableState,
        editHomepageUrlState,
        editIsGenreState,
        id
      )

      const newMetaList = metaListState.map((meta: MetaItem) => {
        if (meta.id !== editIdState) return meta
        meta.name = result.name
        meta.nameJa = result.nameJa
        meta.sortJa = result.sortJa
        meta.isGameDeveloper = result.isGameDeveloper
        meta.primaryImage = result.primaryImage
        meta.secondaryImage = result.secondaryImage
        meta.boxArtImage = result.boxArtImage
        meta.latitude = result.latitude
        meta.longitude = result.longitude
        meta.teamId = result.teamId
        meta.teamOrganizationId = result.teamOrganizationId
        meta.hashtag = result.hashtag
        meta.urlSlug = result.urlSlug
        meta.gameId = result.gameId
        meta.quantifiable = result.quantifiable
        meta.homepageUrl = result.homepageUrl
        meta.isGenre = result.isGenre
        meta.updatedAt = result.updatedAt

        if (updateGroupsFunc) {
          meta.parentGroups = editGameGroupsState
        }

        if (updateGameAlternativeNamesFunc) {
          meta.alternativeNames = editGameAlternativeNamesState
        }

        return meta
      })

      newMetaList.sort(sortByEnglishNameOrJapaneseSort)
      setPreserveMetaListState(newMetaList)
      setMetaListState(newMetaList)
      setEditIdState(null)
      setEditTextState("")
      setEditNameJaState("")
      setEditSortJaState("")
      setEditIsGameDeveloperState(false)
      setEditPrimaryImageState("")
      setEditSecondaryImageState("")
      setEditBoxArtImageState("")
      setEditGameGroupsState([])
      setEditGameGroupsStateOriginal([])
      setEditLatitudeState("")
      setEditLongitudeState("")
      setEditTeamState(null)
      setEditTeamOrganizationState(null)
      setEditGameAlternativeNamesState([])
      setEditGameAlternativeNameInputState("")
      setEditGameAlternativeNamesOriginalState([])
      setEditHashtagState("")
      setEditSlugState("")
      setEditGameIdState(null)
      setEditQuantifiableState(0)
      setEditHomepageUrlState("")
      setEditIsGenreState(0)
      setEditParentTagsState([])
      setEditParentTagsStateOriginal([])
    } catch (err) {
      if (err instanceof Error) setErrorState(err)
    } finally {
      setIsSavingState(false)
      if (updateParentTagsFunc) {
        refreshTags()
      }
    }
  }

  // -- Helpers --
  const fieldSort = (a: MetaItem, b: MetaItem) => {
    if (sortParamsState.field === "ja") {
      const optionalA = a.sortJa || ""
      const optionalB = b.sortJa || ""

      return sortParamsState.sort === "asc"
        ? optionalA.localeCompare(optionalB, "ja")
        : optionalB.localeCompare(optionalA, "ja")
    }
    if (sortParamsState.field === "en") {
      const optionalA = a.name || ""
      const optionalB = b.name || ""
      return sortParamsState.sort === "asc"
        ? optionalA.localeCompare(optionalB)
        : optionalB.localeCompare(optionalA)
    }
    return sortParamsState.sort === "asc"
      ? a.updatedAt.localeCompare(b.updatedAt)
      : b.updatedAt.localeCompare(a.updatedAt)
  }

  return (
    <>
      {[...paginatedMetaListData].sort(fieldSort).map((meta) => (
        <TableList.BodyRow
          key={`meta-list-${meta.id}`}
          data-id={meta.id}
          sx={
            meta.id === focusIdState
              ? {
                  backgroundColor: (theme: { palette: { action: { focus: string } } }) =>
                    theme.palette.action.focus,
                }
              : {}
          }
        >
          {idFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              <Scroll.Element name={meta.id}>
                <div>{meta.id}</div>
              </Scroll.Element>
            </TableList.BodyCell>
          )}
          <TableList.BodyCell style={{ padding: "0px 16px" }}>
            <Scroll.Element name={meta.id}>
              {meta.id === editIdState ? (
                <TextField
                  label={t("meta.headers.name")}
                  variant="outlined"
                  value={editTextState}
                  onChange={(event) => setEditTextState(event.target.value)}
                  style={{ width: "100%", margin: "10px 0" }}
                  disabled={isSavingState}
                />
              ) : (
                <div>{meta.name}</div>
              )}
            </Scroll.Element>
          </TableList.BodyCell>
          <TableList.BodyCell style={{ padding: "0px 16px" }}>
            {meta.id === editIdState ? (
              <TextField
                label={t("meta.headers.nameJa")}
                variant="outlined"
                value={editNameJaState || ""}
                onChange={(event) => setEditNameJaState(event.target.value)}
                style={{ width: "100%", margin: "10px 0" }}
                disabled={isSavingState}
              />
            ) : (
              <div>{meta.nameJa}</div>
            )}
          </TableList.BodyCell>
          <TableList.BodyCell style={{ padding: "0px 16px" }}>
            {meta.id === editIdState ? (
              <TextField
                label={t("meta.headers.sortJa")}
                variant="outlined"
                value={editSortJaState || ""}
                onChange={(event) => setEditSortJaState(event.target.value)}
                style={{ width: "100%", margin: "10px 0" }}
                disabled={isSavingState}
              />
            ) : (
              <div>{meta.sortJa}</div>
            )}
          </TableList.BodyCell>
          {slugFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <TextField
                  label={t("meta.headers.slug")}
                  variant="outlined"
                  value={editSlugState}
                  onChange={(event) => setEditSlugState(event.target.value)}
                  style={{ width: "100%", margin: "10px 0" }}
                  disabled={isSavingState}
                />
              ) : (
                <div>{meta.urlSlug}</div>
              )}
            </TableList.BodyCell>
          )}
          {hashtagFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <TextField
                  label={t("meta.headers.hashtag")}
                  variant="outlined"
                  value={editHashtagState}
                  onChange={(event) => setEditHashtagState(event.target.value.replaceAll("#", ""))}
                  style={{ width: "100%", margin: "10px 0" }}
                  disabled={isSavingState}
                />
              ) : (
                <div>{meta.hashtag}</div>
              )}
            </TableList.BodyCell>
          )}
          {updateGameAlternativeNamesFunc && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState && (
                <>
                  {editGameAlternativeNamesState.map((name) => (
                    <Chip
                      key={name}
                      style={{ marginRight: "6px" }}
                      color="primary"
                      label={name}
                      onDelete={() =>
                        setEditGameAlternativeNamesState(
                          editGameAlternativeNamesState.filter((x) => x !== name)
                        )
                      }
                    />
                  ))}
                  <TextField
                    label={t("meta.headers.alternativeNames")}
                    value={editGameAlternativeNameInputState}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditGameAlternativeNamesState([
                                ...editGameAlternativeNamesState,
                                editGameAlternativeNameInputState,
                              ])
                              setEditGameAlternativeNameInputState("")
                            }}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => setEditGameAlternativeNameInputState(event.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setEditGameAlternativeNamesState([
                          ...editGameAlternativeNamesState,
                          editGameAlternativeNameInputState,
                        ])
                        setEditGameAlternativeNameInputState("")
                        e.preventDefault()
                      }
                    }}
                  />
                </>
              )}
              {meta.id !== editIdState &&
                Array.isArray(meta.alternativeNames) &&
                meta.alternativeNames.map((name) => (
                  <Chip
                    key={name}
                    style={{ marginRight: "6px" }}
                    color="primary"
                    label={name}
                  />
                ))}
            </TableList.BodyCell>
          )}
          {gameDeveloperFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              <Switch
                color="primary"
                name={meta.id}
                disabled={isSavingState || meta.id !== editIdState}
                checked={meta.id === editIdState ? editIsGameDeveloperState : meta.isGameDeveloper}
                onChange={() => setEditIsGameDeveloperState(!editIsGameDeveloperState)}
              />
            </TableList.BodyCell>
          )}
          {primaryImageFieldsAndRatio && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              <MediaUpload
                key="edit-primary"
                url={meta.id === editIdState ? editPrimaryImageState : meta.primaryImage}
                mediaType={metaTypeToMediaType(metaType)}
                onChange={(url) => setEditPrimaryImageState(url)}
                editable={meta.id === editIdState}
                aspect={primaryImageFieldsAndRatio}
                buttonText={t("meta.controls.chooseFile")}
              />
            </TableList.BodyCell>
          )}
          {secondaryImageFieldsAndRatio && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              <MediaUpload
                key="edit-secondary"
                url={meta.id === editIdState ? editSecondaryImageState : meta.secondaryImage}
                mediaType={metaTypeToMediaType(metaType)}
                onChange={(url) => setEditSecondaryImageState(url)}
                editable={meta.id === editIdState}
                aspect={secondaryImageFieldsAndRatio}
                buttonText={t("meta.controls.chooseFile")}
              />
            </TableList.BodyCell>
          )}
          {boxArtImageFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              <MediaUpload
                key="edit-gameArt"
                url={
                  meta.id === editIdState && editBoxArtImageState ? editBoxArtImageState : meta.boxArtImage
                }
                mediaType={metaTypeToMediaType(metaType)}
                onChange={(url) => setEditBoxArtImageState(url)}
                editable={meta.id === editIdState}
                aspect={3 / 4}
                buttonText={t("meta.controls.chooseFile")}
              />
            </TableList.BodyCell>
          )}
          {latLongFields && (
            <>
              <TableList.BodyCell style={{ padding: "0px 16px" }}>
                {meta.id === editIdState ? (
                  <TextField
                    label="GPS Latitude"
                    variant="outlined"
                    value={editLatitudeState}
                    onChange={(event) => setEditLatitudeState(event.target.value)}
                    style={{ margin: "10px 0" }}
                    disabled={isSavingState}
                  />
                ) : (
                  meta.latitude
                )}
              </TableList.BodyCell>
              <TableList.BodyCell style={{ padding: "0px 16px" }}>
                {meta.id === editIdState ? (
                  <TextField
                    label="GPS Longitude"
                    variant="outlined"
                    value={editLongitudeState}
                    onChange={(event) => setEditLongitudeState(event.target.value)}
                    style={{ margin: "10px 0" }}
                    disabled={isSavingState}
                  />
                ) : (
                  meta.longitude
                )}
              </TableList.BodyCell>
            </>
          )}
          {quantifiableFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <Checkbox
                  checked={editQuantifiableState === 1}
                  onChange={(_event, checked) => setEditQuantifiableState(checked ? 1 : 0)}
                />
              ) : (
                <Checkbox
                  checked={meta.quantifiable === 1}
                  disabled={editQuantifiableState === 1}
                />
              )}
            </TableList.BodyCell>
          )}
          {homepageUrlFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <TextField
                  label={t("meta.headers.homepageUrl")}
                  variant="outlined"
                  value={editHomepageUrlState}
                  onChange={(event) => setEditHomepageUrlState(event.target.value)}
                  style={{ margin: "10px 0" }}
                  disabled={isSavingState}
                />
              ) : (
                <>
                  {meta.homepageUrl && (
                    <Link
                      href={meta.homepageUrl}
                      target="_blank"
                    >
                      {new URL(meta.homepageUrl).hostname}
                    </Link>
                  )}
                </>
              )}
            </TableList.BodyCell>
          )}
          {updateParentTagsFunc && (
            <TableList.BodyCell style={{ minWidth: "250px" }}>
              {meta.id === editIdState ? (
                <Autocomplete
                  multiple
                  options={metaListState}
                  getOptionLabel={localisedLabel}
                  value={editParentTagsState}
                  isOptionEqualToValue={(option: Tag, value: Tag) => option.id === value.id}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        // key={option.id}
                        variant="outlined"
                        label={localisedLabel(option) as string}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(_, newValue) => {
                    setEditParentTagsState(newValue)
                  }}
                />
              ) : (
                <>
                  {
                    <Stack
                      gap={1}
                      direction="row"
                    >
                      {getParentTagsForId(tagHierarchy, meta.id).map((parentTag) => (
                        <Chip
                          label={localisedLabel(parentTag) as string}
                          key={parentTag.id}
                        />
                      ))}
                    </Stack>
                  }
                </>
              )}
            </TableList.BodyCell>
          )}
          {teamFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <DropdownSearchable
                  label="Team"
                  value={availableTeamsState?.find((team) => team.id === editTeamState)}
                  options={availableTeamsState}
                  onChange={(e?: Team) => setEditTeamState(e ? e.id : "")}
                  disabled={isSavingState}
                  // isOptionEqualToValue={(option: Team, value: Team) => option.id === value.id}
                />
              ) : (
                availableTeamsState?.find((team) => team.id === meta.teamId)?.name
              )}
            </TableList.BodyCell>
          )}
          {gameFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <DropdownSearchable
                  label="Game"
                  value={availableGamesState?.find((game) => game.id === editGameIdState)}
                  options={availableGamesState}
                  onChange={(e?: Game) => setEditGameIdState(e ? e.id : "")}
                  disabled={isSavingState}
                  // isOptionEqualToValue={(option: Game, value: Game) => option.id === value.id}
                />
              ) : (
                localisedLabel(availableGamesState?.find((game) => game.id === meta.gameId))
              )}
            </TableList.BodyCell>
          )}
          {teamOrganizationFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <DropdownSearchable
                  label="Team organization"
                  value={availableTeamOrganizationsState?.find(
                    (organization) => organization.id === editTeamOrganizationState
                  )}
                  options={availableTeamOrganizationsState}
                  onChange={(e?: TeamOrganization) => setEditTeamOrganizationState(e ? e.id : "")}
                  disabled={isSavingState}
                  // isOptionEqualToValue={(option: TeamOrganization, value: TeamOrganization) =>
                  //   option.id === value.id
                  // }
                />
              ) : (
                availableTeamOrganizationsState?.find(
                  (teamOrganization) => teamOrganization.id === meta.teamOrganizationId
                )?.name
              )}
            </TableList.BodyCell>
          )}
          {updateGroupsFunc && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <DropdownSearchable
                  label={t("meta.headers.groups")}
                  clearOnBlur
                  options={availableGameGroupsState}
                  multiple
                  value={editGameGroupsState}
                  onChange={setEditGameGroupsState}
                  // isOptionEqualToValue={(option: GameGroup, value: GameGroup) => option.id === value.id}
                />
              ) : (
                <div>
                  {meta.parentGroups && meta.parentGroups.length > 0
                    ? meta.parentGroups.map((g) => {
                        const group = availableGameGroupsState?.find((agg) => g.id === agg.id)
                        return group ? (
                          <Chip
                            key={g.id}
                            style={{ marginRight: "6px" }}
                            color="primary"
                            label={group.name}
                          />
                        ) : null
                      })
                    : null}
                </div>
              )}
            </TableList.BodyCell>
          )}
          {isGenreFields && (
            <TableList.BodyCell style={{ padding: "0px 16px" }}>
              {meta.id === editIdState ? (
                <Checkbox
                  checked={editIsGenreState === 1}
                  onChange={(_event, checked) => setEditIsGenreState(checked ? 1 : 0)}
                />
              ) : (
                <Checkbox
                  checked={meta.isGenre === 1}
                  disabled
                />
              )}
            </TableList.BodyCell>
          )}
          <TableList.BodyCell>
            {LocalMomentFromUTCDateTime(meta.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
          </TableList.BodyCell>
          <TableList.BodyCell style={{ padding: "0px 16px" }}>
            <div style={{ textAlign: "right" }}>
              {meta.id === editIdState ? (
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  <StyleButton
                    variant="contained"
                    onClick={() => {
                      setIsSavingState(true)
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      updateMeta(editIdState)
                    }}
                    disabled={
                      isSavingState === true ||
                      editTextState === "" ||
                      (slugFields && editSlugState === "") ||
                      (metaType === MetaType.TEAM && !editTeamOrganizationState)
                    }
                  >
                    {isSavingState ? <CircularProgress /> : t("common:actions.save")}
                  </StyleButton>
                  <Tooltip title={t("common:actions.cancel")}>
                    <span>
                      <IconButton
                        onClick={() => {
                          setEditTextState("")
                          setEditNameJaState("")
                          setEditSortJaState("")
                          setEditIsGameDeveloperState(false)
                          setEditPrimaryImageState("")
                          setEditSecondaryImageState("")
                          setEditBoxArtImageState("")
                          setEditLatitudeState("")
                          setEditLongitudeState("")
                          setEditTeamState(null)
                          setEditTeamOrganizationState(null)
                          setEditGameIdState(null)
                          setEditGameAlternativeNameInputState("")
                          setEditHashtagState("")
                          setEditSlugState("")
                          setEditQuantifiableState(0)
                          setEditHomepageUrlState("")
                          setEditIsGenreState(0)
                          setEditParentTagsState([])
                          setEditIdState(null)
                        }}
                        disabled={isSavingState === true || editTextState === ""}
                        sx={{ width: "32px", height: "32px" }}
                      >
                        <FontAwesomeIcon icon={faWindowClose} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              ) : (
                <React.Fragment>
                  {canCreateAndEditMeta && (
                    <Tooltip title={t("common:actions.edit")}>
                      <span>
                        <IconButton
                          sx={{ width: "32px", height: "32px" }}
                          onClick={() => {
                            setEditTextState(meta.name)
                            setEditNameJaState(meta.nameJa)
                            setEditSortJaState(meta.sortJa)
                            setEditIsGameDeveloperState(meta.isGameDeveloper)
                            setEditPrimaryImageState(meta.primaryImage)
                            setEditSecondaryImageState(meta.secondaryImage)
                            setEditBoxArtImageState(meta.boxArtImage)
                            setEditLatitudeState(meta.latitude)
                            setEditLongitudeState(meta.longitude)
                            setEditTeamState(meta.teamId)
                            setEditIdState(meta.id)
                            setEditSlugState(meta.urlSlug)
                            setEditGameGroupsState(
                              meta.parentGroups
                                ? meta.parentGroups.map((g) =>
                                    availableGameGroupsState?.find((pg) => g.id === pg.id)
                                  )
                                : []
                            )
                            setEditGameGroupsStateOriginal(
                              meta.parentGroups
                                ? meta.parentGroups.map((g) =>
                                    availableGameGroupsState?.find((pg) => g.id === pg.id)
                                  )
                                : []
                            )
                            setEditTeamOrganizationState(meta.teamOrganizationId)
                            setEditGameAlternativeNameInputState("")
                            setEditGameAlternativeNamesState(
                              Array.isArray(meta.alternativeNames) ? [...meta.alternativeNames] : []
                            )
                            setEditGameAlternativeNamesOriginalState(
                              Array.isArray(meta.alternativeNames) ? [...meta.alternativeNames] : []
                            )
                            setEditGameIdState(meta.gameId)
                            setEditHashtagState(meta.hashtag)
                            setEditQuantifiableState(meta.quantifiable)
                            setEditHomepageUrlState(meta.homepageUrl)
                            setEditParentTagsState(getParentTagsForId(tagHierarchy, meta.id))
                            setEditParentTagsStateOriginal(getParentTagsForId(tagHierarchy, meta.id))
                            setEditIsGenreState(meta.isGenre)
                          }}
                          disabled={isSavingState === true || editTextState !== ""}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {canDeleteMeta && (
                    <DeleteRecord
                      text={t("common:actions.delete")}
                      setDeleteMetaObjectState={setDeleteMetaObjectState}
                      meta={meta}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </TableList.BodyCell>
        </TableList.BodyRow>
      ))}
    </>
  )
}
