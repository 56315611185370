import type Language from "../../models/Taiyoro/Meta/Language"

const languages = [
  {
    id: "en",
    label: "EN",
    name: "English",
    flagIcon: "gb",
  },
  {
    id: "ja",
    label: "JA",
    name: "Japanese",
    flagIcon: "jp",
  },
  // {
  //   id: 'ab',
  //   label: 'AB', name: 'Abkhaz',
  // },
  // {
  //   id: 'aa',
  //   label: 'AA', name: 'Afar',
  // },
  {
    id: "af",
    label: "AF",
    name: "Afrikaans",
  },
  // {
  //   id: 'ak',
  //   label: 'AK', name: 'Akan',
  // },
  // {
  //   id: 'sq',
  //   label: 'SQ', name: 'Albanian',
  // },
  {
    id: "am",
    label: "AM",
    name: "Amharic",
  },
  {
    id: "ar",
    label: "AR",
    name: "Arabic",
  },
  // {
  //   id: 'an',
  //   label: 'AN', name: 'Aragonese',
  // },
  // {
  //   id: 'hy',
  //   label: 'HY', name: 'Armenian',
  // },
  {
    id: "as",
    label: "AS",
    name: "Assamese",
  },
  // {
  //   id: 'av',
  //   label: 'AV', name: 'Avaric',
  // },
  {
    id: "ae",
    label: "AE",
    name: "Avestan",
  },
  // {
  //   id: 'ay',
  //   label: 'AY', name: 'Aymara',
  // },
  {
    id: "az",
    label: "AZ",
    name: "Azerbaijani",
  },
  {
    id: "bm",
    label: "BM",
    name: "Bambara",
  },
  {
    id: "ba",
    label: "BA",
    name: "Bashkir",
  },
  {
    id: "eu",
    label: "EU",
    name: "Basque",
  },
  {
    id: "be",
    label: "BE",
    name: "Belarusian",
  },
  {
    id: "bn",
    label: "BN",
    name: "Bengali",
  },
  {
    id: "bh",
    label: "BH",
    name: "Bihari",
  },
  {
    id: "bi",
    label: "BI",
    name: "Bislama",
  },
  {
    id: "bs",
    label: "BS",
    name: "Bosnian",
  },
  {
    id: "br",
    label: "BR",
    name: "Breton",
  },
  {
    id: "bg",
    label: "BG",
    name: "Bulgarian",
  },
  {
    id: "my",
    label: "MY",
    name: "Burmese",
  },
  {
    id: "ca",
    label: "CA",
    name: "Catalan; Valencian",
  },
  {
    id: "ch",
    label: "CH",
    name: "Chamorro",
  },
  // {
  //   id: 'ce',
  //   label: 'CE', name: 'Chechen',
  // },
  // {
  //   id: 'ny',
  //   label: 'NY', name: 'Chichewa; Chewa; Nyanja',
  // },
  // {
  //   id: 'zh',
  //   label: 'ZH', name: 'Chinese',
  // },
  {
    id: "cv",
    label: "CV",
    name: "Chuvash",
  },
  {
    id: "kw",
    label: "KW",
    name: "Cornish",
  },
  {
    id: "co",
    label: "CO",
    name: "Corsican",
  },
  {
    id: "cr",
    label: "CR",
    name: "Cree",
  },
  {
    id: "hr",
    label: "HR",
    name: "Croatian",
  },
  // {
  //   id: 'cs',
  //   label: 'CS', name: 'Czech',
  // },
  // {
  //   id: 'da',
  //   label: 'DA', name: 'Danish',
  // },
  // {
  //   id: 'dv',
  //   label: 'DV', name: 'Divehi; Dhivehi; Maldivian;',
  // },
  {
    id: "nl",
    label: "NL",
    name: "Dutch",
  },
  // {
  //   id: 'eo',
  //   label: 'EO', name: 'Esperanto',
  // },
  {
    id: "et",
    label: "ET",
    name: "Estonian",
  },
  {
    id: "ee",
    label: "EE",
    name: "Ewe",
  },
  {
    id: "fo",
    label: "FO",
    name: "Faroese",
  },
  {
    id: "fj",
    label: "FJ",
    name: "Fijian",
  },
  {
    id: "fi",
    label: "FI",
    name: "Finnish",
  },
  {
    id: "fr",
    label: "FR",
    name: "French",
  },
  // {
  //   id: 'ff',
  //   label: 'FF', name: 'Fula; Fulah; Pulaar; Pular',
  // },
  {
    id: "gl",
    label: "GL",
    name: "Galician",
  },
  // {
  //   id: 'ka',
  //   label: 'KA', name: 'Georgian',
  // },
  {
    id: "de",
    label: "DE",
    name: "German",
  },
  // {
  //   id: 'el',
  //   label: 'EL', name: 'Greek, Modern',
  // },
  {
    id: "gn",
    label: "GN",
    name: "Guaraní",
  },
  {
    id: "gu",
    label: "GU",
    name: "Gujarati",
  },
  {
    id: "ht",
    label: "HT",
    name: "Haitian; Haitian Creole",
  },
  // {
  //   id: 'ha',
  //   label: 'HA', name: 'Hausa',
  // },
  // {
  //   id: 'he',
  //   label: 'HE', name: 'Hebrew (modern)',
  // },
  // {
  //   id: 'hz',
  //   label: 'HZ', name: 'Herero',
  // },
  // {
  //   id: 'hi',
  //   label: 'HI', name: 'Hindi',
  // },
  // {
  //   id: 'ho',
  //   label: 'HO', name: 'Hiri Motu',
  // },
  {
    id: "hu",
    label: "HU",
    name: "Hungarian",
  },
  // {
  //   id: 'ia',
  //   label: 'IA', name: 'Interlingua',
  // },
  {
    id: "id",
    label: "ID",
    name: "Indonesian",
  },
  {
    id: "ie",
    label: "IE",
    name: "Interlingue",
  },
  {
    id: "ga",
    label: "GA",
    name: "Irish",
  },
  // {
  //   id: 'ig',
  //   label: 'IG', name: 'Igbo',
  // },
  // {
  //   id: 'ik',
  //   label: 'IK', name: 'Inupiaq',
  // },
  {
    id: "io",
    label: "IO",
    name: "Ido",
  },
  {
    id: "is",
    label: "IS",
    name: "Icelandic",
  },
  {
    id: "it",
    label: "IT",
    name: "Italian",
  },
  // {
  //   id: 'iu',
  //   label: 'IU', name: 'Inuktitut',
  // },
  // {
  //   id: 'jv',
  //   label: 'JV', name: 'Javanese',
  // },
  // {
  //   id: 'kl',
  //   label: 'KL', name: 'Kalaallisut, Greenlandic',
  // },
  {
    id: "kn",
    label: "KN",
    name: "Kannada",
  },
  // {
  //   id: 'kr',
  //   label: 'KR', name: 'Kanuri',
  // },
  // {
  //   id: 'ks',
  //   label: 'KS', name: 'Kashmiri',
  // },
  // {
  //   id: 'kk',
  //   label: 'KK', name: 'Kazakh',
  // },
  {
    id: "km",
    label: "KM",
    name: "Khmer",
  },
  {
    id: "ki",
    label: "KI",
    name: "Kikuyu, Gikuyu",
  },
  {
    id: "rw",
    label: "RW",
    name: "Kinyarwanda",
  },
  {
    id: "ky",
    label: "KY",
    name: "Kirghiz, Kyrgyz",
  },
  // {
  //   id: 'kv',
  //   label: 'KV', name: 'Komi',
  // },
  {
    id: "kg",
    label: "KG",
    name: "Kongo",
  },
  {
    id: "kr",
    label: "KR",
    name: "Korean",
  },
  // {
  //   id: 'ko',
  //   label: 'KO', name: 'Korean',
  // },
  // {
  //   id: 'ku',
  //   label: 'KU', name: 'Kurdish',
  // },
  // {
  //   id: 'kj',
  //   label: 'KJ', name: 'Kwanyama, Kuanyama',
  // },
  {
    id: "la",
    label: "LA",
    name: "Latin",
  },
  {
    id: "lb",
    label: "LB",
    name: "Luxembourgish, Letzeburgesch",
  },
  // {
  //   id: 'lg',
  //   label: 'LG', name: 'Luganda',
  // },
  {
    id: "li",
    label: "LI",
    name: "Limburgish, Limburgan, Limburger",
  },
  // {
  //   id: 'ln',
  //   label: 'LN', name: 'Lingala',
  // },
  // {
  //   id: 'lo',
  //   label: 'LO', name: 'Lao',
  // },
  {
    id: "lt",
    label: "LT",
    name: "Lithuanian",
  },
  {
    id: "lu",
    label: "LU",
    name: "Luba-Katanga",
  },
  {
    id: "lv",
    label: "LV",
    name: "Latvian",
  },
  // {
  //   id: 'gv',
  //   label: 'GV', name: 'Manx',
  // },
  {
    id: "mk",
    label: "MK",
    name: "Macedonian",
  },
  {
    id: "mg",
    label: "MG",
    name: "Malagasy",
  },
  {
    id: "ms",
    label: "MS",
    name: "Malay",
  },
  {
    id: "ml",
    label: "ML",
    name: "Malayalam",
  },
  {
    id: "mt",
    label: "MT",
    name: "Maltese",
  },
  // {
  //   id: 'mi',
  //   label: 'MI', name: 'Māori',
  // },
  {
    id: "mr",
    label: "MR",
    name: "Marathi (Marāṭhī)",
  },
  {
    id: "mh",
    label: "MH",
    name: "Marshallese",
  },
  {
    id: "mn",
    label: "MN",
    name: "Mongolian",
  },
  {
    id: "na",
    label: "NA",
    name: "Nauru",
  },
  // {
  //   id: 'nv',
  //   label: 'NV', name: 'Navajo, Navaho',
  // },
  // {
  //   id: 'nb',
  //   label: 'NB', name: 'Norwegian Bokmål',
  // },
  // {
  //   id: 'nd',
  //   label: 'ND', name: 'North Ndebele',
  // },
  {
    id: "ne",
    label: "NE",
    name: "Nepali",
  },
  {
    id: "ng",
    label: "NG",
    name: "Ndonga",
  },
  // {
  //   id: 'nn',
  //   label: 'NN', name: 'Norwegian Nynorsk',
  // },
  {
    id: "no",
    label: "NO",
    name: "Norwegian",
  },
  // {
  //   id: 'ii',
  //   label: 'II', name: 'Nuosu',
  // },
  {
    id: "nr",
    label: "NR",
    name: "South Ndebele",
  },
  // {
  //   id: 'oc',
  //   label: 'OC', name: 'Occitan',
  // },
  // {
  //   id: 'oj',
  //   label: 'OJ', name: 'Ojibwe, Ojibwa',
  // },
  {
    id: "cu",
    label: "CU",
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
  },
  {
    id: "om",
    label: "OM",
    name: "Oromo",
  },
  // {
  //   id: 'or',
  //   label: 'OR', name: 'Oriya',
  // },
  // {
  //   id: 'os',
  //   label: 'OS', name: 'Ossetian, Ossetic',
  // },
  {
    id: "pa",
    label: "PA",
    name: "Panjabi, Punjabi",
  },
  // {
  //   id: 'pi',
  //   label: 'PI', name: 'Pāli',
  // },
  // {
  //   id: 'fa',
  //   label: 'FA', name: 'Persian',
  // },
  {
    id: "pl",
    label: "PL",
    name: "Polish",
  },
  {
    id: "ps",
    label: "PS",
    name: "Pashto, Pushto",
  },
  {
    id: "pt",
    label: "PT",
    name: "Portuguese",
  },
  // {
  //   id: 'qu',
  //   label: 'QU', name: 'Quechua',
  // },
  // {
  //   id: 'rm',
  //   label: 'RM', name: 'Romansh',
  // },
  // {
  //   id: 'rn',
  //   label: 'RN', name: 'Kirundi',
  // },
  {
    id: "ro",
    label: "RO",
    name: "Romanian, Moldavian, Moldovan",
  },
  {
    id: "ru",
    label: "RU",
    name: "Russian",
  },
  {
    id: "sa",
    label: "SA",
    name: "Sanskrit (Saṁskṛta)",
  },
  {
    id: "sc",
    label: "SC",
    name: "Sardinian",
  },
  {
    id: "sd",
    label: "SD",
    name: "Sindhi",
  },
  {
    id: "se",
    label: "SE",
    name: "Northern Sami",
  },
  {
    id: "sm",
    label: "SM",
    name: "Samoan",
  },
  {
    id: "sg",
    label: "SG",
    name: "Sango",
  },
  {
    id: "sr",
    label: "SR",
    name: "Serbian",
  },
  {
    id: "gd",
    label: "GD",
    name: "Scottish Gaelic; Gaelic",
  },
  {
    id: "sn",
    label: "SN",
    name: "Shona",
  },
  {
    id: "si",
    label: "SI",
    name: "Sinhala, Sinhalese",
  },
  {
    id: "sk",
    label: "SK",
    name: "Slovak",
  },
  {
    id: "sl",
    label: "SL",
    name: "Slovene",
  },
  {
    id: "so",
    label: "SO",
    name: "Somali",
  },
  {
    id: "st",
    label: "ST",
    name: "Southern Sotho",
  },
  {
    id: "es",
    label: "ES",
    name: "Spanish; Castilian",
  },
  // {
  //   id: 'su',
  //   label: 'SU', name: 'Sundanese',
  // },
  // {
  //   id: 'sw',
  //   label: 'SW', name: 'Swahili',
  // },
  {
    id: "ss",
    label: "SS",
    name: "Swati",
  },
  {
    id: "sv",
    label: "SV",
    name: "Swedish",
  },
  // {
  //   id: 'ta',
  //   label: 'TA', name: 'Tamil',
  // },
  // {
  //   id: 'te',
  //   label: 'TE', name: 'Telugu',
  // },
  {
    id: "tg",
    label: "TG",
    name: "Tajik",
  },
  {
    id: "th",
    label: "TH",
    name: "Thai",
  },
  // {
  //   id: 'ti',
  //   label: 'TI', name: 'Tigrinya',
  // },
  {
    id: "bo",
    label: "BO",
    name: "Tibetan Standard, Tibetan, Central",
  },
  {
    id: "tk",
    label: "TK",
    name: "Turkmen",
  },
  {
    id: "tl",
    label: "TL",
    name: "Tagalog",
  },
  {
    id: "tn",
    label: "TN",
    name: "Tswana",
  },
  {
    id: "to",
    label: "TO",
    name: "Tonga (Tonga Islands)",
  },
  {
    id: "tr",
    label: "TR",
    name: "Turkish",
  },
  // {
  //   id: 'ts',
  //   label: 'TS', name: 'Tsonga',
  // },
  {
    id: "tt",
    label: "TT",
    name: "Tatar",
  },
  {
    id: "tw",
    label: "TW",
    name: "Twi",
  },
  // {
  //   id: 'ty',
  //   label: 'TY', name: 'Tahitian',
  // },
  {
    id: "ug",
    label: "UG",
    name: "Uighur, Uyghur",
  },
  // {
  //   id: 'uk',
  //   label: 'UK', name: 'Ukrainian',
  // },
  // {
  //   id: 'ur',
  //   label: 'UR', name: 'Urdu',
  // },
  {
    id: "uz",
    label: "UZ",
    name: "Uzbek",
  },
  {
    id: "ve",
    label: "VE",
    name: "Venda",
  },
  {
    id: "vi",
    label: "VI",
    name: "Vietnamese",
  },
  // {
  //   id: 'vo',
  //   label: 'VO', name: 'Volapük',
  // },
  // {
  //   id: 'wa',
  //   label: 'WA', name: 'Walloon',
  // },
  {
    id: "cy",
    label: "CY",
    name: "Welsh",
  },
  // {
  //   id: 'wo',
  //   label: 'WO', name: 'Wolof',
  // },
  // {
  //   id: 'fy',
  //   label: 'FY', name: 'Western Frisian',
  // },
  // {
  //   id: 'xh',
  //   label: 'XH', name: 'Xhosa',
  // },
  // {
  //   id: 'yi',
  //   label: 'YI', name: 'Yiddish',
  // },
  // {
  //   id: 'yo',
  //   label: 'YO', name: 'Yoruba',
  // },
  {
    id: "za",
    label: "ZA",
    name: "Zhuang, Chuang",
  },
]

export const fetchLanguages = (): Language[] => {
  return languages.sort((a, b) => a.label.localeCompare(b.label))
}

export const fetchLanguageCodes = (): string[] => {
  return languages.map((language) => language.id)
}
