import { Typography } from "@mui/material"

interface Props {
  page: number
  pages: number
}

const SubPageCounter = (props: Props) => {
  return props.pages > 1 ? (
    <Typography
      position="absolute"
      top={126}
      right={48}
      fontWeight={300}
      fontSize="24px"
      textAlign="right"
    >
      {`${props.page + 1} / ${props.pages}`}
    </Typography>
  ) : (
    <></>
  )
}

export default SubPageCounter
