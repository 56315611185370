import { useContext } from "react"

import { useTheme } from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import moment from "moment"
import { ResponsiveContainer, ComposedChart, XAxis, Line, ReferenceLine, ReferenceArea } from "recharts"
import { UTCDateInJST } from "utils/tools"

export const Chart = () => {
  const {
    eventDate,
    selectedStream,
    streamIdColorMap,
    timeMarks,
    playhead,
    chartData,
    streamVodMap,
    streamViewershipData,
  } = useContext(EventDateBracketsContext)

  const theme = useTheme()

  const matchingVod = eventDate.platforms.find(
    (datePlatform) => datePlatform.id === streamVodMap[selectedStream.id]
  )

  const getClosestDateTime = (datetime: string) => {
    const momentTime = moment.utc(datetime)
    const index = chartData.findIndex((cd) => moment.utc(cd.datetime).isAfter(momentTime))
    if (index === -1) {
      return chartData[chartData.length - 1].datetime
    }
    if (index === 0) {
      return chartData[0].datetime
    }
    return chartData[Math.max(index - 1, 0)].datetime
  }

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      aspect={16 / 9}
    >
      <ComposedChart data={chartData}>
        <XAxis
          dataKey="datetime"
          tickFormatter={(value: string) => UTCDateInJST(value).format("HH:mm")}
        />
        {Object.keys(streamViewershipData).map((streamId) => (
          <Line
            type="monotone"
            key={streamId}
            dataKey={streamId}
            stroke={streamIdColorMap[streamId]}
            dot={false}
            connectNulls
            hide={!Object.keys(streamVodMap).includes(streamId)}
          />
        ))}
        {timeMarks.start !== "" && <ReferenceLine x={timeMarks.start} />}
        <ReferenceLine
          x={playhead}
          stroke={theme.palette.secondary.main}
        />
        {timeMarks.end && <ReferenceLine x={timeMarks.end} />}
        {matchingVod && selectedStream.liveBroadcastStartDatetime && (
          <ReferenceArea
            x1={getClosestDateTime(selectedStream.liveBroadcastStartDatetime)}
            x2={getClosestDateTime(
              moment
                .utc(selectedStream.liveBroadcastStartDatetime)
                .clone()
                .add(selectedStream.liveBroadcastDuration, "minutes")
                .format()
            )}
            fillOpacity={0.07}
            isAnimationActive={false}
            ifOverflow="visible"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
