import type Team from "../../models/Taiyoro/Meta/Team"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type TeamsResponse = {
  data?: {
    admin?: {
      team?: {
        get?: Array<Team>
      }
    }
  }
}

export const fetchTeams = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          team {
            get {
              id
              name
              nameJa
              sortJa
              teamOrganizationId
              primaryImage
              secondaryImage
              gameId
              urlSlug
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as TeamsResponse

    return response.data?.admin?.team?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch teams")
  }
}

export type TeamResponse = {
  data?: {
    admin?: {
      team?: {
        get?: Team | null
      }
    }
  }
}

export const fetchTeam = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          team {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              teamOrganizationId
              primaryImage
              secondaryImage
              urlSlug
              gameId
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as TeamResponse

    return response.data?.admin?.team?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch team")
  }
}

export type CreateTeamResponse = {
  data?: {
    admin?: {
      team?: {
        save?: Team | null
      }
    }
  }
}

export const createTeam = async (
  name: string,
  nameJa?: string,
  sortJa?: string,
  teamOrganizationId?: string,
  primaryImage?: string,
  secondaryImage?: string,
  urlSlug?: string,
  gameId?: string,
  id?: string
) => {
  try {
    const teamInput = [
      ...(id ? [`id: "${id}"`] : []),
      `name: "${name}"`,
      `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
      `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
      `teamOrganizationId: ${teamOrganizationId === "" ? "null" : JSON.stringify(teamOrganizationId)}`,
      `primaryImage: ${primaryImage === "" ? "null" : JSON.stringify(primaryImage)}`,
      `secondaryImage: ${secondaryImage === "" ? "null" : JSON.stringify(secondaryImage)}`,
      `urlSlug: ${urlSlug === "" ? "null" : JSON.stringify(urlSlug)}`,
      `gameId: ${gameId === "" ? "null" : JSON.stringify(gameId)}`,
    ].join(", ")

    console.log(teamInput)

    const response = (await GraphQL(
      `mutation {
      admin {
        team {
          save (team: {
            ${teamInput}
          }) {
            id
            name
            nameJa
            sortJa
            teamOrganizationId
            primaryImage
            secondaryImage
            urlSlug
            gameId
            updatedAt
          }
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreateTeamResponse

    return response.data?.admin?.team?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create team")
  }
}

export type DeleteTeamResponse = {
  data?: {
    admin?: {
      team?: {
        delete: Team
      }
    }
  }
}

export const deleteTeam = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        team {
          delete (
              teamId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeleteTeamResponse

    return response.data?.admin?.team?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete team")
  }
}
