import {
  faShuffle,
  faFilterCircleXmark,
  faListCheck,
  faMagnifyingGlass,
  faComments,
  faChartMixed,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, matchPath } from "react-router"

import { StyleNavLinkButton } from "../../PageLayout/Sidebar/styles"
import { StyleListItem, StyleListItemText } from "../../StyleMaterialUI"

const DataCollectionSidebarEntries = () => {
  const location = useLocation()

  const { t } = useTranslation("data-collection")

  const isDataCollectionRoute = matchPath(location.pathname, {
    path: "/data-collection",
  })

  return (
    <>
      {isDataCollectionRoute && (
        <>
          <StyleNavLinkButton
            exact
            to="/data-collection/suggestions"
          >
            <StyleListItem selected={location.pathname === "/data-collection/suggestions"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faListCheck} />
              </ListItemIcon>
              <StyleListItemText primary={t("suggestions.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
          <StyleNavLinkButton
            exact
            to="/data-collection/ignore"
          >
            <StyleListItem selected={location.pathname === "/data-collection/ignore"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
              </ListItemIcon>
              <StyleListItemText primary={t("ignore.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
          <StyleNavLinkButton
            exact
            to="/data-collection/list"
          >
            <StyleListItem selected={location.pathname === "/data-collection/explore"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </ListItemIcon>
              <StyleListItemText primary={t("explore.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
          <StyleNavLinkButton
            exact
            to="/data-collection/mappings"
          >
            <StyleListItem selected={location.pathname === "/data-collection/mappings"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faShuffle} />
              </ListItemIcon>
              <StyleListItemText primary={t("platformGameMappings.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
          <StyleNavLinkButton
            exact
            to="/data-collection/comments"
          >
            <StyleListItem selected={location.pathname === "/data-collection/comments"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faComments} />
              </ListItemIcon>
              <StyleListItemText primary={t("twitchComments.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
          <StyleNavLinkButton
            exact
            to="/data-collection/chat-density"
          >
            <StyleListItem selected={location.pathname === "/data-collection/chat-density"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faChartMixed} />
              </ListItemIcon>
              <StyleListItemText primary={t("chatDensity.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
        </>
      )}
    </>
  )
}

export default DataCollectionSidebarEntries
