import React, { useState } from "react"
import { useEffect } from "react"

import {
  faExclamationTriangle,
  IconDefinition,
  faCheckCircle,
  faRobot,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Tooltip } from "@mui/material"
import TableRow from "@mui/material/TableRow"
import { useTranslation } from "react-i18next"

import EventStream from "../../../../../../models/Taiyoro/Stream"
import isValidUrl from "../../../../../../utils/taiyoro/validators/streamUrl"
import { Cell, CategoryCell } from "./styles"

interface Props {
  id: string
  groupName: string
  model: EventStream
  flagIcon: string
  eventId: string
  dateId: string
  platformIcon: IconDefinition
}

const Stream = ({ id, groupName, model, flagIcon, platformIcon, eventId, dateId }: Props) => {
  const [viewsLiveConcurrentPeak, setViewsLiveConcurrentPeak] = useState(null)
  const [viewsLiveConcurrentAverage, setViewsLiveConcurrentAverage] = useState(null)
  const [viewsLiveMinutesWatched, setViewsLiveMinutesWatched] = useState(null)
  const [views1Week, setViews1Week] = useState(null)
  const [views1Month, setViews1Month] = useState(null)
  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    if ((model.viewsLiveConcurrentPeakManual || model.viewsLiveConcurrentPeak) !== null) {
      setViewsLiveConcurrentPeak(
        Number(model.viewsLiveConcurrentPeakManual || model.viewsLiveConcurrentPeak).toLocaleString()
      )
    }
    if ((model.viewsLiveConcurrentAverageManual || model.viewsLiveConcurrentAverage) !== null) {
      setViewsLiveConcurrentAverage(
        Number(model.viewsLiveConcurrentAverageManual || model.viewsLiveConcurrentAverage).toLocaleString()
      )
    }
    if ((model.viewsLiveMinutesWatchedManual || model.viewsLiveMinutesWatched) !== null) {
      setViewsLiveMinutesWatched(
        Number(model.viewsLiveMinutesWatchedManual || model.viewsLiveMinutesWatched).toLocaleString()
      )
    }
    if (model.isVod && (model.views1WeekManual || model.views1Week) !== null) {
      setViews1Week(Number(model.views1WeekManual || model.views1Week).toLocaleString())
    }
    if (model.isVod && (model.views1MonthManual || model.views1Month) !== null) {
      setViews1Month(Number(model.views1MonthManual || model.views1Month).toLocaleString())
    }
  }, [model])

  return (
    <TableRow>
      <CategoryCell>{groupName}</CategoryCell>
      <Cell>
        <Tooltip
          placement="bottom"
          title={model.url || ""}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              maxWidth: "250px",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <FontAwesomeIcon
              icon={platformIcon}
              size="lg"
              style={{ paddingRight: "8px" }}
            />
            <span data-id={model.id}>{model.url}</span>
          </div>
        </Tooltip>
      </Cell>
      <Cell align="center">
        {isValidUrl(model.url) && !model.dataCollectionErrored && (views1Week || viewsLiveMinutesWatched) && (
          <Tooltip
            placement="bottom"
            title={t("list.summary.dateList.dataCollectionSuccess")}
          >
            <span>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="lg"
                color="#1AAA55"
              />
            </span>
          </Tooltip>
        )}
        {!isValidUrl(model.url) && (
          <Tooltip
            placement="bottom"
            title={t("list.summary.dateList.invalidOrUnsupportedUrl")}
          >
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="lg"
                color="#ffcc01"
              />
            </span>
          </Tooltip>
        )}
        {isValidUrl(model.url) && model.dataCollectionErrored && (
          <Tooltip
            placement="bottom"
            title={t("list.summary.dateList.dataCollectionFailure")}
          >
            <span>
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="lg"
                color="red"
              />
            </span>
          </Tooltip>
        )}
        {!(views1Week || viewsLiveMinutesWatched) && model.automaticallyGenerated === 1 && (
          <Tooltip
            placement="bottom"
            title={t("list.summary.dateList.autoGenerated")}
          >
            <span>
              <FontAwesomeIcon
                icon={faRobot}
                size="lg"
                color="#a233f0"
              />
            </span>
          </Tooltip>
        )}
      </Cell>
      <Cell align="center">{flagIcon}</Cell>
      <Cell align="center">
        {viewsLiveConcurrentPeak && (
          <Link
            href={`/taiyoro/metrics/${eventId}/${id}/view`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {viewsLiveConcurrentPeak}
          </Link>
        )}
      </Cell>
      <Cell align="center">
        {viewsLiveConcurrentAverage && (
          <Link
            href={`/taiyoro/metrics/${eventId}/${dateId}/autoScannedCollectionMetrics`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {viewsLiveConcurrentAverage}
          </Link>
        )}
      </Cell>
      <Cell align="center">{viewsLiveMinutesWatched}</Cell>
      <Cell align="center">{views1Week}</Cell>
      <Cell align="center">{views1Month}</Cell>
      <Cell></Cell>
    </TableRow>
  )
}

export default Stream
