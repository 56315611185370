import { useEffect, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { LoadingButton } from "@mui/lab"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Stack,
  MenuItem,
  Select,
  Pagination,
  Alert,
  Snackbar,
  Typography,
  Chip,
  Link,
  Tooltip,
  Dialog,
  Button,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import LoadingBox from "../../components/LoadingBox"
import { StylePaper } from "../../components/StyleMaterialUI"
import {
  addToIgnoreList,
  fetchSuggestions,
  PLATFORM_CHOICES,
  removeFromIgnoreList,
} from "../../services/Taiyoro/dataCollection"

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

const PAGE_SIZE = 200

type Query = {
  platform: string
  page: number
}

const IgnoreSuggestions = () => {
  const { t } = useTranslation("data-collection")

  const { user } = useAuth0()

  const [loading, setLoading] = useState(false)

  const [suggestions, setSuggestions] = useState(null)

  const [errorState, setErrorState] = useState(false)

  const [query, setQuery] = useState<Query>({
    platform: PLATFORM_CHOICES[0].label,
    page: 1,
  })

  const [totalCount, setTotalCount] = useState<null | number>(null)

  const [dismissingItem, setDismissingItem] = useState(null)

  const [ignoringItem, setIgnoringItem] = useState(null)

  const [updateItemResponse, setUpdateItemResponse] = useState(null)

  const [batchIgnoreProgress, setBatchIgnoreProgress] = useState(0)

  const [showBatchIgnoreProgress, setShowBatchIgnoreProgress] = useState(false)

  const startBatchIgnore = async () => {
    setShowBatchIgnoreProgress(true)
    for (const suggestion of suggestions) {
      const response = await addToIgnoreList(
        suggestion.platformName,
        suggestion.userName,
        suggestion.channelUrl,
        user.nickname
      )
      const success = response === true
      if (success) {
        setBatchIgnoreProgress((previous) => previous + 1)
      } else {
        break
      }
    }
    setBatchIgnoreProgress(0)
    setShowBatchIgnoreProgress(false)
    load()
  }

  const load = async () => {
    setLoading(true)
    fetchSuggestions(query.platform, PAGE_SIZE * (query.page - 1), PAGE_SIZE)
      .then((response) => {
        setTotalCount(response.totalCount)
        setSuggestions(response.results)
      })
      .catch(() => {
        setErrorState(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    load()
  }, [query])

  const removeItemFromList = (item) => {
    setTotalCount(totalCount - 1)
    setSuggestions(suggestions.filter((suggestion) => suggestion !== item))
  }

  const handleDismissSuggestionItem = async (dismissItem) => {
    setDismissingItem(dismissItem)
    removeFromIgnoreList(
      dismissItem.platformName,
      dismissItem.userName,
      dismissItem.channelUrl,
      user.nickname
    )
      .then((response) => {
        const success = response === true
        setUpdateItemResponse(success)
        success && removeItemFromList(dismissItem)
      })
      .catch(() => setUpdateItemResponse(false))
      .finally(() => {
        setDismissingItem(null)
      })
  }

  const handleAddToIgnoreList = async (ignoreItem) => {
    setIgnoringItem(ignoreItem)
    addToIgnoreList(ignoreItem.platformName, ignoreItem.userName, ignoreItem.channelUrl, user.nickname)
      .then((response) => {
        const success = response === true
        setUpdateItemResponse(success)
        success && removeItemFromList(ignoreItem)
      })
      .catch(() => setUpdateItemResponse(false))
      .finally(() => {
        setIgnoringItem(null)
      })
  }

  return (
    <>
      <Dialog open={showBatchIgnoreProgress}>
        {suggestions && (
          <Box p={2}>
            <CircularProgressWithLabel
              size={80}
              value={(batchIgnoreProgress / suggestions.length) * 100}
            />
          </Box>
        )}
      </Dialog>
      <StylePaper>
        {!loading && errorState && <Alert severity="error">{t("ignore.apiError")}</Alert>}
        <Stack gap={1}>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Select
                required
                value={query.platform}
                onChange={(e) => {
                  setQuery({ platform: e.target.value, page: 1 })
                }}
              >
                {PLATFORM_CHOICES.map((platformChoice) => (
                  <MenuItem
                    key={platformChoice.label}
                    value={platformChoice.label}
                  >
                    {platformChoice.label}
                  </MenuItem>
                ))}
              </Select>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
              >
                <Typography>{t("suggestions.remaining")}</Typography>
                <Chip
                  label={totalCount || 0}
                  variant="outlined"
                  color="secondary"
                />
                <Tooltip title={t("")}>
                  <Button
                    color="success"
                    variant="outlined"
                    disabled={!suggestions || suggestions.length === 0}
                    onClick={startBatchIgnore}
                  >
                    {t("suggestions.batchIgnore", { count: suggestions ? suggestions.length : 0 })}
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Box>
          {loading && <LoadingBox />}
          {!loading && suggestions && totalCount === 0 && (
            <Alert severity="success">{t("suggestions.allDone")}</Alert>
          )}
          {!loading && suggestions && suggestions.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("explore.table.userName")}</TableCell>
                  <TableCell>{t("explore.table.streamTitle")}</TableCell>
                  <TableCell>{t("ignore.table.channelDescription")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {suggestions.map((item) => (
                  <TableRow key={`${item.platformName}-${item.userName}`}>
                    <TableCell>
                      <Link
                        target="_blank"
                        href={item.channelUrl}
                      >
                        {item.userName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.streamTitle}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {item.channelDescription}
                        {item.channelDescription?.length === 128 ? "..." : ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction="row"
                        gap={1}
                        justifyContent="flex-end"
                      >
                        <LoadingButton
                          variant="outlined"
                          color="success"
                          disabled={ignoringItem || dismissingItem}
                          loading={ignoringItem === item}
                          onClick={() => handleAddToIgnoreList(item)}
                        >
                          {t("suggestions.accept")}
                        </LoadingButton>
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          disabled={ignoringItem || dismissingItem}
                          loading={dismissingItem === item}
                          onClick={() => handleDismissSuggestionItem(item)}
                        >
                          {t("suggestions.dismiss")}
                        </LoadingButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {totalCount !== null && (
            <Pagination
              sx={{ alignSelf: "center" }}
              count={Math.ceil(totalCount / PAGE_SIZE)}
              page={query.page}
              onChange={(_event, newPageNumber) =>
                setQuery({ platform: query.platform, page: newPageNumber })
              }
            />
          )}
        </Stack>
      </StylePaper>
      <Snackbar
        open={updateItemResponse !== null}
        onClose={() => setUpdateItemResponse(null)}
      >
        <Alert severity={updateItemResponse === true ? "success" : "error"}>
          {updateItemResponse === true ? t("ignore.removeItemSuccess") : t("ignore.apiError")}
        </Alert>
      </Snackbar>
    </>
  )
}

export default IgnoreSuggestions
