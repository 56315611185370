import { useContext } from "react"

import { faHyphen, faStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Stack, Typography } from "@mui/material"
import SummaryBubble from "components/PlayData/EventReport//summary-bubble"
import Disclaimer from "components/PlayData/EventReport/disclaimer"
import Footer from "components/PlayData/EventReport/footer"
import Header from "components/PlayData/EventReport/header"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"

import { BorderedPage } from "./styles"

const EventSummary = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)
  const { t } = useTranslation("playdata")

  const percentileToStar = (percentile: number) => {
    if (percentile >= 95) {
      return 3
    }
    if (percentile >= 80) {
      return 2
    }
    if (percentile >= 50) {
      return 1
    }
    return undefined
  }

  return (
    <BorderedPage>
      <Stack
        height="100%"
        gap={4}
      >
        <Header title={t("sectionTitles.summary")} />
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="center"
          gap={12}
          direction="row"
          height="100%"
        >
          <SummaryBubble
            title={t("sectionTitles.pcu")}
            stars={percentileToStar(playDataEventReportData.summary.pcu.percentile)}
            value={playDataEventReportData.summary.pcu.value}
          />
          <SummaryBubble
            title={t("sectionTitles.acu")}
            stars={percentileToStar(playDataEventReportData.summary.acu.percentile)}
            value={playDataEventReportData.summary.acu.value}
          />
          <SummaryBubble
            title={t("sectionTitles.hoursWatched")}
            stars={percentileToStar(playDataEventReportData.summary.hoursWatched.percentile)}
            value={playDataEventReportData.summary.hoursWatched.value}
          />
          <SummaryBubble
            title={t("sectionTitles.broadcasts")}
            stars={percentileToStar(playDataEventReportData.summary.dateCount.percentile)}
            value={playDataEventReportData.summary.dateCount.value}
          />
        </Stack>
        <Disclaimer>
          <Stack gap={1}>
            <Box>
              <Typography
                fontSize="14px"
                component="span"
              >
                {t("summaryDisclaimer.line1")}
              </Typography>
              <Stack
                direction="row"
                display="inline-flex"
                gap={4}
                ml={2}
              >
                <Box color="common.green">
                  <FontAwesomeIcon
                    icon={faHyphen}
                    color="common.green"
                  />
                  <Typography
                    fontSize="14px"
                    component="span"
                    color="text.primary"
                    ml={2}
                  >
                    {t("summaryDisclaimer.noRank")}
                  </Typography>
                </Box>
                <Box color="common.green">
                  <FontAwesomeIcon
                    icon={faStar}
                    color="inherit"
                  />
                  <Typography
                    fontSize="14px"
                    color="text.primary"
                    component="span"
                    ml={2}
                  >
                    {t("summaryDisclaimer.star1")}
                  </Typography>
                </Box>
                <Box color="common.green">
                  <FontAwesomeIcon
                    icon={faStar}
                    color="inherit"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    color="inherit"
                  />
                  <Typography
                    fontSize="14px"
                    color="text.primary"
                    component="span"
                    ml={2}
                  >
                    {t("summaryDisclaimer.star2")}
                  </Typography>
                </Box>
                <Box color="common.green">
                  <FontAwesomeIcon
                    icon={faStar}
                    color="inherit"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    color="inherit"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    color="inherit"
                  />
                  <Typography
                    fontSize="14px"
                    color="text.primary"
                    component="span"
                    ml={2}
                  >
                    {t("summaryDisclaimer.star3")}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Typography fontSize="14px">{t("summaryDisclaimer.line2")}</Typography>
            <Typography fontSize="14px">{t("summaryDisclaimer.line3")}</Typography>
            <Typography fontSize="14px">{t("summaryDisclaimer.line4")}</Typography>
          </Stack>
        </Disclaimer>
        <Footer />
      </Stack>
    </BorderedPage>
  )
}

export default EventSummary
