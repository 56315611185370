import { useState } from "react"

import type { Post } from "services/Taiyoro/posts"

type PostFormDataField<T extends keyof Omit<Post, "id">> = {
  dirty: boolean
  value: Pick<Post, T>[T]
}

export type PostFormData = {
  [Property in keyof Omit<Post, "id">]: PostFormDataField<Property>
}

const initialData: PostFormData = {
  slug: {
    dirty: false,
    value: "",
  },
  publicationDatetime: {
    dirty: false,
    value: "",
  },
  published: {
    dirty: false,
    value: false,
  },
  type: {
    dirty: false,
    value: "news",
  },
  title: {
    dirty: false,
    value: "",
  },
  titleJa: {
    dirty: false,
    value: "",
  },
  body: {
    dirty: false,
    value: "",
  },
  bodyJa: {
    dirty: false,
    value: "",
  },
  ogImage: {
    dirty: false,
    value: "",
  },
  metaDescription: {
    dirty: false,
    value: "",
  },
  metaDescriptionJa: {
    dirty: false,
    value: "",
  },
}

export const usePostForm = () => {
  const [formState, setFormState] = useState<PostFormData>(initialData)

  const updateAll = (data: Post) => {
    const state = { ...formState }

    Object.keys(data).forEach((key) => {
      if (key in formState) {
        state[key] = {
          dirty: state[key].dirty,
          value: data[key] ?? "",
        }
      }
    })

    setFormState(state)
  }

  const updateTextField = (field: keyof Omit<Post, "id" | "published">, data: string) => {
    setFormState((prev) => ({ ...prev, [field]: { dirty: true, value: data } }))
  }

  const updatePublicationStatus = (status: boolean) => {
    setFormState((prev) => ({ ...prev, published: { dirty: true, value: status } }))
  }

  const validateTextField = (
    field: keyof Omit<Post, "id" | "published">,
    input: string,
    validationRule: (value: string) => string
  ) => {
    updateTextField(field, validationRule(input))
  }

  const setPristine = (field: keyof Omit<Post, "id" | "published">) => {
    setFormState((prev) => ({ ...prev, [field]: { ...prev[field], dirty: false } }))
  }

  const setAllPristine = () => {
    setFormState((prev) => {
      const state = { ...prev }
      for (const key in state) {
        state[key].dirty = false
      }
      return state
    })
  }

  return {
    formState,
    updateTextField,
    validateTextField,
    updatePublicationStatus,
    updateAll,
    setPristine,
    setAllPristine,
  }
}
