import React, { useState, useEffect, useContext } from "react"

import "@mui/material"
import { MetaPageContext } from "contexts/meta-page"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type Game from "models/Taiyoro/Meta/Game"
import type GameGroup from "models/Taiyoro/Meta/GameGroup"
import type Team from "models/Taiyoro/Meta/Team"
import type TeamOrganization from "models/Taiyoro/Meta/TeamOrganization"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Scroll from "react-scroll"
import type { ScrollOptions } from "react-scroll"
import { useLocation } from "react-use"
import { fetchGames } from "services/Taiyoro/games"

import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { fetchGameGroups } from "../../../services/Taiyoro/gameGroups"
import { fetchTeamOrganizations } from "../../../services/Taiyoro/teamOrganizations"
import { fetchTeams } from "../../../services/Taiyoro/teams"
import sortByEnglishNameOrJapaneseSort from "../../../utils/sortByEnglishNameOrJapaneseSort"
import ErrorDialog from "../../Dialogs/ErrorDialog"
import TableList from "../../Table/TableList"
import DeleteMeta from "../DeleteMeta"
import { MetaPageProvider } from "./MetaProvider"
import { LoadingProgress } from "./components/Loading"
import { CustomPagination } from "./components/Pagination"
import { TableBody } from "./components/Table/Body"
import { CreateAndEditMeta } from "./components/Table/CreateAndEdit"
import { TableHeaders } from "./components/Table/Headers"
import { NoItems } from "./components/Table/NoItems"
import { TableSearch } from "./components/Table/TableSearch"
import type {
  AddNewFunc,
  MetaItem,
  UpdateFunc,
  UpdateGroupsFunc,
  UpdateParentTagsFunc,
  UpdateGameAlternativeNamesFunc,
} from "./types"

interface Props {
  title: string
  fetchFunc: () => Promise<Array<MetaItem> | null>
  addNewFunc: AddNewFunc
  updateFunc: UpdateFunc
  deleteFunc: (id: string) => Promise<boolean>
  metaType: MetaType
  gameDeveloperFields?: boolean
  primaryImageFieldsAndRatio?: number
  secondaryImageFieldsAndRatio?: number
  boxArtImageFields?: boolean
  latLongFields?: boolean
  teamFields?: boolean
  teamOrganizationFields?: boolean
  gameFields?: boolean
  slugFields?: boolean
  quantifiableFields?: boolean
  hashtagFields?: boolean
  homepageUrlFields?: boolean
  isGenreFields?: boolean
  updateGroupsFunc?: UpdateGroupsFunc
  updateGameAlternativeNamesFunc?: UpdateGameAlternativeNamesFunc
  allowNonUniqueNames?: boolean | undefined
  updateParentTagsFunc?: UpdateParentTagsFunc
  idFields?: boolean
}

const MetaPageComponent = ({
  fetchFunc,
  addNewFunc,
  updateFunc,
  deleteFunc,
  metaType,
  gameDeveloperFields,
  primaryImageFieldsAndRatio,
  secondaryImageFieldsAndRatio,
  boxArtImageFields,
  latLongFields,
  teamFields,
  teamOrganizationFields,
  gameFields,
  hashtagFields,
  quantifiableFields,
  slugFields,
  homepageUrlFields,
  isGenreFields,
  updateGroupsFunc,
  updateGameAlternativeNamesFunc,
  allowNonUniqueNames,
  updateParentTagsFunc,
  idFields,
}: Props) => {
  // -- React Contexts --
  const {
    // Data
    metaListState,
    setMetaListState,
    setPreserveMetaListState,
    // Utility
    errorState,
    setErrorState,
    isLoadingState,
    setIsLoadingState,
    // Table Header Utility
    deleteMetaObjectState,
    setDeleteMetaObjectState,
  } = useContext(MetaPageContext)

  const { refreshTags } = useContext(TaiyoroDataContext)

  // -- React States --
  const [availableGameGroupsState, setAvailableGameGroupsState] = useState<Array<GameGroup> | null>([])
  const [availableTeamsState, setAvailableTeamsState] = useState<Array<Team> | null>([])
  const [availableTeamOrganizationsState, setAvailableTeamOrganizationsState] =
    useState<Array<TeamOrganization> | null>([])
  const [availableGamesState, setAvailableGamesState] = useState<Array<Game> | null>([])
  const [focusIdState, setFocusIdState] = useState<null | string>(null)

  // -- Declarations --
  const { t } = useTranslation(["taiyoro", "common"])

  const location = useLocation()

  const metaTypeToMediaType = (metaType: MetaType): MetaType | "" => {
    switch (metaType) {
      case MetaType.CASTER:
        return MetaType.CASTER.toLowerCase() as MetaType
      case MetaType.GAME:
        return MetaType.GAME.toLowerCase() as MetaType
      case MetaType.GAME_GROUP:
        return MetaType.GAME_GROUP.toLowerCase() as MetaType
      case MetaType.ORGANIZER:
        return MetaType.ORGANIZER.toLowerCase() as MetaType
      case MetaType.PLACEMENT:
        return MetaType.PLACEMENT.toLowerCase() as MetaType
      case MetaType.PLATFORM:
        return MetaType.PLATFORM.toLowerCase() as MetaType
      case MetaType.PRODUCER:
        return MetaType.PRODUCER.toLowerCase() as MetaType
      case MetaType.SPONSOR:
        return MetaType.SPONSOR.toLowerCase() as MetaType
      case MetaType.SIGNIFICANT_PLAYER:
        return MetaType.SIGNIFICANT_PLAYER.toLowerCase() as MetaType
      case MetaType.TAG:
        return MetaType.TAG.toLowerCase() as MetaType
      case MetaType.TEAM:
        return MetaType.TEAM.toLowerCase() as MetaType
      case MetaType.VENUE:
        return MetaType.VENUE.toLowerCase() as MetaType
      case MetaType.EVENT:
        return MetaType.EVENT.toLowerCase() as MetaType
      case MetaType.TEAM_ORGANIZATION:
        return MetaType.TEAM_ORGANIZATION.toLowerCase() as MetaType
      default:
        return ""
    }
  }

  const metaProps = {
    metaState: {
      idFields,
      slugFields,
      hashtagFields,
    },
    metaImages: {
      primaryImageFieldsAndRatio,
      secondaryImageFieldsAndRatio,
      boxArtImageFields,
    },
    metaFunctions: {
      updateGameAlternativeNamesFunc,
      updateGroupsFunc,
      updateParentTagsFunc,
      metaTypeToMediaType,
    },
    metaOthers: {
      gameDeveloperFields,
      metaType,
      latLongFields,
      quantifiableFields,
      homepageUrlFields,
      isGenreFields,
      teamFields,
      gameFields,
      availableTeamsState,
      teamOrganizationFields,
      availableGamesState,
      availableTeamOrganizationsState,
    },
  }

  // -- Handlers --
  const handleOnDeleted = (meta: { id: string }) => {
    setMetaListState(metaListState.filter((m) => m.id !== meta.id))
    if (updateParentTagsFunc) {
      refreshTags()
    }
  }

  // -- LifeCycle --
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const focusId = params.get("id")
    focusId && focusId !== focusIdState && setFocusIdState(params.get("id"))
  }, [location, focusIdState])

  useEffect(() => {
    let isMounted = true

    const getMeta = async () => {
      setIsLoadingState(true)
      try {
        if (updateGroupsFunc) {
          const result = await fetchGameGroups()
          if (isMounted) setAvailableGameGroupsState(result)
        }

        if (teamOrganizationFields) {
          const result = await fetchTeamOrganizations()
          if (isMounted) setAvailableTeamOrganizationsState(result)
        }

        if (gameFields) {
          const result = await fetchGames()
          if (isMounted) setAvailableGamesState(result)
        }

        if (teamFields) {
          const result = await fetchTeams()
          if (isMounted) setAvailableTeamsState(result)
        }

        const metas = await fetchFunc()
        if (metas !== null) {
          metas.sort(sortByEnglishNameOrJapaneseSort)

          metas.forEach((meta) => (meta.isGameDeveloper = !!meta.isGameDeveloper))

          if (isMounted) {
            setMetaListState(metas)
            setPreserveMetaListState(metas)
          }
        } else {
          if (isMounted) setErrorState(t("meta.errors.loading"))
        }
      } catch (err) {
        if (err instanceof Error && isMounted) setErrorState(err)
      } finally {
        if (isMounted) setIsLoadingState(false)
      }
    }

    getMeta().catch((err: Error) => {
      if (isMounted) setErrorState(err)
    })

    return () => {
      isMounted = false
    }
  }, [fetchFunc, updateGroupsFunc, teamOrganizationFields, gameFields, teamFields])

  useEffect(() => {
    if (focusIdState) {
      const timeoutId = setTimeout((): void => {
        const scroll = Scroll.scroller

        const scrollOptions: ScrollOptions = {
          offset: -100,
          isDynamic: true,
          smooth: true,
        }

        scroll.scrollTo(focusIdState, scrollOptions)
      }, 0)

      return () => clearTimeout(timeoutId)
    }
  }, [metaListState, focusIdState])

  return (
    <>
      <DeleteMeta
        meta={deleteMetaObjectState}
        type={metaType}
        replacementList={metaListState}
        onModalClosed={() => setDeleteMetaObjectState(null)}
        deleteFunc={deleteFunc}
        onDeleted={handleOnDeleted}
      />

      <TableSearch placeholderText={t("page.search.placeholder")} />

      <TableList size={"medium"}>
        <TableList.Header>
          <TableHeaders {...metaProps} />
        </TableList.Header>
        <TableList.Body>
          <CreateAndEditMeta
            {...metaProps}
            allowNonUniqueNames={allowNonUniqueNames}
            addNewFunc={addNewFunc}
          />
          {!isLoadingState && (
            <TableBody
              {...metaProps}
              focusIdState={focusIdState}
              availableGameGroupsState={availableGameGroupsState}
              updateFunc={updateFunc}
            />
          )}
          {!isLoadingState && metaListState.length === 0 && <NoItems text={t("meta.noItems")} />}
          {isLoadingState && <LoadingProgress />}
        </TableList.Body>
      </TableList>

      <CustomPagination />

      <ErrorDialog
        open={errorState !== null}
        message={typeof errorState === "string" ? errorState : (errorState?.message ?? "")}
        onClose={() => setErrorState(null)}
      />
    </>
  )
}

const MetaPage = (props) => {
  return (
    <MetaPageProvider>
      <MetaPageComponent {...props} />
    </MetaPageProvider>
  )
}

MetaPageComponent.propTypes = {
  title: PropTypes.string.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  addNewFunc: PropTypes.func.isRequired,
  updateFunc: PropTypes.func.isRequired,
  updateGroupsFunc: PropTypes.func,
}

export default MetaPage
