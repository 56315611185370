import { useState } from "react"

import {
  Box,
  Button,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { generateTwitchChatAnalytics } from "services/Taiyoro/dataCollection"

type ApiResponse = {
  dateId: string
  eventId: string
  datePlatformId: string
  eventName: string
  url: string
}

const TwitchComments = () => {
  const [twitchClientId, setTwitchClientId] = useState("")
  const [vodUrl, setVodUrl] = useState("")
  const [error, setError] = useState<null | string>(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<ApiResponse>>([])

  const { t } = useTranslation("data-collection")

  async function generateAnalytics() {
    setError(null)

    if (twitchClientId === "" || vodUrl === "") {
      setError(t("twitchComments.errorFieldsMissing"))
      return
    }

    setLoading(true)

    await generateTwitchChatAnalytics({
      twitchClientId,
      vodUrl,
    })
      .then((res) => {
        const newRow = res as ApiResponse
        const newData = [...data]
        newData.unshift(newRow)
        setData(newData)
      })
      .catch((err: string) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {error && (
        <Box
          mb={2}
          color="red"
        >
          {error}
        </Box>
      )}
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
      >
        <TextField
          label={t("twitchComments.twitchClientId")}
          value={twitchClientId}
          onChange={(e) => setTwitchClientId(e.target.value)}
        />
        <TextField
          label={t("twitchComments.vodUrl")}
          value={vodUrl}
          onChange={(e) => setVodUrl(e.target.value)}
        />
        {loading && (
          <Stack
            ml={3}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        )}
        {!loading && (
          <Button
            variant="contained"
            onClick={() => void generateAnalytics()}
          >
            {t("twitchComments.retrieve")}
          </Button>
        )}
      </Stack>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("twitchComments.tableHeading.dateId")}</TableCell>
            <TableCell>{t("twitchComments.tableHeading.eventId")}</TableCell>
            <TableCell>{t("twitchComments.tableHeading.datePlatformId")}</TableCell>
            <TableCell>{t("twitchComments.tableHeading.eventName")}</TableCell>
            <TableCell>{t("twitchComments.tableHeading.url")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRow key={item.dateId}>
                <TableCell>{item.dateId}</TableCell>
                <TableCell>{item.eventId}</TableCell>
                <TableCell>{item.datePlatformId}</TableCell>
                <TableCell>{item.eventName}</TableCell>
                <TableCell>{item.url}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default TwitchComments
