import { useEffect, useState } from "react"
import React from "react"

import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type { TagHierarchy } from "models/Taiyoro/Meta/Tag"
import type { EventListSearchParams } from "models/Taiyoro/event"
import moment from "moment-timezone"
import { fetchTagHierarchy } from "services/Taiyoro/tags"

export const DEFAULT_CALENDAR_SEARCH_PARAMS: EventListSearchParams = {
  mode: "calendar",
  month: moment(),
}

export const DEFAULT_TAGS_SEARCH_PARAMS: EventListSearchParams = {
  mode: "tags",
  filterTags: [],
}

const TaiyoroDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadingTags, setLoadingTags] = useState(false)
  const [tagHierarchy, setTagHierarchy] = useState<TagHierarchy | null>(null)
  const [eventListSearchParams, setEventListSearchParams] = useState<EventListSearchParams>(
    DEFAULT_CALENDAR_SEARCH_PARAMS
  )

  const loadTags = async () => {
    if (loadingTags) return

    setLoadingTags(true)

    try {
      const response = await fetchTagHierarchy()
      setTagHierarchy(response)
    } catch (error) {
      console.error("Failed to fetch tag hierarchy", error)
    } finally {
      setLoadingTags(false)
    }
  }

  useEffect(() => {
    loadTags().catch((error) => {
      console.error("Unexpected error:", error)
    })
  }, [])

  return (
    <TaiyoroDataContext.Provider
      value={{
        tagHierarchy,
        loadingTags,
        refreshTags: () => void loadTags(),
        eventListSearchParams,
        setEventListSearchParams,
      }}
    >
      {children}
    </TaiyoroDataContext.Provider>
  )
}

export default TaiyoroDataProvider
