import { useContext } from "react"

import { MenuItem, TextField } from "@mui/material"
import {
  DEFAULT_CALENDAR_SEARCH_PARAMS,
  DEFAULT_TAGS_SEARCH_PARAMS,
} from "components/Taiyoro/TaiyoroDataProvider"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import { useTranslation } from "react-i18next"

const SearchSelector = () => {
  const { eventListSearchParams, setEventListSearchParams } = useContext(TaiyoroDataContext)

  const { t } = useTranslation("taiyoro")

  const handleChange = (mode: string) => {
    if (mode === "tags") {
      setEventListSearchParams(DEFAULT_TAGS_SEARCH_PARAMS)
    }
    if (mode === "calendar") {
      setEventListSearchParams(DEFAULT_CALENDAR_SEARCH_PARAMS)
    }
  }

  return (
    <TextField
      value={eventListSearchParams.mode}
      label={t("list.mode.label")}
      onChange={(event) => handleChange(event.target.value)}
      select
    >
      <MenuItem value="calendar">{t("list.mode.calendar.label")}</MenuItem>
      <MenuItem value="tags">{t("list.mode.tags.label")}</MenuItem>
    </TextField>
  )
}

export default SearchSelector
