export default class Sponsor {
  id: string
  // eslint-disable-next-line
  name: string
  nameJa: string
  sortJa: string
  updatedAt: string

  constructor(id, name, nameJa, sortJa, updatedAt: string) {
    this.id = id
    this.name = name
    this.nameJa = nameJa
    this.sortJa = sortJa
    this.updatedAt = updatedAt
  }
}
