import { Switch } from "@mui/material"
import { styled } from "@mui/material/styles"

export const PublishSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "& + .MuiSwitch-track": {
      backgroundColor: theme.palette.warning.main,
    },
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.main,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.warning.main,
  },
}))
