import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

interface SignMediaS3Response {
  data: {
    admin: {
      media: {
        sign: {
          signedRequest: string
          url: string
        }
      }
    }
  }
}

export const signMediaS3 = async (fileType: string, mediaType: string, extension: string) => {
  return GraphQL(
    `mutation {
          admin {
            media {
              sign ( 
                media: {
                    fileType: "${fileType}"
                    mediaType: "${mediaType}"
                    extension: "${extension}"
                }
              )
              {
                signedRequest
                url
              }
            }
          }
        }`,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((result: SignMediaS3Response) => result.data.admin.media.sign)
}
