import React from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { Route, Redirect } from "react-router-dom"

const RoleGuardedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { user } = useAuth0()

  return (
    <Route
      {...rest}
      render={(props) =>
        allowedRoles.includes(user["user_metadata"]?.role) ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  )
}

export default RoleGuardedRoute
