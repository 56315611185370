import React, { useState, useEffect } from "react"

import {
  faCalendarDay,
  faCalendarPen,
  faExclamationTriangle,
  faList,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import InvalidStateDialog from "components/Dialogs/ErrorDialog/InvalidStateDialog"
import { StyleBox } from "components/StyleMaterialUI"
import DataRecoveryDialog from "components/Taiyoro/CreateEvent/DataRecoveryDialog"
import DataRecoveryProvider from "components/Taiyoro/CreateEvent/DataRecoveryProvider"
import Dates from "components/Taiyoro/CreateEvent/Dates"
import EventGames from "components/Taiyoro/CreateEvent/EventGames"
import Information from "components/Taiyoro/CreateEvent/Information"
import Registration from "components/Taiyoro/CreateEvent/Registration"
import EventSponsors from "components/Taiyoro/CreateEvent/Sponsors"
import DeleteEventDialog from "components/Taiyoro/DeleteEventDialog"
import PublishToggle from "components/Taiyoro/PublishToggle"
import ViewLogs from "components/Taiyoro/ViewLogs"
import { EventContext } from "contexts/event"
import { errorContext } from "index"
import { PublishedState } from "models/Taiyoro/event"
import { UserRole } from "models/UserManagement"
import { useTranslation } from "react-i18next"
import { useHistory, withRouter } from "react-router-dom"
import { Link as ReactRouterDomLink } from "react-router-dom"
import { useDebounce } from "react-use"
import { fetchCasters } from "services/Taiyoro/casters"
import { fetchCurrencies } from "services/Taiyoro/currencies"
import { deleteEvent, editEvent, fetchEvent } from "services/Taiyoro/event"
import { fetchGames, createGame } from "services/Taiyoro/games"
import { fetchOrganizers } from "services/Taiyoro/organizers"
import { fetchPlacements } from "services/Taiyoro/placements"
import { fetchPlatforms } from "services/Taiyoro/platforms"
import { fetchProducers } from "services/Taiyoro/producers"
import { fetchSignificantPlayers } from "services/Taiyoro/significantPlayers"
import { fetchSponsors } from "services/Taiyoro/sponsors"
import { createSponsor } from "services/Taiyoro/sponsors"
import { fetchTeams } from "services/Taiyoro/teams"
import { fetchVenues } from "services/Taiyoro/venues"
import { HIGH_ACCESS_ROLES } from "utils/roles"
import sortByEnglishNameOrJapaneseSort from "utils/sortByEnglishNameOrJapaneseSort"
import { openMetaEditNewTab } from "utils/uri"
import useRolesCanAccess from "utils/useRolesCanAccess"

import { TaiyoroEventLink } from "./taiyoro-event-link"

const Edit = (props) => {
  const history = useHistory()
  const [initialLoadingState, setInitialLoadingState] = useState(true)
  const [loadingState, setLoadingState] = useState(true)
  const [savingState, setSavingState] = useState(false)

  const [availablePlayersState, setAvailablePlayersState] = useState([])
  const [availableGamesState, setAvailableGamesState] = useState([])
  const [availableOrganizersState, setAvailableOrganizersState] = useState([])
  const [availableVenuesState, setAvailableVenuesState] = useState([])
  const [availableTeamsState, setAvailableTeamsState] = useState([])
  const [availableSponsorsState, setAvailableSponsorsState] = useState([])
  const [availablePlatformsState, setAvailablePlatformsState] = useState([])
  const [availableCurrenciesState, setAvailableCurrenciesState] = useState([])
  const [availableProducersState, setAvailableProducersState] = useState([])
  const [availableCastersState, setAvailableCastersState] = useState([])
  const [availablePlacementsState, setAvailablePlacementsState] = useState([])

  const [loadedModelState, setLoadedModelState] = useState(null)

  const [publishedState, setPublishedState] = useState(0)
  const [gamesState, setGamesState] = useState([])
  const [datesState, setDatesState] = useState([])
  const [errorState] = errorContext()

  const [additionDeletionQueueState, setAdditionDeletionQueueState] = useState([])
  const [showDeleteDialogState, setShowDeleteDialogState] = useState(false)

  const [forceOpenInvalidStateModal, setForceOpenInvalidStateModal] = useState(false)

  const { t } = useTranslation(["taiyoro", "common"])

  const isHighAccessUser = useRolesCanAccess(HIGH_ACCESS_ROLES)

  const isEditableToEditor = useRolesCanAccess([UserRole.EDITOR]) && publishedState === PublishedState.Draft

  const canDeleteEvent = isHighAccessUser || isEditableToEditor

  const fetchMeta = async () => {
    const [
      games,
      teams,
      organizers,
      sponsors,
      venues,
      platforms,
      players,
      currencies,
      producers,
      placements,
    ] = await Promise.all([
      fetchGames(),
      fetchTeams(),
      fetchOrganizers(),
      fetchSponsors(),
      fetchVenues(),
      fetchPlatforms(),
      fetchSignificantPlayers(),
      fetchCurrencies(),
      fetchProducers(),
      fetchPlacements(),
    ])
    setAvailableGamesState(games.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailableTeamsState(teams.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailableOrganizersState(organizers.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailableSponsorsState(sponsors.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailableVenuesState(venues.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailablePlatformsState(
      (platforms || []).sort((a) => {
        // NOTE: These names are reliant on the database entries not being renamed.
        // This is not a good way of doing things
        // @TODO meta platforms need to have a sort order db column
        if (a.name === "Youtube") return -1
        if (a.name === "Twitch") return -1

        return 0
      })
    )
    setAvailableProducersState(producers.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailablePlayersState(players.sort(sortByEnglishNameOrJapaneseSort) || [])
    setAvailableCurrenciesState(currencies.sort((a, b) => a.localeCompare(b)) || [])
    setAvailableCastersState(
      (await fetchCasters().then((casters) => casters.sort(sortByEnglishNameOrJapaneseSort))) || []
    )
    setAvailablePlacementsState(placements.sort(sortByEnglishNameOrJapaneseSort) || [])
    setInitialLoadingState(false)
  }

  useEffect(() => {
    fetchMeta()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setPublishedState(0)
    setGamesState([])
    setDatesState([])
    setAdditionDeletionQueueState([])
    setLoadedModelState(null)
    // eslint-disable-next-line
  }, [props.match.params.eventId])

  useEffect(() => {
    const fetchEventFromAPI = async (id) => {
      let event = await fetchEvent(id)
      if (event) {
        setLoadedModelState(event)
        setPublishedState(event.published)
        setGamesState(event.games)

        setDatesState(event.dates)
      }
    }

    if (!initialLoadingState && props.match.params.eventId) {
      fetchEventFromAPI(props.match.params.eventId)
    }
    // eslint-disable-next-line
  }, [initialLoadingState])

  useEffect(() => {
    //Stop loading for edit page
    if (loadedModelState && !initialLoadingState && loadingState) {
      setLoadingState(false)
    }
  }, [loadedModelState, loadingState, initialLoadingState])

  const processAdditionDeletionQueue = async () => {
    setSavingState(true)
    const toProcess = additionDeletionQueueState.slice()
    for (let i = 0; i < toProcess.length; i++) {
      await toProcess[i]()
    }
    setAdditionDeletionQueueState(additionDeletionQueueState.filter((item) => !toProcess.includes(item)))
    setSavingState(false)
  }

  useDebounce(
    () => {
      additionDeletionQueueState.length > 0 && processAdditionDeletionQueue()
    },
    700,
    [additionDeletionQueueState]
  )

  const handlePublishChange = (published) => {
    setPublishedState(published)
  }

  const onGamesUpdate = (games) => {
    setGamesState(games)
  }

  const onDatesUpdate = (dates) => {
    setDatesState([...dates])
  }

  return (
    <>
      <Box>
        <Breadcrumbs>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            color="inherit"
            component={ReactRouterDomLink}
            to="/taiyoro/event/list"
          >
            <FontAwesomeIcon
              icon={faList}
              transform="down-2"
            />
            {t("list.pageTitle")}
          </Link>
          <Link
            component={ReactRouterDomLink}
            underline="hover"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            color="inherit"
            to={`/taiyoro/event/${props.match.params.eventId}`}
          >
            <FontAwesomeIcon icon={faCalendarDay} />
            {loadedModelState && loadedModelState.name}
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            color="text.primary"
          >
            <FontAwesomeIcon icon={faCalendarPen} />
            {t("common:actions.edit")}
          </Typography>
        </Breadcrumbs>
      </Box>
      {savingState && (
        <StyleBox ml={6}>
          <Grid container>
            <Grid item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "#3f51b5",
                  fontSize: "14px",
                  marginRight: "6px",
                }}
              >
                {t("edit.saving")}
              </div>
            </Grid>
            <Grid item>
              <CircularProgress size={20}></CircularProgress>
            </Grid>
          </Grid>
        </StyleBox>
      )}
      <Box pt="12px">
        {(initialLoadingState || loadingState) && <CircularProgress />}
        {!initialLoadingState && !loadingState && loadedModelState && (
          <EventContext.Provider value={[loadedModelState, setLoadedModelState]}>
            <DataRecoveryProvider availablePlatforms={availablePlatformsState}>
              <Box marginBottom="12px">
                <PublishToggle
                  published={publishedState}
                  onPublishedChanged={handlePublishChange}
                  updateFunc={(value) => editEvent(loadedModelState.id, "published", value)}
                />
                {errorState.length > 0 && (
                  <Button
                    sx={{
                      marginRight: "8px",
                    }}
                    onClick={() => setForceOpenInvalidStateModal(true)}
                    color="warning"
                    variant="contained"
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span style={{ marginLeft: "8px" }}>
                      {t("edit.errors.indicator")}
                      {errorState.length}
                      {t("edit.errors.counter")}
                    </span>
                  </Button>
                )}
                <ViewLogs id={loadedModelState.id} />
                {canDeleteEvent && (
                  <Button
                    onClick={() => setShowDeleteDialogState(true)}
                    variant="contained"
                    color="error"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    <span style={{ marginLeft: "8px" }}>{t("edit.deleteEvent")}</span>
                  </Button>
                )}
              </Box>
              {publishedState === PublishedState.Published && <TaiyoroEventLink />}
              <Information
                loadedModel={loadedModelState}
                availableOrganizers={availableOrganizersState}
                availableCurrencies={availableCurrenciesState}
                availableProducers={availableProducersState}
                availableCasters={availableCastersState}
              />
              <Registration />
              <EventSponsors
                loadedModel={loadedModelState}
                availableSponsors={availableSponsorsState}
                availableCurrencies={availableCurrenciesState}
                availableDates={datesState}
                onDelete={(callback) => {
                  if (loadedModelState !== null) {
                    setAdditionDeletionQueueState([...additionDeletionQueueState, callback])
                  }
                }}
                onCreateSponsor={(value) => {
                  const f = async (value) => {
                    const result = await createSponsor(value)
                    setAvailableSponsorsState([result, ...availableSponsorsState])
                    openMetaEditNewTab("sponsors")
                    setSavingState(false)
                    return result
                  }
                  setSavingState(true)
                  return f(value)
                }}
              />
              <EventGames
                loadedModel={loadedModelState}
                availableCurrencies={availableCurrenciesState}
                availableGames={availableGamesState}
                availablePlayers={availablePlayersState}
                availableTeams={availableTeamsState}
                availablePlacements={availablePlacementsState}
                onUpdate={onGamesUpdate}
                onSave={(callback) => {
                  if (loadedModelState) {
                    setAdditionDeletionQueueState([...additionDeletionQueueState, callback])
                  }
                  return true
                }}
                onDelete={(id, callback) => {
                  if (datesState.find((date) => date.game === id)) {
                    return false
                  }
                  if (datesState.length > 0 && gamesState.length === 1) {
                    return false
                  }
                  if (loadedModelState && id) {
                    setAdditionDeletionQueueState([...additionDeletionQueueState, callback])
                  }
                  return true
                }}
                onCreateGame={(value) => {
                  const f = async (value) => {
                    const result = await createGame(value)
                    setAvailableGamesState([result, ...availableGamesState])
                    openMetaEditNewTab("games")
                    return result
                  }
                  return f(value)
                }}
              />
              <Dates
                gamesState={gamesState}
                availableGames={availableGamesState}
                availablePlatforms={availablePlatformsState}
                availableVenues={availableVenuesState}
                onUpdate={onDatesUpdate}
                onDelete={(callback) =>
                  setAdditionDeletionQueueState([...additionDeletionQueueState, callback])
                }
                onDeleteStream={(callback) =>
                  setAdditionDeletionQueueState([...additionDeletionQueueState, callback])
                }
              />
              <DataRecoveryDialog />
            </DataRecoveryProvider>
          </EventContext.Provider>
        )}
      </Box>
      <InvalidStateDialog
        forceOpen={forceOpenInvalidStateModal}
        onModalClosed={() => setForceOpenInvalidStateModal(false)}
      />
      <DeleteEventDialog
        open={showDeleteDialogState === true}
        model={loadedModelState}
        onDelete={async () => {
          const resp = await deleteEvent(loadedModelState.id)
          resp && history.push("/taiyoro/event/list")
        }}
        onClose={() => setShowDeleteDialogState(false)}
      />
    </>
  )
}

export default withRouter(Edit)
