import { useContext, useEffect, useState } from "react"

import { Autocomplete, Chip, TextField, autocompleteClasses } from "@mui/material"
import clsx from "clsx"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type { Tag, TagNode } from "models/Taiyoro/Meta/Tag"
import { useTranslation } from "react-i18next"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

import { GroupableTags } from "./styles"

export type FlattenedHierarchy = {
  tags: Array<Tag>
  groupIds: Array<string>
}

const sortNoGroupTagsToTheTop = (tagNodeA: TagNode, tagNodeB: TagNode) =>
  tagNodeA.children.length - tagNodeB.children.length

export const flattenTagHierarchy = (tagHierarchy: TagNode[]): FlattenedHierarchy => {
  let flattenedTags: Array<Tag> = []
  let groupIds: Array<string> = []

  for (const tagNode of [...tagHierarchy].sort(sortNoGroupTagsToTheTop)) {
    const { tag, children } = tagNode

    flattenedTags.push(tag)
    const { tags: childTags } = flattenTagHierarchy(children)
    if (children.length > 0) {
      flattenedTags = flattenedTags.concat(childTags)
      groupIds.push(tag.id)
    }
  }

  return { tags: flattenedTags, groupIds }
}

const TagFilter = () => {
  const { tagHierarchy, loadingTags, eventListSearchParams, setEventListSearchParams } =
    useContext(TaiyoroDataContext)

  const [flattenedTagHierarchy, setFlattenedTagHierarchy] = useState<FlattenedHierarchy>({
    tags: [],
    groupIds: [],
  })

  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    if (tagHierarchy && flattenedTagHierarchy.tags.length === 0) {
      setFlattenedTagHierarchy(flattenTagHierarchy(tagHierarchy))
    }
  }, [tagHierarchy])

  const localisedLabel = useLocalisedLabel()

  const isGroup = (tag: Tag) => flattenedTagHierarchy.groupIds.some((tagId) => tagId === tag.id)

  const isNoGroup = (tag: Tag) => tagHierarchy.some((tagNode) => tagNode.tag.id === tag.id)

  return (
    <Autocomplete
      multiple
      options={flattenedTagHierarchy.tags}
      loading={loadingTags}
      value={eventListSearchParams.filterTags}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => localisedLabel(option)}
      filterSelectedOptions={false}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={localisedLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("list.mode.tags.placeholder")}
        />
      )}
      renderOption={(props, option) => {
        return (
          <GroupableTags
            {...props}
            key={option.id}
            className={clsx(
              [autocompleteClasses.option, "option"],
              isGroup(option) && "group",
              isNoGroup(option) && "noGroup"
            )}
          >
            {localisedLabel(option)}
          </GroupableTags>
        )
      }}
      onChange={(_, newValue) => {
        setEventListSearchParams({ ...eventListSearchParams, filterTags: newValue })
      }}
      sx={{ minWidth: "375px" }}
    />
  )
}

export default TagFilter
