import React, { useState, useEffect } from "react"

import { faTwitch, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faVideo } from "@fortawesome/pro-solid-svg-icons"
import { Box, Tooltip } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useTranslation } from "react-i18next"

import type Language from "../../../../../../models/Taiyoro/Meta/Language"
import type Platform from "../../../../../../models/Taiyoro/Meta/Platform"
import type EventStream from "../../../../../../models/Taiyoro/Stream"
import type EventDate from "../../../../../../models/Taiyoro/eventDate"
import Stream from "./Stream"
import { Root, Container, Title, Cell, FooterCellEmpty, FooterCell, Total } from "./styles"

interface Props {
  eventDate: EventDate
  eventPlatforms: EventStream[]
  platforms: Platform[]
  languages: Language[]
  broadcastDuration: number
  eventId: string
}

const StreamList = ({
  eventPlatforms,
  eventDate,
  platforms,
  languages,
  broadcastDuration,
  eventId,
}: Props) => {
  const [streamsState, setStreamsState] = useState([])
  const [VODsState, setVODsState] = useState([])

  const [viewsLiveConcurrentPeak, setViewsLiveConcurrentPeak] = useState(null)
  const [viewsLiveConcurrentAverage, setViewsLiveConcurrentAverage] = useState(null)
  const [viewsLiveMinutesWatched, setViewsLiveMinutesWatched] = useState(null)
  const [views1Week, setViews1Week] = useState(null)
  const [views1Month, setViews1Month] = useState(null)
  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    const streamItems = []
    const vods = []

    eventPlatforms.forEach((s) => {
      if (s.isVod) {
        vods.push(s)
      } else {
        streamItems.push(s)
      }
    })

    setStreamsState(streamItems)
    setVODsState(vods)

    let views1Week = null
    let views1Month = null

    eventPlatforms.forEach((stream) => {
      if (stream.isVod && (stream.views1WeekManual || stream.views1Week) !== null) {
        views1Week === null
          ? (views1Week = stream.views1WeekManual || stream.views1Week)
          : (views1Week += stream.views1WeekManual || stream.views1Week)
      }
      if (stream.isVod && (stream.views1MonthManual || stream.views1Month) !== null) {
        views1Month === null
          ? (views1Month = stream.views1MonthManual || stream.views1Month)
          : (views1Month += stream.views1MonthManual || stream.views1Month)
      }
    })

    if (eventDate.analytics) {
      setViewsLiveMinutesWatched(
        eventDate.analytics.viewsLiveMinutesWatched &&
          Math.round(Number(eventDate.analytics.viewsLiveMinutesWatched))
      )
      setViewsLiveConcurrentPeak(
        eventDate.analytics.viewsLiveConcurrentPeak && Math.round(eventDate.analytics.viewsLiveConcurrentPeak)
      )
      setViewsLiveConcurrentAverage(
        eventDate.analytics.viewsLiveConcurrentAverage &&
          Math.round(eventDate.analytics.viewsLiveConcurrentAverage)
      )
    }
    setViews1Week(views1Week)
    setViews1Month(views1Month)
  }, [eventPlatforms, broadcastDuration, eventDate])

  const mapPlatformToIcon = (platformId) => {
    const platform = platforms.find((p) => p.id === platformId)
    if (!platform) {
      return faVideo
    }
    switch (platform.name.toLowerCase()) {
      case "twitch":
        return faTwitch
      case "youtube":
        return faYoutube
      default:
        return faVideo
    }
  }

  const mapLanguageToFlag = (lang) => {
    switch (lang) {
      case "en":
        return "gb"
      default:
        return "jp"
    }
  }

  return (
    <Root>
      <TableCell style={{ width: "175px" }}></TableCell>
      <Container colSpan={6}>
        <Table>
          <TableHead>
            <TableRow>
              <Title></Title>
              <Title>{t("list.summary.dateList.streamList.channel")}</Title>
              <Title></Title>
              <Title align="center">{t("list.summary.dateList.streamList.language")}</Title>
              <Title align="center">{t("list.summary.dateList.streamList.pcu")}</Title>
              <Title align="center">{t("list.summary.dateList.streamList.acu")}</Title>
              <Title align="center">{t("list.summary.dateList.streamList.minsWatched")}</Title>
              <Title align="center">{t("list.summary.dateList.streamList.7dayViews")}</Title>
              <Title align="center">{t("list.summary.dateList.streamList.30dayViews")}</Title>
              <Title></Title>
            </TableRow>
          </TableHead>
          <TableBody>
            {streamsState.map((s, idx) => (
              <Stream
                key={s.id}
                id={s.id}
                eventId={eventId}
                dateId={eventDate.id}
                groupName={idx === 0 && t("list.summary.dateList.streamList.streams")}
                model={s}
                flagIcon={languages.find((a) => a.id === s.language)?.flagIcon || s.language}
                platformIcon={mapPlatformToIcon(s.platform)}
              />
            ))}
            {VODsState.map((s, idx) => (
              <Stream
                key={s.id}
                id={s.id}
                eventId={eventId}
                dateId={eventDate.id}
                groupName={idx === 0 && t("list.summary.dateList.streamList.vods")}
                model={s}
                flagIcon={mapLanguageToFlag(s.language)}
                platformIcon={mapPlatformToIcon(s.platform)}
              />
            ))}
            <TableRow>
              <Cell></Cell>
              <FooterCellEmpty></FooterCellEmpty>
              <FooterCellEmpty></FooterCellEmpty>
              <FooterCellEmpty></FooterCellEmpty>
              <FooterCell align="center">
                <Total>
                  {viewsLiveConcurrentPeak && (
                    <Tooltip
                      placement="bottom"
                      title={t("list.summary.dateList.pcu")}
                    >
                      <Box>{viewsLiveConcurrentPeak.toLocaleString()}</Box>
                    </Tooltip>
                  )}
                </Total>
              </FooterCell>
              <FooterCell align="center">
                <Total>
                  {viewsLiveConcurrentAverage && (
                    <Tooltip
                      placement="bottom"
                      title={t("list.summary.dateList.acu")}
                    >
                      <Box>{viewsLiveConcurrentAverage.toLocaleString()}</Box>
                    </Tooltip>
                  )}
                </Total>
              </FooterCell>
              <FooterCell align="center">
                <Total>{viewsLiveMinutesWatched && viewsLiveMinutesWatched.toLocaleString()}</Total>
              </FooterCell>
              <FooterCell align="center">
                <Total>{views1Week && views1Week.toLocaleString()}</Total>
              </FooterCell>
              <FooterCell align="center">
                <Total>{views1Month && views1Month.toLocaleString()}</Total>
              </FooterCell>
              <FooterCellEmpty></FooterCellEmpty>
            </TableRow>
          </TableBody>
        </Table>
      </Container>
    </Root>
  )
}

export default StreamList
