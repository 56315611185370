import React from "react"

import { styled } from "@mui/material"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

import { space } from "../../Styles/vars"

export const StyleHeaderCell = styled(TableCell)({
  textAlign: (props) => props.textAlign || "",
})

export const StyleBodyRow = styled(({ colorBG, ...other }) => <TableRow {...other} />)({})

export const StyleBodyCell = styled(TableCell)`
  &&& {
    padding: ${space.m};
  }
`

export const StyleBodyControlsCell = styled(TableCell)`
  &&& {
    text-align: right;
  }
`
