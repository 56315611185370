import React from "react"

import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import type { SelectChangeEvent } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { PostMarkdownInput } from "components/Taiyoro/Posts/PostMarkdownInput"
import type { PostFormData } from "components/Taiyoro/Posts/usePostForm"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { POST_TYPES, type Post, type PostImage } from "services/Taiyoro/posts"

interface Props {
  formState: PostFormData
  updateTextField: (field: keyof Omit<Post, "id" | "published">, value: string) => void
  validateTextField: (
    field: keyof Omit<Post, "id" | "published">,
    input: string,
    validationRule: (val: string) => string
  ) => void
  updatePublicationStatus: (value: boolean) => void
  formStatePristine: boolean
  handleSave: () => void
  handlePreview: () => void
  images: Array<PostImage>
}

export const PostForm = ({
  formState,
  updatePublicationStatus,
  updateTextField,
  validateTextField,
  handleSave,
  handlePreview,
  formStatePristine,
  images,
}: Props) => {
  const { t } = useTranslation("taiyoro")

  const {
    i18n: { language: locale },
  } = useTranslation()

  return (
    <Box sx={{ flex: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mb: 2 }}
        gap={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginRight: "auto" }}
        >
          <Switch
            checked={formState.published.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updatePublicationStatus(e.target.checked)}
          />
          <Typography>{t("posts.detailsPage.publish")}</Typography>
        </Stack>
        <Button
          variant="contained"
          type="button"
          onClick={handlePreview}
          color="primary"
        >
          {t("posts.detailsPage.preview")}
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={handleSave}
          disabled={formStatePristine}
          color="primary"
        >
          {t("posts.detailsPage.save")}
        </Button>
      </Stack>
      <Stack alignItems="stretch">
        <InputLabel id="post-form-type">{t("posts.detailsPage.type")}</InputLabel>
        <Select
          labelId="post-form-type"
          sx={{ mb: 4 }}
          onChange={(e: SelectChangeEvent<string>) => updateTextField("type", e.target.value)}
          value={formState.type.value}
        >
          {POST_TYPES.map((option) => (
            <MenuItem
              key={`report-${option}`}
              value={option}
            >
              {t(`posts.types.${option}`)}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label={t("posts.detailsPage.titleJa")}
          sx={{ mb: 4 }}
          value={formState.titleJa.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTextField("titleJa", e.target.value)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            validateTextField("titleJa", e.target.value, (value) => {
              if (value.length > 0) return value
              return "Title (Japanese)"
            })
          }
        />
        <TextField
          label={t("posts.detailsPage.title")}
          sx={{ mb: 4 }}
          value={formState.title.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTextField("title", e.target.value)}
        />
        <TextField
          label={t("posts.detailsPage.slug")}
          sx={{ mb: 4 }}
          value={formState.slug.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTextField("slug", e.target.value)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            validateTextField("slug", e.target.value, (value) => {
              const formattedValue = value
                .toLowerCase()
                // Replace groups of spaces with hyphens
                .replace(/[ ]+/g, "-")
                // Remove anything that's not an English letter or a number (\w is equivalent to [a-zA-Z0-9_]), for example Japanese characters
                .replace(/[^\w-]+/g, "")
              // Check to make sure that we have at least one group of at least one symbol consisting of lowercase letters and/or numbers, followed by any number of groups beginning with a hyphen and otherwise containing only lowercase letters and numbers
              const regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g
              if (regex.test(formattedValue)) return formattedValue
              return "slug"
            })
          }
        />
        <DateTimePicker
          label={t("posts.detailsPage.publicationDatetime")}
          value={moment(formState.publicationDatetime.value)}
          onChange={(newValue: moment.Moment) => {
            const value = newValue ? newValue.utc().format() : moment.utc().format()
            updateTextField("publicationDatetime", value)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ mb: 4, color: "currentColor" }}
            />
          )}
        />
        <InputLabel id="post-form-og-image">{t("posts.detailsPage.ogImage")}</InputLabel>
        <Select
          labelId="post-form-og-image"
          sx={{ mb: 4 }}
          onChange={(e: SelectChangeEvent<string>) => updateTextField("ogImage", e.target.value)}
          value={formState.ogImage.value}
        >
          <MenuItem value="">{t("posts.detailsPage.noImage")}</MenuItem>
          {images.map((image) => (
            <MenuItem
              value={image.id}
              key={image.id}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ width: "100px", pt: 0.5, pb: 0.5, "& img": { maxWidth: "100%" } }}
              >
                <img
                  src={image.url}
                  alt={locale === "ja" || !image.altText ? image.altTextJa : image.altText}
                />
                <div>{locale === "ja" || !image.altText ? image.altTextJa : image.altText}</div>
              </Stack>
            </MenuItem>
          ))}
        </Select>
        <TextField
          label={t("posts.detailsPage.metaDescriptionJa")}
          sx={{ mb: 4 }}
          value={formState.metaDescriptionJa.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateTextField("metaDescriptionJa", e.target.value)
          }
        />
        <TextField
          label={t("posts.detailsPage.metaDescription")}
          sx={{ mb: 4 }}
          value={formState.metaDescription.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateTextField("metaDescription", e.target.value)
          }
        />
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{ mb: 2 }}
          >
            {t("posts.detailsPage.bodyJa")}
          </Typography>
          <PostMarkdownInput
            initialValue={formState.bodyJa.value}
            onChange={(data: string) => updateTextField("bodyJa", data)}
            validationRule={(value) => {
              if (value.length > 0) return value
              return "Body Text (Japanese)"
            }}
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{ mb: 2 }}
          >
            {t("posts.detailsPage.body")}
          </Typography>
          <PostMarkdownInput
            initialValue={formState.body.value}
            onChange={(data: string) => updateTextField("body", data)}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            type="button"
            onClick={handleSave}
            disabled={formStatePristine}
            color="primary"
          >
            {t("posts.detailsPage.save")}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
