import type { MisconfiguredChannel } from "models/Taiyoro/Stream"
import GraphQL from "utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

export const repairMisconfiguredChannel = async (misconfiguredChannel: MisconfiguredChannel) => {
  const { url, ...updates } = misconfiguredChannel
  return GraphQL(
    `mutation {
      admin {
        event {
          saveDatePlatform (
            datePlatform: {
            ${Object.keys(updates).reduce(
              (acc, curr: string) => `${acc} ${curr}: ${JSON.stringify(updates[curr])}`,
              ""
            )}
            }
          )
          {
            id
          }
          }
        }
      }
      `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.admin.event.saveDatePlatform.id)
}

export const fetchMisconfiguredChannels = async (): Promise<MisconfiguredChannel[]> =>
  GraphQL(
    `query {
      admin {
        playData {
          getUnknownChannelDatePlatforms {
            id
            dateId
            platformId
            url
            channelId
            channelDisplayName
          }
        }
      }
    }
    `,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((res) => res.data.admin.playData.getUnknownChannelDatePlatforms)
