import React, { useContext, useMemo } from "react"

import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Stack, Tooltip, Typography } from "@mui/material"
import { PickemModalContext, PickemSettingsContext } from "contexts/pickem"
import type EventDate from "models/Taiyoro/eventDate"
import { useTranslation } from "react-i18next"

interface Props {
  children: React.ReactNode
  date: EventDate
}

export const DateSection = ({ children, date }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  const {
    setSelectedGroup,
    setPrefilledGroupData,
    setIsGroupModalOpen,
    setSelectedDailyPickem,
    setPrefilledData,
    setIsPickemModalOpen,
  } = useContext(PickemModalContext)

  const { dailyPickemGroupList } = useContext(PickemSettingsContext)

  const highestGroupPriority = useMemo(
    () =>
      dailyPickemGroupList
        .filter((group) => group.dateId === date.id)
        .reduce((acc, curr) => (curr.priority > acc ? curr.priority : acc), 0),
    [dailyPickemGroupList, date.id]
  )

  const startYear = date.startTime.format("YYYY")
  const startMonth = date.startTime.format("MM")
  const startDay = date.startTime.format("DD")
  const endYear = date.endTime.format("YYYY")
  const endMonth = date.endTime.format("MM")
  const endDay = date.endTime.format("DD")
  const year = language === "ja" ? "年" : "/"
  const month = language === "ja" ? "月" : "/"
  const day = language === "ja" ? "日" : ""
  const endDate = `${endYear !== startYear ? endYear + year : ""}${endMonth !== startMonth ? endMonth + month : ""}${endDay !== startDay ? endDay + day : ""}`

  return (
    <Stack gap={1}>
      <Typography
        component="h3"
        variant="h6"
        sx={{ borderBottom: "1px solid rgba(127, 127, 127, 0.25)" }}
      >
        {date.name}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="top"
      >
        <Typography sx={{ fontSize: "0.875rem", opacity: 0.75 }}>
          {date.startTime.format(`YYYY${year}MM${month}DD${day} HH:mm`)}–{endDate}{" "}
          {date.endTime.format("hh:mm")}
        </Typography>
        <Stack
          gap={1}
          direction="row"
        >
          <Tooltip title={t("eventPickem.daily.newGroupForDateTooltip")}>
            <Button
              size="small"
              variant="outlined"
              color="warning"
              aria-label="add"
              onClick={() => {
                setSelectedGroup(null)
                setPrefilledGroupData({
                  dateId: date.id,
                  priority: highestGroupPriority + 1,
                })
                setIsGroupModalOpen(true)
              }}
              sx={{
                flexShrink: 0,
              }}
            >
              {t("eventPickem.daily.newGroupForDate")}
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginLeft: "8px" }}
              />
            </Button>
          </Tooltip>
          <Tooltip title={t("eventPickem.daily.newPickemForDateTooltip")}>
            <Button
              size="small"
              variant="outlined"
              color="success"
              aria-label="add"
              onClick={() => {
                setSelectedDailyPickem(null)
                setPrefilledData({
                  dateId: date.id,
                })
                setIsPickemModalOpen(true)
              }}
              sx={{
                flexShrink: 0,
              }}
            >
              {t("eventPickem.daily.newPickemForDate")}
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginLeft: "8px" }}
              />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack gap={2}>{children}</Stack>
    </Stack>
  )
}
