import { useContext, useState } from "react"

import { faCalculator } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material"
import LoadingBox from "components/LoadingBox"
import { NotificationContext } from "contexts/notification"
import { UserRole } from "models/UserManagement"
import { useTranslation } from "react-i18next"
import { recalculateTaiyoroScores } from "services/Taiyoro/event"
import { HIGH_ACCESS_ROLES } from "utils/roles"
import useRolesCanAccess from "utils/useRolesCanAccess"

export const RecalculateTaiyoroScoreWeek = () => {
  const canRecalculate = useRolesCanAccess([...HIGH_ACCESS_ROLES, UserRole.EDITOR])

  const [showDialog, setShowDialog] = useState(false)

  const [loading, setLoading] = useState(false)

  const { setNotification } = useContext(NotificationContext)

  const handleRecalculate = () => {
    setLoading(true)
    recalculateTaiyoroScores()
      .then((success) => {
        if (!success) {
          throw new Error()
        }
        setNotification({
          message: t("common:dataUpdateSuccess"),
          severity: "success",
        })
      })
      .catch((error: Error) => {
        setNotification({
          message: error.message,
          severity: "error",
        })
      })
      .finally(() => {
        setLoading(false)
        setShowDialog(false)
      })
  }

  const { t } = useTranslation(["common", "taiyoro"])

  return (
    <>
      {canRecalculate && (
        <Button
          variant="contained"
          onClick={() => setShowDialog(true)}
          sx={{ marginLeft: "12px" }}
        >
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
          >
            <FontAwesomeIcon icon={faCalculator} />
            {t("taiyoro:recalculateTaiyoroScore.button")}
          </Stack>
        </Button>
      )}
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>{t("taiyoro:recalculateTaiyoroScore.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("taiyoro:recalculateTaiyoroScore.week.description")}</DialogContentText>
          {loading && <LoadingBox />}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setShowDialog(false)}
          >
            {t("common:actions.cancel")}
          </Button>
          <Button
            disabled={loading}
            onClick={(e) => {
              e.stopPropagation()
              handleRecalculate()
            }}
          >
            {t("taiyoro:recalculateTaiyoroScore.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
