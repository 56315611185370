import { useContext } from "react"

import { Box, Stack, Typography, useTheme } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ReferenceLine,
  ComposedChart,
  ResponsiveContainer,
  Cell,
} from "recharts"
import useEvenSizedPages from "utils/useEvenSizedPages"

import { MAX_ITEMS_PER_GRAPH, MAX_BAR_WIDTH } from "."
import Disclaimer from "./disclaimer"
import Footer from "./footer"
import Header from "./header"
import { BorderedPage, LegendContainer } from "./styles"
import SubPageCounter from "./sub-page-counter"
import XAxisTick from "./x-axis-tick"

const WatchTime = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const data = playDataEventReportData.charts.watchtime

  const dates = [...Array.from(new Set(data.map((d) => d.dateName)))]

  const { t } = useTranslation("playdata")

  const theme = useTheme()

  const pages = useEvenSizedPages(dates.length, MAX_ITEMS_PER_GRAPH)

  const filterDataPage = (page: number) => {
    let offset = 0
    for (let i = 0; i < page; i++) {
      offset = offset + pages[i]
    }
    const pageDates = dates.slice(offset, offset + pages[page])
    return data.filter((d) => pageDates.includes(d.dateName))
  }

  const maxWatchTime = Math.max(...data.map((d) => d.watchtime))

  return (
    <>
      {pages.map((_itemsPerPage, page) => (
        <BorderedPage key={page}>
          <SubPageCounter
            page={page}
            pages={pages.length}
          />
          <Stack
            height="100%"
            gap={6}
            justifyContent="space-between"
          >
            <Stack gap={4}>
              <Header title={t("sectionTitles.watchTime")} />
              <Typography
                fontSize="24px"
                fontWeight="300"
                px={6}
              >
                {t("watchTimeInfo")}
              </Typography>
            </Stack>
            <Stack gap={4}>
              <LegendContainer>
                <Stack
                  direction="row"
                  gap={2}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      bgcolor="common.blue"
                    />
                    <Typography component="span">{t("legends.watchTime")}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      bgcolor="common.pink"
                    />
                    <Typography component="span">{t("legends.highestWatchTime")}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Box
                      width="64px"
                      height="1px"
                      borderTop="2px dashed"
                      borderColor="common.blue"
                    />
                    <Typography component="span">{t("legends.watchTimeAverage")}</Typography>
                  </Stack>
                </Stack>
              </LegendContainer>
              <Box
                height="450px"
                px={6}
                mt="auto"
                mb="auto"
              >
                <ResponsiveContainer
                  height="100%"
                  width="100%"
                >
                  <ComposedChart
                    barGap={-30}
                    data={filterDataPage(page)}
                    margin={{
                      top: 50,
                      bottom: 50,
                      right: 100,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      vertical={false}
                    />
                    <YAxis
                      tickFormatter={(value) => Number(value).toLocaleString()}
                      tickLine={false}
                      tick={{ fill: theme.palette.text.primary, fontWeight: "bold" }}
                      tickCount={3}
                      stroke={theme.palette.text.primary}
                      width={90}
                    />
                    <Bar
                      dataKey="watchtime"
                      fill={theme.palette.common.blue}
                      maxBarSize={MAX_BAR_WIDTH}
                      isAnimationActive={false}
                    >
                      {filterDataPage(page).map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={
                            entry.watchtime === maxWatchTime
                              ? theme.palette.common.pink
                              : theme.palette.common.blue
                          }
                        />
                      ))}
                    </Bar>
                    <XAxis
                      dataKey="dateName"
                      height={100}
                      tick={<XAxisTick />}
                      xAxisId={0}
                      tickLine={false}
                      minTickGap={-1000}
                      ticks={filterDataPage(page).map((d) => d.dateName)}
                    />
                    {/* Reference lines need to be at the end of of the chart definition to be rendered on top properly
      https://github.com/recharts/recharts/issues/1163#issuecomment-407824600 */}
                    <ReferenceLine
                      y={data.reduce((acc: number, curr) => acc + curr.watchtime, 0) / data.length}
                      stroke={theme.palette.common.blue}
                      strokeDasharray="3 3"
                      isFront
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Box>
            </Stack>
            <Stack gap={4}>
              <Disclaimer>
                <Typography fontSize="14px">{t("watchTimeDisclaimer")}</Typography>
              </Disclaimer>
              <Footer />
            </Stack>
          </Stack>
        </BorderedPage>
      ))}
    </>
  )
}

export default WatchTime
