import { useContext, useEffect, useMemo, useState } from "react"

import { Box, Button, Modal, Stack, Typography } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import type { Player, Team } from "models/Taiyoro/Meta/Placement"
import { useTranslation } from "react-i18next"
import { usePrevious } from "react-use"
import type {
  AnswerOptions,
  DailyPickemModified,
  DailyPickemNew,
  DailyPickemStatus,
  DailyPickemType,
} from "services/Taiyoro/Pickem/daily"
import { type DailyPickemAny } from "services/Taiyoro/Pickem/daily"

import { AnswerOptionsControl } from "./Controls/AnswerOptions"
import { DateControl } from "./Controls/Date"
import { DeadlineControl } from "./Controls/Deadline"
import { GroupControl } from "./Controls/GroupControl"
import { PriorityControl } from "./Controls/PriorityControl"
import { PromptControl } from "./Controls/Prompt"
import { RewardControl } from "./Controls/Reward"
import { TypeControl } from "./Controls/Type"

interface Props {
  isOpen: boolean
  onClose: () => void
  addDailyPickem: (dailyPickem: DailyPickemNew) => void
  updateDailyPickem: (dailyPickem: DailyPickemModified | DailyPickemNew) => void
  selectedDailyPickem: DailyPickemAny | null
  prefilledData: {
    dateId?: string
    groupId?: string
    priority?: number
  } | null
}

export const DailyPickemModal = ({
  isOpen,
  onClose,
  selectedDailyPickem,
  prefilledData,
  addDailyPickem,
  updateDailyPickem,
}: Props) => {
  const { t } = useTranslation("taiyoro")
  const { eventState, dailyPickemGroupList } = useContext(PickemSettingsContext)

  const [status, setStatus] = useState<DailyPickemStatus>("new")
  const [id, setId] = useState<string>("")
  const [dateId, setDateId] = useState<string>("")
  const [gameId, setGameId] = useState<string>("")
  const [type, setType] = useState<DailyPickemType>("match")
  const [prompt, setPrompt] = useState<string>("")
  const [promptJa, setPromptJa] = useState<string>("")
  const [reward, setReward] = useState<number>(0)
  const [deadline, setDeadline] = useState<string>("")
  const [groupId, setGroupId] = useState<string>("")
  const [priority, setPriority] = useState<number>(1)
  const [answerOptions, setAnswerOptions] = useState<AnswerOptions>({ type: "participant", data: [] })

  const previousGameId = usePrevious(gameId)

  useEffect(() => {
    if (selectedDailyPickem && !prefilledData) {
      setStatus(selectedDailyPickem.status)
      setId(selectedDailyPickem.id)
      setDateId(selectedDailyPickem.dateId)
      setType(selectedDailyPickem.type)
      setPrompt(selectedDailyPickem.prompt ?? "")
      setPromptJa(selectedDailyPickem.promptJa)
      setReward(selectedDailyPickem.reward)
      setDeadline(selectedDailyPickem.deadline)
      setAnswerOptions(selectedDailyPickem.answerOptions)
      setGroupId(selectedDailyPickem.groupId ?? "")
      setPriority(selectedDailyPickem.priority ?? 0)
    }

    if (prefilledData && !selectedDailyPickem) {
      if (prefilledData.dateId) setDateId(prefilledData.dateId)
      if (prefilledData.groupId) setGroupId(prefilledData.groupId)
      if (prefilledData.priority) setPriority(prefilledData.priority)
    }
  }, [selectedDailyPickem, prefilledData])

  const availableParticipants = useMemo(() => {
    if (gameId !== previousGameId && previousGameId !== undefined && previousGameId !== "") {
      setAnswerOptions({ type: "participant", data: [] })
      if (status !== "new") {
        setStatus("modified")
      }
    }

    const selectedGames = eventState?.games.filter((value) => value.gameId === gameId)

    const participantsMap = new Map<string, (Player & { type: "player" }) | (Team & { type: "team" })>()

    if (!selectedGames) return participantsMap

    selectedGames
      .flatMap((game) => game.participants.players.map((playerData) => playerData.player))
      .filter((player) => player !== undefined)
      .forEach((player) => {
        participantsMap.set(player.id, { ...player, type: "player" as const })
      })

    selectedGames
      .flatMap((game) => game.participants.teams.map((teamData) => teamData.team))
      .filter((team) => team !== undefined)
      .forEach((team) => {
        participantsMap.set(team.id, { ...team, type: "team" as const })
      })

    return participantsMap
  }, [eventState, gameId])

  const availableGroupIds = useMemo(() => {
    if (!dateId) return []

    return dailyPickemGroupList.filter((group) => group.dateId === dateId).map((group) => group.id)
  }, [dateId, dailyPickemGroupList])

  if (!eventState?.id) return null

  const eventId = eventState.id

  const clear = () => {
    setStatus("new")
    setId("")
    setDateId("")
    setGameId("")
    setType("match")
    setPrompt("")
    setPromptJa("")
    setReward(0)
    setDeadline("")
    setAnswerOptions({ type: "participant", data: [] })
  }

  const setStatusToModified = () => {
    setStatus("modified")
  }

  const savePickem = () => {
    if (status === "new" && id === "") {
      const tempId = `${Date.now()}`

      addDailyPickem({
        id: tempId,
        status,
        dateId,
        eventId,
        type,
        prompt,
        promptJa,
        reward,
        deadline,
        answerOptions,
        groupId,
        priority,
      })

      return
    }

    if (status === "modified" || (status === "new" && id !== "")) {
      const correctAnswer =
        selectedDailyPickem?.status === "saved" || selectedDailyPickem?.status === "modified"
          ? selectedDailyPickem.correctAnswer
          : ""

      updateDailyPickem({
        status,
        id,
        dateId,
        eventId,
        type,
        prompt,
        promptJa,
        reward,
        deadline,
        answerOptions,
        correctAnswer,
        groupId,
        priority,
      })
    }
  }

  const disableSave =
    dateId === "" ||
    promptJa === "" ||
    reward === 0 ||
    deadline === "" ||
    answerOptions.data.length < 2 ||
    (answerOptions.type === "freetext" &&
      (answerOptions.data[0].contentJa === "" || answerOptions.data[1].contentJa === ""))

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        clear()
        onClose()
      }}
      aria-labelledby="daily-pickem-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "500px",
          maxWidth: "90%",
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Stack gap={2}>
          <Typography
            variant="h5"
            component="h4"
            id="daily-pickem-modal-title"
          >
            {selectedDailyPickem && t("eventPickem.daily.modal.editDaily")}
            {!selectedDailyPickem && t("eventPickem.daily.modal.newDaily")}
          </Typography>
          <DateControl
            value={dateId}
            updateValue={setDateId}
            setGameId={setGameId}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <GroupControl
            value={groupId}
            updateValue={setGroupId}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
            availableGroupIds={availableGroupIds}
          />
          <PriorityControl
            value={priority}
            updateValue={setPriority}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <TypeControl
            value={type}
            updateValue={setType}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <PromptControl
            value={{ prompt, promptJa }}
            updateValue={setPrompt}
            updateValueJa={setPromptJa}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <RewardControl
            value={reward}
            updateValue={setReward}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <DeadlineControl
            value={deadline}
            updateValue={setDeadline}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
          />
          <AnswerOptionsControl
            value={answerOptions}
            updateValue={setAnswerOptions}
            updateStatus={status !== "new" ? setStatusToModified : undefined}
            gameId={gameId}
            pickemType={type}
            availableParticipants={availableParticipants}
          />
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <Button
              variant="text"
              onClick={() => {
                clear()
                onClose()
              }}
            >
              {t("common:actions.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                savePickem()
                clear()
                onClose()
              }}
              disabled={disableSave}
            >
              {t("common:actions.save")}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
