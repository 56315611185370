import type React from "react"
import { createContext } from "react"

import type { Paginate } from "components/Taiyoro/MetaPage/helpers"
import type { MetaItem } from "components/Taiyoro/MetaPage/types"

export const noop = () => {
  console.warn("noop function called")
}

//

export type PaginationContext = {
  paginateNumberOfRecords: number
  setPaginateNumberOfRecords: React.Dispatch<React.SetStateAction<number>>
  paginatePage: number
  setPaginatePage: React.Dispatch<React.SetStateAction<number>>
  paginatedMetaListData: Paginate<MetaItem> | Array<never>
  setPaginatedMetaListData: React.Dispatch<Paginate<MetaItem> | Array<never>>
}

export const paginationContext: PaginationContext = {
  paginateNumberOfRecords: 20,
  setPaginateNumberOfRecords: noop,
  paginatePage: 1,
  setPaginatePage: noop,
  paginatedMetaListData: [],
  setPaginatedMetaListData: noop,
}

//

export type DataContext = {
  metaListState: Array<MetaItem>
  setMetaListState: React.Dispatch<React.SetStateAction<Array<MetaItem>>>
  preserveMetaListState: Array<MetaItem>
  setPreserveMetaListState: React.Dispatch<React.SetStateAction<Array<MetaItem>>>
}

export const dataContext: DataContext = {
  metaListState: [],
  setMetaListState: noop,
  preserveMetaListState: [],
  setPreserveMetaListState: noop,
}

//

export type UtilityContext = {
  errorState: Error | string | null
  setErrorState: React.Dispatch<React.SetStateAction<Error | string | null>>
  isLoadingState: boolean
  setIsLoadingState: React.Dispatch<React.SetStateAction<boolean>>
  isSavingState: boolean
  setIsSavingState: React.Dispatch<React.SetStateAction<boolean>>
}

export const utilityContext: UtilityContext = {
  errorState: null,
  setErrorState: noop,
  isLoadingState: true,
  setIsLoadingState: noop,
  isSavingState: false,
  setIsSavingState: noop,
}

//

export type SortParams = {
  field: "en" | "ja" | "updatedAt"
  sort: "asc" | "desc"
}

export type TableHeaderUtilityContext = {
  sortParamsState: SortParams
  setSortParamsState: React.Dispatch<React.SetStateAction<SortParams>>
  deleteMetaObjectState: MetaItem | null
  setDeleteMetaObjectState: React.Dispatch<React.SetStateAction<MetaItem | null>>
}

export const tableHeaderUtilityContext: TableHeaderUtilityContext = {
  sortParamsState: {
    field: "ja",
    sort: "asc",
  },
  setSortParamsState: noop,
  deleteMetaObjectState: null,
  setDeleteMetaObjectState: noop,
}

//

export const initialContext = {
  ...paginationContext,
  ...dataContext,
  ...utilityContext,
  ...tableHeaderUtilityContext,
}

export const MetaPageContext = createContext(initialContext)
