import { useContext, useEffect, useState } from "react"

import { faPlus, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Avatar,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material"
import LoadingBox from "components/LoadingBox"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import { NotificationContext } from "contexts/notification"
import type Game from "models/Taiyoro/Meta/Game"
import { MetaType } from "models/Taiyoro/Meta/MetaType"
import { type MetaWithImages } from "models/Taiyoro/Meta/Placement"
import { useTranslation } from "react-i18next"
import { type EventDateBracket, addMetaToBracket, deleteMetaFromBracket } from "services/Taiyoro/brackets"
import { fetchSignificantPlayers } from "services/Taiyoro/significantPlayers"
import { fetchTags } from "services/Taiyoro/tags"
import { fetchTeamOrganizations } from "services/Taiyoro/teamOrganizations"
import { fetchTeams } from "services/Taiyoro/teams"
import { localisedLabel } from "utils/i18n"

interface Props {
  bracket: EventDateBracket
  games: Array<Game>
}

// There's a bit of a desync between MetaType enum values + what is used as
// the key for API response + keys used in locale files for the Meta, so we need
// to create a lot of mappings to avoid doing a big refactor
const SUPPORTED_META_TYPES = [
  MetaType.SIGNIFICANT_PLAYER,
  MetaType.TEAM,
  MetaType.TEAM_ORGANIZATION,
  MetaType.TAG,
]

const META_TYPE_TRANSLATION_SLUG_MAP = {
  [MetaType.SIGNIFICANT_PLAYER]: "players",
  [MetaType.TEAM]: "teams",
  [MetaType.TEAM_ORGANIZATION]: "teamOrganizations",
  [MetaType.TAG]: "tags",
}

const META_TYPE_REVERSE_MAP = {
  players: MetaType.SIGNIFICANT_PLAYER,
  teams: MetaType.TEAM,
  teamOrganizations: MetaType.TEAM_ORGANIZATION,
  tags: MetaType.TAG,
}

const META_TYPE_FETCH_FUNC_MAP = {
  [MetaType.SIGNIFICANT_PLAYER]: fetchSignificantPlayers,
  [MetaType.TEAM]: fetchTeams,
  [MetaType.TEAM_ORGANIZATION]: fetchTeamOrganizations,
  [MetaType.TAG]: fetchTags,
}

type MetaWithImagesAndGame = MetaWithImages & {
  gameId?: string
}

export const LinkedMetaManagement = (props: Props) => {
  const { setBrackets } = useContext(EventDateBracketsContext)

  const [selectedMetaType, setSelectedMetaType] = useState(SUPPORTED_META_TYPES[0])

  const { setNotification } = useContext(NotificationContext)

  const [openAddDialog, setOpenAddDialog] = useState(false)

  const { t } = useTranslation("taiyoro")

  const [loading, setLoading] = useState(false)

  const [adding, setAdding] = useState(false)

  const [options, setOptions] = useState<Array<MetaWithImagesAndGame>>([])

  const handleMetaTypeChange = (event: SelectChangeEvent<MetaType>) => {
    setSelectedMetaType(event.target.value as MetaType)
  }

  const handleError = (error: Error) => {
    setNotification({
      message: error.message,
      severity: "error",
    })
  }

  const closeAndReset = () => {
    setAdding(false)
    setLoading(false)
    setOpenAddDialog(false)
  }

  const handleAddMetaToBracket = (meta: MetaWithImages) => {
    setAdding(true)
    addMetaToBracket(props.bracket.id, selectedMetaType, meta.id)
      .then((success) => {
        if (!success) {
          throw new Error("Error adding Meta to bracket")
        }
        setBrackets((oldBrackets) => {
          const bracket = oldBrackets.find((b) => b.id === props.bracket.id)!
          const selectedMetaTypeMapped = META_TYPE_TRANSLATION_SLUG_MAP[selectedMetaType]
          bracket.linkedMeta[selectedMetaTypeMapped].push(meta)
          return oldBrackets
        })
      })
      .catch(handleError)
      .finally(() => closeAndReset())
  }

  const handleOnDelete = (meta: MetaWithImages) => {
    const deletedMetaTypeString = Object.keys(props.bracket.linkedMeta).find((metaType) =>
      props.bracket.linkedMeta[metaType].some((linkedMeta) => linkedMeta.id === meta.id)
    )!
    const deletedMetaType = META_TYPE_REVERSE_MAP[deletedMetaTypeString]
    if (!deletedMetaType) {
      throw new Error("Couldn't find the MetaType of the deleted Meta object.")
    }
    deleteMetaFromBracket(props.bracket.id, deletedMetaType, meta.id)
      .then((success) => {
        if (!success) {
          throw new Error("Error deleting Meta from bracket")
        }
        setBrackets((oldBrackets) => {
          const newBrackets = [...oldBrackets]
          const bracket = newBrackets.find((b) => b.id === props.bracket.id)
          bracket.linkedMeta[deletedMetaTypeString] = bracket.linkedMeta[deletedMetaTypeString].filter(
            (m) => m.id !== meta.id
          )
          return newBrackets
        })
      })
      .catch(handleError)
  }

  // When the selected meta type is changed, fetch all of that Meta to display as options in the Autocomplete field.
  useEffect(() => {
    setLoading(true)
    setOptions([])
    META_TYPE_FETCH_FUNC_MAP[selectedMetaType]()
      .then((meta) => setOptions(meta))
      .finally(() => setLoading(false))
  }, [selectedMetaType])

  // Transforming Linked Meta (an object denoted by MetaType for keys) into an iterable data type
  const linkedMetaList: Array<MetaWithImagesAndGame> = Object.keys(props.bracket.linkedMeta).reduce(
    (acc, curr) => {
      return [...acc, ...props.bracket.linkedMeta[curr]]
    },
    []
  )

  const getOptionLabel = (option: MetaWithImagesAndGame) => {
    if (!option.gameId) {
      return localisedLabel(option) as string
    }
    return `${localisedLabel(option) as string} - ${localisedLabel(props.games.find((game) => game.id === option.gameId)) as string}`
  }

  return (
    <>
      <Stack
        direction="row"
        gap={0.5}
        alignItems="center"
        flexWrap="wrap"
        p={1}
      >
        {linkedMetaList.map((meta) => (
          <Chip
            avatar={
              <Avatar src={meta.secondaryImage || meta.primaryImage}>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Avatar>
            }
            label={localisedLabel(meta) as string}
            key={meta.id}
            onDelete={() => handleOnDelete(meta)}
          />
        ))}
        <IconButton onClick={() => setOpenAddDialog(true)}>
          <FontAwesomeIcon icon={faPlus} />
        </IconButton>
      </Stack>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          {!adding && (
            <Stack gap={2}>
              <Select
                value={selectedMetaType}
                onChange={handleMetaTypeChange}
              >
                {SUPPORTED_META_TYPES.map((metaType) => (
                  <MenuItem
                    key={metaType}
                    value={metaType}
                  >
                    {t(`meta.pageTitles.${META_TYPE_TRANSLATION_SLUG_MAP[metaType]}`)}
                  </MenuItem>
                ))}
              </Select>
              {!loading && (
                <Autocomplete
                  options={options}
                  getOptionLabel={getOptionLabel}
                  fullWidth
                  disabled={loading}
                  clearOnBlur
                  loading={loading}
                  onChange={(_event, newValue) => {
                    if (newValue) {
                      handleAddMetaToBracket(newValue)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("brackets.selectMeta")}
                    />
                  )}
                />
              )}
              {loading && <LoadingBox />}
            </Stack>
          )}
          {adding && <LoadingBox />}
        </DialogContent>
      </Dialog>
    </>
  )
}
