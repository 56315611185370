import { useContext } from "react"

import { Box, Paper, Stack, ThemeProvider, Typography } from "@mui/material"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import { useTranslation } from "react-i18next"
import { playDataThemeDark } from "styles/PlayData"
import { UTCDateInJST } from "utils/tools"

import Footer from "./footer"
import { BorderedPage, PageNumber } from "./styles"

const EventCover = () => {
  const playDataEventReportData = useContext(PlayDataEventReportDataContext)

  const { t } = useTranslation("playdata")

  const getEventDurationTimeRange = () => {
    const startTime = UTCDateInJST(playDataEventReportData.startDatetime)
    const endTime = UTCDateInJST(playDataEventReportData.lastStartDatetime)
    if (startTime.isSame(endTime, "day")) {
      return startTime.format("YYYY.MM.DD")
    }
    return `${startTime.format("YYYY.MM.DD")} ~ ${endTime.format("YYYY.MM.DD")}`.concat(
      playDataEventReportData.summary.finishedDateCount !== playDataEventReportData.summary.dateCount.value
        ? ` ${t("ongoing")}`
        : ""
    )
  }

  return (
    <ThemeProvider theme={playDataThemeDark}>
      <Paper
        elevation={0}
        sx={{ borderRadius: 0 }}
      >
        <BorderedPage className="cover-page">
          <Stack
            height="100%"
            alignItems="center"
            width="100%"
            gap={1}
          >
            <Box
              alignSelf="flex-end"
              mt={4}
              mr={6}
            >
              <PageNumber />
            </Box>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
            >
              <img
                src="/images/playdata-logo.png"
                height="20px"
                alt="PLAYDATA"
              />
              <Typography
                fontSize="24px"
                fontWeight="300"
                lineHeight="1"
              >
                {t("reportType")}
              </Typography>
            </Stack>
            <Typography
              fontSize="64px"
              fontWeight="900"
              textAlign="center"
              mx={6}
            >
              {playDataEventReportData.name}
            </Typography>
            <Typography
              fontSize="32px"
              fontWeight="300"
            >
              {getEventDurationTimeRange()}
            </Typography>
            <Box flex="1">
              <Stack
                justifyContent="center"
                height="100%"
              >
                <img
                  src={playDataEventReportData.imageUrl}
                  height="487px"
                  width="855px"
                  alt={playDataEventReportData.name}
                  style={{ border: "solid 8px white" }}
                />
              </Stack>
            </Box>
            <Box
              mt="auto"
              width="100%"
            >
              <Footer />
            </Box>
          </Stack>
        </BorderedPage>
      </Paper>
    </ThemeProvider>
  )
}

export default EventCover
