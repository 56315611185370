import { useContext, useState } from "react"

import { faListTree } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Avatar,
  Badge,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  badgeClasses,
} from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import { useTranslation } from "react-i18next"
import type { EventDateBracket } from "services/Taiyoro/brackets"

interface Props {
  bracket: EventDateBracket
}

export const StreamAnalyticsList = (props: Props) => {
  const { eventDate, streamIdColorMap, streamVodMap } = useContext(EventDateBracketsContext)

  const [open, setOpen] = useState(false)

  const { t } = useTranslation("taiyoro")

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("channelRepair.channelName")}</TableCell>
                  <TableCell align="right">{t("list.header.pcu")}</TableCell>
                  <TableCell align="right">{t("list.header.acu")}</TableCell>
                  <TableCell align="right">{t("list.header.minutesWatched")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.bracket.streamAnalytics.map((streamAnalytic) => {
                  const stream = eventDate.platforms.find(
                    (platform) => platform.id === streamAnalytic.datePlatformId
                  )

                  if (!stream) {
                    return <></>
                  }

                  const matchingVod = eventDate.platforms.find(
                    (datePlatform) => datePlatform.id === streamVodMap[stream.id]
                  )

                  return (
                    <TableRow
                      key={streamAnalytic.datePlatformId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                        >
                          <Badge
                            overlap="circular"
                            variant="dot"
                            sx={{
                              [`& .${badgeClasses.badge}`]: {
                                backgroundColor: streamIdColorMap[stream.id],
                              },
                            }}
                          >
                            <Avatar
                              alt={matchingVod?.channelDisplayName ?? stream.channelDisplayName ?? ""}
                              src={matchingVod?.channelIconUrl ?? undefined}
                            />
                          </Badge>
                          <Typography>{stream.channelDisplayName}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        {streamAnalytic.viewsLiveConcurrentPeak.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {streamAnalytic.viewsLiveConcurrentAverage.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {streamAnalytic.viewsLiveMinutesWatched.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <IconButton
        color="default"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faListTree} />
      </IconButton>
    </>
  )
}
