import type Platform from "../../models/Taiyoro/Meta/Platform"
import GraphQL from "../../utils/graphql"

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

export type PlatformsResponse = {
  data?: {
    admin?: {
      platform?: {
        get?: Array<Platform>
      }
    }
  }
}

export const fetchPlatforms = async () => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          platform {
            get {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as PlatformsResponse

    return response.data?.admin?.platform?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch platforms")
  }
}

export type PlatformResponse = {
  data?: {
    admin?: {
      platform?: {
        get?: Platform | null
      }
    }
  }
}

export const fetchPlatform = async (id: string) => {
  try {
    const response = (await GraphQL(
      `query {
        admin {
          platform {
            get (id: "${id}") {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as PlatformResponse

    return response.data?.admin?.platform?.get ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to fetch platform")
  }
}

export type CreatePlatformResponse = {
  data?: {
    admin?: {
      platform?: {
        save?: Platform | null
      }
    }
  }
}

export const createPlatform = async (
  name: string,
  nameJa: string | null,
  sortJa: string | null,
  id: string | null
) => {
  const platformInput = [
    ...(id ? [`id: "${id}"`] : []),
    `name: "${name}"`,
    `nameJa: ${nameJa === "" ? "null" : JSON.stringify(nameJa)}`,
    `sortJa: ${sortJa === "" ? "null" : JSON.stringify(sortJa)}`,
  ].join(", ")

  try {
    const response = (await GraphQL(
      `mutation {
        admin {
          platform {
            save (platform: {
              ${platformInput}
            }) {
              id
              name
              nameJa
              sortJa
              updatedAt
            }
          }
        }
      }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as CreatePlatformResponse

    return response.data?.admin?.platform?.save ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to create platform")
  }
}

export type DeletePlatformResponse = {
  data?: {
    admin?: {
      platform?: {
        delete: Platform
      }
    }
  }
}

export const deletePlatform = async (id: string) => {
  try {
    const response = (await GraphQL(
      `mutation {
      admin {
        platform {
          delete (
              platformId: "${id}"
          )
        }
      }
    }`,
      undefined,
      undefined,
      undefined,
      undefined,
      endpoint
    )) as DeletePlatformResponse

    return response.data?.admin?.platform?.delete ?? null
  } catch (err) {
    console.error("Error:", err instanceof Error ? err.message : "Unexpected error")
    throw new Error("Failed to delete platform")
  }
}
