import { TableCell } from "@mui/material"
import { styled, Box } from "@mui/material"

export const SortArrow = styled(Box)`
  margin-left: 6px;
  min-width: 15px;
  display: inline-block;
`

export const SortableTableCell = styled(TableCell)`
  cursor: pointer;
  white-space: nowrap;
`
