import { useEffect, useState } from "react"

import LoadingBox from "components/LoadingBox"
import { PlayDataEventReportDataContext } from "contexts/playdata-event-report"
import type { PlayDataEventReportData } from "models/PlayData"
import { useSearchParam } from "react-use"
import { fetchPlayDataEventReportData } from "services/PlayData"

const PlayDataEventReportDataProvider = ({ children }) => {
  const eventId = useSearchParam("eventId")

  const customer = useSearchParam("customer") || "CUSTOMER"

  const [loading, setLoading] = useState(true)

  const [playDataEventReportData, setPlayDataEventReportData] = useState<PlayDataEventReportData | null>(null)

  const load = async () => {
    fetchPlayDataEventReportData(eventId).then((res) => {
      setPlayDataEventReportData({ customer, ...res })
      setLoading(false)
    })
  }

  useEffect(() => {
    eventId && load()
    // eslint-disable-next-line
  }, [eventId])

  return (
    <PlayDataEventReportDataContext.Provider value={playDataEventReportData}>
      {loading ? <LoadingBox /> : <>{children}</>}
    </PlayDataEventReportDataContext.Provider>
  )
}

export default PlayDataEventReportDataProvider
