import React from "react"

import { Link } from "@mui/material"
import moment from "moment"

import { fetchDatePlatformClips } from "../../../services/Taiyoro/event"

export default (props) => {
  const [list, setList] = React.useState(
    [] as {
      id: string
      datePlatformId: string
      thirdPartyId: string
      thumbnailUrl: string
      url: string
      embedUrl: string
      broadcasterName: string
      creatorName: string
      language: string
      title: string
      viewCount: number
      clipCreatedAt: string
      durationSeconds: number
      extraMeta: string
    }[]
  )
  React.useEffect(() => {
    if (props.match.params.datePlatformId) {
      fetchDatePlatformClips(props.match.params.datePlatformId).then((dataArr) => {
        setList(dataArr)
      })
    }
  }, [props.match.params.datePlatformId])

  return (
    <div
      style={{
        width: "1000px",
        margin: "auto",
      }}
    >
      <h1 style={{ textAlign: "center" }}>CLIPS LIST</h1>
      {list.length > 0 &&
        list.map((item) => (
          <div
            style={{
              borderStyle: "solid",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <p>
              <Link href={item.url}>
                <img src={item.thumbnailUrl} />
              </Link>
            </p>
            <p>
              <Link
                style={{ color: "green" }}
                href={item.url}
              >
                {item.title}
              </Link>{" "}
              - Clipped by{" "}
              <Link
                style={{ color: "yellow" }}
                href={`https://twitch.tv/${item.creatorName}`}
              >
                {item.creatorName}
              </Link>
            </p>
            <p>
              {moment.utc(item.clipCreatedAt).tz("Asia/Tokyo").local().format("YYYY-MM-DD HH:mm:ss Z")} (
              {item.clipCreatedAt} UTC) , {item.durationSeconds} seconds long
            </p>
          </div>
        ))}
    </div>
  )
}
