import React, { useContext } from "react"

import { faCalendarDay, faList } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { Visibility as VisibilityIcon } from "@mui/icons-material/"
import { Stack, Typography, Box, Breadcrumbs, Link } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import { Link as ReactRouterDomLink } from "react-router-dom"

import Participants from "../Participants"
import SaveData from "../SaveData"

export const SettingsOptions = () => {
  const { t } = useTranslation("taiyoro")
  const { eventState } = useContext(PickemSettingsContext)

  return (
    <Box>
      <Box>
        <Breadcrumbs sx={{ mb: 1 }}>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            color="inherit"
            component={ReactRouterDomLink}
            to="/taiyoro/event/list"
          >
            <FontAwesomeIcon
              icon={faList}
              transform="down-2"
            />
            {t("list.pageTitle")}
          </Link>
          <Link
            component={ReactRouterDomLink}
            underline="hover"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            color="inherit"
            to={`/taiyoro/event/${eventState?.id ?? ""}`}
          >
            <FontAwesomeIcon icon={faCalendarDay} />
            {eventState?.name}
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            color="text.primary"
          >
            <img
              src="/images/pickem-logo.svg"
              height="18px"
              alt=""
            />
            {t("eventPickem.title")}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ mb: 3, fontWeight: 700 }}
        >
          {t("eventPickem.title")}
        </Typography>

        <Box>
          {/* <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            endIcon={<VisibilityIcon />}
            sx={{ mr: 2 }}
          >
            {t("eventPickem.preview")}
          </Button> */}

          <SaveData />
        </Box>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          mb={1}
        >
          {eventState?.name}
        </Typography>
        <Participants />
      </Stack>
    </Box>
  )
}
