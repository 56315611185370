import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { fetchOrganizers, createOrganizer, deleteOrganizer } from "../../../services/Taiyoro/organizers"

const MetaOrganizersPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.organizers")}
      fetchFunc={fetchOrganizers}
      deleteFunc={deleteOrganizer}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createOrganizer(name, nameJa, sortJa, isGameDeveloper, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createOrganizer(name, nameJa, sortJa, isGameDeveloper, id)}
      metaType={MetaType.ORGANIZER}
      gameDeveloperFields
    />
  )
}

export default MetaOrganizersPage
