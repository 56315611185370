import moment from "moment-timezone"

import type { EventTier } from "../../../models/Taiyoro/event"
import { mapSortFieldToValidBackendSortField } from "../../../pages/Taiyoro/Analytics/entities"
import type { SortState } from "../../../pages/Taiyoro/Analytics/entities"
import GraphQL from "../../../utils/graphql"

export interface AnalyticsResponse {
  total: {
    viewsLiveMinutesWatched: number
    viewsLiveConcurrentAverage: number
    viewsLiveConcurrentPeak: number
  }
  platforms: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    viewsLiveConcurrentAverage: number
    viewsLiveConcurrentPeak: number
    dataCollectionErrors: number
    liveBroadcastDurationMinutes: number
    averageDailyMinutesWatched: number
    numEvents: number
    numDays: number
    numDaysTracked: number
    numStreams: number
    platform: {
      id: string
      name: string
      nameJa: string
      sortJa: string
    }
  }[]
  sponsors: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    viewsLiveConcurrentPeak: number
    viewsLiveConcurrentAverage: number
    numDays: number
    numDaysTracked: number
    averageDailyMinutesWatched: number
    dataCollectionErrors: number
    numGames: number
    numEvents: number
    sponsor: {
      id: string
      name: string
      nameJa: string
      sortJa: string
    }
  }[]
  casters: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    liveBroadcastDurationMinutes: number
    viewsLiveConcurrentPeak: number
    viewsLiveConcurrentAverage: number
    averageDailyMinutesWatched: number
    numDays: number
    numEvents: number
    numGames: number
    caster: {
      id: string
      name: string
      nameJa: string
      sortJa: string
      primaryImage: string
    }
  }[]
  games: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    viewsLiveConcurrentPeak: number
    viewsLiveConcurrentAverage: number
    averageDailyMinutesWatched: number
    liveBroadcastDurationMinutes: number
    numEvents: number
    numDays: number
    numSponsors: number
    saturationScore: number
    peakEventName: string
    peakDateName: string
    game: {
      id: string
      name: string
      nameJa: string
      sortJa: string
      primaryImage: string
      secondaryImage: string
    }
  }[]
  dates: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    viewsLiveConcurrentAverage: number
    viewsLiveConcurrentPeak: number
    dataCollectionErrors: number
    taiyoroDateWeekRating: number
    date: {
      id: string
      name: string
      gameId: string
      startDatetime: string
      endDatetime: string
      taiyoroRating: number
    }
    event: {
      id: string
      name: string
      eventUrl: string
      tier: EventTier
      imageUrl: string
    }
    game: {
      id: string
      name: string
      nameJa: string
      sortJa: string
      primaryImage: string
      secondaryImage: string
    }
  }[]
  events: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    averageDailyMinutesWatched: number
    viewsLiveConcurrentAverage: number
    viewsLiveConcurrentPeak: number
    numDays: number
    numDaysTracked: number
    dataCollectionErrors: number
    eventStartDatetime: string
    eventEndDatetime: string
    event: {
      id: string
      name: string
      eventUrl: string
      tier: EventTier
      imageUrl: string
      taiyoroRating: number
    }
    games: {
      id: string
      name: string
      nameJa: string
      sortJa: string
      primaryImage: string
      secondaryImage: string
    }[]
  }[]
  organizers: {
    viewsLiveMinutesWatched: number
    viewsLiveMinutesWatchedPercentageOfTotal: number
    averageDailyMinutesWatched: number
    viewsLiveConcurrentPeak: number
    viewsLiveConcurrentAverage: number
    liveBroadcastDurationMinutes: number
    numEvents: number
    numDays: number
    numDaysTracked: number
    numGames: number
    organizer: {
      id: string
      name: string
      nameJa: string
      sortJa: string
      isGameDeveloper: boolean
    }
  }[]
  type: string
  datetimeFrom: string
  datetimeTo: string
  offset: number
  limit: number
  totalCount: number
}

export enum AnalyticEntity {
  GAMES = "GAMES",
  DATES = "DATES",
  EVENTS = "EVENTS",
  PLATFORMS = "PLATFORMS",
  SPONSORS = "SPONSORS",
  ORGANIZERS = "ORGANIZERS",
  CASTERS = "CASTERS",
  MISSING_DATA = "MISSING_DATA",
  RESTARTED_STREAMS = "RESTARTED_STREAMS",
}

export enum ReportEntity {
  TOURNAMENT_VIEWERSHIP_RANK = "TOURNAMENT_VIEWERSHIP_RANK",
  GAME_TITLE_VIEWERSHIP_RANK = "GAME_TITLE_VIEWERSHIP_RANK",
  TOURNAMENT_PCV_RANK = "TOURNAMENT_PCV_RANK",
  GAME_TITLE_PCV_RANK = "GAME_TITLE_PCV_RANK",
  SPONSOR_VIEWERSHIP_RANK = "SPONSOR_VIEWERSHIP_RANK",
  GAME_TITLE_SCORE_RANK = "GAME_TITLE_SCORE_RANK",
}

export enum AnalyticSort {
  MINUTES_WATCHED = "MINUTES_WATCHED",
  AVERAGE_DAILY_MINUTES_WATCHED = "AVERAGE_DAILY_MINUTES_WATCHED",
  NUM_DAYS = "NUM_DAYS",
  AVERAGE_CONCURRENTS = "AVERAGE_CONCURRENTS",
  PEAK_CONCURRENTS = "PEAK_CONCURRENTS",
}

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS || "http://localhost:3999/api/graph"

const getAnalytics = async (
  entity: AnalyticEntity,
  sort: SortState,
  startTime: moment.Moment,
  endTime: moment.Moment,
  offset: number = 0,
  limit: number = 30
): Promise<AnalyticsResponse> => {
  let response = await GraphQL(
    `query {
            admin {
              analytics {
                specific (entityType: ${entity}, sort: ${
                  mapSortFieldToValidBackendSortField(sort.field) + "_" + sort.order
                }, datetimeFrom: "${startTime.format()}", datetimeTo: "${endTime.format()}", offset: ${offset}, limit: ${limit}) {
                  total {
                    viewsLiveMinutesWatched
                    viewsLiveConcurrentAverage
                    viewsLiveConcurrentPeak
                  }
                  platforms {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    viewsLiveConcurrentAverage
                    viewsLiveConcurrentPeak
                    dataCollectionErrors
                    liveBroadcastDurationMinutes
                    averageDailyMinutesWatched
                    numEvents
                    numDays
                    numDaysTracked
                    numStreams
                    platform {
                      id
                      name
                      nameJa
                      sortJa
                    }
                  }
                  sponsors {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    viewsLiveConcurrentPeak
                    viewsLiveConcurrentAverage
                    numDays
                    numDaysTracked
                    averageDailyMinutesWatched
                    dataCollectionErrors
                    numGames
                    numEvents
                    sponsor {
                      id
                      name
                      nameJa
                      sortJa
                    }
                  }
                  games {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    viewsLiveConcurrentPeak
                    viewsLiveConcurrentAverage
                    averageDailyMinutesWatched
                    liveBroadcastDurationMinutes
                    numEvents
                    numDays
                    numSponsors
                    saturationScore
                    peakEventName
                    peakDateName
                    game {
                      id
                      name
                      nameJa
                      sortJa
                      primaryImage
                      secondaryImage
                    }
                  }
                  dates {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    viewsLiveConcurrentAverage
                    viewsLiveConcurrentPeak
                    dataCollectionErrors
                    taiyoroDateWeekRating
                    date {
                      id
                      name
                      gameId
                      startDatetime
                      endDatetime
                      taiyoroRating
                    }
                    event {
                      id
                      name
                      eventUrl
                      imageUrl
                      tier
                    }
                    game {
                      id
                      name
                      nameJa
                      sortJa
                      primaryImage
                      secondaryImage
                    }
                  }
                  events {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    viewsLiveConcurrentPeak
                    viewsLiveConcurrentAverage
                    averageDailyMinutesWatched
                    numDays
                    numDaysTracked
                    dataCollectionErrors
                    eventStartDatetime
                    eventEndDatetime
                    event {
                      id
                      name
                      eventUrl
                      tier
                      imageUrl
                      taiyoroRating
                    }
                    games {
                      id
                      name
                      nameJa
                      sortJa
                      primaryImage
                      secondaryImage
                    }
                  }
                  organizers {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    averageDailyMinutesWatched
                    viewsLiveConcurrentPeak
                    viewsLiveConcurrentAverage
                    liveBroadcastDurationMinutes
                    numEvents
                    numDays
                    numDaysTracked
                    numGames
                    organizer {
                      id
                      name
                      nameJa
                      sortJa
                      isGameDeveloper
                    }
                  }
                  casters {
                    viewsLiveMinutesWatched
                    viewsLiveMinutesWatchedPercentageOfTotal
                    averageDailyMinutesWatched
                    viewsLiveConcurrentPeak
                    viewsLiveConcurrentAverage
                    liveBroadcastDurationMinutes
                    numEvents
                    numDays
                    numGames
                    caster {
                      id
                      name
                      nameJa
                      sortJa
                      primaryImage
                    }
                  }
                  datetimeFrom
                  datetimeTo
                  offset
                  limit
                  totalCount
                }
              }
            }
          }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

  return (
    (response.data &&
      response.data.admin &&
      response.data.admin.analytics &&
      response.data.admin.analytics.specific &&
      response.data.admin.analytics.specific) ||
    null
  )
}

export type DiscordUsage = {
  guildId: string
  name: string
  icon: string | null
  gameIds: Array<string>
  eventIds: Array<string>
  teamIds: Array<string>
  dateIds: Array<string>
  favoritesUserIds: Array<string>
  syncAllEvents: 1 | 0
  syncAllGames: 1 | 0
  syncAllTeams: 1 | 0
  memberCount: number
  dateJoined: string
}

export const fetchDiscordUsages = async (): Promise<Array<DiscordUsage>> => {
  return GraphQL(
    `query {
            discord {
              getGuildUsages {
                    guildId
                    name
                    icon
                    gameIds
                    eventIds
                    teamIds
                    dateIds
                    favoritesUserIds
                    syncAllEvents
                    syncAllGames
                    syncAllTeams
                    memberCount
                    dateJoined
                }
            }
        }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return response.data && response.data.discord && response.data.discord.getGuildUsages
  })
}

export const fetchHeatmapCSVData = async (minStartDatetime: string, page: number): Promise<string> => {
  return GraphQL(
    `query {
        admin {
          analytics {
            acuHeatmapCsv(minStartDatetime: ${JSON.stringify(
              minStartDatetime
            )}, page: ${page}, eventsPerPage: 20)
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  ).then((response) => {
    return (
      response.data &&
      response.data.admin &&
      response.data.admin.analytics &&
      response.data.admin.analytics.acuHeatmapCsv
    )
  })
}

const analyticReportTypeToAPIReportType = (type: AnalyticEntity | ReportEntity) => {
  switch (type) {
    case AnalyticEntity.EVENTS:
      return "EVENT"
    case AnalyticEntity.DATES:
      return "DATE"
    default:
      return type
  }
}

interface CSVFileDownloadLinkResponse {
  data: {
    admin: {
      analytics: {
        csvDownload: {
          link: string
        }
      }
    }
  }
}
export const fetchCSVFileDownloadUrl = async (
  type: AnalyticEntity | ReportEntity,
  from: string,
  to: string
): Promise<string> => {
  const gqlType = analyticReportTypeToAPIReportType(type)
  return GraphQL(
    `query {
        admin {
          analytics {
            csvDownload(datetimeFrom: "${from}", datetimeTo: "${to}", type: "${gqlType}") {
              link
            }
          }
        }
      }`,
    undefined,
    undefined,
    undefined,
    undefined,
    endpoint
  ).then((response: CSVFileDownloadLinkResponse) => response.data.admin.analytics.csvDownload.link)
}

export default getAnalytics
