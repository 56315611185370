import React, { useState, useEffect, useContext } from "react"

import { faCopy, faSearch, faDownload, faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { ToggleButtonGroup, ToggleButton } from "@mui/material"
import DateTextField from "components/Form/DateTextField"
import { NotificationContext } from "contexts/notification"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"
import { AnalyticEntity, fetchCSVFileDownloadUrl, ReportEntity } from "services/Taiyoro/Analytics"
import { downloadFileFromUrl } from "utils/tools"

import { RecalculateTaiyoroScoreWeek } from "./RecalculateTaiyoroScoreWeek"

interface Props {
  onChange: (
    entity: AnalyticEntity | ReportEntity,
    startDatetime: moment.Moment,
    endDatetime: moment.Moment,
    offset?: number,
    limit?: number
  ) => void
  copyToClipboard: () => void
}

const CSV_DOWNLOADABLE_REPORTS = [
  AnalyticEntity.DATES,
  AnalyticEntity.EVENTS,
  AnalyticEntity.MISSING_DATA,
  AnalyticEntity.RESTARTED_STREAMS,
]

const Options = ({ onChange, copyToClipboard }: Props) => {
  const [analyticsEntityOptions, setAnalyticsEntityOptions] = useState<Array<string>>([])

  const [reportEntityOptions, setReportEntityOptions] = useState<Array<string>>([])

  const [selectedEntityState, setSelectedAnalyticState] = useState<AnalyticEntity | ReportEntity>(
    AnalyticEntity.DATES
  )

  const [startDatetimeState, setStartDatetimeState] = useState<moment.Moment>(
    moment.tz("Asia/Tokyo").subtract(1, "month")
  )

  const [endDatetimeState, setEndDatetimeState] = useState<moment.Moment>(moment.tz("Asia/Tokyo"))

  const [analyticsViewType, setAnalyticsViewType] = useState("standard")

  const [isCopiedState, setIsCopiedState] = useState<boolean>(false)

  const canDownloadCsvForCurrentReport = CSV_DOWNLOADABLE_REPORTS.some(
    (report) => report === selectedEntityState
  )

  const { t } = useTranslation("taiyoro")

  const [fetching, setFetching] = useState(false)
  const { setNotification } = useContext(NotificationContext)

  const handleError = () => {
    setNotification({
      severity: "error",
      message: t("common:errorLoadingData"),
    })
  }

  const downloadCSV = () => {
    setFetching(true)
    fetchCSVFileDownloadUrl(selectedEntityState, startDatetimeState.format(), endDatetimeState.format())
      .then(downloadFileFromUrl)
      .catch(handleError)
      .finally(() => setFetching(false))
  }

  useEffect(() => {
    let options: Array<string> = []
    for (const value in AnalyticEntity) {
      options.push(value)
    }
    setAnalyticsEntityOptions(options)

    let reportOptions: Array<string> = []
    for (const value in ReportEntity) {
      reportOptions.push(value)
    }
    setReportEntityOptions(reportOptions)
    // eslint-disable-next-line
  }, [])

  const handleViewTypeChange = (_event, value: string) => {
    if (!value) {
      return
    }
    if (value === "reports") {
      setSelectedAnalyticState(reportEntityOptions[0] as ReportEntity)
    }
    if (value === "standard") {
      setSelectedAnalyticState(analyticsEntityOptions[0] as AnalyticEntity)
    }
    setAnalyticsViewType(value)
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={6}
        >
          <ToggleButtonGroup
            color="primary"
            value={analyticsViewType}
            exclusive
            onChange={handleViewTypeChange}
          >
            <ToggleButton value="standard">{t(`analytics.form.standard`)}</ToggleButton>
            <ToggleButton value="reports">{t(`analytics.form.reports`)}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <FormControl>
            <InputLabel shrink>{t(`analytics.form.entity`)}</InputLabel>
            {analyticsViewType === "standard" && (
              <Select
                value={selectedEntityState}
                onChange={(e) => {
                  setSelectedAnalyticState(e.target.value as AnalyticEntity)
                }}
              >
                {analyticsEntityOptions.map((option: string) => (
                  <MenuItem
                    key={`analytics-type-${option}`}
                    value={option}
                  >
                    {t(`analytics.form.type.standard.${option}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
            {analyticsViewType === "reports" && (
              <Select
                value={selectedEntityState}
                onChange={(e) => {
                  setSelectedAnalyticState(e.target.value as ReportEntity)
                }}
              >
                {reportEntityOptions.map((option: string) => (
                  <MenuItem
                    key={`report-${option}`}
                    value={option}
                  >
                    {t(`analytics.form.type.reports.${option}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DateTextField
            label={t("analytics.form.startDatetime.title")}
            value={startDatetimeState.format("YYYY-MM-DDTHH:mm")}
            onChange={(e: { target: { value: string | null } }) => {
              if (e.target.value && e.target.value.length > 0) {
                setStartDatetimeState(moment(e.target.value, "YYYY/MM/DDTHH:mm"))
              } else {
                setStartDatetimeState(moment())
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DateTextField
            label={t("analytics.form.endDatetime.title")}
            value={endDatetimeState.format("YYYY-MM-DDTHH:mm")}
            onChange={(e: { target: { value: string | null } }) => {
              if (e.target.value && e.target.value.length > 0) {
                setEndDatetimeState(moment(e.target.value, "YYYY/MM/DDTHH:mm"))
              } else {
                setEndDatetimeState(moment())
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button
            onClick={() => {
              if (
                [AnalyticEntity.MISSING_DATA, AnalyticEntity.RESTARTED_STREAMS].includes(
                  selectedEntityState as AnalyticEntity
                )
              ) {
                setNotification({
                  severity: "warning",
                  message: t("analytics.form.csvOnlyReport"),
                })
                return
              }
              onChange(selectedEntityState, startDatetimeState, endDatetimeState)
              setIsCopiedState(false)
            }}
            variant="contained"
            color="primary"
            startIcon={
              <FontAwesomeIcon
                icon={faSearch}
                size="1x"
              />
            }
          >
            {t("analytics.form.fetch")}
          </Button>
          <Button
            onClick={() => {
              copyToClipboard()
              setIsCopiedState(true)
            }}
            variant="contained"
            color="primary"
            sx={{ marginLeft: "12px" }}
            startIcon={
              <FontAwesomeIcon
                icon={faCopy}
                size="1x"
              />
            }
          >
            {isCopiedState ? t("analytics.form.clipboard.copied") : t("analytics.form.clipboard.copy")}
          </Button>
          {canDownloadCsvForCurrentReport && (
            <Button
              onClick={() => downloadCSV()}
              variant="contained"
              color="primary"
              disabled={fetching}
              sx={{ marginLeft: "12px" }}
              startIcon={
                fetching ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="1x"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="1x"
                  />
                )
              }
            >
              {t("analytics.form.downloadCsv")}
            </Button>
          )}
          <RecalculateTaiyoroScoreWeek />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Options
