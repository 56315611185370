import React from "react"

import ChatDensityVisualizer from "components/DataCollection/ChatDensity/visualizer"
import PageWrapper from "components/PageLayout/PageWrapper"
import PlayData from "components/PlayData"
import EventReport from "components/PlayData/EventReport"
import PlayDataEventReportDataProvider from "components/PlayData/provider"
import RoleGuardedRoute from "components/RoleGuardedRoute"
import TaiyoroManagement from "components/Taiyoro"
import { UserRole } from "models/UserManagement"
import NotFound from "pages/404"
import { ChatDensity } from "pages/DataCollection/chatDensity"
// Taiyoro
import IgnoreList from "pages/DataCollection/ignoreList"
// Data Collection
import DataCollectionList from "pages/DataCollection/list"
import PlatformGameMappings from "pages/DataCollection/platformGameMappings"
import IgnoreSuggestions from "pages/DataCollection/suggestions"
import TwitchComments from "pages/DataCollection/twitchComments"
import Home from "pages/Home"
// Scouting
import Scouting from "pages/Scouting"
// Users
import UserEdit from "pages/UserManagement/user-edit"
import UsersList from "pages/UserManagement/users-list"
import { Route, Switch } from "react-router-dom"
import { ADMIN_ROLES } from "utils/roles"

let path = window.location.hash.slice(1)

if (path) {
  window.location.hash = ""
  window.history.replaceState({}, "", `/${path}`)
}

const Dashboard = () => {
  const scoutingAndPlayDataAccess = [...ADMIN_ROLES, UserRole.PRODUCER]

  return (
    <PageWrapper>
      <Switch>
        <Route
          exact
          path="/"
          component={Home}
        />

        {/* Users */}
        <RoleGuardedRoute
          exact
          path="/users/:product"
          component={UsersList}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/users/:product/:userId/edit"
          component={UserEdit}
          allowedRoles={ADMIN_ROLES}
        />

        {/* Taiyoro */}
        <Route path="/taiyoro">
          <TaiyoroManagement />
        </Route>

        {/* Data Collection */}
        <RoleGuardedRoute
          exact
          path="/data-collection/suggestions"
          component={IgnoreSuggestions}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/data-collection/list"
          component={DataCollectionList}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/data-collection/mappings"
          component={PlatformGameMappings}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/data-collection/ignore"
          component={IgnoreList}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/data-collection/comments"
          component={TwitchComments}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/data-collection/chat-density"
          component={ChatDensity}
          allowedRoles={ADMIN_ROLES}
        />
        <RoleGuardedRoute
          exact
          path="/data-collection/chat-density/:type/:vodId"
          component={ChatDensityVisualizer}
          allowedRoles={ADMIN_ROLES}
        />
        {/* Scouting */}
        <RoleGuardedRoute
          exact
          path="/scouting"
          component={Scouting}
          allowedRoles={scoutingAndPlayDataAccess}
        />
        {/* PLAYDATA */}
        <RoleGuardedRoute
          exact
          path="/playdata"
          component={PlayData}
          allowedRoles={scoutingAndPlayDataAccess}
        />
        {/* (Roy) Temporary route for quick previewing */}
        <Switch>
          <Route
            exact
            path="/playdata/generate-preview"
          >
            <PlayDataEventReportDataProvider>
              <EventReport />
            </PlayDataEventReportDataProvider>
          </Route>
        </Switch>
        <Route component={NotFound} />
      </Switch>
    </PageWrapper>
  )
}

export default Dashboard
