const LABEL_MAX_LENGTH = 25

const XAxisTick = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value.length > LABEL_MAX_LENGTH
          ? payload.value.substring(0, LABEL_MAX_LENGTH).concat("...")
          : payload.value}
      </text>
    </g>
  )
}

export default XAxisTick
