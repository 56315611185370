import { useEffect } from "react"

import { Box, TextField } from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import { useTranslation } from "react-i18next"

interface Props {
  value: string
  updateValue: (value: string) => void
  updateStatus?: () => void
}

export const DeadlineControl = ({ value, updateValue, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  useEffect(() => {
    if (value === "") {
      const date = moment(new Date()).utc()
      date.seconds(0)
      updateValue(date.toISOString())
    }
  }, [value])

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          label={t("eventPickem.daily.modal.deadline")}
          value={moment.utc(value).tz("Asia/Tokyo")}
          onChange={(date) => {
            const dateWithoutSeconds = date?.clone().seconds(0)
            const newDate = dateWithoutSeconds!.toISOString()
            updateValue(newDate)
            updateStatus?.()
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  )
}
