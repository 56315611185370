import { useState, useEffect, useRef, useContext } from "react"
import React from "react"

import { faCalendars, faCopy, faDesktop, faMobile } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Paper,
  IconButton,
  Tooltip,
  Autocomplete,
  CircularProgress,
} from "@mui/material"
import LoadingBox from "components/LoadingBox"
import { NotificationContext } from "contexts/notification"
import type Game from "models/Taiyoro/Meta/Game"
import type Organizer from "models/Taiyoro/Meta/Organizer"
import type Producer from "models/Taiyoro/Meta/Producer"
import { HexAlphaColorPicker, RgbaStringColorPicker } from "react-colorful"
import { useTranslation } from "react-i18next"
import { useDebounce } from "react-use"
import { fetchGames } from "services/Taiyoro/games"
import { fetchProducers } from "services/Taiyoro/producers"
import { type TaiyoroUser, searchUsersByName } from "services/UserManagement/user"
import sortByEnglishNameOrJapaneseSort from "utils/sortByEnglishNameOrJapaneseSort"
import { TAIYORO_DOMAIN } from "utils/taiyoro"
import validator from "validator"

import { fetchOrganizers } from "../../../services/Taiyoro/organizers"

const CalendarEmbed = () => {
  const { t } = useTranslation("taiyoro")
  const [filterType, setFilterType] = useState("game")

  const [fontColor, setFontColor] = useState("")
  const [bgColor, setBgColor] = useState("")
  const [bgTileColor, setBgTileColor] = useState("")
  const [bgHighlightTile, setBgHighlightTile] = useState("")
  const [mobileBgEventColor, setMobileBgEventColor] = useState("")
  const [fontColorOnEventBg, setFontColorOnEventBg] = useState("")

  const [isGradient, setIsGradient] = useState(false)
  const [hoverBgColor, setHoverBgColor] = useState("")
  const [firstHoverColor, setFirstHoverColor] = useState("")
  const [secondHoverColor, setSecondHoverColor] = useState("")
  const [logoColor, setLogoColor] = useState("")

  const [loadingState, setLoadingState] = useState(true)
  const [games, setGames] = useState<Array<Game>>([])
  const [gameId, setGameId] = useState("")
  const [companies, setCompanies] = useState<Array<Producer | Organizer>>([])
  const [previewMode, setPreviewMode] = useState<"desktop" | "mobile">("desktop")
  const [userSearchValue, setUserSearchValue] = useState("")
  const [users, setUsers] = useState<Array<TaiyoroUser>>([])
  const [searchingUsers, setSearchingUsers] = useState(false)

  const userSearchAbortController = useRef<AbortController | null>(null)

  const { setNotification } = useContext(NotificationContext)

  const displayError = () => {
    setNotification({
      severity: "error",
      message: t("common:errorLoadingData"),
    })
  }

  useDebounce(
    () => {
      if (userSearchValue === "") {
        return
      }
      setSearchingUsers(true)
      // If there is a pending fetch request with associated AbortController, abort
      if (userSearchAbortController.current) {
        userSearchAbortController.current.abort()
      }
      userSearchAbortController.current = new AbortController()
      const { signal } = userSearchAbortController.current

      searchUsersByName(userSearchValue, signal)
        .then((res) => {
          setSearchingUsers(false)
          setUsers(res)
        })
        .catch(() => {
          setSearchingUsers(false)
          displayError()
        })
    },
    300,
    [userSearchValue]
  )

  const [filterValue, setFilterValue] = useState("")

  const [showColorPicker, setShowColorPicker] = useState<string | false>(false)

  const searchParams = new URLSearchParams({})
  fontColor !== "" && searchParams.append("fontColor", fontColor)
  bgColor !== "" && searchParams.append("bgColor", bgColor)
  bgTileColor !== "" && searchParams.append("bgTileColor", bgTileColor)
  bgHighlightTile !== "" && searchParams.append("bgHighlightTile", bgHighlightTile)
  mobileBgEventColor !== "" && searchParams.append("mobileBgEventColor", mobileBgEventColor)
  logoColor !== "" && searchParams.append("logoColor", logoColor)
  fontColorOnEventBg !== "" && searchParams.append("fontColorOnEventBg", fontColorOnEventBg)
  if (!isGradient) {
    hoverBgColor !== "" && searchParams.append("hoverBgColor", hoverBgColor)
  } else {
    firstHoverColor !== "" && searchParams.append("firstHoverColor", firstHoverColor)
    secondHoverColor !== "" && searchParams.append("secondHoverColor", secondHoverColor)
  }

  const load = () => {
    setLoadingState(true)
    filterType === "game" && void loadGames()
    filterType === "company" && void loadCompanies()
    filterType === "user" && setLoadingState(false)
  }

  const loadGames = async () => {
    try {
      const fetchedGames = await fetchGames()
      if (!fetchedGames) {
        throw new Error()
      }
      fetchedGames.sort(sortByEnglishNameOrJapaneseSort)
      setGames(fetchedGames)
      setGameId(fetchedGames[0].id)
      setFilterValue(fetchedGames[0].name)
      setLoadingState(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingState(false)
    }
  }

  const loadCompanies = async () => {
    try {
      const organizers = await fetchOrganizers()
      const producers = await fetchProducers()
      if (organizers && producers) {
        const companySet = new Set([...organizers, ...producers])
        const companyArray = Array.from(companySet)
        companyArray.sort(sortByEnglishNameOrJapaneseSort)
        setCompanies(companyArray)
        setFilterValue(companyArray[0].name)
        setLoadingState(false)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingState(false)
    }
  }

  useEffect(() => {
    load()
  }, [filterType])

  const baseColors = {
    fontColor: {
      label: "calendarEmbed.fontColor",
      id: "fontColor",
      variable: fontColor,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setFontColor(event.target.value)
      },
      onChange: setFontColor,
    },
    fontColorOnEventBg: {
      label: "calendarEmbed.fontColorOnEventBg",
      id: "fontColorOnEventBg",
      variable: fontColorOnEventBg,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setFontColorOnEventBg(event.target.value)
      },
      onChange: setFontColorOnEventBg,
    },
    bgColor: {
      label: "calendarEmbed.bgColor",
      id: "bgColor",
      variable: bgColor,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setBgColor(event.target.value)
      },
      onChange: setBgColor,
    },
    bgTileColor: {
      label: "calendarEmbed.bgTileColor",
      id: "bgTileColor",
      variable: bgTileColor,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setBgTileColor(event.target.value)
      },
      onChange: setBgTileColor,
    },
    bgHighlightTile: {
      label: "calendarEmbed.bgHighlightTile",
      id: "bgHighlightTile",
      variable: bgHighlightTile,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setBgHighlightTile(event.target.value)
      },
      onChange: setBgHighlightTile,
    },
    mobileBgEventColor: {
      label: "calendarEmbed.mobileBgEventColor",
      id: "mobileBgEventColor",
      variable: mobileBgEventColor,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setMobileBgEventColor(event.target.value)
      },
      onChange: setMobileBgEventColor,
    },
    logoColor: {
      label: "calendarEmbed.logoColor",
      id: "logoColor",
      variable: logoColor,
      setFunction: (event: React.ChangeEvent<HTMLInputElement>) => {
        setLogoColor(event.target.value)
      },
      onChange: setLogoColor,
    },
  }

  const handleCopyToClipboard = () => {
    void navigator.clipboard.writeText(
      `<iframe id="taiyoro-embed" frameBorder="0" src="${TAIYORO_DOMAIN}/schedule/${filterType}/${
        filterType === "game" ? gameId : encodeURI(filterValue)
      }?${searchParams.toString()}" scrolling="no" style="width: 100%; aspect-ratio: 1 / 1;"> </iframe>`
    )
  }

  const removeSpace = (value: string) => {
    return value.replaceAll(" ", "")
  }

  const getFilterTypeLabel = () => {
    switch (filterType) {
      case "game":
        return t("calendarEmbed.game")
      case "company":
        return t("calendarEmbed.company")
      default:
        return t("calendarEmbed.user")
    }
  }

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 3 }}
      >
        <FontAwesomeIcon
          icon={faCalendars}
          style={{ marginRight: "10px" }}
        />
        {t("calendarEmbed.title")}
      </Typography>

      <Stack
        direction={{ sm: "column", md: "row" }}
        spacing={{ sm: 2, md: 8 }}
      >
        <Stack>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 230 }}
          >
            <InputLabel id="filterType">{t("calendarEmbed.filterType")}</InputLabel>
            <Select
              labelId="filterType"
              id="filterType"
              onChange={(e) => {
                setFilterType(e.target.value)
                setFilterValue("")
              }}
              value={filterType}
            >
              <MenuItem
                key="game"
                value="game"
              >
                {t("calendarEmbed.game")}
              </MenuItem>
              <MenuItem
                key="company"
                value="company"
              >
                {t("calendarEmbed.company")}
              </MenuItem>
              <MenuItem
                key="user"
                value="user"
              >
                {t("calendarEmbed.user")}
              </MenuItem>
            </Select>
          </FormControl>

          {["game", "company"].includes(filterType) && (
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 230 }}
            >
              <InputLabel id="filterIdentifier">{getFilterTypeLabel()}</InputLabel>
              <Select
                labelId="filterIdentifier"
                id="filterIdentifier"
                onChange={(e) => {
                  setFilterValue(e.target.value)
                  setGameId(games.filter((game) => game.name === e.target.value)[0].id)
                }}
                value={filterValue}
              >
                {filterType === "game" &&
                  (loadingState || games.length === 0 ? (
                    <LoadingBox />
                  ) : (
                    games.map((game) => (
                      <MenuItem
                        key={game.id}
                        value={game.name}
                      >
                        {game.name}
                      </MenuItem>
                    ))
                  ))}
                {filterType === "company" &&
                  (loadingState || companies.length === 0 ? (
                    <LoadingBox />
                  ) : (
                    companies.map((company) => (
                      <MenuItem
                        key={company.id}
                        value={company.name}
                      >
                        {company.name}
                      </MenuItem>
                    ))
                  ))}
              </Select>
            </FormControl>
          )}

          {filterType === "user" && (
            <Box m={1}>
              <Autocomplete
                options={users}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(user) => user.username}
                fullWidth
                clearOnBlur
                loading={searchingUsers}
                inputValue={userSearchValue}
                onInputChange={(_event, newInputValue) => {
                  setUserSearchValue(newInputValue)
                }}
                onChange={(_event, newValue) => {
                  setFilterValue(newValue?.extendedId ?? "")
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getFilterTypeLabel()}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {searchingUsers ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          )}

          {Object.entries(baseColors).map(([key, colorValue]) => {
            return (
              <React.Fragment key={key}>
                <TextField
                  variant="standard"
                  sx={{ m: 1, minWidth: 230 }}
                  label={t(colorValue.label)}
                  id={`${colorValue.id}`}
                  error={colorValue.variable !== "" && !validator.isHexColor(colorValue.variable)}
                  InputLabelProps={{ shrink: colorValue.variable ? true : false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            width: "24px",
                            height: "24px",
                            backgroundColor: colorValue.variable,
                            border: "1px solid #818181",
                            minWidth: "auto",
                            "&:hover": {
                              backgroundColor: colorValue.variable,
                            },
                          }}
                          onClick={() => setShowColorPicker(colorValue.id)}
                          onBlur={() => setShowColorPicker(false)}
                        ></Button>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={() => setShowColorPicker(colorValue.id)}
                  onBlur={() => setShowColorPicker(false)}
                  value={colorValue.variable}
                  onChange={colorValue.setFunction}
                />
                <Box sx={{ position: "relative" }}>
                  <HexAlphaColorPicker
                    color={colorValue.variable}
                    onChange={colorValue.onChange}
                    onFocus={() => setShowColorPicker(colorValue.id)}
                    onBlur={() => setShowColorPicker(false)}
                    style={{
                      visibility: showColorPicker === colorValue.id ? "visible" : "hidden",
                      opacity: showColorPicker === colorValue.id ? "100%" : "0%",
                      position: "absolute",
                      right: "-200px",
                      top: "-50px",
                      transition: "0.3s ease",
                    }}
                  />
                </Box>
              </React.Fragment>
            )
          })}

          <Typography
            variant="h6"
            sx={{ mt: 3 }}
          >
            {t("calendarEmbed.hoverBgColor")}
          </Typography>

          <FormControlLabel
            sx={{ marginRight: "auto" }}
            control={
              <Switch
                checked={isGradient}
                onChange={() => {
                  setIsGradient(!isGradient)
                }}
              />
            }
            label={t("calendarEmbed.addGradient")}
          />

          {!isGradient && (
            <>
              <TextField
                variant="standard"
                sx={{ m: 1, minWidth: 230 }}
                label={t("calendarEmbed.hoverBgColor")}
                id="hoverBgColor"
                error={hoverBgColor !== "" && !validator.isRgbColor(hoverBgColor, true)}
                InputLabelProps={{ shrink: hoverBgColor ? true : false }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          width: "24px",
                          height: "24px",
                          backgroundColor: hoverBgColor,
                          border: "1px solid #818181",
                          minWidth: "auto",
                          "&:hover": {
                            backgroundColor: hoverBgColor,
                          },
                        }}
                        onClick={() => setShowColorPicker("hoverBgColor")}
                        onBlur={() => setShowColorPicker(false)}
                      ></Button>
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setShowColorPicker("hoverBgColor")}
                onBlur={() => setShowColorPicker(false)}
                value={hoverBgColor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHoverBgColor(removeSpace(event.target.value))
                }}
              />
              <Box sx={{ position: "relative" }}>
                <RgbaStringColorPicker
                  color={hoverBgColor}
                  onChange={(value: string) => setHoverBgColor(removeSpace(value))}
                  onFocus={() => setShowColorPicker("hoverBgColor")}
                  onBlur={() => setShowColorPicker(false)}
                  style={{
                    visibility: showColorPicker === "hoverBgColor" ? "visible" : "hidden",
                    opacity: showColorPicker === "hoverBgColor" ? "100%" : "0%",
                    position: "absolute",
                    right: "-200px",
                    top: "-50px",
                    transition: "0.3s ease",
                  }}
                />
              </Box>
            </>
          )}

          {isGradient && (
            <>
              <TextField
                variant="standard"
                sx={{ m: 1, minWidth: 230 }}
                label={t("calendarEmbed.firstColor")}
                id="hoverBgColor"
                error={firstHoverColor !== "" && !validator.isRgbColor(firstHoverColor, true)}
                InputLabelProps={{ shrink: firstHoverColor ? true : false }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          width: "24px",
                          height: "24px",
                          backgroundColor: firstHoverColor,
                          border: "1px solid #818181",
                          minWidth: "auto",
                          "&:hover": {
                            backgroundColor: firstHoverColor,
                          },
                        }}
                        onClick={() => setShowColorPicker("firstHoverColor")}
                        onBlur={() => setShowColorPicker(false)}
                      ></Button>
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setShowColorPicker("firstHoverColor")}
                onBlur={() => setShowColorPicker(false)}
                value={firstHoverColor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstHoverColor(removeSpace(event.target.value))
                }}
              />
              <Box sx={{ position: "relative" }}>
                <RgbaStringColorPicker
                  color={firstHoverColor}
                  onChange={(value: string) => setFirstHoverColor(removeSpace(value))}
                  onFocus={() => setShowColorPicker("firstHoverColor")}
                  onBlur={() => setShowColorPicker(false)}
                  style={{
                    visibility: showColorPicker === "firstHoverColor" ? "visible" : "hidden",
                    opacity: showColorPicker === "firstHoverColor" ? "100%" : "0%",
                    position: "absolute",
                    right: "-200px",
                    top: "-50px",
                    transition: "0.3s ease",
                  }}
                />
              </Box>

              <TextField
                variant="standard"
                sx={{ m: 1, minWidth: 230 }}
                label={t("calendarEmbed.secondColor")}
                id="hoverBgColor"
                error={secondHoverColor !== "" && !validator.isRgbColor(secondHoverColor, true)}
                InputLabelProps={{ shrink: secondHoverColor ? true : false }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          width: "24px",
                          height: "24px",
                          backgroundColor: secondHoverColor,
                          border: "1px solid #818181",
                          minWidth: "auto",
                          "&:hover": {
                            backgroundColor: secondHoverColor,
                          },
                        }}
                        onClick={() => setShowColorPicker("secondHoverColor")}
                        onBlur={() => setShowColorPicker(false)}
                      ></Button>
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setShowColorPicker("secondHoverColor")}
                onBlur={() => setShowColorPicker(false)}
                value={secondHoverColor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSecondHoverColor(removeSpace(event.target.value))
                }}
              />
              <Box sx={{ position: "relative" }}>
                <RgbaStringColorPicker
                  color={secondHoverColor}
                  onChange={(value: string) => setSecondHoverColor(removeSpace(value))}
                  onFocus={() => setShowColorPicker("secondHoverColor")}
                  onBlur={() => setShowColorPicker(false)}
                  style={{
                    visibility: showColorPicker === "secondHoverColor" ? "visible" : "hidden",
                    opacity: showColorPicker === "secondHoverColor" ? "100%" : "0%",
                    position: "absolute",
                    right: "-200px",
                    top: "-50px",
                    transition: "0.3s ease",
                  }}
                />
              </Box>
            </>
          )}
          <Stack
            sx={{ width: "100%" }}
            mt={3}
          >
            <Typography
              variant="h6"
              sx={{ mb: 3 }}
            >
              {t("calendarEmbed.codePreview")}
            </Typography>
            <Paper sx={{ p: 4, wordBreak: "break-all", position: "relative" }}>
              <code>
                &lt;iframe id="taiyoro-embed" frameBorder="0" src="{TAIYORO_DOMAIN}/schedule/{filterType}/
                {filterType === "game" ? gameId : encodeURI(filterValue)}?{searchParams.toString()}"
                scrolling="no" style="width: 100%; aspect-ratio: 1 / 1;"&gt; &lt;/iframe&gt;
              </code>
              <Box sx={{ position: "absolute", right: 1, top: 1 }}>
                <Tooltip title={t("analytics.form.clipboard.copy")}>
                  <IconButton
                    onClick={handleCopyToClipboard}
                    size="small"
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Paper>
          </Stack>
        </Stack>

        <Stack
          direction={{ sm: "column", xl: "row" }}
          spacing={{ md: 8 }}
        >
          <Stack sx={{ width: previewMode === "desktop" ? "861px" : "400px", mb: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              mb={3}
            >
              <Typography variant="h6">{t("calendarEmbed.livePreview")}</Typography>
              <FontAwesomeIcon
                icon={faDesktop}
                style={{ marginLeft: "8px" }}
              />
              <Switch
                checked={previewMode === "mobile"}
                onChange={(_e, checked) => {
                  setPreviewMode(checked ? "mobile" : "desktop")
                }}
              />
              <FontAwesomeIcon icon={faMobile} />
            </Stack>
            {loadingState ? (
              <LoadingBox />
            ) : (
              <iframe
                style={{ width: "100%", aspectRatio: "1/1" }}
                id="taiyoro-embed"
                frameBorder="0"
                src={`${TAIYORO_DOMAIN}/schedule/${filterType}/${
                  filterType === "game" ? gameId : filterValue
                }?${searchParams.toString()}`}
                scrolling="no"
              ></iframe>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default CalendarEmbed
