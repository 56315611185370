import { useCallback, useEffect, useState } from "react"

import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import type { Player, Team } from "models/Taiyoro/Meta/Placement"
import { useTranslation } from "react-i18next"
import type { AnswerOptions, DailyPickemType } from "services/Taiyoro/Pickem/daily"

import { FreetextAnswerOptionsControl } from "./Freetext"
import { ParticipantAnswerOptionsControl } from "./Participant"

interface Props {
  value: AnswerOptions
  updateValue: (value: AnswerOptions) => void
  updateStatus?: () => void
  gameId: string
  pickemType: DailyPickemType
  availableParticipants: Map<string, (Player & { type: "player" }) | (Team & { type: "team" })>
}

export const AnswerOptionsControl = ({
  value,
  updateValue,
  updateStatus,
  pickemType,
  availableParticipants,
}: Props) => {
  const { t } = useTranslation("taiyoro")
  const [type, setType] = useState<AnswerOptions["type"] | null>(null)

  useEffect(() => {
    if (value.type === "participant") {
      setType("participant")
    }
    if (value.type === "freetext") {
      setType("freetext")
    }
  }, [value])

  const resetAnswerOptions = useCallback(() => {
    updateValue({
      type: "participant",
      data: [],
    })
    updateStatus?.()
  }, [updateValue, updateStatus])

  useEffect(() => {
    if (pickemType === "match" && type === "freetext") {
      setType("participant")
      resetAnswerOptions()
    }
  }, [pickemType, type, resetAnswerOptions])

  return (
    <Stack gap={2}>
      <Box>
        <Typography
          variant="h6"
          component="h5"
        >
          {t("eventPickem.daily.modal.answers.answerType")}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={type}
          onChange={(event) => {
            const newType = event.target.value as AnswerOptions["type"]
            setType(newType)
            updateValue({
              type: newType,
              data: [],
            })
            updateStatus?.()
          }}
          row
        >
          <FormControlLabel
            value="participant"
            control={<Radio />}
            label={t("eventPickem.daily.modal.answers.answerTypeParticipant")}
          />
          {pickemType === "quiz" && (
            <FormControlLabel
              value="freetext"
              control={<Radio />}
              label={t("eventPickem.daily.modal.answers.answerTypeFreetext")}
            />
          )}
        </RadioGroup>
      </Box>
      <Box>
        <Typography
          variant="h6"
          component="h5"
        >
          {t("eventPickem.daily.modal.answers.possibleAnswers")}
        </Typography>
        {type === "participant" && value.type === "participant" && (
          <ParticipantAnswerOptionsControl
            value={value}
            updateValue={updateValue}
            updateStatus={updateStatus}
            availableParticipants={availableParticipants}
          />
        )}
        {type === "freetext" && value.type === "freetext" && (
          <FreetextAnswerOptionsControl
            value={value}
            updateValue={updateValue}
            updateStatus={updateStatus}
          />
        )}
      </Box>
    </Stack>
  )
}
