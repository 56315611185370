import { Grid } from "@mui/material"
import { styled, Box } from "@mui/material"

export const Root = styled(Grid)`
  margin-top: 8px;
  padding-bottom: 8px;

  &:nth-child(2) {
    margin-top: 0;
  }
`

export const StyleTimezone = styled(Box)`
  ul {
    z-index: 10;
    background-color: #ddd;
  }
`
