import { Box, Stack } from "@mui/material"
import ArticlesTodo from "components/Taiyoro/Articles/ArticlesTodo"
import ReviewedArticles from "components/Taiyoro/Articles/ReviewedArticles"

const ArticlesPage = () => {
  return (
    <Stack
      height="calc(100% - 32px)"
      gap={1}
      boxSizing="border-box"
    >
      <Box flex="1">
        <ArticlesTodo />
      </Box>
      <Box flex="1">
        <ReviewedArticles />
      </Box>
    </Stack>
  )
}

export default ArticlesPage
