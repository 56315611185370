import { useAuth0 } from "@auth0/auth0-react"

import type { UserRole } from "../models/UserManagement"

const useRolesCanAccess = (roles: Array<UserRole>) => {
  const { user, isAuthenticated } = useAuth0()

  return isAuthenticated && roles.some((role) => role === user["user_metadata"].role)
}

export default useRolesCanAccess
