import { faHyphen, faStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Paper, Stack, Typography } from "@mui/material"

interface Props {
  stars?: number
  value: number
  title: string
}

const SummaryBubble = (props: Props) => {
  return (
    <Paper
      elevation={20}
      sx={{
        width: "350px",
        height: "350px",
        borderRadius: "50%",
        WebkitPrintColorAdjust: "exact",
        WebkitFilter: "opacity(1)",
      }}
    >
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        gap={3}
      >
        <Box color="common.green">
          {!props.stars && (
            <FontAwesomeIcon
              icon={faHyphen}
              color="inherit"
              size="2x"
            />
          )}
          {props.stars && (
            <Stack
              direction="row"
              gap={2}
            >
              {Array.from("x".repeat(props.stars)).map((_x, index) => (
                <FontAwesomeIcon
                  key={index}
                  icon={faStar}
                  color="inherit"
                  size="2x"
                  transform="grow-2"
                />
              ))}
            </Stack>
          )}
        </Box>
        <Typography
          fontWeight="900"
          fontSize="56px"
          lineHeight={1}
        >
          {props.value.toLocaleString()}
        </Typography>
        <Typography
          fontSize="24px"
          color="grey.500"
        >
          {props.title}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default SummaryBubble
