import React from "react"

import type { Article } from "models/Taiyoro/article"

type ArticlesTodoContextType = {
  articles: Array<Article>
  loading: boolean
  reload: () => void
}

export const ArticlesTodoContext = React.createContext<ArticlesTodoContextType>({
  articles: [],
  loading: true,
  reload: () => {},
})
