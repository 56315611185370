import { useContext, useRef } from "react"

import { Alert, Box } from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import moment from "moment"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import type { OnProgressProps } from "react-player/base"
import { useDebounce } from "react-use"
import { UTCDateInJST } from "utils/tools"

export const VideoPlayer = () => {
  const { eventDate, selectedStream, timeMarks, playhead, setPlayhead, chartData, streamVodMap } =
    useContext(EventDateBracketsContext)

  const matchingVodOfSelectedStream = eventDate.platforms.find(
    (datePlatform) => datePlatform.id === streamVodMap[selectedStream.id]
  )

  const ref = useRef<ReactPlayer>(null)

  const { t } = useTranslation("taiyoro")

  // Understand the difference between the selected streams IRL start time and VOD progress offset, and find
  // the closest chart data and set the playHead to that.
  const handleProgess = (progress: OnProgressProps) => {
    const startTime = moment.utc(selectedStream.liveBroadcastStartDatetime)
    const playheadTime = startTime.clone().add(Math.round(progress.playedSeconds), "seconds")
    const index = chartData.findIndex((cd) => moment.utc(cd.datetime).isAfter(playheadTime))
    if (chartData[Math.max(index - 1, 0)].datetime !== playhead) {
      setPlayhead(chartData[index].datetime)
    }
  }

  // Scrubbing through using the Scrubber component spams updates to TimeMarks, the Player cannot handle
  // such a spam of calls to .seekTo very well
  useDebounce(
    () => {
      if (!ref.current || !matchingVodOfSelectedStream) {
        return
      }
      const selectedTimeMark = UTCDateInJST(timeMarks[timeMarks.selected])
      const streamStartTime = UTCDateInJST(selectedStream.liveBroadcastStartDatetime!)
      const duration = moment.duration(selectedTimeMark.diff(streamStartTime))
      const differenceInSeconds = duration.asSeconds()
      ref.current.seekTo(Math.max(differenceInSeconds, 0), "seconds")
    },
    700,
    [timeMarks]
  )

  return (
    <Box
      width="100%"
      height="100%"
    >
      {matchingVodOfSelectedStream && (
        <ReactPlayer
          ref={ref}
          width="100%"
          height="100%"
          url={matchingVodOfSelectedStream.url!}
          playing={true}
          loop={true}
          onProgress={handleProgess}
          controls
        />
      )}
      {!matchingVodOfSelectedStream && (
        <Alert
          sx={{ width: "100%", height: "100%" }}
          severity="warning"
        >
          {t("brackets.noMatch")}
        </Alert>
      )}
    </Box>
  )
}
