import React, { useEffect, useState } from "react"

import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, IconButton, TableCell, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"

import { ContributionType, PriorityType } from "../../../../../models/Taiyoro/Sponsor"
import { UserRole } from "../../../../../models/UserManagement"
import {
  addDateSponsor,
  addEventSponsorContributionType,
  deleteDateSponsor,
  deleteEventSponsorContributionType,
  editEventSponsor,
} from "../../../../../services/Taiyoro/event"
import { ADMIN_ROLES, HIGH_ACCESS_ROLES } from "../../../../../utils/roles"
import useRolesCanAccess from "../../../../../utils/useRolesCanAccess"
import TableList from "../../../../Table/TableList"
import RealTimeUpdateField from "../../../RealTimeUpdateField"

interface Props {
  sponsor
  eventId
  isEventPublished
  onDelete
  availableDates
  availableCurrencies
}

const SponsorEntry = (props: Props) => {
  const [allDatesState, setAllDatesState] = useState(props.sponsor.allDates)

  const [dateIdsState, setDateIdsState] = useState(props.sponsor.dateIds)

  const { t } = useTranslation(["taiyoro", "common"])

  const isHighLevelRole = useRolesCanAccess(HIGH_ACCESS_ROLES)
  const isDeletableByEditor = useRolesCanAccess([UserRole.EDITOR]) && !props.isEventPublished

  //Filtering out deleted dates
  useEffect(() => {
    setDateIdsState(
      props.sponsor.dateIds.filter((dateId) => props.availableDates.some((date) => date.id === dateId))
    )
  }, [props.availableDates, props.sponsor])

  const getDateSponsorTag = (date) => {
    if (date) {
      return (
        <>
          <span>{date.name}</span>
          <span style={{ opacity: "0.5", marginLeft: "6px" }}>
            {date.startTime.date()}/{date.startTime.month() + 1}
          </span>
        </>
      )
    }
  }

  const onDeleteDate = async (dateId) => {
    await deleteDateSponsor(dateId, props.sponsor.id)
  }

  const onAddDate = async (dateId) => {
    await addDateSponsor(dateId, props.sponsor.id)
  }

  //Listening for changes to the list of Dates on the Event and keeping EventSponsors date fields
  //up-to-date, by adding Dates to EventSponors that are flagged with allDates=true
  useEffect(() => {
    let addedDateIds = []

    if (allDatesState) {
      addedDateIds = props.availableDates
        .map((date) => date.id)
        .filter((availableId) => !dateIdsState.some((id) => availableId === id))
    }

    if (addedDateIds.length > 0) {
      setDateIdsState([...dateIdsState, ...addedDateIds])
    }

    // eslint-disable-next-line
  }, [props.availableDates, allDatesState])

  return (
    <React.Fragment>
      <TableList.BodyRow>
        <TableList.BodyCell>{props.sponsor.name}</TableList.BodyCell>
        <TableList.BodyCell>
          <RealTimeUpdateField
            type="searchable"
            options={Object.values(ContributionType).filter((value) => typeof value === "string") as string[]}
            label={t("edit.sponsors.contributionTypes")}
            disableClearable
            addFunc={(contributionType) =>
              addEventSponsorContributionType(props.eventId, props.sponsor.id, contributionType)
            }
            deleteFunc={(contributionType) =>
              deleteEventSponsorContributionType(props.eventId, props.sponsor.id, contributionType)
            }
            initialValue={props.sponsor.contributionTypes}
          />
        </TableList.BodyCell>
        <TableList.BodyCell>
          <RealTimeUpdateField
            type="number-format"
            label={t("edit.sponsors.revenue")}
            updateFunc={(value) => editEventSponsor(props.eventId, props.sponsor.id, "revenue", value)}
            initialValue={props.sponsor.revenue && props.sponsor.revenue.split(".")[0]}
          />
        </TableList.BodyCell>
        <TableList.BodyCell>
          <RealTimeUpdateField
            type="select"
            options={props.availableCurrencies}
            label={t("edit.sponsors.currency")}
            disableClearable
            updateFunc={(value) => editEventSponsor(props.eventId, props.sponsor.id, "currency", value)}
            initialValue={props.sponsor.currency}
          />
        </TableList.BodyCell>
        <TableList.BodyCell>
          <RealTimeUpdateField
            type="select"
            options={Object.values(PriorityType).filter((value) => typeof value === "string") as string[]}
            label={t("edit.sponsors.priority")}
            disableClearable
            updateFunc={(value) => editEventSponsor(props.eventId, props.sponsor.id, "priority", value)}
            initialValue={props.sponsor.priority}
          />
        </TableList.BodyCell>
        <TableList.BodyCell>
          <RealTimeUpdateField
            type="switch"
            label=""
            updateFunc={(value) => {
              setAllDatesState(value)
              return editEventSponsor(props.eventId, props.sponsor.id, "allDates", value)
            }}
            initialValue={props.sponsor.allDates}
          />
        </TableList.BodyCell>
        <TableList.ControlsCell>
          {(isHighLevelRole || isDeletableByEditor) && (
            <Tooltip title={t("common:actions.delete")}>
              <IconButton
                color="error"
                onClick={() => {
                  Promise.all(dateIdsState.map((id) => () => onDeleteDate(id))).then(() => {
                    props.onDelete()
                  })
                }}
                sx={{ width: "32px", height: "32px" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            </Tooltip>
          )}
        </TableList.ControlsCell>
      </TableList.BodyRow>
      {!allDatesState && (
        <TableList.BodyRow key={`sponsor-list-dates-${props.sponsor.id}`}>
          <TableCell align="right">{t("edit.sponsors.activeDates")}</TableCell>
          <TableCell colSpan={4}>
            <RealTimeUpdateField
              type="searchable"
              options={props.availableDates}
              label={t("edit.sponsors.addDate")}
              initialValue={dateIdsState.map((dateId) =>
                props.availableDates.find((date) => date.id === dateId)
              )}
              getOptionLabel={(option) => getDateSponsorTag(option)}
              addFunc={(sponsor) => addDateSponsor(sponsor.id, props.sponsor.id)}
              deleteFunc={(sponsor) => deleteDateSponsor(sponsor.id, props.sponsor.id)}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              onClick={() => {
                props.availableDates
                  .map((date) => date.id)
                  .filter((dateId) => !dateIdsState.includes(dateId))
                  .forEach(onAddDate)
                setDateIdsState(props.availableDates.map((date) => date.id))
              }}
            >
              {t("edit.sponsors.addAll")}
            </Button>
            <Button
              onClick={() => {
                dateIdsState.forEach(onDeleteDate)
                props.sponsor.dateIds = []
                setDateIdsState([])
              }}
            >
              {t("edit.sponsors.deleteAll")}
            </Button>
          </TableCell>
        </TableList.BodyRow>
      )}
    </React.Fragment>
  )
}

export default SponsorEntry
