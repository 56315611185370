import React, { useContext, useState } from "react"

import { Groups as GroupsIcon, Download as DownloadIcon } from "@mui/icons-material/"
import { Box, Button, Typography, Modal, Stack } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import { convertAndDownloadBlobAsCSV } from "utils/tools"

import { ParticipantsTable } from "./ParticipantsTable"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, calc(-50% + 32px))",
  width: "90vw",
  maxHeight: "calc(100% - 64px - 64px)",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const Participants = () => {
  const { t } = useTranslation("taiyoro")

  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { participants, eventState } = useContext(PickemSettingsContext)

  const handleDownload = () => {
    const filename = `${eventState?.name ?? ""} ${t("eventPickem.pickem")} ${t("eventPickem.participants.participatingUsers")}`
    const blob = new Blob(
      [
        `${Object.values(t("eventPickem.participants.tableHeaders", { returnObjects: true })).join(",")}\n`,
        participants
          .map((participant) => {
            return Object.keys(t("eventPickem.participants.tableHeaders", { returnObjects: true }))
              .map((key) => `${participant[key] as string}`)
              .join(",")
          })
          .join("\n"),
      ],
      {
        type: "text/csv;encoding:utf-8",
      }
    )

    convertAndDownloadBlobAsCSV(blob, filename)
  }

  return (
    <Box
      ml="auto"
      mr={0}
    >
      <Button
        component="label"
        variant="contained"
        endIcon={<GroupsIcon />}
        onClick={handleOpen}
      >
        {t("eventPickem.participants.showParticipatingUsers")}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-pickem-participants-heading"
        aria-describedby="modal-pickem-participants-body"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              id="modal-pickem-participants-heading"
              variant="h6"
              component="h2"
            >
              {t("eventPickem.participants.participatingUsers")}
            </Typography>
            <Button
              component="label"
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              Download CSV
            </Button>
          </Stack>

          <Box
            id="modal-pickem-participants-body"
            sx={{ mt: 2 }}
          >
            <ParticipantsTable />
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Participants
