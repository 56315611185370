import React from "react"

import { Link, Paper } from "@mui/material"
import moment from "moment"
import { LineChart, Line, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts"

import { fetchDatePlatformStreamStats, fetchEvent } from "../../../services/Taiyoro/event"
import { fetchPlatforms } from "../../../services/Taiyoro/platforms"

export default (props) => {
  const [points, setPoints] = React.useState([])
  const [eventName, setEventName] = React.useState(null)
  const [dateName, setDateName] = React.useState(null)
  const [platformId, setPlatformId] = React.useState(null)
  const [platforms, setPlatforms] = React.useState({})
  const [displayRawData, setDisplayRawData] = React.useState(false)

  const [fromDateStringBuffer, setFromDateStringBuffer] = React.useState(null)
  const [toDateStringBuffer, setToDateStringBuffer] = React.useState(null)
  const [fromDateString, setFromDateString] = React.useState(null)
  const [toDateString, setToDateString] = React.useState(null)

  const [refreshCounter, setRefreshCounter] = React.useState(0)

  React.useEffect(() => {
    metricsResult = null
    setRefreshCounter(refreshCounter + 1) // force rerender after clearing cache
  }, [fromDateString, toDateString])

  React.useEffect(() => {
    if (props.match.params.datePlatformId) {
      fetchDatePlatformStreamStats(props.match.params.datePlatformId).then((dataArr) => {
        setPoints(dataArr)
      })
    }
    if (props.match.params.eventId) {
      fetchEvent(props.match.params.eventId).then((data) => {
        if (data) {
          setEventName(data.name)
          if (data) {
            const dates = data ? data.dates : null
            if (dates) {
              for (let i = 0; i < dates.length; i++) {
                let found = false
                const date = dates[i]
                if (date.platforms) {
                  for (let j = 0; j < date.platforms.length; j++) {
                    const dp = date.platforms[j]
                    if (dp.id === props.match.params.datePlatformId) {
                      found = true
                      setDateName(date.name)
                      setPlatformId(dp.platform)
                      break
                    }
                  }
                  if (found) break
                }
              }
            }
          }
        }
      })
    }
  }, [props.match.params.datePlatformId, props.match.params.eventId])

  React.useEffect(() => {
    const process = async () => {
      const pforms = await fetchPlatforms()
      if (pforms) {
        setPlatforms(
          pforms.reduce((acc, platform) => {
            acc[platform.id] = platform.name
            return acc
          }, {})
        )
      }
    }
    process()
  }, [])

  const data = React.useMemo(() => {
    // if points are separated more than 3.5 minutes, then we plot every point.
    let displayAll = false
    if (points.length > 1) {
      const pt1 = moment(points[0].datetime)
      const pt2 = moment(points[1].datetime)
      console.log("pt1", pt1)
      console.log("pt2", pt2)
      console.log("diff", pt2.diff(pt1, "minutes", true))
      if (pt2.diff(pt1, "minutes", true) > 3.5) {
        displayAll = true
      }
    }
    return points
      .filter((_, index) => displayAll || index % 5 === 0 || index === points.length - 1)
      .map((entry) => {
        const date = new Date(entry.datetime)
        date.setSeconds(0)
        date.setMilliseconds(0)
        return {
          date: moment(date).tz("Asia/Tokyo").local().format("HH:mm"),
          dateString: moment(date).tz("Asia/Tokyo").local().format("MM/DD Z"),
          timeString: moment(date).tz("Asia/Tokyo").local().format("HH:mm"),
          viewers: entry.concurrentViewers,
        }
        // return {date: moment.utc(date).tz('Asia/Tokyo').format('hh:mm a'), viewers: entry.concurrentViewers}
      })
  }, [points])

  return (
    <div style={{ display: "flex", flexWrap: "wrap", WebkitPrintColorAdjust: "exact" }}>
      <div style={{ margin: "5% 5%" }}>
        <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>{eventName}</h1>
        <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>
          {dateName} ({platformId && platforms && platforms[platformId]})
        </h1>
        <div
          style={{
            width: "1000px",
            height: "600px",
          }}
        >
          {points.length > 0 && (
            <LineChart
              width={1000}
              height={600}
              data={data}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line
                type="monotone"
                dataKey="viewers"
                stroke="#f73365"
              />
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray="5 5"
              />
              <XAxis
                dataKey="date"
                padding={{ left: 30, right: 30 }}
              />
              <YAxis width={100} />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          )}
        </div>
        {points.length > 0 && (
          <div>
            <p>
              From:{" "}
              <input
                type="text"
                onChange={(e: any) => setFromDateStringBuffer(e.target.value)}
              />
              , To:{" "}
              <input
                type="text"
                onChange={(e: any) => setToDateStringBuffer(e.target.value)}
              />{" "}
              <button
                onClick={() => {
                  setFromDateString(fromDateStringBuffer)
                  setToDateString(toDateStringBuffer)
                }}
              >
                Calculate
              </button>{" "}
              Note: Format should be like '2022-03-02T18:30:00+09:00'
            </p>
            {fromDateString && toDateString ? (
              <p>
                Time Period: {fromDateString} - {toDateString}
              </p>
            ) : (
              <p>Time Period: All</p>
            )}
            <p>
              ACV:{" "}
              {
                calcMetrics(getSliceForDateRange(points, fromDateString, toDateString))
                  .views_live_concurrent_average
              }
              , PCV:{" "}
              {
                calcMetrics(getSliceForDateRange(points, fromDateString, toDateString))
                  .views_live_concurrent_peak
              }
              , Minutes Watched:{" "}
              {
                calcMetrics(getSliceForDateRange(points, fromDateString, toDateString))
                  .views_live_minutes_watched
              }{" "}
              Total Duration:{" "}
              {
                calcMetrics(getSliceForDateRange(points, fromDateString, toDateString))
                  .live_broadcast_duration
              }{" "}
              minutes
            </p>
          </div>
        )}
        {props.match.params.eventId && props.match.params.datePlatformId && (
          <p style={{ textAlign: "center", width: "50%" }}>
            <button onClick={() => setDisplayRawData(!displayRawData)}>Toggle Raw Data</button>
          </p>
        )}

        {displayRawData && (
          <div>
            <p>{JSON.stringify(points)}</p>
          </div>
        )}
      </div>
      {props.match.params.datePlatformId ? (
        <Link
          href={`/taiyoro/metrics/${props.match.params.eventId}/${props.match.params.datePlatformId}/clips`}
        >
          Clips List
        </Link>
      ) : (
        <div />
      )}
    </div>
  )
}

const CustomTooltip = ({ payload, label, active }: any) => {
  console.log("payload", payload)
  return active ? (
    <Paper>
      <div style={{ marginBottom: "3px", textAlign: "center" }}>
        <strong>
          {/* {moment(datum.primary.toISOString())
            .tz('Asia/Tokyo')
            .local()
            .format('MM/DD Z')} */}
          {payload[0].payload.dateString}
        </strong>
      </div>
      <div style={{ marginBottom: "3px", textAlign: "center" }}>
        <strong>
          {/* {moment(datum.primary.toISOString())
            .tz('Asia/Tokyo')
            .local()
            .format('HH:mm')} */}
          {payload[0].payload.timeString}
        </strong>
      </div>
      <table style={{ whiteSpace: "nowrap" }}>
        <tbody>
          <tr style={{ opacity: 1, fontWeight: "bold" }}>
            <td
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "5px",
              }}
            >
              <svg
                width="16"
                height="16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="7"
                  style={{
                    stroke: "white",
                    fill: "rgb(255, 52, 101)",
                    strokeWidth: 2,
                  }}
                ></circle>
              </svg>
            </td>
            <td>Viewers:</td>
            <td style={{ textAlign: "right" }}>{payload[0].payload.viewers}</td>
          </tr>
        </tbody>
      </table>
    </Paper>
  ) : null
}

const getSliceForDateRange = (data: { datetime: string }[], from: string, to: string) => {
  const arr = data
  if (!from || !to) return arr
  const fromUnix = Date.parse(from)
  const toUnix = Date.parse(to)

  const ret = []
  arr.forEach((d) => {
    const u = Date.parse(d.datetime)
    if (u >= fromUnix && u <= toUnix) {
      ret.push(d)
    }
  })
  return ret
}

let metricsResult: {
  views_live_concurrent_average: number
  views_live_minutes_watched: number
  views_live_concurrent_peak: number
  live_broadcast_duration: number
} = null
const calcMetrics = (
  arr: {
    datetime: string
    concurrentViewers: number
  }[]
) => {
  if (metricsResult) return metricsResult
  let timeline = arr
  const roundDownToNearestSecond = (unixTimestampMs) => unixTimestampMs - (unixTimestampMs % 60000)
  let views_live_minutes_watched = 0
  let live_broadcast_duration = 0
  let peak = (timeline[0] && timeline[0].concurrentViewers) || 0

  let lastSignificantPointIndex = timeline.length
  for (let i = timeline.length - 1; i >= 0; i--) {
    if (timeline[i] && timeline[i].concurrentViewers > 0) {
      lastSignificantPointIndex = i
      break
    }
  }
  timeline = timeline.slice(0, lastSignificantPointIndex)

  for (let i = 1; i < timeline.length; i++) {
    const entry = timeline[i]
    const previousTimePoint = timeline[i - 1]
    const minutesSince =
      (roundDownToNearestSecond(Date.parse(entry.datetime)) -
        roundDownToNearestSecond(Date.parse(previousTimePoint.datetime))) /
      60000
    if (minutesSince > 0) {
      views_live_minutes_watched +=
        ((previousTimePoint.concurrentViewers + entry.concurrentViewers) / 2) * minutesSince
      live_broadcast_duration += minutesSince
    }
    if (entry.concurrentViewers > peak) {
      peak = entry.concurrentViewers
    }
  }
  const views_live_concurrent_average = Math.floor(
    (views_live_minutes_watched || 0) / (live_broadcast_duration || 1)
  )
  metricsResult = {
    views_live_concurrent_peak: peak,
    views_live_concurrent_average: views_live_concurrent_average,
    views_live_minutes_watched: Math.floor(views_live_minutes_watched),
    live_broadcast_duration,
  }
  return metricsResult
}
