import { useContext } from "react"

import { Box, Stack, Typography } from "@mui/material"
import { EventDateBracketsContext } from "contexts/event-date-brackets"
import { useTranslation } from "react-i18next"

import { Chart } from "./chart"
import { EventDateBracketsList } from "./list"
import { Scrubber } from "./scrubber"
import { StreamList } from "./stream-list"
import { VideoPlayer } from "./videoPlayer"

export const EventDateBracket = () => {
  const { event, eventDate } = useContext(EventDateBracketsContext)

  const { t } = useTranslation("taiyoro")

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        gap={2}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          flex={1}
          px={2}
        >
          <img
            src={event.imageUrl}
            alt="event banner"
            width="200px"
          />
          <Stack gap={1}>
            <Typography variant="h5">{event.name}</Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {eventDate.name}
            </Typography>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
            >
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {t("list.header.acu")}: {eventDate.analytics.viewsLiveConcurrentAverage.toLocaleString()}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {t("list.header.pcu")}: {eventDate.analytics.viewsLiveConcurrentPeak.toLocaleString()}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {t("list.header.minutesWatched")}:{" "}
                {Number(eventDate.analytics.viewsLiveMinutesWatched).toLocaleString()}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box
          flex={1}
          px={2}
          alignSelf="flex-end"
        >
          <StreamList />
        </Box>
      </Stack>

      <Stack
        direction="row"
        gap={2}
        alignItems="flex-start"
      >
        <Box
          flex={1}
          px={2}
          overflow="hidden" // This property is critical to make the chart resize - otherwise is expands, but never shrinks.
        >
          <Box
            width="100%"
            sx={{ aspectRatio: "16/9" }}
          >
            <Chart />
          </Box>
        </Box>
        <Box
          flex={1}
          px={2}
        >
          <Box
            width="100%"
            sx={{ aspectRatio: "16/9" }}
          >
            <VideoPlayer />
          </Box>
        </Box>
      </Stack>

      <Stack
        direction="row"
        gap={2}
        alignItems="flex-start"
      >
        <Box
          flex={1}
          px={2}
        >
          <Scrubber />
        </Box>
        <Box
          flex={1}
          px={2}
        ></Box>
      </Stack>

      <EventDateBracketsList />
    </Stack>
  )
}
