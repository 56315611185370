import React from "react"

import { Auth0Provider } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()

  const handleRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain="playbrain.auth0.com"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || "hU7APHGhT5V7cNPYg5x7Ue6pSEMDeGn5"}
      redirectUri={window.location.origin}
      onRedirectCallback={handleRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
