import { useContext } from "react"

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Stack, Typography, Link } from "@mui/material"
import { EventContext } from "contexts/event"
import { TAIYORO_DOMAIN } from "utils/taiyoro"

export const TaiyoroEventLink = () => {
  const [event] = useContext(EventContext)

  return (
    <Link
      display="block"
      mb={3}
      href={`${TAIYORO_DOMAIN}/event/${event.id!}/${event.urlSlug}`}
      target="_blank"
    >
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
      >
        <img
          src="/images/taiyoro-logo.png"
          width="20px"
          height="20px"
          alt=""
        />
        <Typography
          component="span"
          variant="body2"
        >{`/${event.id!}/${event.urlSlug}`}</Typography>
        <FontAwesomeIcon icon={faExternalLink} />
      </Stack>
    </Link>
  )
}
