import React, { useEffect, useContext } from "react"
import type { SetStateAction } from "react"

import { Stack, Select, MenuItem, Pagination } from "@mui/material"
import type { SelectChangeEvent } from "@mui/material"
import { MetaPageContext } from "contexts/meta-page"

import { paginate } from "../../helpers"

export const CustomPagination = () => {
  // -- React Contexts --
  const {
    paginateNumberOfRecords,
    setPaginateNumberOfRecords,
    paginatePage,
    setPaginatePage,
    setPaginatedMetaListData,
    metaListState,
  } = useContext(MetaPageContext)

  // -- Declarations --
  const pageCount = Math.ceil(metaListState.length / paginateNumberOfRecords)

  // -- Handlers --
  const handleChangepaginateNumberOfRecords = (event: SelectChangeEvent<number>) => {
    setPaginateNumberOfRecords(event.target.value as number)
    setPaginatePage(1)
  }

  const handleChangePaginate = (_, value: SetStateAction<number>) => {
    setPaginatePage(value)
  }

  // -- LifeCycle --
  useEffect(() => {
    const result = paginate(metaListState, paginateNumberOfRecords, paginatePage)

    if (!result) {
      setPaginatedMetaListData([])
      return
    }

    setPaginatedMetaListData(result)
  }, [metaListState, paginatePage, paginateNumberOfRecords, setPaginatedMetaListData])

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        p: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Select
        labelId="pagination-select-label"
        id="pagination-simple-select"
        value={paginateNumberOfRecords}
        onChange={handleChangepaginateNumberOfRecords}
        sx={{
          fontSize: "0.875rem",
          "& .MuiSelect-select": {
            padding: "6px 16px",
          },
          "& .MuiMenuItem-root": {
            fontSize: "0.875rem",
          },
        }}
      >
        {[5, 10, 20, 50, 100].map((value) => (
          <MenuItem
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </Select>

      <Pagination
        count={pageCount}
        page={paginatePage}
        onChange={handleChangePaginate}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  )
}
