import { EventDateBracket } from "components/Taiyoro/Brackets/EventDateBracket"
import { EventDateBracketsProvider } from "components/Taiyoro/Brackets/EventDateBracketsProvider"

export const EventDateBracketsPage = () => {
  return (
    <EventDateBracketsProvider>
      <EventDateBracket />
    </EventDateBracketsProvider>
  )
}
