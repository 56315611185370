export enum MetaType {
  CASTER = "CASTER",
  GAME = "GAME",
  GAME_GROUP = "GAME_GROUP",
  ORGANIZER = "ORGANIZER",
  PLACEMENT = "PLACEMENT",
  PLATFORM = "PLATFORM",
  PRODUCER = "PRODUCER",
  SPONSOR = "SPONSOR",
  SIGNIFICANT_PLAYER = "SIGNIFICANT_PLAYER",
  TAG = "TAG",
  TEAM = "TEAM",
  TEAM_ORGANIZATION = "TEAM_ORGANIZATION",
  VENUE = "VENUE",
  EVENT = "EVENT",
}
