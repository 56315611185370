import { useContext } from "react"

import { faTrashUndo } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fab, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"

import { FeaturedEventsContext } from "../FeaturedEventsProvider"

export const DiscardChanges = () => {
  const { pendingApiChangesQueue, reload } = useContext(FeaturedEventsContext)

  const { t } = useTranslation("common")

  return (
    <Tooltip title={t("actions.cancel")}>
      <span>
        <Fab
          size="medium"
          color="error"
          aria-label="discard"
          onClick={() => reload()}
          disabled={pendingApiChangesQueue.length === 0}
          sx={{
            flexShrink: 0,
          }}
        >
          <FontAwesomeIcon icon={faTrashUndo} />
        </Fab>
      </span>
    </Tooltip>
  )
}
