import React, { useContext } from "react"

import { Box, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import type { GridColDef, GridColumns } from "@mui/x-data-grid"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import type { PickemUser } from "services/Taiyoro/Pickem/participants"

export const ParticipantsTable = () => {
  const { t } = useTranslation("taiyoro")
  const { participants } = useContext(PickemSettingsContext)

  const renderCells: Record<string, GridColDef<PickemUser>["renderCell"]> = {
    participation: (params) => {
      return <Typography>{params.value}%</Typography>
    },
  }

  const columns: GridColumns<PickemUser> = Object.keys(
    t("eventPickem.participants.tableHeaders", { returnObjects: true })
  ).map((key) => ({
    flex: 1,
    field: key,
    sortable: true,
    headerName: t(`eventPickem.participants.tableHeaders.${key}`),
    renderCell: renderCells[key],
  }))

  return (
    <Box
      sx={{ width: "100%", height: "100%" }}
      mt={2}
    >
      <DataGrid
        sx={{ height: "calc(100% - 85px)" }}
        columns={columns}
        rows={participants}
        disableVirtualization
        autoHeight={true}
        rowHeight={100}
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        hideFooter
        density="comfortable"
      />
    </Box>
  )
}
