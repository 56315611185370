import React, { useState, useEffect, useContext } from "react"

import { faCalendarAlt, faMemoCircleInfo, faPaste } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Chip, Grid, Link, Skeleton, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material"
import { TaiyoroDataContext } from "contexts/tag-hierarchy"
import type Game from "models/Taiyoro/Meta/Game"
import type { Tag } from "models/Taiyoro/Meta/Tag"
import type { EventListItem } from "models/Taiyoro/event"
import { useTranslation } from "react-i18next"
import { Link as ReactRouterDomLink } from "react-router-dom"
import { useLocalisedLabel } from "utils/useLocalisedLabel"

import Duration from "./Duration"

interface Props {
  eventListItem: EventListItem
  games: Array<Game>
}

const SummaryLink = (props: Props) => {
  const [totalMinutesWatchedState, setTotalMinutesWatchedState] = useState(null)
  const [greatestPCUState, setGreatestPCUState] = useState(null)
  const [totalACUState, setTotalACUState] = useState(null)
  const [gamesState, setGamesState] = useState([])
  const { t } = useTranslation("taiyoro")
  const localisedLabel = useLocalisedLabel()
  const { eventListSearchParams, setEventListSearchParams } = useContext(TaiyoroDataContext)

  useEffect(() => {
    if (props.eventListItem.analytics) {
      !totalMinutesWatchedState &&
        props.eventListItem.analytics.viewsLiveMinutesWatched &&
        setTotalMinutesWatchedState(Math.round(Number(props.eventListItem.analytics.viewsLiveMinutesWatched)))
      !greatestPCUState &&
        props.eventListItem.analytics.viewsLiveConcurrentPeak &&
        setGreatestPCUState(Math.round(props.eventListItem.analytics.viewsLiveConcurrentPeak))
      !totalACUState &&
        props.eventListItem.analytics.viewsLiveConcurrentAverage &&
        setTotalACUState(Math.round(props.eventListItem.analytics.viewsLiveConcurrentAverage))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.games.length > 0) {
      const gameList = props.eventListItem.games.map(
        (game) => props.games.find((game2) => game2.id === game.gameId) || {}
      )
      setGamesState(gameList)
    }
  }, [props.games])

  const searchByTag = (tag: Tag) => {
    const tags = eventListSearchParams.filterTags ? [...eventListSearchParams.filterTags] : []
    if (tags.some((t) => tag.id === t.id)) {
      return
    }
    tags.push(tag)
    setEventListSearchParams({
      mode: "tags",
      filterTags: tags,
    })
  }

  return (
    <TableRow>
      <TableCell sx={{ maxWidth: "500px" }}>
        <Stack gap={1}>
          <Stack
            direction="row"
            gap={1}
            mb={1}
            alignItems="center"
          >
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Link
                  to={`/taiyoro/event/${props.eventListItem.id}`}
                  component={ReactRouterDomLink}
                  underline="hover"
                  color="inherit"
                >
                  <Typography
                    data-id={props.eventListItem.id}
                    fontSize="18px"
                  >
                    {props.eventListItem.name}
                  </Typography>
                </Link>
                {props.eventListItem.origin !== "JP" && (
                  <Chip
                    size="small"
                    label={t("list.summary.international")}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            alignItems="center"
          >
            {!props.eventListItem.published && (
              <Chip
                size="small"
                color="warning"
                label={t("list.summary.draft").toUpperCase()}
                icon={<FontAwesomeIcon icon={faPaste} />}
              />
            )}
            {gamesState.length > 0 &&
              gamesState.map((game, index) => (
                <Chip
                  key={index}
                  size="small"
                  color="primary"
                  label={game.name ?? ""}
                  style={{ maxWidth: "140px" }}
                />
              ))}
            {gamesState.length === 0 && (
              <>
                <Skeleton
                  animation="wave"
                  width="140px"
                  height="24px"
                  variant="rectangular"
                  sx={{ borderRadius: 4 }}
                />
                <Skeleton
                  animation="wave"
                  width="140px"
                  height="24px"
                  variant="rectangular"
                  sx={{ borderRadius: 4 }}
                />
              </>
            )}
          </Stack>
          {props.eventListItem.tags.length > 0 && (
            <Stack
              direction="row"
              gap={1}
              flexWrap="wrap"
              alignItems="center"
            >
              {props.eventListItem.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  size="small"
                  color="default"
                  label={localisedLabel(tag)}
                  style={{ maxWidth: "140px" }}
                  onClick={() => searchByTag(tag)}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        {props.eventListItem.notes && (
          <Tooltip title={<Typography>{props.eventListItem.notes}</Typography>}>
            <FontAwesomeIcon
              icon={faMemoCircleInfo}
              size="lg"
            />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Duration dates={props.eventListItem.dates} />
      </TableCell>
      <TableCell style={{ minWidth: "350px" }}>
        <Grid
          container
          spacing={0}
        >
          <Grid
            item
            xs={4}
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {greatestPCUState && greatestPCUState.toLocaleString()}
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {totalACUState && totalACUState.toLocaleString()}
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {totalMinutesWatchedState && totalMinutesWatchedState.toLocaleString()}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid
          container
          spacing={0}
          style={{ width: "60px" }}
        >
          <Grid
            item
            xs={6}
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            <FontAwesomeIcon
              icon={faCalendarAlt}
              size="1x"
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ textAlign: "center", fontSize: "20px" }}
          >
            {props.eventListItem.dates.length}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default SummaryLink
