import { SetStateAction, useContext, useEffect, useState } from "react"
import React from "react"

import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Skeleton,
  Stack,
  Slider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material"
import { EventContext } from "contexts/event"
import { NotificationContext } from "contexts/notification"
import { useTranslation } from "react-i18next"
import { ComposedChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import { updateDatePlatformTrim, fetchDatePlatformTrim } from "services/Taiyoro/datePlatform"
import { fetchDatePlatformStreamStats } from "services/Taiyoro/event"
import { UTCDateInJST } from "utils/tools"

interface Props {
  openControls: [boolean, React.Dispatch<SetStateAction<boolean>>]
  id: string
}

const findClosestTimestampIndex = (data: Array<{ datetime: string }>, timestamp: string) => {
  const timestampMoment = UTCDateInJST(timestamp)
  return data.findIndex(({ datetime }, index) => {
    const datetimeMoment = UTCDateInJST(datetime)
    if (index === 0) {
      return timestampMoment.format() <= datetimeMoment.format()
    }
    if (index === data.length - 1) {
      return true
    }
    const previousDatetime = UTCDateInJST(data[index - 1].datetime)
    const nextDatetime = UTCDateInJST(data[index + 1].datetime)
    return (
      datetimeMoment.isSame(timestampMoment) ||
      (timestampMoment.format() > previousDatetime.format() &&
        timestampMoment.format() < nextDatetime.format())
    )
  })
}

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation("taiyoro")

  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box p={2}>
          <Typography>
            {t("edit.dataTrim.timestamp")}: {UTCDateInJST(label).format("HH:mm")}
          </Typography>
          <Typography>
            {t("edit.dataTrim.concurrentViewers")}: {payload[0].value}
          </Typography>
        </Box>
      </Paper>
    )
  }

  return null
}

export const TrimDialog = (props: Props) => {
  const [open, setOpen] = props.openControls
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [trimValues, setTrimValues] = useState([])
  const [serverTrimValues, setServerTrimValues] = useState([])
  const [data, setData] = useState([])
  const { setNotification } = useContext(NotificationContext)
  const { t } = useTranslation(["common", "taiyoro"])
  const theme = useTheme()
  const [event, setEvent] = useContext(EventContext)

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      const viewershipData = await fetchDatePlatformStreamStats(props.id)
      const { startDatetime, endDatetime } = await fetchDatePlatformTrim(props.id)
      setData(viewershipData)
      if (startDatetime && endDatetime) {
        const values = [
          findClosestTimestampIndex(viewershipData, startDatetime),
          findClosestTimestampIndex(viewershipData, endDatetime),
        ]
        setTrimValues(values)
        setServerTrimValues(values)
      } else {
        const values = [0, viewershipData.length - 1]
        setTrimValues(values)
        setServerTrimValues(values)
      }
      setLoading(false)
    }
    const reset = () => {
      setData([])
      setTrimValues([])
      setServerTrimValues([])
    }
    open && load()
    !open && reset()
  }, [open, props.id])

  const handleError = (error: any) => {
    setNotification({
      message: error,
      severity: "error",
    })
  }

  const handleTrim = () => {
    setSaving(true)
    updateDatePlatformTrim(props.id, data[trimValues[0]].datetime, data[trimValues[1]].datetime)
      .then((updatedDatePlatforms) => {
        setServerTrimValues(trimValues)
        setNotification({
          message: t("dataUpdateSuccess"),
          severity: "success",
        })
        const dateId = event.dates.find((date) =>
          date.platforms.some((platform) => platform.id === props.id)
        )?.id
        setEvent((oldEvent) => {
          const newEvent = Object.assign(Object.create(Object.getPrototypeOf(oldEvent)), oldEvent)
          newEvent.dates.find((date) => date.id === dateId).platforms = updatedDatePlatforms
          return newEvent
        })
      })
      .catch(handleError)
      .finally(() => {
        setSaving(false)
        setOpen(false)
      })
  }

  const noUnsavedChangesExist = () =>
    trimValues.length === serverTrimValues.length &&
    trimValues.every((value, index) => serverTrimValues[index] === value)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: { overflow: "visible" } }}
    >
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack gap={1}>
          <Box
            width="100%"
            height="400px"
          >
            {loading && (
              <Skeleton
                height="100%"
                width="100%"
                variant="rounded"
                animation="wave"
              />
            )}
            {!loading && (
              <ResponsiveContainer>
                <ComposedChart data={data}>
                  <XAxis
                    dataKey="datetime"
                    tickFormatter={(value) => UTCDateInJST(value).format("HH:mm")}
                  />
                  <Tooltip content={CustomTooltip as any} />
                  <Line
                    type="monotone"
                    dataKey="concurrentViewers"
                    stroke={theme.palette.secondary.main}
                  />
                  {trimValues.length > 1 && (
                    <>
                      <ReferenceLine x={data[trimValues[0]].datetime} />
                      <ReferenceLine x={data[trimValues[1]].datetime} />
                    </>
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </Box>
          <Slider
            value={trimValues}
            min={0}
            max={data.length - 1}
            onChange={(_event, values) => setTrimValues(values as number[])}
            valueLabelDisplay="auto"
            disabled={loading && saving}
            valueLabelFormat={(value) => {
              if (value === -1) {
                return ""
              }
              return (
                <>
                  <Typography>
                    {t("taiyoro:edit.dataTrim.timestamp")}:{" "}
                    {UTCDateInJST(data[value].datetime).format("HH:mm")}
                  </Typography>
                  <Typography>
                    {t("taiyoro:edit.dataTrim.concurrentViewers")}: {data[value].concurrentViewers}
                  </Typography>
                </>
              )
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="text"
            disabled={saving}
            onClick={() => setOpen(false)}
          >
            {t("actions.cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            disabled={loading || saving || noUnsavedChangesExist()}
            onClick={handleTrim}
            loading={saving}
          >
            {t("actions.save")}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
