import React from "react"
import { useState } from "react"
import { useEffect } from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useTranslation } from "react-i18next"

import Date from "./Date"
import { TableHeader } from "./styles"

const DatesList = ({ dates, games, venues, platforms, languages, eventId }) => {
  const [expandedState, setExpandedState] = useState({})
  const [datesState, setDatesState] = useState([])
  const { t } = useTranslation("taiyoro")

  const toggleStreamVisibility = (id: string) => {
    const s = expandedState
    s[id] = !s[id]
    setExpandedState({ ...s })
  }

  useEffect(() => {
    const sortedDates = dates
    sortedDates.sort((a, b) => (a.startTime.isBefore(b.startTime) ? -1 : 1))
    setDatesState(sortedDates)
  }, [dates])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>{t("list.summary.dateList.headers.title")}</TableHeader>
          <TableHeader style={{ textAlign: "center", width: "300px" }}>
            {t("list.summary.dateList.headers.schedule")}
          </TableHeader>
          <TableHeader>{t("list.summary.dateList.headers.game")}</TableHeader>
          <TableHeader>{t("list.summary.dateList.headers.offline")}</TableHeader>
          <TableHeader>{t("list.summary.dateList.headers.venue")}</TableHeader>
          <TableHeader>{t("list.summary.dateList.headers.audience")}</TableHeader>
          <TableHeader>{t("analytics.results.TAIYORO_RATING")}</TableHeader>
          <TableHeader style={{ width: "32px", minWidth: "32px" }}></TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {datesState.map((date) => (
          <Date
            key={date.id}
            date={date}
            eventId={eventId}
            games={games}
            venues={venues}
            platforms={platforms}
            languages={languages}
            expanded={expandedState[date.id] === true}
            onToggle={(id: string) => toggleStreamVisibility(id)}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default DatesList
