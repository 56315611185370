import { useState } from "react"

import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton } from "@mui/material"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { useTranslation } from "react-i18next"

export const DeleteBracketConfirmation = ({ onConfirm }: { onConfirm: () => void }) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation("common")

  const handleConfirm = () => {
    setOpen(false)
    onConfirm()
  }

  return (
    <>
      <ConfirmationDialog
        open={open}
        body={t("common:actions.checkAgain")}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirm}
        confirmText={t("common:actions.delete")}
        cancelText={t("common:actions.cancel")}
      />
      <IconButton
        color="error"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    </>
  )
}
