import { useContext } from "react"

import { FormControlLabel, Stack, Switch } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"

export const VisibilitySettings = () => {
  const { t } = useTranslation("taiyoro")
  const { settings, setSettings } = useContext(PickemSettingsContext)

  const handleChange = () => {
    const newSettings = { ...settings, isPublic: !settings.isPublic }
    setSettings(newSettings)
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
    >
      <FormControlLabel
        control={<Switch aria-label="make public" />}
        label={t("eventPickem.public")}
        checked={settings.isPublic}
        onChange={handleChange}
      />
    </Stack>
  )
}
