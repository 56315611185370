import React from "react"

import { useTranslation } from "react-i18next"

import MetasPage from "../../../components/Taiyoro/MetaPage"
import { MetaType } from "../../../models/Taiyoro/Meta/MetaType"
import { fetchCasters, createCaster, deleteCaster } from "../../../services/Taiyoro/casters"

const MetaCasterPage = () => {
  const { t } = useTranslation("taiyoro")

  return (
    <MetasPage
      title={t("meta.pageTitles.casters")}
      fetchFunc={fetchCasters}
      addNewFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createCaster(name, nameJa, sortJa, primaryImage, id)}
      updateFunc={(
        name,
        nameJa,
        sortJa,
        isGameDeveloper,
        primaryImage,
        secondaryImage,
        boxArtImage,
        latitude,
        longitude,
        team,
        teamOrganizationId,
        hashtag,
        gameId,
        urlSlug,
        quantifiable,
        homepageUrl,
        isGenre,
        id
      ) => createCaster(name, nameJa, sortJa, primaryImage, id)}
      deleteFunc={deleteCaster}
      primaryImageFieldsAndRatio={4 / 5}
      metaType={MetaType.CASTER}
    />
  )
}

export default MetaCasterPage
