import React, { useState, useEffect, useContext } from "react"

import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DialogContent, DialogContentText, DialogActions, Stack, Button, Box } from "@mui/material/"
import LoadingBox from "components/LoadingBox"
import { DataRecoveryContext, RecoveryStep } from "contexts/data-recovery"
import { useTranslation } from "react-i18next"
import { fetchRecoverySuggestions } from "services/Taiyoro/dataCollection"
import type { DataCollectionResult } from "services/Taiyoro/dataCollection"

import RecoverElement from "../../RecoverElement"
import StreamResults from "../../StreamResults"
import { StyledLink } from "../../styles"

const Suggestions = () => {
  const { t } = useTranslation(["taiyoro", "common"])

  const [loading, setLoading] = useState(true)
  const [streamSuggestions, setStreamSuggestions] = useState<Array<DataCollectionResult>>([])
  const {
    selectedEventDate,
    selectedStreams,
    setSelectedStreams,
    handleNewRecover,
    setError,
    setStep,
    resetDialog,
    recoveryLoading,
  } = useContext(DataRecoveryContext)

  const noSuggestions = streamSuggestions.length === 0

  useEffect(() => {
    setLoading(true)
    setSelectedStreams([])
    fetchRecoverySuggestions(selectedEventDate.id)
      .then((fetchedResponse) => {
        if (fetchedResponse && fetchedResponse.length) {
          setStreamSuggestions(fetchedResponse)
        }
      })
      .catch(() => {
        setError(true)
        resetDialog()
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {loading && (
        <DialogContent sx={{ mb: 3 }}>
          <LoadingBox />
        </DialogContent>
      )}

      {!loading && (
        <>
          <DialogContent>
            <DialogContentText sx={{ color: "text.primary" }}>
              {t("dialogs.recover.suggestionsResults")}
            </DialogContentText>
            <DialogContentText sx={{ pt: 3, pb: 2, color: "text.primary" }}>
              {t("dialogs.recover.selectStream")}
            </DialogContentText>
            <StreamResults>
              {noSuggestions && (
                <DialogContentText sx={{ p: 3, color: "text.primary" }}>
                  {t("dialogs.recover.noSuggestions")}
                </DialogContentText>
              )}
              {!noSuggestions &&
                streamSuggestions.map((stream) => (
                  <RecoverElement
                    data={stream}
                    key={stream.id}
                  />
                ))}
            </StreamResults>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: 1 }}
            >
              {noSuggestions && (
                <>
                  <StyledLink onClick={() => setStep(RecoveryStep.CHANNEL_FLOW)}>
                    {t("dialogs.recover.searchByChannel")}
                  </StyledLink>
                  <Stack
                    direction="row"
                    alignItems="center"
                  >
                    <StyledLink
                      disabled={recoveryLoading}
                      onClick={() => setStep(RecoveryStep.INITIAL)}
                    >
                      {t("common:actions.back")}
                    </StyledLink>
                    <StyledLink onClick={() => setStep(RecoveryStep.VOD_FLOW)}>
                      {t("dialogs.recover.searchByVod")}
                    </StyledLink>
                  </Stack>
                </>
              )}
              {!noSuggestions && (
                <>
                  <StyledLink
                    disabled={recoveryLoading}
                    onClick={() => setStep(RecoveryStep.VOD_FLOW)}
                  >
                    {t("dialogs.recover.notSuggested")}
                  </StyledLink>
                  <Stack
                    direction="row"
                    alignItems="center"
                  >
                    <StyledLink
                      disabled={recoveryLoading}
                      onClick={() => setStep(RecoveryStep.INITIAL)}
                    >
                      {t("common:actions.back")}
                    </StyledLink>
                    <Button
                      sx={{ ml: 3 }}
                      onClick={() => setSelectedStreams(streamSuggestions)}
                      color="primary"
                      variant="contained"
                      disabled={recoveryLoading}
                    >
                      {t("dialogs.recover.selectAll")}
                    </Button>
                    <Button
                      sx={{ ml: 3 }}
                      onClick={recoveryLoading ? () => {} : () => handleNewRecover()}
                      color="primary"
                      autoFocus
                      variant="contained"
                      disabled={selectedStreams === undefined || selectedStreams.length === 0 ? true : false}
                    >
                      {recoveryLoading && (
                        <Box
                          component="span"
                          sx={{ pr: 1 }}
                        >
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spinPulse
                          />
                        </Box>
                      )}
                      {t("dialogs.recover.recover")}
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </DialogActions>
        </>
      )}
    </>
  )
}

export default Suggestions
