import React, { useEffect, useState } from "react"

import { faDiscord } from "@fortawesome/free-brands-svg-icons"
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Avatar,
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"

import LoadingBox from "../../../components/LoadingBox"
import { StylePaper } from "../../../components/StyleMaterialUI"
import { SortArrow } from "../../../components/Taiyoro/Analytics/Table/styles"
import type Game from "../../../models/Taiyoro/Meta/Game"
import { fetchDiscordUsages } from "../../../services/Taiyoro/Analytics"
import type { DiscordUsage } from "../../../services/Taiyoro/Analytics"
import { fetchGames } from "../../../services/Taiyoro/games"
import { localisedLabel } from "../../../utils/i18n"

const GUILD_ICON_BASE = "https://cdn.discordapp.com/icons"

const enum SortColumn {
  MEMBER_COUNT,
  DATE_JOINED,
}

const DiscordReport = () => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Array<DiscordUsage>>([])
  const [sortColumn, setSortColumn] = useState<SortColumn>(SortColumn.MEMBER_COUNT)
  const [games, setGames] = useState<Array<Game>>([])
  const { t } = useTranslation("taiyoro")

  const fetchUsages = () => {
    fetchDiscordUsages().then((results) => {
      setResults(results.sort((a, b) => b.memberCount - a.memberCount))
      setLoading(false)
    })
  }

  const load = () => {
    setLoading(true)
    fetchGames().then((games) => {
      setGames(games)
      fetchUsages()
    })
  }

  useEffect(() => {
    load()
    // @ts-ignore
  }, [])

  const changeSort = (sortColumn: SortColumn) => {
    if (sortColumn === SortColumn.MEMBER_COUNT) {
      setSortColumn(SortColumn.MEMBER_COUNT)
      setResults(results.sort((a, b) => b.memberCount - a.memberCount))
    }
    if (sortColumn === SortColumn.DATE_JOINED) {
      setSortColumn(SortColumn.DATE_JOINED)
      setResults(results.sort((a, b) => new Date(b.dateJoined).getTime() - new Date(a.dateJoined).getTime()))
    }
  }

  return (
    <>
      <StylePaper>
        {loading && <LoadingBox />}
        {!loading && results && (
          <>
            <Table sx={{ marginBottom: "24px" }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("discordReport.table.totalServers")}</TableCell>
                  <TableCell>{t("discordReport.table.totalMembers")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{results.length}</TableCell>
                  <TableCell>{results.reduce((prev, curr) => prev + curr.memberCount, 0)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("discordReport.table.id")}</TableCell>
                  <TableCell>{t("discordReport.table.name")}</TableCell>
                  <TableCell
                    onClick={() => changeSort(SortColumn.MEMBER_COUNT)}
                    style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                  >
                    {t("discordReport.table.memberCount")}
                    {sortColumn === SortColumn.MEMBER_COUNT && (
                      <SortArrow>
                        <FontAwesomeIcon
                          color="#f30362"
                          icon={faArrowDown}
                        />
                      </SortArrow>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => changeSort(SortColumn.DATE_JOINED)}
                    style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                  >
                    {t("discordReport.table.dateJoined")}
                    {sortColumn === SortColumn.DATE_JOINED && (
                      <SortArrow>
                        <FontAwesomeIcon
                          color="#f30362"
                          icon={faArrowDown}
                        />
                      </SortArrow>
                    )}
                  </TableCell>
                  <TableCell>{t("discordReport.table.games")}</TableCell>
                  <TableCell>{t("discordReport.table.events")}</TableCell>
                  <TableCell>{t("discordReport.table.teams")}</TableCell>
                  <TableCell>{t("discordReport.table.favorites")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={result.guildId}>
                    <TableCell>{result.guildId}</TableCell>
                    <TableCell>
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Avatar src={`${GUILD_ICON_BASE}/${result.guildId}/${result.icon}.png`}>
                            <FontAwesomeIcon icon={faDiscord} />
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Box ml="6px">{result.name}</Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{result.memberCount}</TableCell>
                    <TableCell>
                      {moment.utc(result.dateJoined).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm")}
                    </TableCell>
                    <TableCell>
                      {result.syncAllGames === 0 ? (
                        <Tooltip
                          title={result.gameIds
                            .map((gameId) => localisedLabel(games.find((game) => game.id === gameId)))
                            .join(", ")}
                        >
                          <Box>
                            <Link>{result.gameIds.length}</Link>
                          </Box>
                        </Tooltip>
                      ) : (
                        <Typography>{t("discordReport.table.all")}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {result.syncAllEvents === 0 ? result.eventIds.length : t("discordReport.table.all")}
                    </TableCell>
                    <TableCell>
                      {result.syncAllTeams === 0 ? result.teamIds.length : t("discordReport.table.all")}
                    </TableCell>
                    <TableCell>{result.favoritesUserIds.length}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </StylePaper>
    </>
  )
}

export default DiscordReport
