import { Stack, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  value: { name: string; nameJa: string }
  updateValue: (value: string) => void
  updateValueJa: (value: string) => void
  updateStatus?: () => void
}

export const NameControl = ({ value, updateValue, updateValueJa, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  return (
    <Stack gap={2}>
      <TextField
        label={t("eventPickem.daily.modal.groupNameJa")}
        value={value.nameJa}
        onChange={(event) => {
          updateValueJa(event.target.value)
          updateStatus?.()
        }}
        fullWidth
      />
      <TextField
        label={t("eventPickem.daily.modal.groupNameEn")}
        value={value.name}
        onChange={(event) => {
          updateValue(event.target.value)
          updateStatus?.()
        }}
        fullWidth
      />
    </Stack>
  )
}
