import { Box, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  value: number
  updateValue: (value: number) => void
  updateStatus?: () => void
}

export const RewardControl = ({ value, updateValue, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  return (
    <Box>
      <TextField
        label={t("eventPickem.daily.modal.reward")}
        value={value}
        type="number"
        onChange={(event) => {
          const value = Number(event.target.value)
          if (!isNaN(value) && value >= 0) {
            updateValue(value)
            updateStatus?.()
          }
        }}
        fullWidth
      />
    </Box>
  )
}
