import { styled, Box } from "@mui/material"
import { TableRow } from "@mui/material"
import TableCell from "@mui/material/TableCell"

export const Root = styled(TableRow)`
  padding: 0 !important;
`
export const Container = styled(TableCell)`
  padding: 0 !important;
`

export const Title = styled(TableCell)`
  font-size: 12px !important;
  border-bottom: none !important;
  text-align: ${(props) => props.align || "left"};
  padding-top: 0 !important;
  padding-bottom: 8px !important;
`

export const Cell = styled(TableCell)`
  padding: 2px 16px !important;
  text-align: ${(props) => props.align || "left"};
`

export const CategoryCell = styled(TableCell)`
  padding: 2px 16px !important;
  text-align: right !important;
  font-weight: bold !important;
  line-height: 2 !important;
  letter-spacing: 0.9px !important;
`
export const FooterCellEmpty = styled(TableCell)`
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  border-bottom: 0 !important;
  text-align: ${(props) => props.align || "left"};
`

export const FooterCell = styled(FooterCellEmpty)`
  border-top: 2px solid #000 !important;
`

export const Total = styled(Box)`
  font-weight: bold;
`
