import React from "react"

import { Grid } from "@mui/material"

import { StyleTypography, StyleCssBaseline } from "../components/StyleMaterialUI"

const NotFound = () => {
  return (
    <React.Fragment>
      <StyleCssBaseline />
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
      >
        <Grid
          item
          xs={8}
        >
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
            >
              <StyleTypography
                align="center"
                variant="h5"
              >
                PLAYBRAIN DASHBOARD
              </StyleTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <StyleTypography
                align="center"
                variant="h5"
              >
                404: PAGE NOT FOUND
              </StyleTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default NotFound
