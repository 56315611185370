export const fetchCurrencies = (): string[] => {
  return shortIds
}

const countries = [
  { id: "JPY", label: "JPY (Japan Yen)" },
  { id: "USD", label: "USD (United States Dollar)" },
  { id: "AED", label: "AED (United Arab Emirates Dirham)" },
  { id: "AFN", label: "AFN (Afghanistan Afghani)" },
  { id: "ALL", label: "ALL (Albania Lek)" },
  { id: "AMD", label: "AMD (Armenia Dram)" },
  { id: "ANG", label: "ANG (Netherlands Antilles Guilder)" },
  { id: "AOA", label: "AOA (Angola Kwanza)" },
  { id: "ARS", label: "ARS (Argentina Peso)" },
  { id: "AUD", label: "AUD (Australia Dollar)" },
  { id: "AWG", label: "AWG (Aruba Guilder)" },
  { id: "AZN", label: "AZN (Azerbaijan Manat)" },
  { id: "BAM", label: "BAM (Bosnia and Herzegovina Convertible Mark)" },
  { id: "BBD", label: "BBD (Barbados Dollar)" },
  { id: "BDT", label: "BDT (Bangladesh Taka)" },
  { id: "BGN", label: "BGN (Bulgaria Lev)" },
  { id: "BHD", label: "BHD (Bahrain Dinar)" },
  { id: "BIF", label: "BIF (Burundi Franc)" },
  { id: "BMD", label: "BMD (Bermuda Dollar)" },
  { id: "BND", label: "BND (Brunei Darussalam Dollar)" },
  { id: "BOB", label: "BOB (Bolivia Bolíviano)" },
  { id: "BRL", label: "BRL (Brazil Real)" },
  { id: "BSD", label: "BSD (Bahamas Dollar)" },
  { id: "BTN", label: "BTN (Bhutan Ngultrum)" },
  { id: "BWP", label: "BWP (Botswana Pula)" },
  { id: "BYN", label: "BYN (Belarus Ruble)" },
  { id: "BZD", label: "BZD (Belize Dollar)" },
  { id: "CAD", label: "CAD (Canada Dollar)" },
  { id: "CDF", label: "CDF (Congo/Kinshasa Franc)" },
  { id: "CHF", label: "CHF (Switzerland Franc)" },
  { id: "CLP", label: "CLP (Chile Peso)" },
  { id: "CNY", label: "CNY (China Yuan Renminbi)" },
  { id: "COP", label: "COP (Colombia Peso)" },
  { id: "CRC", label: "CRC (Costa Rica Colon)" },
  { id: "CUC", label: "CUC (Cuba Convertible Peso)" },
  { id: "CUP", label: "CUP (Cuba Peso)" },
  { id: "CVE", label: "CVE (Cape Verde Escudo)" },
  { id: "CZK", label: "CZK (Czech Republic Koruna)" },
  { id: "DJF", label: "DJF (Djibouti Franc)" },
  { id: "DKK", label: "DKK (Denmark Krone)" },
  { id: "DOP", label: "DOP (Dominican Republic Peso)" },
  { id: "DZD", label: "DZD (Algeria Dinar)" },
  { id: "EGP", label: "EGP (Egypt Pound)" },
  { id: "ERN", label: "ERN (Eritrea Nakfa)" },
  { id: "ETB", label: "ETB (Ethiopia Birr)" },
  { id: "EUR", label: "EUR (Euro Member Countries)" },
  { id: "FJD", label: "FJD (Fiji Dollar)" },
  { id: "FKP", label: "FKP (Falkland Islands (Malvinas) Pound)" },
  { id: "GBP", label: "GBP (United Kingdom Pound)" },
  { id: "GEL", label: "GEL (Georgia Lari)" },
  { id: "GGP", label: "GGP (Guernsey Pound)" },
  { id: "GHS", label: "GHS (Ghana Cedi)" },
  { id: "GIP", label: "GIP (Gibraltar Pound)" },
  { id: "GMD", label: "GMD (Gambia Dalasi)" },
  { id: "GNF", label: "GNF (Guinea Franc)" },
  { id: "GTQ", label: "GTQ (Guatemala Quetzal)" },
  { id: "GYD", label: "GYD (Guyana Dollar)" },
  { id: "HKD", label: "HKD (Hong Kong Dollar)" },
  { id: "HNL", label: "HNL (Honduras Lempira)" },
  { id: "HRK", label: "HRK (Croatia Kuna)" },
  { id: "HTG", label: "HTG (Haiti Gourde)" },
  { id: "HUF", label: "HUF (Hungary Forint)" },
  { id: "IDR", label: "IDR (Indonesia Rupiah)" },
  { id: "ILS", label: "ILS (Israel Shekel)" },
  { id: "IMP", label: "IMP (Isle of Man Pound)" },
  { id: "INR", label: "INR (India Rupee)" },
  { id: "IQD", label: "IQD (Iraq Dinar)" },
  { id: "IRR", label: "IRR (Iran Rial)" },
  { id: "ISK", label: "ISK (Iceland Krona)" },
  { id: "JEP", label: "JEP (Jersey Pound)" },
  { id: "JMD", label: "JMD (Jamaica Dollar)" },
  { id: "JOD", label: "JOD (Jordan Dinar)" },
  { id: "KES", label: "KES (Kenya Shilling)" },
  { id: "KGS", label: "KGS (Kyrgyzstan Som)" },
  { id: "KHR", label: "KHR (Cambodia Riel)" },
  { id: "KMF", label: "KMF (Comorian Franc)" },
  { id: "KPW", label: "KPW (Korea (North) Won)" },
  { id: "KRW", label: "KRW (Korea (South) Won)" },
  { id: "KWD", label: "KWD (Kuwait Dinar)" },
  { id: "KYD", label: "KYD (Cayman Islands Dollar)" },
  { id: "KZT", label: "KZT (Kazakhstan Tenge)" },
  { id: "LAK", label: "LAK (Laos Kip)" },
  { id: "LBP", label: "LBP (Lebanon Pound)" },
  { id: "LKR", label: "LKR (Sri Lanka Rupee)" },
  { id: "LRD", label: "LRD (Liberia Dollar)" },
  { id: "LSL", label: "LSL (Lesotho Loti)" },
  { id: "LYD", label: "LYD (Libya Dinar)" },
  { id: "MAD", label: "MAD (Morocco Dirham)" },
  { id: "MDL", label: "MDL (Moldova Leu)" },
  { id: "MGA", label: "MGA (Madagascar Ariary)" },
  { id: "MKD", label: "MKD (Macedonia Denar)" },
  { id: "MMK", label: "MMK (Myanmar (Burma) Kyat)" },
  { id: "MNT", label: "MNT (Mongolia Tughrik)" },
  { id: "MOP", label: "MOP (Macau Pataca)" },
  { id: "MRU", label: "MRU (Mauritania Ouguiya)" },
  { id: "MUR", label: "MUR (Mauritius Rupee)" },
  { id: "MVR", label: "MVR (Maldives (Maldive Islands) Rufiyaa)" },
  { id: "MWK", label: "MWK (Malawi Kwacha)" },
  { id: "MXN", label: "MXN (Mexico Peso)" },
  { id: "MYR", label: "MYR (Malaysia Ringgit)" },
  { id: "MZN", label: "MZN (Mozambique Metical)" },
  { id: "NAD", label: "NAD (Namibia Dollar)" },
  { id: "NGN", label: "NGN (Nigeria Naira)" },
  { id: "NIO", label: "NIO (Nicaragua Cordoba)" },
  { id: "NOK", label: "NOK (Norway Krone)" },
  { id: "NPR", label: "NPR (Nepal Rupee)" },
  { id: "NZD", label: "NZD (New Zealand Dollar)" },
  { id: "OMR", label: "OMR (Oman Rial)" },
  { id: "PAB", label: "PAB (Panama Balboa)" },
  { id: "PEN", label: "PEN (Peru Sol)" },
  { id: "PGK", label: "PGK (Papua New Guinea Kina)" },
  { id: "PHP", label: "PHP (Philippines Peso)" },
  { id: "PKR", label: "PKR (Pakistan Rupee)" },
  { id: "PLN", label: "PLN (Poland Zloty)" },
  { id: "PYG", label: "PYG (Paraguay Guarani)" },
  { id: "QAR", label: "QAR (Qatar Riyal)" },
  { id: "RON", label: "RON (Romania Leu)" },
  { id: "RSD", label: "RSD (Serbia Dinar)" },
  { id: "RUB", label: "RUB (Russia Ruble)" },
  { id: "RWF", label: "RWF (Rwanda Franc)" },
  { id: "SAR", label: "SAR (Saudi Arabia Riyal)" },
  { id: "SBD", label: "SBD (Solomon Islands Dollar)" },
  { id: "SCR", label: "SCR (Seychelles Rupee)" },
  { id: "SDG", label: "SDG (Sudan Pound)" },
  { id: "SEK", label: "SEK (Sweden Krona)" },
  { id: "SGD", label: "SGD (Singapore Dollar)" },
  { id: "SHP", label: "SHP (Saint Helena Pound)" },
  { id: "SLL", label: "SLL (Sierra Leone Leone)" },
  { id: "SOS", label: "SOS (Somalia Shilling)" },
  { id: "SPL", label: "SPL (Seborga Luigino)" },
  { id: "SRD", label: "SRD (Suriname Dollar)" },
  { id: "STN", label: "STN (São Tomé and Príncipe Dobra)" },
  { id: "SVC", label: "SVC (El Salvador Colon)" },
  { id: "SYP", label: "SYP (Syria Pound)" },
  { id: "SZL", label: "SZL (eSwatini Lilangeni)" },
  { id: "THB", label: "THB (Thailand Baht)" },
  { id: "TJS", label: "TJS (Tajikistan Somoni)" },
  { id: "TMT", label: "TMT (Turkmenistan Manat)" },
  { id: "TND", label: "TND (Tunisia Dinar)" },
  { id: "TOP", label: "TOP (Tonga Pa'anga)" },
  { id: "TRY", label: "TRY (Turkey Lira)" },
  { id: "TTD", label: "TTD (Trinidad and Tobago Dollar)" },
  { id: "TVD", label: "TVD (Tuvalu Dollar)" },
  { id: "TWD", label: "TWD (Taiwan New Dollar)" },
  { id: "TZS", label: "TZS (Tanzania Shilling)" },
  { id: "UAH", label: "UAH (Ukraine Hryvnia)" },
  { id: "UGX", label: "UGX (Uganda Shilling)" },
  { id: "UYU", label: "UYU (Uruguay Peso)" },
  { id: "UZS", label: "UZS (Uzbekistan Som)" },
  { id: "VEF", label: "VEF (Venezuela Bolívar)" },
  { id: "VND", label: "VND (Viet Nam Dong)" },
  { id: "VUV", label: "VUV (Vanuatu Vatu)" },
  { id: "WST", label: "WST (Samoa Tala)" },
  {
    id: "XAF",
    label: "XAF (Communauté Financière Africaine (BEAC) CFA Franc BEAC)",
  },
  { id: "XCD", label: "XCD (East Caribbean Dollar)" },
  {
    id: "XDR",
    label: "XDR (International Monetary Fund (IMF) Special Drawing Rights)",
  },
  { id: "XOF", label: "XOF (Communauté Financière Africaine (BCEAO) Franc)" },
  { id: "XPF", label: "XPF (Comptoirs Français du Pacifique (CFP) Franc)" },
  { id: "YER", label: "YER (Yemen Rial)" },
  { id: "ZAR", label: "ZAR (South Africa Rand)" },
  { id: "ZMW", label: "ZMW (Zambia Kwacha)" },
  { id: "ZWD", label: "ZWD (Zimbabwe Dollar)" },
]

const shortIds = countries.map((currency) => currency.id)
