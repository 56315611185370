import React from "react"

import { fetchEvent } from "../../../services/Taiyoro/event"
import { fetchPlatforms } from "../../../services/Taiyoro/platforms"
import { RenderGraph, RenderLegend } from "./metricsUtil"

export default (props) => {
  const [eventData, setEventData] = React.useState(null)
  const [dateLabels, setDateLabels] = React.useState({})
  React.useEffect(() => {
    if (props.match.params.eventId) {
      fetchEvent(props.match.params.eventId).then((data) => {
        setEventData(data)
        const dateLabelMap = {}
        if (data) {
          const dates = data ? data.dates : null
          if (dates) {
            for (let i = 0; i < dates.length; i++) {
              const date = dates[i]
              dateLabelMap[date.startTime.tz("Asia/Tokyo").format("MM/DD")] = date
            }
          }
        }
        setDateLabels(dateLabelMap)
      })
    }
  }, [props.match.params.eventId])
  const [platforms, setPlatforms] = React.useState({})
  React.useEffect(() => {
    if (props.match.params.eventId) {
      fetchPlatforms().then((data) => {
        setPlatforms(
          data.reduce((acc, p) => {
            if (eventData) {
              const dates = eventData ? eventData.dates : null
              if (dates) {
                for (let i = 0; i < dates.length; i++) {
                  const date = dates[i]
                  for (let j = 0; j < date.platforms.length; j++) {
                    const platform = date.platforms[j]
                    if (
                      !platform.dataCollectionErrored &&
                      !platform.isVod &&
                      (platform.viewsLiveConcurrentPeak || platform.viewsLiveConcurrentPeakManual)
                    ) {
                      if (platform.platform === p.id) {
                        acc[p.id] = p
                        break
                      }
                    }
                  }
                }
              }
            }
            return acc
          }, {})
        )
      })
    }
  }, [eventData])
  const platformsArr: any[] = Object.values(platforms)
  const platformIds = platformsArr.map((p) => p.id)
  const eventDates = eventData ? eventData.dates : null
  // A react-chart hyper-responsively and continuously fills the available
  // space of its parent element automatically
  const puppeteerDelaySeconds = 10
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>{eventData && eventData.name}</h1>
      <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>{"Minutes Watched"}</h1>
      <div style={{ display: "flex", margin: "auto" }}>
        {RenderGraph(
          eventDates,
          platformIds,
          platforms,
          dateLabels,
          "minutes",
          "viewsLiveMinutesWatched",
          "viewsLiveMinutesWatchedManual"
        )}
        {RenderLegend(platformsArr)}
      </div>
      <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>{"Average Concurrents"}</h1>
      <div style={{ display: "flex", margin: "auto" }}>
        {RenderGraph(
          eventDates,
          platformIds,
          platforms,
          dateLabels,
          "ACV",
          "viewsLiveConcurrentAverage",
          "viewsLiveConcurrentAverageManual"
        )}
        {RenderLegend(platformsArr)}
      </div>
      <h1 style={{ width: "100%", textAlign: "center", margin: "auto" }}>{"Peak Concurrents"}</h1>
      <div style={{ display: "flex", margin: "auto" }}>
        {RenderGraph(
          eventDates,
          platformIds,
          platforms,
          dateLabels,
          "PCV",
          "viewsLiveConcurrentPeak",
          "viewsLiveConcurrentPeakManual"
        )}
        {RenderLegend(platformsArr)}
      </div>
    </div>
  )
}
